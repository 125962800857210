import React from 'react';
import { Box, BoxProps, OSKIcon, OSKThemeType, Typography } from 'oskcomponents';
import { Capture, Sensor } from 'oskcore';
import CSS from 'csstype';
import styled, { useTheme } from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { date_format_long, utc_time_format_long } from '~/utils';
import { dequeueFile, enqueueFile, toggleFileIntent } from '~/redux/modules/data/cart';
import store, { AppDispatch, RootState } from '~/redux/store';
import { ImgTiff, CloudCoverageChip } from '~/atoms';
import { FaEye } from 'react-icons/fa';
import gdux, { GDUX_HIGHLIGHT_ADD, GDUX_HIGHLIGHT_REMOVE, GDUX_SELECT_ADD, GDUX_SELECT_REMOVE } from '~/gdux';

export type CaptureItemProps = {
    /** The fileId (capture.id) this item represents */
    fileId: string | number;
    /** The capture to populate this item with */
    data: Capture;
    /** A style to override the container with */
    style?: CSS.Properties;
    /** Whether this item is actively selected or not */
    selected?: boolean;
    /** Whether to hide the selection functionality or not */
    hideIcon?: boolean;
    /** If true, all click actions will only affect the DownloadIntent.skip flag */
    intentOnly?: boolean;
    /** A list of sensors */
    sensors: Sensor[];
    /** Optional click event to invoke when the item is selected */
    onClick?: (capture: Capture) => void;
    /** Optional event that's invoked when the thumbnail is clicked. */
    onThumbnailClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, capture: Capture) => void;
    /** The method to invoke when a footprint is clicked */
    onFootprintSelected?: (file: Capture) => void;
} & Omit<Omit<BoxProps, 'ref'>, 'onClick'>;

export const CaptureItem = ({
    fileId,
    intentOnly,
    selected,
    style,
    data,
    hideIcon,
    sensors,
    onClick,
    onThumbnailClick,
    onFootprintSelected,
    ...props
}: CaptureItemProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const sensorMap = sensors.reduce((acc, cur) => {
        acc[cur.osk_id] = cur.osk_sensor_name;
        return acc;
    }, {} as Record<number, string>);

    return (
        <Box
            style={{
                justifyContent: 'space-around',
                padding: '8px',
                paddingLeft: '16px',
                paddingRight: '8px',
                borderLeft: `3px solid ${selected ? 'rgba(249, 102, 33, 0.7)' : 'transparent'}`,
                borderTop: `1px solid ${theme.colors.black200}`,
                ...style,
            }}
            onMouseEnter={() => gdux.trigger(GDUX_HIGHLIGHT_ADD, fileId.toString())}
            onMouseLeave={() => gdux.trigger(GDUX_HIGHLIGHT_REMOVE, fileId.toString())}
            onClick={function (evt) {
                evt.stopPropagation();
                evt.preventDefault();

                if (intentOnly) {
                    dispatch(toggleFileIntent(`${data.id}`));
                } else {
                    onFootprintSelected && onFootprintSelected(data);
                }

                onClick && onClick(data);
            }}
            grow
            {...props}
            center="vertical"
        >
            <CaptureItemThumbnail
                thumbnail={data.preview_artifact}
                {...(onThumbnailClick && { onClick: (e: any) => onThumbnailClick(e, data) })}
            />
            <Box
                style={{
                    paddingLeft: '10px',
                    justifyContent: 'space-around',
                    height: '120px',
                }}
                col
            >
                <Typography variant="heading4">{date_format_long(data.acquisition_time)}</Typography>
                <Box style={{ color: theme.colors.black600 }}>
                    <OSKIcon code="clock" mr={8} />
                    <Typography variant="caption1">{utc_time_format_long(data.acquisition_time)}</Typography>
                </Box>
                <Box style={{ color: theme.colors.black600 }}>
                    <OSKIcon code="ghost-2" mr={8} strokeWidth={1} />
                    <Typography variant="caption1">{sensorMap[data.sensor_id]}</Typography>
                </Box>
                <CloudCoverageChip
                    value={
                        data.cloud_cover_pct === undefined || data.cloud_cover_pct === null
                            ? undefined
                            : Math.round(data.cloud_cover_pct)
                    }
                />
            </Box>
            <Box grow />
            {hideIcon !== true && (
                <Box center="all" w={40} style={{ marginRight: '7px' }}>
                    <OSKIcon fill={theme.colors.black600} code={selected ? 'trash' : 'plus'} />
                </Box>
            )}
        </Box>
    );
};

type ThumbnailProps = {
    /** The thumbnail URL*/
    thumbnail: string | null;
    /** A method that's called when the thumbnail is clicked */
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    /** Styled-component passthrough */
    className?: string;
};
const CaptureItemThumbnail = styled(({ thumbnail, onClick, className, ...props }: ThumbnailProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            col
            className={className}
            onClick={(e) => onClick && onClick(e)}
            style={{ position: 'relative' }}
            {...props}
        >
            <Box
                className="thumbnail-eye"
                style={{
                    position: 'absolute',
                    backgroundColor: theme.colors.gray0a,
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    borderRadius: '7px',
                    fontSize: '2em',
                }}
                center="all"
            >
                <FaEye fill="white" />
            </Box>
            {thumbnail && <ImgTiff src={thumbnail} width={125} height={125} loading={'lazy'} />}
            {!thumbnail && (
                <img
                    src="/images/no-image.png"
                    title="There is no thumbnail generated for this capture"
                    width={125}
                    height={125}
                    loading="lazy"
                />
            )}
        </Box>
    );
})`
    .thumbnail-eye {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }
    ${(props: any) =>
        props.onClick &&
        `.thumbnail-eye:hover {
            opacity: 1;
        }`}
`;

const mapStateToProps = (state: RootState, ownProps: Partial<CaptureItemProps>) => {
    const { fileId } = ownProps;
    const enqueuedItem = state.data.cart.enqueued[fileId ?? '-1'];

    return {
        selected: enqueuedItem ? enqueuedItem.skip !== true : false,
        data: state.data.search.resultMap[fileId ?? '-1'],
        sensors: state.osk.sensors,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onFootprintSelected: (file: Capture) => {
            const { id: fileId, task_id: taskId } = file;
            if (store.getState().data.cart.enqueued[fileId]) {
                dispatch(dequeueFile(fileId));
                gdux.trigger(GDUX_SELECT_REMOVE, file.id);
            } else {
                dispatch(enqueueFile({ fileId, taskId }, file));
                gdux.trigger(GDUX_SELECT_ADD, file.id);
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaptureItem);
