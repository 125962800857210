import { format, utcToZonedTime } from 'date-fns-tz';
import { LatLng } from 'leaflet';
import { fround, isDev, ProgramUser } from 'oskcore';
import { PROGRAM_ID_LOCAL_STORAGE_KEY, PROGRAM_PROFILE_LOCAL_STORAGE_KEY } from './redux/modules/session';
import { OSKProfile } from 'oskcore/src/templates/OSKAppProvider';
import { SpectraGLEngine } from 'spectra-gl';

export type DateLike = string | Date | number | null | undefined;

// Determine the google API key for map/geocoding/etc access
// It is injected at build time through an environment variable
// eslint-disable-next-line
let googleApiKey = '';
try {
    eval(`googleApiKey = import.meta.env.VITE_GOOGLE_API_KEY`);
} catch (ex) {
} finally {
    if (googleApiKey.length === 0) {
        // Developer account
        googleApiKey = 'AIzaSyD6Yo5ufGZXc22NYXaEZVQnfskH2UbKbZ0';
    }
}

export function getGoogleApiKey() {
    return googleApiKey;
}

/**
 * This method will take an HTMLElement and a ref.current element and
 * then walk up the DOM, looking for a match. Effectively, you can use this
 * to see if the evt.target is a sub-tree node of a ref. If it is, this
 * implies that the user has clicked inside whatever ref.current is.
 *
 * A good use case for this is - you open a pop-up window and want to
 * then listen for any click on the page. If the click is outside
 * the pop-up window, you know you can dismiss it.
 */
export function clickedInside(el: HTMLElement | null, ref: any): boolean {
    if (el === undefined || el === null) return false;
    if (el === ref) return true;
    return clickedInside(el.parentNode as HTMLElement, ref);
}

/// This method takes a string in the format of yyyy-MM-dd
/// and returns a timezone-neutralized date object
export function date_only(datelike?: string | null) {
    if (datelike === null || datelike === undefined) return null;
    const date = new Date(datelike);
    const dateWithoutTz = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
    return dateWithoutTz;
}

/* This method takes an object that *can* be a date and
    formats it MM-DD-YYY. */
export function date_format(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return format(date, 'M/d/yyyy');
}

/* This method takes an object that *can* be a date and
    formats it Mon DD YYYY. */
export function date_format_long(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return format(date, 'MMM d yyyy');
}

/* This method takes an object that *can* be a date and
    formats it Month DD, YYYY. */
export function date_format_longer(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return format(date, 'MMMM d, yyyy');
}

/**
 * This method takes an object that *can* be a date and
 * formats the time as HH:MM AM/PM.
 * @param datelike An object that represents a date.
 * @param noForcedZero Whether or not to add a 0 in front of single-digit hours.
 * @returns A formatted string representing the time of the given datelike object.
 */
export function time_format(datelike?: DateLike, noForcedZero?: boolean) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return format(date, noForcedZero ? 'h:mm a' : 'hh:mm a');
}

/* This method takes an  object that *can* be a date and
    formats it M/d/yyy HH:mm:ss 'UTC'*/
export function utc_date_format(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return `${format(date, 'M/d/yyyy HH:mm:ss')} UTC`;
}

/* This method takes an  object that *can* be a date and
    formats it HH:mm 'UTC'*/
export function utc_time_format(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return `${format(date, 'HH:mm')} UTC`;
}

/* This method takes an  object that *can* be a date and
    formats it HH:mm:ss 'UTC'*/
export function utc_time_format_long(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return `${format(date, 'HH:mm:ss')} UTC`;
}

const DEFAULT_MAP_ZOOM = 13;
export function LatLngToPosition(pos?: LatLng) {
    if (!pos) return '';

    return `@${pos.lat ?? 0},${pos.lng ?? 0},${pos.alt ?? DEFAULT_MAP_ZOOM}`;
}

/// Given an input string, try to find if it starts with a GPS coordinate
/// And if it does, return that coordinate.
export function parseCoordinates(input: string): LatLng | null {
    let parts = input.split(','); // First try comma
    if (parts.length === 1) parts = input.split(' '); // Then try space
    if (parts.length >= 2) {
        // Try to parse each side.
        const lat = parseCoordinateFloat(parts[0]);
        const lng = parseCoordinateFloat(parts[1]);

        if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
            return new LatLng(lat, lng);
        }
    }
    return null;
}

/// Given an input latitude or longitude, parse out its format and return the number.
export function parseCoordinateFloat(input: string): number | null {
    if (!input) return null;
    const cleanInput = input.replace(' ', '').replace(',', '').toUpperCase();

    /* Keep a running cache of the input string until it hits a market denoting
        which bucket to put it in. */
    let deg = 0,
        min = 0,
        sec = 0,
        flip = false;
    let cache = '';
    for (let i = 0; i < cleanInput.length; i++) {
        if (cleanInput[i] === '°') {
            // degrees
            deg = parseFloat(cache);
            cache = '';
            continue;
        } else if ("’'".includes(cleanInput[i])) {
            // minutes
            min = parseFloat(cache);
            cache = '';
            continue;
        } else if (cleanInput[i] === '"') {
            // seconds
            sec = parseFloat(cache);
            cache = '';
            continue;
        } else if ('SW'.includes(cleanInput[i])) {
            // flip
            flip = true;
            continue;
        }

        cache += cleanInput[i];
    }

    // If there's still unattended input, assume it's the next-least significant value
    if (cache && !isNaN(parseFloat(cache))) {
        if (deg) {
            if (min) {
                sec = parseFloat(cache);
            } else {
                min = parseFloat(cache);
            }
        } else {
            deg = parseFloat(cache);
        }
    }

    /* 
        If the degrees at this point are greater than the threshold,
        it's one of the following formats:
        - DDDMMSS.SSS
        - DDMM.MMM
        - DDMMSS
    */
    if (Math.abs(deg) > 180) {
        const dddmmss_sss = /(\d{2,3})(\d{2})(\d{2}\.\d+)/g;
        const dddmm_mmm = /(\d{2,3})(\d{2}\.\d+)/g;
        const ddmmss = /(\d{2})(\d{2})(\d{2})/g;

        let parts = Array.from(cleanInput.matchAll(dddmmss_sss))[0];
        if (parts && parts.length === 4) {
            deg = parseFloat(parts[1]);
            min = parseFloat(parts[2]);
            sec = parseFloat(parts[3]);
        } else {
            parts = Array.from(cleanInput.matchAll(dddmm_mmm))[0];
            if (parts && parts.length === 3) {
                deg = parseFloat(parts[1]);
                min = parseFloat(parts[2]);
            } else {
                parts = Array.from(cleanInput.matchAll(ddmmss))[0];
                if (parts && parts.length === 4) {
                    deg = parseFloat(parts[1]);
                    min = parseFloat(parts[2]);
                    sec = parseFloat(parts[3]);
                }
            }
        }
        flip = 'SW'.includes(cleanInput.charAt(cleanInput.length - 1));
    }

    // Convert minutes and seconds into degrees
    if (min) {
        if (Math.sign(min) !== Math.sign(deg)) min *= -1;
        deg += min / 60;
    }
    if (sec) {
        if (Math.sign(sec) !== Math.sign(deg)) sec *= -1;
        deg += sec / 3600;
    }

    // Flip the sign if needed
    if (flip) deg = -deg;

    return deg;
}

export function coord_format(coords: number[]): string {
    return `${fround(coords[1], 3)}, ${fround(coords[0], 3)}`;
}

// TODO: Refactor this ASAP
export function getProgramId() {
    // First, check the url
    const urlPaths = window.location.pathname.split('/');
    // NOTE: The -1 here is because we do i and i+1. This prevents NPE.
    for (let i = 0; i < urlPaths.length - 1; i++) {
        const firstTerm = urlPaths[i];
        const secondTerm = parseInt(`${urlPaths[i + 1]}`);

        // If we encounte program/number then we can safely assume
        // that number is the programId because all of our routes
        // (at the time of this writing) follow such a pattern.
        if (firstTerm.toLowerCase() === 'program' && !isNaN(secondTerm)) {
            localStorage.setItem(PROGRAM_ID_LOCAL_STORAGE_KEY, `${secondTerm}`);
            return secondTerm;
        }
    }

    const activeProgramId = localStorage.getItem(PROGRAM_ID_LOCAL_STORAGE_KEY);
    if (activeProgramId !== null) {
        return parseInt(activeProgramId);
    } else {
        return -1;
    }
}

/// This method will reach into the cache and return the profile associated
/// with the user in relation to their currently active program.
export function getCachedProgramProfile() {
    const json = sessionStorage.getItem(PROGRAM_PROFILE_LOCAL_STORAGE_KEY);
    if (json) {
        return JSON.parse(json) as ProgramUser;
    } else {
        return null;
    }
}

export function user_fullname(user: OSKProfile) {
    return `${user.first_name} ${user.last_name}`;
}

export function parseS3(uri: string) {
    // First, detect the protocol
    uri = uri.replace('s3://', '');
    uri = uri.replace('S3://', '');

    // Detect the bucket name
    const s3Bucket = uri.substring(0, uri.includes('/') ? uri.indexOf('/') : uri.length);
    const s3Folder = uri.substring(s3Bucket.length + 1) ?? '';

    // Trim trailing slash.
    return {
        s3Bucket,
        s3Folder,
    };
}

/** Given a parent node, puts all children with a single #text element underneath them
 * in the `nodes` array.
 */
export function getSimpleChildNodes(
    node: Node,
    returnNodes: Array<Node>,
    excludeElementTypes: Array<string>,
    style?: string,
) {
    if (node.childNodes.length > 0) {
        node.childNodes.forEach((child) => getSimpleChildNodes(child, returnNodes, excludeElementTypes, style));
    }

    if (node.childNodes.length === 1 && node.firstChild?.nodeName === '#text') {
        const cNode = node.cloneNode(true) as any;

        if ('style' in cNode) cNode.style = style ? style : undefined;
        if ('class' in cNode) cNode.class = undefined;

        if (!excludeElementTypes || !excludeElementTypes.includes(cNode.nodeName.toLowerCase())) {
            returnNodes.push(cNode);
        }
    }
}

/**
 * Configure localStorage to cache a given programId
 * @param programId The programId to set in local storage
 */
export function setProgramIdCache(programId: number) {
    localStorage.setItem(PROGRAM_ID_LOCAL_STORAGE_KEY, `${programId}`);
}

export type LastApp = 'monitor' | 'data' | null;

/**
 * Set the most recently used app. Note: this method is program-aware
 * @param app The app most recently used
 */
export function setLastAppAccessed(app: LastApp) {
    const key = `last_app_accessed_${getProgramId()}`;

    if (app) {
        localStorage.setItem(key, app);
    } else {
        localStorage.removeItem(key);
    }
}

/**
 * Retrieve the most recently accessed app for the active program
 * @returns The most recently accessed app for the active program
 */
export function getLastAppAccessed(programId: number = getProgramId()): LastApp {
    const key = `last_app_accessed_${programId}`;
    return localStorage.getItem(key) as LastApp;
}

// Create a global instance of spectraGL
export const spectraGLEngine = new SpectraGLEngine();

/**
 * This method will evaluate the uri and our current dev environment.
 * If the uri is a downsampled-product and we are running locally,
 * use the proxy.
 *
 * @param uri The uri of a geotiff to fetch
 */
export function fetchGeotiff(uri: string) {
    // If the uri is a downsampled bucket and we're in local dev, do the thing.
    if (uri.includes('downsampled-processed-products') && isDev()) {
        uri = uri.replace('https://', '');
        uri = uri.replace('http://', '');
        uri = uri.substring(uri.indexOf('/') + 1);
        uri = `/downsampled_products/${uri}`;
    }

    return fetch(uri);
}

/** p - a percentage from 0-1
 *  min - the lower limit of the range to map to
 *  max - the upper limit of the range to map to
 *
 *  e.g. (0.5, 20, 30) = 25
 *       (0.8, 1, 10) = 8
 */
export function remap_to_range(p: number, min: number, max: number) {
    const range = max - min;
    return min + range * p;
}

export function checkLatLngLineCollision(line1: LatLng[], line2: LatLng[]): boolean {
    if (line1.length < 2 || line2.length < 2) return false;

    const uA =
        ((line2[1].lat - line2[0].lat) * (line1[0].lng - line2[0].lng) -
            (line2[1].lng - line2[0].lng) * (line1[0].lat - line2[0].lat)) /
        ((line2[1].lng - line2[0].lng) * (line1[1].lat - line1[0].lat) -
            (line2[1].lat - line2[0].lat) * (line1[1].lng - line1[0].lng));

    const uB =
        ((line1[1].lat - line1[0].lat) * (line1[0].lng - line2[0].lng) -
            (line1[1].lng - line1[0].lng) * (line1[0].lat - line2[0].lat)) /
        ((line2[1].lng - line2[0].lng) * (line1[1].lat - line1[0].lat) -
            (line2[1].lat - line2[0].lat) * (line1[1].lng - line1[0].lng));

    return uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1;
}

export function ensureUUIDFormat(uuid: string) {
    if (!uuid) return '';
    const u = uuid.trim().toLowerCase();

    if (u.length === 32) {
        return `${u.substring(0, 8)}-${u.substring(8, 12)}-${u.substring(12, 16)}-${u.substring(16, 20)}-${u.substring(
            20,
        )}`;
    } else {
        return uuid;
    }
}
