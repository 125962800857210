import { ToggleRouter } from './toggleRouter';

/**
 * This class represents all the feature flag business logic that we will support
 * in Sigma Data. Instead of splatting if conditions throughout the code, strive
 * to create a function in this ToggleDecisions class which is a more generic
 * representation of the feature in question. By adding this extra layer of
 * abstraction, we decouple decision logic from implementation logic.
 */
export class ToggleDecisions {
    router: ToggleRouter;

    constructor(router: ToggleRouter) {
        this.router = router;
    }

    /**
     * Return true if the current user should see program administrator features.
     */
    showProgramAdminFeatures() {
        return this.router.isFeatureSet('program_admin');
    }

    /***
     * Return true if the current user should see internal OSK administrator features.
     */
    showOSKAdminFeatures() {
        return this.router.isFeatureSet('osk_admin');
    }

    /***
     * Return true if the current user should see Sigma Data.
     */
    showSigmaData() {
        return this.router.isFeatureSet('user_can_access_data') || this.router.isFeatureSet('user_can_access_tasking');
    }

    /***
     * Return true if the current user should see Sigma Monitor.
     */
    showSigmaMonitor() {
        return this.router.isFeatureSet('user_can_access_monitor');
    }

    /***
     * Return true if the current user should see Sigma Tools.
     */
    showSigmaTools() {
        return this.router.isFeatureSet('osk_employee');
    }

    /**
     * Return true if the current user should not see maps
     */
    hideMaps() {
        return this.router.isFeatureSet('running_in_test_mode');
    }

    /**
     * Return true if the current user has apps associated with their account.
     */
    hasApps() {
        return this.showSigmaData() || this.showSigmaMonitor() || this.showOSKAdminFeatures() || this.showSigmaTools();
    }

    /**
     * Return true if the current user should have access to tasking
     */
    showTasking() {
        return this.router.isFeatureSet('osk_employee');
    }
}
