import React, { useEffect, useRef } from 'react';
import { Box, SidePanel, SidePanelHandle, SidePanelProps, Typography } from 'oskcomponents';
import { useDisableFeatureOnMouseOver, useMap } from '~/hooks';
import { RootState } from '~/redux/store';
import { connect, useDispatch } from 'react-redux';
import SearchDataCollectPreview from '~/organisms/SearchDataCollectPreview';
import { setWizardFlow } from '~/redux/modules/data/cart';
import ArchiveSearchCheckoutButton from './ArchiveSearchCheckoutButton';
import { Capture, OSKGeoJson, noop } from 'oskcore';

type SearchSidePanelProps = {
    /** From redux, the search results */
    searchResults?: any;
    /** From redux, any error that was thrown by the search */
    searchError?: string;
    /** A flag representing whether there is a search in progress */
    isSearching?: boolean;
} & SidePanelProps;

const SearchSidePanel = ({ searchResults, searchError, isSearching, ...props }: SearchSidePanelProps) => {
    const dispatch = useDispatch();
    const map = useMap();

    const searchPanelHandle = useRef<SidePanelHandle>(null);
    const searchPanelRef = useRef<HTMLDivElement>(null);

    useDisableFeatureOnMouseOver(searchPanelRef, 'Zoom', true);
    useDisableFeatureOnMouseOver(searchPanelRef, 'Drag', true);

    useEffect(() => {
        if (searchResults || searchError || isSearching) {
            searchPanelHandle.current?.open();
        }
    }, [searchResults, searchError, isSearching]);

    return (
        <Box
            style={{
                overflow: 'hidden',
                pointerEvents: 'auto',
            }}
            grow
        >
            <SidePanel
                collapsible={searchResults !== undefined && searchResults.length === 0}
                panelId="search-default"
                style={{ minHeight: 'min-content' }}
                ref={searchPanelHandle}
                {...props}
            >
                <Box ref={searchPanelRef} id="search-panel" col grow style={{ height: '100%' }}>
                    <Box col>
                        <Box center="vertical" style={{ justifyContent: 'space-between' }}>
                            <Typography variant="heading2">Search Results</Typography>
                        </Box>
                    </Box>

                    <Box
                        col
                        style={{
                            display: 'block',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '100%',
                            paddingTop: '14px',
                        }}
                    >
                        <SearchDataCollectPreview
                            onFootprintClick={(capture: Capture) => {
                                const centroid = OSKGeoJson.fromAPIGeometry(capture.footprint).getCentroid();
                                map.panTo(centroid);
                            }}
                        />
                    </Box>

                    {/* DO NOT REMOVE this box!!! It adds padding. */}
                    <Box h={120} />

                    <Box
                        h={80}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            padding: '4px 8px',
                            boxShadow: '0px -1px 17px rgba(0, 0, 0, 0.19)',
                        }}
                        center="all"
                    >
                        <ArchiveSearchCheckoutButton
                            onClick={() => {
                                dispatch(setWizardFlow('search'));
                            }}
                        />
                    </Box>
                </Box>
            </SidePanel>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const { results, errorMessage, isSearching } = state.data.search;
    return {
        searchResults: results,
        searchError: errorMessage,
        isSearching,
    };
};

export { SearchSidePanel };
export default connect(mapStateToProps, noop)(SearchSidePanel);
