/**
    This component is designed to list Search Results that come back
    from the API. It is listed in the side panel on the Map view.
    Toggling an item will either enqueue or dequeue it from the cart.
*/

import { Box, OSKIcon, OSKThemeType, Spinner, Typography } from 'oskcomponents';
import { Capture, DownloadIntent, OSKGeoJson, SigmaAPI, noop } from 'oskcore';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { CaptureGroup } from '~/molecules/CaptureGroup';
import CaptureItem from '~/molecules/CaptureItem';
import { RootState } from '~/redux/store';
import { CartGroupIcon, dequeueFiles, enqueueFilesById, getCollectIcon } from '~/redux/modules/data/cart';
import { addCloudOverlayMap, FootprintOverlayMode, setOverlayModeForTask } from '~/redux/modules/data/search';
import { useMap } from '~/hooks';
import gdux, { GDUX_SELECT_ADD, GDUX_SELECT_REMOVE } from '~/gdux';
import { getProgramId } from '~/utils';

type SearchDataCaptureGroupProps = {
    /** Title to display in the dropdown parent */
    title: string;
    /** Optional subtitle to display below the title */
    subtitle?: string;
    /** The list of fileIds in the collect */
    fileIds: string[];
    /** The icon to show at the summary */
    icon: CartGroupIcon;
    /** The overlay mode for the aoi of this task */
    overlayMode?: FootprintOverlayMode;
    /** From redux, map of cloud artifact urls for a given file_id. */
    cloudOverlayMap?: Record<string, string>;
    /** A method that's called when a CaptureItem is clicked. */
    onFootprintClick?: (capture: Capture) => void;
    /** A method that's called when the overlay mode is changed. */
    onOverlayModeChange?: (mode: FootprintOverlayMode) => void;
};
const SearchDataCaptureGroup = ({
    title,
    subtitle,
    fileIds,
    icon,
    overlayMode = 'none',
    cloudOverlayMap,
    onFootprintClick,
    onOverlayModeChange,
}: SearchDataCaptureGroupProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const map = useMap();
    const program = getProgramId();

    const [artifactsLoaded, setArtifactsLoaded] = useState<boolean>(false);

    const ValidViewModes = ['None', 'RGB'];
    if (cloudOverlayMap) {
        const cloudMapInChildren = fileIds.find((fileId) => {
            return fileId in cloudOverlayMap;
        });
        if (cloudMapInChildren) {
            ValidViewModes.push('Clouds');
        }
    }

    return (
        <CaptureGroup
            onIconClick={() => {
                if (icon === 'all') {
                    dispatch(dequeueFiles(fileIds));
                    fileIds.forEach((id) => gdux.trigger(GDUX_SELECT_REMOVE, id));
                } else {
                    dispatch(enqueueFilesById(fileIds));
                    fileIds.forEach((id) => gdux.trigger(GDUX_SELECT_ADD, id));
                }
            }}
            beforeExpand={() => {
                if (!artifactsLoaded) {
                    return new Promise((resolve) => {
                        const promises: Promise<void>[] = [];
                        fileIds.forEach((file_id: string) => {
                            promises.push(
                                SigmaAPI.getCaptureArtifact({ id: file_id, program }).then((result) => {
                                    const { cloud_cover_map_artifact } = result.data;
                                    if (file_id && cloud_cover_map_artifact) {
                                        dispatch(addCloudOverlayMap(file_id, cloud_cover_map_artifact));
                                    }
                                }),
                            );
                        });

                        Promise.all(promises).finally(() => {
                            setArtifactsLoaded(true);
                            resolve();
                        });
                    });
                }

                return Promise.resolve();
            }}
            defaultExpanded={false}
            mb={18}
            taskId={title}
            caption={subtitle}
            key={`task_${title}`}
            icon={icon}
        >
            <Box
                bg={theme.colors.lightGray}
                h={47}
                grow
                center="vertical"
                p={12}
                style={{ justifyContent: 'space-between' }}
            >
                <Typography variant="body3" strong>
                    View:
                </Typography>
                <Box grow style={{ justifyContent: 'space-around' }}>
                    {ValidViewModes.map((option) => {
                        const selected = option.toLowerCase() === overlayMode;
                        const color = selected ? theme.colors.orange50 : theme.colors.black400;
                        return (
                            <Box
                                key={`${option}-radio`}
                                onClick={() => {
                                    onOverlayModeChange &&
                                        onOverlayModeChange(option.toLowerCase() as FootprintOverlayMode);
                                }}
                                center="vertical"
                            >
                                <label
                                    style={{ userSelect: 'none', marginRight: '6px', cursor: 'pointer' }}
                                    htmlFor={`${title}-${option}-radio`}
                                >
                                    {option}
                                </label>
                                <input
                                    type="radio"
                                    id={`${title}-${option}-radio`}
                                    value={option}
                                    style={{ display: 'none', cursor: 'pointer' }}
                                />
                                <Box
                                    style={{
                                        width: '18px',
                                        height: '18px',
                                        border: `2px solid ${color}`,
                                        borderRadius: '100px',
                                    }}
                                    center="all"
                                >
                                    {selected && (
                                        <Box
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                transform: 'scale(.7)',
                                                backgroundColor: color,
                                                borderRadius: '50px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            {fileIds.map((fileId, idx) => (
                <CaptureItem
                    fileId={fileId}
                    key={`task_item_${fileId}`}
                    style={{
                        margin: '0',
                        backgroundColor: theme.colors.white,
                    }}
                    onClick={(capture: Capture) => onFootprintClick && onFootprintClick(capture)}
                    onThumbnailClick={(e, capture) => {
                        e.preventDefault();
                        e.stopPropagation();

                        // Fit the footprint in the map view
                        const geo = OSKGeoJson.fromAPIGeometry(capture.footprint);
                        map.fitCoordinates([geo]);

                        if (overlayMode === 'none') {
                            dispatch(setOverlayModeForTask(capture.task_id, 'rgb'));
                        }
                    }}
                />
            ))}
        </CaptureGroup>
    );
};

const mapSearchDataCaptureGroupStateToProps = (state: RootState, ownProps: Partial<SearchDataCaptureGroupProps>) => {
    return {
        fileIds: state.data.search.collectToFileIdList[ownProps.title ?? '-1'],
        icon: getCollectIcon(state, ownProps.title ?? '-1'),
        cloudOverlayMap: state.data.search.cloudOverlayMap,
    };
};

const ConnectedSearchDataCaptureGroup = connect(mapSearchDataCaptureGroupStateToProps, noop)(SearchDataCaptureGroup);

export type SearchDataTaskPreviewProps = {
    /** List of all collects */
    taskIdList: string[];
    /** If true, we are waiting on the search API to return new results */
    isSearching?: boolean;
    /** From redux, the current cart */
    cart?: Record<string, DownloadIntent | undefined>;
    /** From redux, search results */
    searchResults?: any;
    /** From redux, a map of overlay modes by task id */
    taskOverlayModes: Record<string, FootprintOverlayMode>;
    /** A method that's called when a CaptureItem is clicked. */
    onFootprintClick?: (capture: Capture) => void;
};

type SelectedStatus = 'some' | 'none' | 'all';

export const SearchDataTaskPreview = ({
    isSearching,
    taskIdList,
    cart,
    searchResults,
    taskOverlayModes,
    onFootprintClick,
}: SearchDataTaskPreviewProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();

    const numSelected = Object.values(cart ?? []).filter((item) => item).length;
    const numPossible = Object.values(searchResults ?? {}).length;
    const selectedMode: SelectedStatus = numSelected === 0 ? 'none' : numSelected === numPossible ? 'all' : 'some';

    if (isSearching) {
        return (
            <Box grow center="all" style={{ height: '100%' }}>
                <Spinner size="Large" variant="Box" />
            </Box>
        );
    } else if (taskIdList.length === 0) {
        return (
            <Box col style={{ height: '100%' }}>
                <Typography variant="body4">There are no images available for the current parameters.</Typography>
                <Box center="all" grow col style={{ marginTop: '-200px' }}>
                    <OSKIcon code="no-footprints" style={{ width: '250px', height: '250px', marginBottom: '-32px' }} />
                    <Typography variant="heading2" style={{ textAlign: 'center', color: theme.colors.black600 }}>
                        No Images
                    </Typography>
                </Box>
            </Box>
        );
    } else {
        return (
            <>
                <Box
                    center="vertical"
                    style={{ padding: '0px 0px 20px 20px' }}
                    onClick={() => {
                        const allFiles = searchResults.map((result: Capture) => result.id);
                        switch (selectedMode) {
                            case 'none':
                            case 'some':
                                // Select all
                                dispatch(enqueueFilesById(allFiles));
                                allFiles.forEach((id: string) => gdux.trigger(GDUX_SELECT_ADD, id));
                                break;
                            case 'all':
                                // Deselect all
                                dispatch(dequeueFiles(allFiles));
                                allFiles.forEach((id: string) => gdux.trigger(GDUX_SELECT_REMOVE, id));
                                break;
                        }
                    }}
                >
                    <OSKIcon
                        code={
                            selectedMode === 'none'
                                ? 'reg-square'
                                : selectedMode === 'all'
                                ? 'check-square'
                                : 'dash-square'
                        }
                        scale={150}
                        fill={theme.colors.black600}
                    />
                    <Typography variant="body4" style={{ paddingLeft: '12px' }}>
                        Select All AOIs
                    </Typography>
                </Box>
                {taskIdList.map((taskId) => (
                    <ConnectedSearchDataCaptureGroup
                        key={`search_data_task_group_${taskId}`}
                        title={taskId}
                        overlayMode={taskId in taskOverlayModes ? taskOverlayModes[taskId] : 'none'}
                        onFootprintClick={(capture: Capture) => onFootprintClick && onFootprintClick(capture)}
                        onOverlayModeChange={(mode: FootprintOverlayMode) => {
                            dispatch(setOverlayModeForTask(taskId, mode));
                        }}
                    />
                ))}
            </>
        );
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        isSearching: state.data.search.isSearching,
        taskIdList: Object.keys(state.data.search.collectToFileIdList),
        cart: state.data.cart.enqueued,
        searchResults: state.data.search.results,
        taskOverlayModes: state.data.search.taskOverlayModes,
    };
};

export default connect(mapStateToProps, noop)(SearchDataTaskPreview);
