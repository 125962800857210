/* tslint:disable */
/* eslint-disable */
/**
 * Orbital Sidekick Sigma API
 * The Orbital Sidekick Sigma API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Represents a user\'s association with a program. Includes relevant details about the user and the list of roles they have on a given program.
 * @export
 * @interface AddUserToProgramRequest
 */
export interface AddUserToProgramRequest {
    /**
     * The id of the user
     * @type {number}
     * @memberof AddUserToProgramRequest
     */
    id?: number;
    /**
     * The email address of the user
     * @type {string}
     * @memberof AddUserToProgramRequest
     */
    email?: string;
    /**
     * A list of role names associated with the user
     * @type {Array<string>}
     * @memberof AddUserToProgramRequest
     */
    roles: Array<string>;
}
/**
 * For security purposes, we will not return client_secret in any requests except for create.
 * @export
 * @interface Application
 */
export interface Application {
    /**
     *
     * @type {number}
     * @memberof Application
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    client_id?: string;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    user: string;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof Application
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    client_type: ApplicationClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    authorization_grant_type: ApplicationAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface ApplicationCreate
 */
export interface ApplicationCreate {
    /**
     *
     * @type {number}
     * @memberof ApplicationCreate
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    client_id?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    user: string;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof ApplicationCreate
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    client_type: ApplicationCreateClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    authorization_grant_type: ApplicationCreateAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    client_secret?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface ApplicationCreateRequest
 */
export interface ApplicationCreateRequest {
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    client_id?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    user: string;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    client_type: ApplicationCreateRequestClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    authorization_grant_type: ApplicationCreateRequestAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    client_secret?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateRequestClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateRequestAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 * For security purposes, we will not return client_secret in any requests except for create.
 * @export
 * @interface ApplicationRequest
 */
export interface ApplicationRequest {
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    client_id?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    user: string;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof ApplicationRequest
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    client_type: ApplicationRequestClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    authorization_grant_type: ApplicationRequestAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationRequestClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationRequestAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     *
     * @type {number}
     * @memberof Asset
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Asset
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof Asset
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof Asset
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Asset
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Asset
     */
    monitoring_start_date: string | null;
    /**
     *
     * @type {string}
     * @memberof Asset
     */
    monitoring_end_date: string | null;
    /**
     *
     * @type {string}
     * @memberof Asset
     */
    monitoring_frequency: AssetMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AssetMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface AssetDetail
 */
export interface AssetDetail {
    /**
     *
     * @type {number}
     * @memberof AssetDetail
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof AssetDetail
     */
    name: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof AssetDetail
     */
    aoi: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof AssetDetail
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof AssetDetail
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof AssetDetail
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof AssetDetail
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof AssetDetail
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof AssetDetail
     */
    monitoring_frequency?: AssetDetailMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AssetDetailMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface AssetRequest
 */
export interface AssetRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof AssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof AssetRequest
     */
    report_emails?: Array<string>;
}
/**
 *
 * @export
 * @interface Capture
 */
export interface Capture {
    /**
     * Unique ID for a captured image
     * @type {string}
     * @memberof Capture
     */
    id: string;
    /**
     * The sensor that was used to capture the image
     * @type {number}
     * @memberof Capture
     */
    sensor_id: number;
    /**
     * Unique ID for a Task. A Task is a collection of images taken together in sequence.
     * @type {string}
     * @memberof Capture
     */
    task_id: string;
    /**
     * A polygon representing the area covered by the imagery
     * @type {Geometry | GeometryCollection}
     * @memberof Capture
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     * The time at which the image was captured
     * @type {string}
     * @memberof Capture
     */
    acquisition_time: string;
    /**
     * When the image completed processing
     * @type {string}
     * @memberof Capture
     */
    processed_date: string;
    /**
     * RGB Preview of Imagery
     * @type {string}
     * @memberof Capture
     */
    preview_artifact: string | null;
    /**
     * Percentage of the image which is covered in clouds
     * @type {number}
     * @memberof Capture
     */
    cloud_cover_pct?: number | null;
}
/**
 *
 * @export
 * @interface CaptureArtifact
 */
export interface CaptureArtifact {
    /**
     * Unique ID for a captured image
     * @type {string}
     * @memberof CaptureArtifact
     */
    file_id: string;
    /**
     * Preview of visible RGB
     * @type {string}
     * @memberof CaptureArtifact
     */
    rgb_map_artifact: string | null;
    /**
     * Preview of cloud cover overlay
     * @type {string}
     * @memberof CaptureArtifact
     */
    cloud_cover_map_artifact: string | null;
}
/**
 *
 * @export
 * @interface DataPipelineCalibration
 */
export interface DataPipelineCalibration {
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    calibration_type: number;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibration
     */
    calibration_time: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibration
     */
    raw_s3_uri: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibration
     */
    working_s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    validation_status: number;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    samples?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    bands?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    bin?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface DataPipelineCalibrationRequest
 */
export interface DataPipelineCalibrationRequest {
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    calibration_type: number;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibrationRequest
     */
    calibration_time: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibrationRequest
     */
    raw_s3_uri: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibrationRequest
     */
    working_s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    validation_status: number;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    samples?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    bands?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    bin?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface Detection
 */
export interface Detection {
    /**
     *
     * @type {string}
     * @memberof Detection
     */
    id: string;
    /**
     * Latitude & longitude where this detection was found
     * @type {Geometry | GeometryCollection}
     * @memberof Detection
     */
    coordinates: Geometry | GeometryCollection;
    /**
     * General category for the detection (e.g., intrusion)
     * @type {string}
     * @memberof Detection
     */
    call_type: string;
    /**
     * Category for the detection, representing the specific kind of finding found
     * @type {string}
     * @memberof Detection
     */
    detection_type: string;
    /**
     * Reference to the source image for this detection
     * @type {number}
     * @memberof Detection
     */
    capture: number | null;
    /**
     * Time that the image was processed and the finding was detected
     * @type {string}
     * @memberof Detection
     */
    created_at: string;
    /**
     * Customer asset associated with this finding
     * @type {number}
     * @memberof Detection
     */
    asset: number;
    /**
     * Classification for this detection
     * @type {string}
     * @memberof Detection
     */
    detection_class: DetectionDetectionClassEnum;
    /**
     * URLs for image artifacts associated with this detection. Each entry takes the form of an image type (e.g., \'RGB\') and a signed URL which allows retrieval of the image for a short time, usually 7 days
     * @type {{ [key: string]: string; }}
     * @memberof Detection
     */
    artifacts: { [key: string]: string };
    /**
     * Time that the image used to find this detection was collected
     * @type {string}
     * @memberof Detection
     */
    detected_at: string;
    /**
     * Feedback and status of detection after in situ confirmation
     * @type {string}
     * @memberof Detection
     */
    feedback_status?: DetectionFeedbackStatusEnum;
    /**
     * Explanation for why the selected feedback status was chosen
     * @type {string}
     * @memberof Detection
     */
    feedback_text?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DetectionDetectionClassEnum {
    Hidden = 'hidden',
    Detection = 'detection',
    Alert = 'alert',
}
/**
 * @export
 * @enum {string}
 */
export enum DetectionFeedbackStatusEnum {
    Unreviewed = 'Unreviewed',
    Unresolved = 'Unresolved',
    ResolvedAccepted = 'Resolved - Accepted',
    ResolvedRejected = 'Resolved - Rejected',
}

/**
 *
 * @export
 * @interface DetectionArtifact
 */
export interface DetectionArtifact {
    /**
     *
     * @type {number}
     * @memberof DetectionArtifact
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof DetectionArtifact
     */
    artifact_type: string;
    /**
     *
     * @type {string}
     * @memberof DetectionArtifact
     */
    uri: string;
    /**
     * Internal detection ID
     * @type {number}
     * @memberof DetectionArtifact
     */
    detection: number;
}
/**
 *
 * @export
 * @interface DetectionArtifactRequest
 */
export interface DetectionArtifactRequest {
    /**
     *
     * @type {string}
     * @memberof DetectionArtifactRequest
     */
    artifact_type: string;
    /**
     *
     * @type {string}
     * @memberof DetectionArtifactRequest
     */
    uri: string;
    /**
     * Internal detection ID
     * @type {number}
     * @memberof DetectionArtifactRequest
     */
    detection: number;
}
/**
 *
 * @export
 * @interface DetectionRequest
 */
export interface DetectionRequest {
    /**
     * Feedback and status of detection after in situ confirmation
     * @type {string}
     * @memberof DetectionRequest
     */
    feedback_status?: DetectionRequestFeedbackStatusEnum;
    /**
     * Explanation for why the selected feedback status was chosen
     * @type {string}
     * @memberof DetectionRequest
     */
    feedback_text?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DetectionRequestFeedbackStatusEnum {
    Unreviewed = 'Unreviewed',
    Unresolved = 'Unresolved',
    ResolvedAccepted = 'Resolved - Accepted',
    ResolvedRejected = 'Resolved - Rejected',
}

/**
 *
 * @export
 * @interface Elevation
 */
export interface Elevation {
    /**
     *
     * @type {string}
     * @memberof Elevation
     */
    dataset: string;
    /**
     *
     * @type {number}
     * @memberof Elevation
     */
    elevation: number;
}
/**
 *
 * @export
 * @interface ElevationSummary
 */
export interface ElevationSummary {
    /**
     *
     * @type {string}
     * @memberof ElevationSummary
     */
    dataset: string;
    /**
     *
     * @type {number}
     * @memberof ElevationSummary
     */
    max_elevation: number;
    /**
     *
     * @type {number}
     * @memberof ElevationSummary
     */
    min_elevation: number;
    /**
     *
     * @type {number}
     * @memberof ElevationSummary
     */
    mean_elevation: number;
    /**
     *
     * @type {number}
     * @memberof ElevationSummary
     */
    median_elevation: number;
}
/**
 *
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * The type of the geos instance
     * @type {string}
     * @memberof Geometry
     */
    type: string;
    /**
     *
     * @type {Array<number> | Array<Array>}
     * @memberof Geometry
     */
    coordinates: Array<number> | Array<any>;
}
/**
 *
 * @export
 * @interface GeometryCollection
 */
export interface GeometryCollection {
    /**
     * The type of the geos instance
     * @type {string}
     * @memberof GeometryCollection
     */
    type: GeometryCollectionTypeEnum;
    /**
     *
     * @type {Array<Geometry>}
     * @memberof GeometryCollection
     */
    geometries: Array<Geometry>;
}

/**
 * @export
 * @enum {string}
 */
export enum GeometryCollectionTypeEnum {
    GeometryCollection = 'GeometryCollection',
}

/**
 *
 * @export
 * @interface InternalAsset
 */
export interface InternalAsset {
    /**
     *
     * @type {number}
     * @memberof InternalAsset
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof InternalAsset
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof InternalAsset
     */
    aoi: number;
    /**
     *
     * @type {number}
     * @memberof InternalAsset
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof InternalAsset
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof InternalAsset
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InternalAsset
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalAsset
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalAsset
     */
    monitoring_frequency?: InternalAssetMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalAssetMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface InternalAssetRequest
 */
export interface InternalAssetRequest {
    /**
     *
     * @type {string}
     * @memberof InternalAssetRequest
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof InternalAssetRequest
     */
    aoi: number;
    /**
     *
     * @type {number}
     * @memberof InternalAssetRequest
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof InternalAssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof InternalAssetRequest
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InternalAssetRequest
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalAssetRequest
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalAssetRequest
     */
    monitoring_frequency?: InternalAssetRequestMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalAssetRequestMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface InternalCapture
 */
export interface InternalCapture {
    /**
     *
     * @type {number}
     * @memberof InternalCapture
     */
    id: number;
    /**
     * RGB Preview of Imagery
     * @type {string}
     * @memberof InternalCapture
     */
    preview_artifact: string | null;
    /**
     * Unique ID for a Task. A Task is a collection of images taken together in sequence.
     * @type {string}
     * @memberof InternalCapture
     */
    task_id: string;
    /**
     * Unique ID for a captured image
     * @type {string}
     * @memberof InternalCapture
     */
    file_id: string;
    /**
     *
     * @type {string}
     * @memberof InternalCapture
     */
    pipeline_execution_id?: string | null;
    /**
     * The time at which the image was captured
     * @type {string}
     * @memberof InternalCapture
     */
    acquisition_time: string;
    /**
     * When the image completed processing
     * @type {string}
     * @memberof InternalCapture
     */
    processed_date: string;
    /**
     * A polygon representing the area covered by the imagery
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCapture
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     * Percentage of the image which is covered in clouds
     * @type {number}
     * @memberof InternalCapture
     */
    cloud_cover_pct?: number | null;
    /**
     * The sensor that was used to capture the image
     * @type {number}
     * @memberof InternalCapture
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface InternalCaptureRequest
 */
export interface InternalCaptureRequest {
    /**
     * Unique ID for a Task. A Task is a collection of images taken together in sequence.
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    task_id: string;
    /**
     * Unique ID for a captured image
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    file_id: string;
    /**
     *
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    pipeline_execution_id?: string | null;
    /**
     * The time at which the image was captured
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    acquisition_time: string;
    /**
     * A polygon representing the area covered by the imagery
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCaptureRequest
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     * Percentage of the image which is covered in clouds
     * @type {number}
     * @memberof InternalCaptureRequest
     */
    cloud_cover_pct?: number | null;
    /**
     * The sensor that was used to capture the image
     * @type {number}
     * @memberof InternalCaptureRequest
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface InternalCaptureTask
 */
export interface InternalCaptureTask {
    /**
     *
     * @type {string}
     * @memberof InternalCaptureTask
     */
    task_id: string;
    /**
     *
     * @type {number}
     * @memberof InternalCaptureTask
     */
    sensor: number;
    /**
     *
     * @type {string}
     * @memberof InternalCaptureTask
     */
    start_acquisition_time: string;
    /**
     *
     * @type {string}
     * @memberof InternalCaptureTask
     */
    end_acquisition_time: string;
}
/**
 * Serializer used for CREATE operation on the InternalSensorTaskViewSet
 * @export
 * @interface InternalCreateSensorTask
 */
export interface InternalCreateSensorTask {
    /**
     * The tag is used to uniquely identify this tasking in a human-readable form for this sensor.  An example of the tag, would be: \'OSK-20230511-00000001-00000001\'.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    tag: string;
    /**
     * The SensorSchedule object represents a complete schedule for 1 individual Sensor.  A SensorSchedule object is made up of multiple SensorTask objects.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    sensor_schedule: number;
    /**
     * This is the unique id of the tasking used by the Payload. This will be used to identify each tasking in the SQL script and in the Flight JAS Script.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    task_id: string;
    /**
     * This field contains a comma separate list of names of all the targets that will be collected by this tasking.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    target_name: string;
    /**
     * The capture_index represents an ascending numeric index that starts at 0 and identifies this capture in the entire schedule for a specific sensor. The next capture should always be capture_index + 1. The parent SensorSchedule tracks the total number of captures, so you can use that to determine if this is the last capture_index.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    capture_index: number;
    /**
     * The capture_area represents the Polygon(s) that are captured during this SensorTask (aka Tasking).
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCreateSensorTask
     */
    capture_area: Geometry | GeometryCollection;
    /**
     * The priority represents the maximum priority of all the TaskingRequests that will be collected by this SensorTask.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    priority: InternalCreateSensorTaskPriorityEnum;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value of GSD should represent the GSD of the collect at it\'s exact center point.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    gsd: number;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value should represent the minimum GSD (or the GSD of the point closest to the satellite).
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    gsd_min: number;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value should represent the maximum GSD (or the GSD of the point furthest from the satellite).
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    gsd_max: number;
    /**
     * The score of the SensorTask (aka Tasking) is computed by the Tasking Engine and is subject to change. Only taskings that produce the most optimal path through the graph of all possible taskings will be selected (and inserted into the database as a SensorTask). SensorTasks that are not selected will not (currently) appear in the database.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    score: number;
    /**
     * Length of time in microseconds that the payload will wait (after passing the target) to mark the task as \"FAILED\". Defaults to 10 seconds.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    capture_error_us?: number;
    /**
     * The pass_time represents the exact time that the satellite will be able to perform the collect. This means the satellite has slewed to the proper orientation, warmed up the camera, and turned the camera on prior to this moment. All other timings are centered around this value.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    pass_time: string;
    /**
     * Before each capture, the camera must warmup for a certain period of time. Also, before each eclipse, the payload\'s camera is shutdown. This means that the first capture after an eclipse, it takes the camera a bit longer to warmup. For the first capture Post-Eclipse, the camera takes 5 minutes to warmup. For all subsequent captures (of that orbit), it takes the camera 1 minute to warmup. capture_on_time contains the actual time the camera begins to warmup.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    camera_on_time: string;
    /**
     * capture_start_time represents the time (inclusive) that the camera begins to capture imagery.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    capture_start_time: string;
    /**
     * capture_end_time represents the time (exclusive) that the camera stops capturing imagery.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    capture_end_time: string;
    /**
     * capture_point contains the longitude, latitude, and altitude that the satellite is pointing at, at capture_start_time
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCreateSensorTask
     */
    capture_point: Geometry | GeometryCollection;
    /**
     * The slew angle of the satellite in degrees.  At every second, we know the position and velocity of the satellite. We also know that when the slew_angle is 0, the satellite is nadir pointing. If you can imagine rotating the nadir pointing vector by capture_slew_angle using the velocity vector as the axis of rotation, you will understand the meaning of this field.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    capture_slew_angle: number;
    /**
     * The moment in time that the satellite will begin slewing to perform the capture.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    capture_slew_start_time: string;
    /**
     * The number of seconds the slew will take.  A value of 0.0 means the satellite will compute the fastest slew duration possible to reach the desired slew angle.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    capture_slew_duration: number;
    /**
     * When the satellite is not performing a collect, it needs to be doing something \"in the interim\". prev_interim_mode signifies what the satellite was doing \"in the interim\" before the current tasking (aka SensorTask). This affects various timings.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    prev_interim_mode: InternalCreateSensorTaskPrevInterimModeEnum;
    /**
     * When the satellite is not performing a collect, it needs to be doing something \"in the interim\". next_interim_mode signifies what the satellite should do \"in the interim\" after the current tasking (aka SensorTask). This affects various timings.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    next_interim_mode: InternalCreateSensorTaskNextInterimModeEnum;
    /**
     * orbit_capture_index represents the capture number of the current SensorTask for the current Orbit. This will be used to determine camera warm up times and determine if the payload should be shutdown after the current collect.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    orbit_capture_index: number;
    /**
     * total_orbit_captures represents the number of captures that will occur in the current (same) orbit as this SensorTask object. This will be used in conjunction with the orbit_capture_index field.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    total_orbit_captures: number;
    /**
     * orbit_num represents which orbit the current SensorTask (tasking) is in. This value will be pulled from the core_api_tick table.
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    orbit_num: number;
    /**
     * This represents the datetime after the current collect in which the satellite should begin slewing in order to return to next_interim_mode (sun tracking or nadir tracking).
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    post_capture_slew_start_time: string;
    /**
     * This represents the duration (in seconds) the post-capture slew operation will take. After the current tasking / collect, the satellite should return to next_interim_mode. This value represents how long that slew operation will take. Note, that a value of 0.0 tells the satellite to slew \"optimally\" (the satellite will calculate the shortest amount of time to perform the slew operation).
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    post_capture_slew_duration: number;
    /**
     * This linkage will track which TaskingRequest objects caused this SensorTask to be created. It is important to note that more than 1 TaskingRequest can be collected by a single SensorTask. Also, more than 1 SensorTask might be required to collect a single TaskingRequest (hence the many-to-many relationship).
     * @type {Array<number>}
     * @memberof InternalCreateSensorTask
     */
    tasking_requests: Array<number>;
    /**
     * This field represents the actual Flight JAS script that will be sent to the Payload. This Flight JAS script is constructed based on all the data in this object. This Flight JAS script will be embedded in the Flight JAS JSON script stored in the SensorSchedule parent object.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    flight_jas: string;
    /**
     * This field represents a single insert statement, that is constructed from the data in this object, that can be run on the Payload\'s SQLite database. This SQL insert Script will be combined into a single SQL script within the parent SensorSchedule object. The way this script is generated will need to be determined by the version of the SQLite database currently on the Payload.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    sql_insert_script: string;
    /**
     * The UUID of the HSI Config used for this Tasking. This represents the hsi_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    hsi_config: string;
    /**
     * The UUID of the PAN Config used for this Tasking. This represents the pan_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    pan_config: string;
    /**
     * The UUID of the Radiometric Config used for this Tasking. This represents the radiometric_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    radiometric_config: string;
    /**
     * The UUID of the Pipeline Config used for this Tasking. This represents the pipeline_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    pipeline_config: string;
    /**
     * The time that the payload starts to power down.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    power_down_start_time?: string | null;
    /**
     * The approximate time that the payload has completed the power down process. The actual power down time of the payload is likely to occur after this value. Currently, this value represents the earliest possible power down time. This is because the Flight Jas commands take a variable amount of time to execute. Also, the each power down command can be blocking or non-blocking and is followed by a WAIT(sec) command (which effectively sleeps the process). This value currently represents the power_down_start_time + TOTAL_WAIT_SECS. We should probably add an additional buffer to this value at some point.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    power_down_end_time?: string | null;
    /**
     * The time of the first action of this task. No 2 tasks within the same SensorSchedule can have overlapping window_start_time / window_end_time. These values are used by the optimization process to determine if 2 tasks overlap.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    window_start_time: string;
    /**
     * The time of the last action of this task. No 2 tasks within the same SensorSchedule can have overlapping window_start_time / window_end_time. These values are used by the optimization process to determine if 2 tasks overlap.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    window_end_time: string;
    /**
     * True if this Tasking will use the hyperspectral sensor to perform the collect. False otherwise. This value is used in the Payload SQL script.
     * @type {boolean}
     * @memberof InternalCreateSensorTask
     */
    use_hsi: boolean;
    /**
     * True if this Tasking will use the panchromatic (RGB) sensor to perform the collect. False otherwise. This value is used in the Payload SQL script.
     * @type {boolean}
     * @memberof InternalCreateSensorTask
     */
    use_pan: boolean;
    /**
     *
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    id: number;
    /**
     * task_status contains the status of the task, generated by the Payload or inferred when the imagery comes in via the datapipeline.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    task_status: InternalCreateSensorTaskTaskStatusEnum;
    /**
     * The date when this SensorTask was created.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    create_date: string;
    /**
     * The datetime when this SensorTask was last updated.
     * @type {string}
     * @memberof InternalCreateSensorTask
     */
    update_date: string;
    /**
     * When imagery comes back down from the satellite, a capture record is created. Within the downlinked data, the task_id (defined within this object) should also be included. This will allow us to directly link the capture to the current SensorTask object. This field will be None until the imagery comes down and runs through the data pipeline (which will populate this field).
     * @type {number}
     * @memberof InternalCreateSensorTask
     */
    capture: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalCreateSensorTaskPriorityEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalCreateSensorTaskPrevInterimModeEnum {
    SunTracking = 'SUN_TRACKING',
    NadirTracking = 'NADIR_TRACKING',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalCreateSensorTaskNextInterimModeEnum {
    SunTracking = 'SUN_TRACKING',
    NadirTracking = 'NADIR_TRACKING',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalCreateSensorTaskTaskStatusEnum {
    Uplinked = 'UPLINKED',
    Failed = 'FAILED',
    FailedSettings = 'FAILED_SETTINGS',
    FailedMissedAcq = 'FAILED_MISSED_ACQ',
    FailedDarkCapture = 'FAILED_DARK_CAPTURE',
    FailedCapture = 'FAILED_CAPTURE',
    FailedProcessing = 'FAILED_PROCESSING',
    Downlinked = 'DOWNLINKED',
}

/**
 * Serializer used for CREATE operation on the InternalSensorTaskViewSet
 * @export
 * @interface InternalCreateSensorTaskRequest
 */
export interface InternalCreateSensorTaskRequest {
    /**
     * The tag is used to uniquely identify this tasking in a human-readable form for this sensor.  An example of the tag, would be: \'OSK-20230511-00000001-00000001\'.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    tag: string;
    /**
     * The SensorSchedule object represents a complete schedule for 1 individual Sensor.  A SensorSchedule object is made up of multiple SensorTask objects.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    sensor_schedule: number;
    /**
     * This is the unique id of the tasking used by the Payload. This will be used to identify each tasking in the SQL script and in the Flight JAS Script.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    task_id: string;
    /**
     * This field contains a comma separate list of names of all the targets that will be collected by this tasking.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    target_name: string;
    /**
     * The capture_index represents an ascending numeric index that starts at 0 and identifies this capture in the entire schedule for a specific sensor. The next capture should always be capture_index + 1. The parent SensorSchedule tracks the total number of captures, so you can use that to determine if this is the last capture_index.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_index: number;
    /**
     * The capture_area represents the Polygon(s) that are captured during this SensorTask (aka Tasking).
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_area: Geometry | GeometryCollection;
    /**
     * The priority represents the maximum priority of all the TaskingRequests that will be collected by this SensorTask.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    priority: InternalCreateSensorTaskRequestPriorityEnum;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value of GSD should represent the GSD of the collect at it\'s exact center point.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    gsd: number;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value should represent the minimum GSD (or the GSD of the point closest to the satellite).
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    gsd_min: number;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value should represent the maximum GSD (or the GSD of the point furthest from the satellite).
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    gsd_max: number;
    /**
     * The score of the SensorTask (aka Tasking) is computed by the Tasking Engine and is subject to change. Only taskings that produce the most optimal path through the graph of all possible taskings will be selected (and inserted into the database as a SensorTask). SensorTasks that are not selected will not (currently) appear in the database.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    score: number;
    /**
     * Length of time in microseconds that the payload will wait (after passing the target) to mark the task as \"FAILED\". Defaults to 10 seconds.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_error_us?: number;
    /**
     * The pass_time represents the exact time that the satellite will be able to perform the collect. This means the satellite has slewed to the proper orientation, warmed up the camera, and turned the camera on prior to this moment. All other timings are centered around this value.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    pass_time: string;
    /**
     * Before each capture, the camera must warmup for a certain period of time. Also, before each eclipse, the payload\'s camera is shutdown. This means that the first capture after an eclipse, it takes the camera a bit longer to warmup. For the first capture Post-Eclipse, the camera takes 5 minutes to warmup. For all subsequent captures (of that orbit), it takes the camera 1 minute to warmup. capture_on_time contains the actual time the camera begins to warmup.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    camera_on_time: string;
    /**
     * capture_start_time represents the time (inclusive) that the camera begins to capture imagery.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_start_time: string;
    /**
     * capture_end_time represents the time (exclusive) that the camera stops capturing imagery.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_end_time: string;
    /**
     * capture_point contains the longitude, latitude, and altitude that the satellite is pointing at, at capture_start_time
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_point: Geometry | GeometryCollection;
    /**
     * The slew angle of the satellite in degrees.  At every second, we know the position and velocity of the satellite. We also know that when the slew_angle is 0, the satellite is nadir pointing. If you can imagine rotating the nadir pointing vector by capture_slew_angle using the velocity vector as the axis of rotation, you will understand the meaning of this field.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_slew_angle: number;
    /**
     * The moment in time that the satellite will begin slewing to perform the capture.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_slew_start_time: string;
    /**
     * The number of seconds the slew will take.  A value of 0.0 means the satellite will compute the fastest slew duration possible to reach the desired slew angle.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    capture_slew_duration: number;
    /**
     * When the satellite is not performing a collect, it needs to be doing something \"in the interim\". prev_interim_mode signifies what the satellite was doing \"in the interim\" before the current tasking (aka SensorTask). This affects various timings.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    prev_interim_mode: InternalCreateSensorTaskRequestPrevInterimModeEnum;
    /**
     * When the satellite is not performing a collect, it needs to be doing something \"in the interim\". next_interim_mode signifies what the satellite should do \"in the interim\" after the current tasking (aka SensorTask). This affects various timings.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    next_interim_mode: InternalCreateSensorTaskRequestNextInterimModeEnum;
    /**
     * orbit_capture_index represents the capture number of the current SensorTask for the current Orbit. This will be used to determine camera warm up times and determine if the payload should be shutdown after the current collect.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    orbit_capture_index: number;
    /**
     * total_orbit_captures represents the number of captures that will occur in the current (same) orbit as this SensorTask object. This will be used in conjunction with the orbit_capture_index field.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    total_orbit_captures: number;
    /**
     * orbit_num represents which orbit the current SensorTask (tasking) is in. This value will be pulled from the core_api_tick table.
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    orbit_num: number;
    /**
     * This represents the datetime after the current collect in which the satellite should begin slewing in order to return to next_interim_mode (sun tracking or nadir tracking).
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    post_capture_slew_start_time: string;
    /**
     * This represents the duration (in seconds) the post-capture slew operation will take. After the current tasking / collect, the satellite should return to next_interim_mode. This value represents how long that slew operation will take. Note, that a value of 0.0 tells the satellite to slew \"optimally\" (the satellite will calculate the shortest amount of time to perform the slew operation).
     * @type {number}
     * @memberof InternalCreateSensorTaskRequest
     */
    post_capture_slew_duration: number;
    /**
     * This linkage will track which TaskingRequest objects caused this SensorTask to be created. It is important to note that more than 1 TaskingRequest can be collected by a single SensorTask. Also, more than 1 SensorTask might be required to collect a single TaskingRequest (hence the many-to-many relationship).
     * @type {Array<number>}
     * @memberof InternalCreateSensorTaskRequest
     */
    tasking_requests: Array<number>;
    /**
     * This field represents the actual Flight JAS script that will be sent to the Payload. This Flight JAS script is constructed based on all the data in this object. This Flight JAS script will be embedded in the Flight JAS JSON script stored in the SensorSchedule parent object.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    flight_jas: string;
    /**
     * This field represents a single insert statement, that is constructed from the data in this object, that can be run on the Payload\'s SQLite database. This SQL insert Script will be combined into a single SQL script within the parent SensorSchedule object. The way this script is generated will need to be determined by the version of the SQLite database currently on the Payload.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    sql_insert_script: string;
    /**
     * The UUID of the HSI Config used for this Tasking. This represents the hsi_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    hsi_config: string;
    /**
     * The UUID of the PAN Config used for this Tasking. This represents the pan_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    pan_config: string;
    /**
     * The UUID of the Radiometric Config used for this Tasking. This represents the radiometric_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    radiometric_config: string;
    /**
     * The UUID of the Pipeline Config used for this Tasking. This represents the pipeline_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    pipeline_config: string;
    /**
     * The time that the payload starts to power down.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    power_down_start_time?: string | null;
    /**
     * The approximate time that the payload has completed the power down process. The actual power down time of the payload is likely to occur after this value. Currently, this value represents the earliest possible power down time. This is because the Flight Jas commands take a variable amount of time to execute. Also, the each power down command can be blocking or non-blocking and is followed by a WAIT(sec) command (which effectively sleeps the process). This value currently represents the power_down_start_time + TOTAL_WAIT_SECS. We should probably add an additional buffer to this value at some point.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    power_down_end_time?: string | null;
    /**
     * The time of the first action of this task. No 2 tasks within the same SensorSchedule can have overlapping window_start_time / window_end_time. These values are used by the optimization process to determine if 2 tasks overlap.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    window_start_time: string;
    /**
     * The time of the last action of this task. No 2 tasks within the same SensorSchedule can have overlapping window_start_time / window_end_time. These values are used by the optimization process to determine if 2 tasks overlap.
     * @type {string}
     * @memberof InternalCreateSensorTaskRequest
     */
    window_end_time: string;
    /**
     * True if this Tasking will use the hyperspectral sensor to perform the collect. False otherwise. This value is used in the Payload SQL script.
     * @type {boolean}
     * @memberof InternalCreateSensorTaskRequest
     */
    use_hsi: boolean;
    /**
     * True if this Tasking will use the panchromatic (RGB) sensor to perform the collect. False otherwise. This value is used in the Payload SQL script.
     * @type {boolean}
     * @memberof InternalCreateSensorTaskRequest
     */
    use_pan: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalCreateSensorTaskRequestPriorityEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalCreateSensorTaskRequestPrevInterimModeEnum {
    SunTracking = 'SUN_TRACKING',
    NadirTracking = 'NADIR_TRACKING',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalCreateSensorTaskRequestNextInterimModeEnum {
    SunTracking = 'SUN_TRACKING',
    NadirTracking = 'NADIR_TRACKING',
}

/**
 * Internal API for data product information
 * @export
 * @interface InternalDataProduct
 */
export interface InternalDataProduct {
    /**
     * Primary key identifying a DataProduct
     * @type {number}
     * @memberof InternalDataProduct
     */
    id: number;
    /**
     * Short name for the product.
     * @type {string}
     * @memberof InternalDataProduct
     */
    product_level: InternalDataProductProductLevelEnum;
    /**
     * Help text for the product level value
     * @type {string}
     * @memberof InternalDataProduct
     */
    product_level_help_text: string;
    /**
     * Short name for the file format.
     * @type {string}
     * @memberof InternalDataProduct
     */
    file_format: InternalDataProductFileFormatEnum;
    /**
     * Help text for file_format
     * @type {string}
     * @memberof InternalDataProduct
     */
    file_format_help_text: string;
    /**
     * Delivery artifacts for the data product
     * @type {Array<string>}
     * @memberof InternalDataProduct
     */
    artifacts: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalDataProductProductLevelEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalDataProductFileFormatEnum {
    Nitf = 'NITF',
    Envi = 'ENVI',
}

/**
 *
 * @export
 * @interface InternalDetection
 */
export interface InternalDetection {
    /**
     * Internal detection ID
     * @type {number}
     * @memberof InternalDetection
     */
    pk_id: number;
    /**
     * URLs for image artifacts associated with this detection. Each entry takes the form of an image type (e.g., \'RGB\') and a signed URL which allows retrieval of the image for a short time, usually 7 days
     * @type {{ [key: string]: string; }}
     * @memberof InternalDetection
     */
    artifacts: { [key: string]: string };
    /**
     * Classification for this detection
     * @type {string}
     * @memberof InternalDetection
     */
    detection_class?: InternalDetectionDetectionClassEnum;
    /**
     * Internal review status of detection
     * @type {number}
     * @memberof InternalDetection
     */
    review_status?: InternalDetectionReviewStatusEnum;
    /**
     * Feedback and status of detection after in situ confirmation
     * @type {string}
     * @memberof InternalDetection
     */
    feedback_status?: InternalDetectionFeedbackStatusEnum;
    /**
     * Explanation for why the selected feedback status was chosen
     * @type {string}
     * @memberof InternalDetection
     */
    feedback_text?: string;
    /**
     * Unique ID for this detection within a customer program
     * @type {string}
     * @memberof InternalDetection
     */
    external_id: string;
    /**
     * Latitude & longitude where this detection was found
     * @type {Geometry | GeometryCollection}
     * @memberof InternalDetection
     */
    coordinates: Geometry | GeometryCollection;
    /**
     * General category for the detection (e.g., intrusion)
     * @type {string}
     * @memberof InternalDetection
     */
    call_type: string;
    /**
     * Category for the detection, representing the specific kind of finding found
     * @type {string}
     * @memberof InternalDetection
     */
    detection_type: string;
    /**
     * Time that the image used to find this detection was collected
     * @type {string}
     * @memberof InternalDetection
     */
    detected_at: string;
    /**
     * Time that the image was processed and the finding was detected
     * @type {string}
     * @memberof InternalDetection
     */
    created_at: string;
    /**
     * Intensity/magnitude for this detection. This value is not used for all detections, and has different meanings depending on the detection type
     * @type {number}
     * @memberof InternalDetection
     */
    intensity_value?: number | null;
    /**
     * Measurement unit for the intensity value (if applicable)
     * @type {string}
     * @memberof InternalDetection
     */
    intensity_unit?: string | null;
    /**
     * Total area of the detection area in square meters
     * @type {number}
     * @memberof InternalDetection
     */
    area?: number | null;
    /**
     * When the detection was reviewed, if applicable
     * @type {string}
     * @memberof InternalDetection
     */
    reviewed_at?: string | null;
    /**
     * Reference to the source image for this detection
     * @type {number}
     * @memberof InternalDetection
     */
    capture?: number | null;
    /**
     * Customer asset associated with this finding
     * @type {number}
     * @memberof InternalDetection
     */
    asset: number;
    /**
     * Who reviewed the processing findings, if anyone
     * @type {number}
     * @memberof InternalDetection
     */
    reviewed_by?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionDetectionClassEnum {
    Hidden = 'hidden',
    Detection = 'detection',
    Alert = 'alert',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionReviewStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionFeedbackStatusEnum {
    Unreviewed = 'Unreviewed',
    Unresolved = 'Unresolved',
    ResolvedAccepted = 'Resolved - Accepted',
    ResolvedRejected = 'Resolved - Rejected',
}

/**
 *
 * @export
 * @interface InternalDetectionRequest
 */
export interface InternalDetectionRequest {
    /**
     * Classification for this detection
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    detection_class?: InternalDetectionRequestDetectionClassEnum;
    /**
     * Internal review status of detection
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    review_status?: InternalDetectionRequestReviewStatusEnum;
    /**
     * Feedback and status of detection after in situ confirmation
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    feedback_status?: InternalDetectionRequestFeedbackStatusEnum;
    /**
     * Explanation for why the selected feedback status was chosen
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    feedback_text?: string;
    /**
     * Latitude & longitude where this detection was found
     * @type {Geometry | GeometryCollection}
     * @memberof InternalDetectionRequest
     */
    coordinates: Geometry | GeometryCollection;
    /**
     * General category for the detection (e.g., intrusion)
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    call_type: string;
    /**
     * Category for the detection, representing the specific kind of finding found
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    detection_type: string;
    /**
     * Time that the image used to find this detection was collected
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    detected_at: string;
    /**
     * Intensity/magnitude for this detection. This value is not used for all detections, and has different meanings depending on the detection type
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    intensity_value?: number | null;
    /**
     * Measurement unit for the intensity value (if applicable)
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    intensity_unit?: string | null;
    /**
     * Total area of the detection area in square meters
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    area?: number | null;
    /**
     * When the detection was reviewed, if applicable
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    reviewed_at?: string | null;
    /**
     * Reference to the source image for this detection
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    capture?: number | null;
    /**
     * Customer asset associated with this finding
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    asset: number;
    /**
     * Who reviewed the processing findings, if anyone
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    reviewed_by?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionRequestDetectionClassEnum {
    Hidden = 'hidden',
    Detection = 'detection',
    Alert = 'alert',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionRequestReviewStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionRequestFeedbackStatusEnum {
    Unreviewed = 'Unreviewed',
    Unresolved = 'Unresolved',
    ResolvedAccepted = 'Resolved - Accepted',
    ResolvedRejected = 'Resolved - Rejected',
}

/**
 * Serializer for the InternalGroundStationViewSet
 * @export
 * @interface InternalGroundStation
 */
export interface InternalGroundStation {
    /**
     *
     * @type {number}
     * @memberof InternalGroundStation
     */
    id: number;
    /**
     * The datetime when this GroundStation was created (in our DB).
     * @type {string}
     * @memberof InternalGroundStation
     */
    create_date: string;
    /**
     * The datetime when this GroundStation was last updated (in our DB).
     * @type {string}
     * @memberof InternalGroundStation
     */
    update_date: string;
    /**
     * The name of the ground station.
     * @type {string}
     * @memberof InternalGroundStation
     */
    name: string;
    /**
     * An abbreviation that can be used to uniquely identify this GroundStation within AD\'s database.
     * @type {string}
     * @memberof InternalGroundStation
     */
    short_name: string;
    /**
     * The frequency band that the ground station supports for uplink.
     * @type {string}
     * @memberof InternalGroundStation
     */
    uplink_band: InternalGroundStationUplinkBandEnum;
    /**
     * The frequency band that the ground station supports for downlink.
     * @type {string}
     * @memberof InternalGroundStation
     */
    downlink_band: InternalGroundStationDownlinkBandEnum;
    /**
     * Longitude, latitude, and altitude representing where this ground station is located.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalGroundStation
     */
    coordinates: Geometry | GeometryCollection;
    /**
     * Start time (inclusive) when we plan to start using this ground station. Block out times will only be generated while the ground station is active.
     * @type {string}
     * @memberof InternalGroundStation
     */
    active_start?: string | null;
    /**
     * End time (exclusive) when we plan to stop using this ground station. Block out times will only be generated while the ground station is active.
     * @type {string}
     * @memberof InternalGroundStation
     */
    active_end?: string | null;
    /**
     * The minimum angle of elevation (exclusive) that the satellite can be from the ground station for a downlink or uplink to occur. We will only generate block out times when the peak elevation of the satellite is strictly greater than this value.
     * @type {number}
     * @memberof InternalGroundStation
     */
    blockout_min_angle_of_elevation?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalGroundStationUplinkBandEnum {
    Uhf = 'UHF',
    SBand = 'S-band',
    KaBand = 'Ka-band',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalGroundStationDownlinkBandEnum {
    Uhf = 'UHF',
    SBand = 'S-band',
    KaBand = 'Ka-band',
}

/**
 * Serializer for the InternalGroundStationPassViewSet
 * @export
 * @interface InternalGroundStationPass
 */
export interface InternalGroundStationPass {
    /**
     *
     * @type {number}
     * @memberof InternalGroundStationPass
     */
    id: number;
    /**
     * The datetime when this GroundStationPass was created (in our DB).
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    create_date: string;
    /**
     * The datetime when this GroundStationPass was last updated (in our DB).
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    update_date: string;
    /**
     * The GroundStation that this GroundStationPass is associated with.
     * @type {number}
     * @memberof InternalGroundStationPass
     */
    ground_station: number | null;
    /**
     * External UUID that identifies this record.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    external_id?: string | null;
    /**
     * The external source where this GroundStationPass originates.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    external_source?: InternalGroundStationPassExternalSourceEnum;
    /**
     * The external_id of the Sensor object that this GroundStationPass object belongs to.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    sensor_external_id: string;
    /**
     * String that can be used to uniquely identify which ground station this GroundStationPass is associated with.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    ground_station_short_name: string;
    /**
     * Start time of the pass (inclusive).
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    start_time: string;
    /**
     * End time of the pass (exclusive).
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    end_time: string;
    /**
     * Rise time of the pass.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    rise_time?: string | null;
    /**
     * Set time of the pass.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    set_time?: string | null;
    /**
     * Max Elevation in Degrees.
     * @type {number}
     * @memberof InternalGroundStationPass
     */
    max_elevation: number;
    /**
     * Status indicating whether AD or ASI has booked this pass for downlinking.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    schedule_status?: string | null;
    /**
     * Field indicating if the pass will be operated by AD or ASI.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    operator?: string | null;
    /**
     * The seed date is used to group together records that were imported at the same time. Only records containing the max(seed_date) of the table are considered active.
     * @type {string}
     * @memberof InternalGroundStationPass
     */
    seed_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalGroundStationPassExternalSourceEnum {
    Ad = 'AD',
    Null = 'null',
}

/**
 * Serializer for the InternalGroundStationPassViewSet
 * @export
 * @interface InternalGroundStationPassRequest
 */
export interface InternalGroundStationPassRequest {
    /**
     * External UUID that identifies this record.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    external_id?: string | null;
    /**
     * The external source where this GroundStationPass originates.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    external_source?: InternalGroundStationPassRequestExternalSourceEnum;
    /**
     * The external_id of the Sensor object that this GroundStationPass object belongs to.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    sensor_external_id: string;
    /**
     * String that can be used to uniquely identify which ground station this GroundStationPass is associated with.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    ground_station_short_name: string;
    /**
     * Start time of the pass (inclusive).
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    start_time: string;
    /**
     * End time of the pass (exclusive).
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    end_time: string;
    /**
     * Rise time of the pass.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    rise_time?: string | null;
    /**
     * Set time of the pass.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    set_time?: string | null;
    /**
     * Max Elevation in Degrees.
     * @type {number}
     * @memberof InternalGroundStationPassRequest
     */
    max_elevation: number;
    /**
     * Status indicating whether AD or ASI has booked this pass for downlinking.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    schedule_status?: string | null;
    /**
     * Field indicating if the pass will be operated by AD or ASI.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    operator?: string | null;
    /**
     * The seed date is used to group together records that were imported at the same time. Only records containing the max(seed_date) of the table are considered active.
     * @type {string}
     * @memberof InternalGroundStationPassRequest
     */
    seed_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalGroundStationPassRequestExternalSourceEnum {
    Ad = 'AD',
    Null = 'null',
}

/**
 * This serializer will be used by internal applications that need all the available fields in the Tick model.
 * @export
 * @interface InternalOpportunity
 */
export interface InternalOpportunity {
    /**
     *
     * @type {number}
     * @memberof InternalOpportunity
     */
    id: number;
    /**
     * The start time of the propagator run which produced this tick
     * @type {string}
     * @memberof InternalOpportunity
     */
    propagator_seed_time: string;
    /**
     * Nadir point directly underneath the sensor.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOpportunity
     */
    nadir_point: Geometry | GeometryCollection;
    /**
     * Polygon covering Field of View while pointed at nadir.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOpportunity
     */
    nadir_fov: Geometry | GeometryCollection;
    /**
     * Polygon covering all areas potentially within the Field of View allowed by slewing. Sensors will not be able to image the entire max field of view in one pass.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOpportunity
     */
    max_fov: Geometry | GeometryCollection;
    /**
     * Start of the time interval covered by this opportunity.
     * @type {string}
     * @memberof InternalOpportunity
     */
    start_time: string;
    /**
     * End of the time interval covered by this opportunity, by default 1 second after start_time.
     * @type {string}
     * @memberof InternalOpportunity
     */
    end_time: string;
    /**
     * The position of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    position: Array<number>;
    /**
     * The next tick\'s position of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    position_next: Array<number>;
    /**
     * The velocity of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    velocity: Array<number>;
    /**
     * The next tick\'s velocity of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    velocity_next: Array<number>;
    /**
     * The J2000 time for this record.  The J2000 time represents the number of seconds that have passed since Jan 1, 2000 at 12:00:00 PM.
     * @type {number}
     * @memberof InternalOpportunity
     */
    j2000_time: number;
    /**
     * The position of the satellite in the ECEF Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    position_ecef: Array<number>;
    /**
     * The position of the satellite in the ECEF Coordinate System as a vector at time t + 1. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    position_ecef_next: Array<number>;
    /**
     * The velocity of the satellite in the ECEF Coordinate System as a vector.The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    velocity_ecef: Array<number>;
    /**
     * The velocity of the satellite in the ECEF Coordinate System as a vector at time t + 1.The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunity
     */
    velocity_ecef_next: Array<number>;
    /**
     * If this field is true, then the satellite is considered \"eclipsed\" which means it is currently night at the location of the satellite.  At the time of this writing, collecting imagery is not possible if the target area is eclipsed.
     * @type {boolean}
     * @memberof InternalOpportunity
     */
    eclipsed: boolean;
    /**
     * This field indicates whether or not this tick is blocked out via the core_api_taskingblockoutwindow. If true, the tick is not eligible for tasking. If false, the tick is eligible for tasking.
     * @type {boolean}
     * @memberof InternalOpportunity
     */
    blocked_out: boolean;
    /**
     * This field represents the orbit number of the Tick.  An orbit is defined as 1 eclipsed & 1 non-eclipsed period (starting with an eclipse and ending with non-eclipse).  This will be useful for filtering data from the client (and grabbing single orbits).
     * @type {number}
     * @memberof InternalOpportunity
     */
    orbit_num: number;
    /**
     * This field represents the row number (starting at 0) of this tick record in the original orbit propagator file. This is used by the tasking engine.
     * @type {number}
     * @memberof InternalOpportunity
     */
    row_num: number;
    /**
     * This represents the ellipsoidal height of the satellite (in meters). In other words, this is the distance the satellite is from the WGS84 ellipsoid.  This value can be used to find the true altitude of the satellite (the Orthometric Height) by using this simple equation:  Orthometric Height = Ellipsoidal Height - Geoid Height  Currently the most accurate Geoid that covers most of the world is the EGM2008 Geoid (which you can use to find the Geoid Height).
     * @type {number}
     * @memberof InternalOpportunity
     */
    ellipsoidal_height: number;
    /**
     * The sensor this tick belongs to.
     * @type {number}
     * @memberof InternalOpportunity
     */
    sensor: number;
    /**
     * The state vector that was used as input into the Orbit Propagator which produced this Tick record.
     * @type {number}
     * @memberof InternalOpportunity
     */
    state_vector?: number | null;
}
/**
 * This serializer will be used by internal applications that need all the available fields in the Tick model.
 * @export
 * @interface InternalOpportunityRequest
 */
export interface InternalOpportunityRequest {
    /**
     * The start time of the propagator run which produced this tick
     * @type {string}
     * @memberof InternalOpportunityRequest
     */
    propagator_seed_time: string;
    /**
     * Nadir point directly underneath the sensor.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOpportunityRequest
     */
    nadir_point: Geometry | GeometryCollection;
    /**
     * Polygon covering Field of View while pointed at nadir.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOpportunityRequest
     */
    nadir_fov: Geometry | GeometryCollection;
    /**
     * Polygon covering all areas potentially within the Field of View allowed by slewing. Sensors will not be able to image the entire max field of view in one pass.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOpportunityRequest
     */
    max_fov: Geometry | GeometryCollection;
    /**
     * Start of the time interval covered by this opportunity.
     * @type {string}
     * @memberof InternalOpportunityRequest
     */
    start_time: string;
    /**
     * End of the time interval covered by this opportunity, by default 1 second after start_time.
     * @type {string}
     * @memberof InternalOpportunityRequest
     */
    end_time: string;
    /**
     * The position of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    position: Array<number>;
    /**
     * The next tick\'s position of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    position_next: Array<number>;
    /**
     * The velocity of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    velocity: Array<number>;
    /**
     * The next tick\'s velocity of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    velocity_next: Array<number>;
    /**
     * The J2000 time for this record.  The J2000 time represents the number of seconds that have passed since Jan 1, 2000 at 12:00:00 PM.
     * @type {number}
     * @memberof InternalOpportunityRequest
     */
    j2000_time: number;
    /**
     * The position of the satellite in the ECEF Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    position_ecef: Array<number>;
    /**
     * The position of the satellite in the ECEF Coordinate System as a vector at time t + 1. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    position_ecef_next: Array<number>;
    /**
     * The velocity of the satellite in the ECEF Coordinate System as a vector.The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    velocity_ecef: Array<number>;
    /**
     * The velocity of the satellite in the ECEF Coordinate System as a vector at time t + 1.The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof InternalOpportunityRequest
     */
    velocity_ecef_next: Array<number>;
    /**
     * If this field is true, then the satellite is considered \"eclipsed\" which means it is currently night at the location of the satellite.  At the time of this writing, collecting imagery is not possible if the target area is eclipsed.
     * @type {boolean}
     * @memberof InternalOpportunityRequest
     */
    eclipsed: boolean;
    /**
     * This field indicates whether or not this tick is blocked out via the core_api_taskingblockoutwindow. If true, the tick is not eligible for tasking. If false, the tick is eligible for tasking.
     * @type {boolean}
     * @memberof InternalOpportunityRequest
     */
    blocked_out: boolean;
    /**
     * This field represents the orbit number of the Tick.  An orbit is defined as 1 eclipsed & 1 non-eclipsed period (starting with an eclipse and ending with non-eclipse).  This will be useful for filtering data from the client (and grabbing single orbits).
     * @type {number}
     * @memberof InternalOpportunityRequest
     */
    orbit_num: number;
    /**
     * This field represents the row number (starting at 0) of this tick record in the original orbit propagator file. This is used by the tasking engine.
     * @type {number}
     * @memberof InternalOpportunityRequest
     */
    row_num: number;
    /**
     * This represents the ellipsoidal height of the satellite (in meters). In other words, this is the distance the satellite is from the WGS84 ellipsoid.  This value can be used to find the true altitude of the satellite (the Orthometric Height) by using this simple equation:  Orthometric Height = Ellipsoidal Height - Geoid Height  Currently the most accurate Geoid that covers most of the world is the EGM2008 Geoid (which you can use to find the Geoid Height).
     * @type {number}
     * @memberof InternalOpportunityRequest
     */
    ellipsoidal_height: number;
    /**
     * The sensor this tick belongs to.
     * @type {number}
     * @memberof InternalOpportunityRequest
     */
    sensor: number;
    /**
     * The state vector that was used as input into the Orbit Propagator which produced this Tick record.
     * @type {number}
     * @memberof InternalOpportunityRequest
     */
    state_vector?: number | null;
}
/**
 * Adds the relevant fields for serializing orders from internal endpoints
 * @export
 * @interface InternalOrder
 */
export interface InternalOrder {
    /**
     *
     * @type {number}
     * @memberof InternalOrder
     */
    pk: number;
    /**
     * The Program for which this order has been placed
     * @type {number}
     * @memberof InternalOrder
     */
    program: number;
    /**
     * Universal unique ID (UUID) assigned to each order
     * @type {string}
     * @memberof InternalOrder
     */
    id: string;
    /**
     * Priority of this order. 0 (High) to 9 (low)
     * @type {number}
     * @memberof InternalOrder
     */
    priority?: number;
    /**
     * The provider of the data for this order (OSK)
     * @type {string}
     * @memberof InternalOrder
     */
    dataProvider: string;
    /**
     * Name of the customer for this order
     * @type {string}
     * @memberof InternalOrder
     */
    dataConsumer?: string;
    /**
     * Timestamp that this order was placed, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof InternalOrder
     */
    orderTimeStamp: string;
    /**
     * Unique requirement ID defined by customer
     * @type {string}
     * @memberof InternalOrder
     */
    reqId?: string;
    /**
     * Optional space for comments or notes about this order
     * @type {string}
     * @memberof InternalOrder
     */
    customerComments?: string;
    /**
     * Specifies if this a Tasking Order for new imagery or an Archive order for existing imagery
     * @type {string}
     * @memberof InternalOrder
     */
    orderType: InternalOrderOrderTypeEnum;
    /**
     * Unique Requirement Line Item ID defined by customer
     * @type {string}
     * @memberof InternalOrder
     */
    reqLineItemId?: string;
    /**
     * True when updating a previous Order
     * @type {boolean}
     * @memberof InternalOrder
     */
    isReOrder?: boolean | null;
    /**
     * If Reorder is True, The reason the reorder was needed
     * @type {string}
     * @memberof InternalOrder
     */
    reOrderReason?: string;
    /**
     * Name of the customer\'s Point of Contact for this order
     * @type {string}
     * @memberof InternalOrder
     */
    customerPoc?: string;
    /**
     * Phone number for the customer\'s point of contact on this order
     * @type {string}
     * @memberof InternalOrder
     */
    customerPocPhoneNumber?: string;
    /**
     * If True, special handling required for this order
     * @type {boolean}
     * @memberof InternalOrder
     */
    requiredSpecialHandling?: boolean | null;
    /**
     * Specific instructions for special handling if needed
     * @type {string}
     * @memberof InternalOrder
     */
    specialHandlingInstructions?: string;
    /**
     * Minimum acceptable GSD in meters
     * @type {number}
     * @memberof InternalOrder
     */
    minimumGsd?: number | null;
    /**
     * Desired GSD for order in meters
     * @type {number}
     * @memberof InternalOrder
     */
    requestedGsd?: number | null;
    /**
     * Address of GovCloud S3 Bucket
     * @type {string}
     * @memberof InternalOrder
     */
    s3Bucket: string | null;
    /**
     * Type of S3 bucket being used
     * @type {string}
     * @memberof InternalOrder
     */
    s3Type?: InternalOrderS3TypeEnum;
    /**
     * Optional Sub-folder within s3 Bucket path
     * @type {string}
     * @memberof InternalOrder
     */
    s3Folder?: string | null;
    /**
     * The data product level for the order
     * @type {string}
     * @memberof InternalOrder
     */
    productLevel?: InternalOrderProductLevelEnum;
    /**
     * The file format the data should be delivered in
     * @type {string}
     * @memberof InternalOrder
     */
    fileFormat?: InternalOrderFileFormatEnum;
    /**
     * Display name used to describe this Order
     * @type {string}
     * @memberof InternalOrder
     */
    name?: string;
    /**
     *
     * @type {OrderStatus}
     * @memberof InternalOrder
     */
    orderStatus: OrderStatus | null;
    /**
     * Always False except where True is allowed for point target orders.
     * @type {boolean}
     * @memberof InternalOrder
     */
    isArchaic01?: boolean;
    /**
     * Withold prevents imagery from showing in the public catalog.
     * @type {boolean}
     * @memberof InternalOrder
     */
    isWithhold?: boolean;
    /**
     * How long to withhold image from catalog. Possible Options: 30 days, Permanent
     * @type {string}
     * @memberof InternalOrder
     */
    withholdLength?: InternalOrderWithholdLengthEnum;
    /**
     * Which sensor is requested to complete the tasking order. Default: Any sensor
     * @type {string}
     * @memberof InternalOrder
     */
    sensor?: string;
    /**
     * When imagery collection starts, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof InternalOrder
     */
    startCollectDateTime?: string;
    /**
     * When imagery collection ends, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof InternalOrder
     */
    endCollectDateTime?: string;
    /**
     * Maximum cloud cover acceptable in image as a percentage. Range: 0-100
     * @type {number}
     * @memberof InternalOrder
     */
    maximumCloudCover?: number | null;
    /**
     * Minimum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0.00 - 45.00 deg
     * @type {number}
     * @memberof InternalOrder
     */
    minimumOffNadirAngle?: number;
    /**
     * Maximum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0 - 90 deg
     * @type {number}
     * @memberof InternalOrder
     */
    maximumOffNadirAngle?: number;
    /**
     * The minimum azimuth angle from target to satellite for the collection with respect to North (clockwise). Range 0.0 - 359.99 deg
     * @type {number}
     * @memberof InternalOrder
     */
    minimumAzimuthAngle?: number | null;
    /**
     * The maximum azimuth angle from target to satellite for the collection with respect to North. Range: 0.0 - 359.99 deg. NOTE: Angles are read clockwise so it is possible for max to be less than min.
     * @type {number}
     * @memberof InternalOrder
     */
    maximumAzimuthAngle?: number | null;
    /**
     * The minimum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof InternalOrder
     */
    minimumSunElevationAngle?: number | null;
    /**
     * The maximum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof InternalOrder
     */
    maximumSunElevationAngle?: number | null;
    /**
     * The minimum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof InternalOrder
     */
    minimumSunAzimuth?: number | null;
    /**
     * The maximum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof InternalOrder
     */
    maximumSunAzimuth?: number | null;
    /**
     * If this order is for mono or stereo imagery. NOTE: Only mono imagery accepted at this time
     * @type {string}
     * @memberof InternalOrder
     */
    monoStereo?: string | null;
    /**
     * Maximum acceptable GSD. Range: 0.01 to 100.00 meters
     * @type {number}
     * @memberof InternalOrder
     */
    maximumAcceptableGsd?: number | null;
    /**
     * Allows order to be satisfied with an archive imagery, new imagery collection is not required.
     * @type {boolean}
     * @memberof InternalOrder
     */
    isArchiveAcceptable?: boolean | null;
    /**
     * Earliest date allowable for collection date of archived data, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof InternalOrder
     */
    archiveAcceptableDate?: string | null;
    /**
     * Required to collect in one access
     * @type {boolean}
     * @memberof InternalOrder
     */
    isSinglePass?: boolean | null;
    /**
     * Area of interest to collect, in GeoJSON or WKT format
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOrder
     */
    aoiTarget?: Geometry | GeometryCollection | null;
    /**
     * Geometry type of target
     * @type {string}
     * @memberof InternalOrder
     */
    aoiTargetType?: InternalOrderAoiTargetTypeEnum;
    /**
     * List of captures requested in this Order. Captures are referred to using inventoryId
     * @type {Array<string>}
     * @memberof InternalOrder
     */
    inventoryId?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalOrderOrderTypeEnum {
    Archive = 'archive',
    Tasking = 'tasking',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderS3TypeEnum {
    S3 = 'S3',
    GovCloudS3 = 'GovCloud S3',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderProductLevelEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderFileFormatEnum {
    Nitf = 'NITF',
    Envi = 'ENVI',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderWithholdLengthEnum {
    _30Days = '30 days',
    Permanent = 'permanent',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderAoiTargetTypeEnum {
    Point = 'point',
    Area = 'area',
}

/**
 * Adds the relevant fields for serializing orders from internal endpoints
 * @export
 * @interface InternalOrderRequest
 */
export interface InternalOrderRequest {
    /**
     * The Program for which this order has been placed
     * @type {number}
     * @memberof InternalOrderRequest
     */
    program: number;
    /**
     * Priority of this order. 0 (High) to 9 (low)
     * @type {number}
     * @memberof InternalOrderRequest
     */
    priority?: number;
    /**
     * Name of the customer for this order
     * @type {string}
     * @memberof InternalOrderRequest
     */
    dataConsumer?: string;
    /**
     * Unique requirement ID defined by customer
     * @type {string}
     * @memberof InternalOrderRequest
     */
    reqId?: string;
    /**
     * Optional space for comments or notes about this order
     * @type {string}
     * @memberof InternalOrderRequest
     */
    customerComments?: string;
    /**
     * Specifies if this a Tasking Order for new imagery or an Archive order for existing imagery
     * @type {string}
     * @memberof InternalOrderRequest
     */
    orderType: InternalOrderRequestOrderTypeEnum;
    /**
     * Unique Requirement Line Item ID defined by customer
     * @type {string}
     * @memberof InternalOrderRequest
     */
    reqLineItemId?: string;
    /**
     * True when updating a previous Order
     * @type {boolean}
     * @memberof InternalOrderRequest
     */
    isReOrder?: boolean | null;
    /**
     * If Reorder is True, The reason the reorder was needed
     * @type {string}
     * @memberof InternalOrderRequest
     */
    reOrderReason?: string;
    /**
     * Name of the customer\'s Point of Contact for this order
     * @type {string}
     * @memberof InternalOrderRequest
     */
    customerPoc?: string;
    /**
     * Phone number for the customer\'s point of contact on this order
     * @type {string}
     * @memberof InternalOrderRequest
     */
    customerPocPhoneNumber?: string;
    /**
     * If True, special handling required for this order
     * @type {boolean}
     * @memberof InternalOrderRequest
     */
    requiredSpecialHandling?: boolean | null;
    /**
     * Specific instructions for special handling if needed
     * @type {string}
     * @memberof InternalOrderRequest
     */
    specialHandlingInstructions?: string;
    /**
     * Minimum acceptable GSD in meters
     * @type {number}
     * @memberof InternalOrderRequest
     */
    minimumGsd?: number | null;
    /**
     * Desired GSD for order in meters
     * @type {number}
     * @memberof InternalOrderRequest
     */
    requestedGsd?: number | null;
    /**
     * Address of GovCloud S3 Bucket
     * @type {string}
     * @memberof InternalOrderRequest
     */
    s3Bucket: string | null;
    /**
     * Type of S3 bucket being used
     * @type {string}
     * @memberof InternalOrderRequest
     */
    s3Type?: InternalOrderRequestS3TypeEnum;
    /**
     * Optional Sub-folder within s3 Bucket path
     * @type {string}
     * @memberof InternalOrderRequest
     */
    s3Folder?: string | null;
    /**
     * The data product level for the order
     * @type {string}
     * @memberof InternalOrderRequest
     */
    productLevel?: InternalOrderRequestProductLevelEnum;
    /**
     * The file format the data should be delivered in
     * @type {string}
     * @memberof InternalOrderRequest
     */
    fileFormat?: InternalOrderRequestFileFormatEnum;
    /**
     * Display name used to describe this Order
     * @type {string}
     * @memberof InternalOrderRequest
     */
    name?: string;
    /**
     * Always False except where True is allowed for point target orders.
     * @type {boolean}
     * @memberof InternalOrderRequest
     */
    isArchaic01?: boolean;
    /**
     * Withold prevents imagery from showing in the public catalog.
     * @type {boolean}
     * @memberof InternalOrderRequest
     */
    isWithhold?: boolean;
    /**
     * How long to withhold image from catalog. Possible Options: 30 days, Permanent
     * @type {string}
     * @memberof InternalOrderRequest
     */
    withholdLength?: InternalOrderRequestWithholdLengthEnum;
    /**
     * Which sensor is requested to complete the tasking order. Default: Any sensor
     * @type {string}
     * @memberof InternalOrderRequest
     */
    sensor?: string;
    /**
     * When imagery collection starts, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof InternalOrderRequest
     */
    startCollectDateTime?: string;
    /**
     * When imagery collection ends, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof InternalOrderRequest
     */
    endCollectDateTime?: string;
    /**
     * Maximum cloud cover acceptable in image as a percentage. Range: 0-100
     * @type {number}
     * @memberof InternalOrderRequest
     */
    maximumCloudCover?: number | null;
    /**
     * Minimum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0.00 - 45.00 deg
     * @type {number}
     * @memberof InternalOrderRequest
     */
    minimumOffNadirAngle?: number;
    /**
     * Maximum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0 - 90 deg
     * @type {number}
     * @memberof InternalOrderRequest
     */
    maximumOffNadirAngle?: number;
    /**
     * The minimum azimuth angle from target to satellite for the collection with respect to North (clockwise). Range 0.0 - 359.99 deg
     * @type {number}
     * @memberof InternalOrderRequest
     */
    minimumAzimuthAngle?: number | null;
    /**
     * The maximum azimuth angle from target to satellite for the collection with respect to North. Range: 0.0 - 359.99 deg. NOTE: Angles are read clockwise so it is possible for max to be less than min.
     * @type {number}
     * @memberof InternalOrderRequest
     */
    maximumAzimuthAngle?: number | null;
    /**
     * The minimum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof InternalOrderRequest
     */
    minimumSunElevationAngle?: number | null;
    /**
     * The maximum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof InternalOrderRequest
     */
    maximumSunElevationAngle?: number | null;
    /**
     * The minimum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof InternalOrderRequest
     */
    minimumSunAzimuth?: number | null;
    /**
     * The maximum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof InternalOrderRequest
     */
    maximumSunAzimuth?: number | null;
    /**
     * If this order is for mono or stereo imagery. NOTE: Only mono imagery accepted at this time
     * @type {string}
     * @memberof InternalOrderRequest
     */
    monoStereo?: string | null;
    /**
     * Maximum acceptable GSD. Range: 0.01 to 100.00 meters
     * @type {number}
     * @memberof InternalOrderRequest
     */
    maximumAcceptableGsd?: number | null;
    /**
     * Allows order to be satisfied with an archive imagery, new imagery collection is not required.
     * @type {boolean}
     * @memberof InternalOrderRequest
     */
    isArchiveAcceptable?: boolean | null;
    /**
     * Earliest date allowable for collection date of archived data, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof InternalOrderRequest
     */
    archiveAcceptableDate?: string | null;
    /**
     * Required to collect in one access
     * @type {boolean}
     * @memberof InternalOrderRequest
     */
    isSinglePass?: boolean | null;
    /**
     * Area of interest to collect, in GeoJSON or WKT format
     * @type {Geometry | GeometryCollection}
     * @memberof InternalOrderRequest
     */
    aoiTarget?: Geometry | GeometryCollection | null;
    /**
     * Geometry type of target
     * @type {string}
     * @memberof InternalOrderRequest
     */
    aoiTargetType?: InternalOrderRequestAoiTargetTypeEnum;
    /**
     * List of captures requested in this Order. Captures are referred to using inventoryId
     * @type {Array<string>}
     * @memberof InternalOrderRequest
     */
    inventoryId?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalOrderRequestOrderTypeEnum {
    Archive = 'archive',
    Tasking = 'tasking',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderRequestS3TypeEnum {
    S3 = 'S3',
    GovCloudS3 = 'GovCloud S3',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderRequestProductLevelEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderRequestFileFormatEnum {
    Nitf = 'NITF',
    Envi = 'ENVI',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderRequestWithholdLengthEnum {
    _30Days = '30 days',
    Permanent = 'permanent',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderRequestAoiTargetTypeEnum {
    Point = 'point',
    Area = 'area',
}

/**
 * serializer used for internal endpoints
 * @export
 * @interface InternalOrderStatus
 */
export interface InternalOrderStatus {
    /**
     * Universal unique ID (UUID) assigned to each status
     * @type {string}
     * @memberof InternalOrderStatus
     */
    id: string;
    /**
     *
     * @type {number}
     * @memberof InternalOrderStatus
     */
    pk: number;
    /**
     *
     * @type {string}
     * @memberof InternalOrderStatus
     */
    order: string;
    /**
     * Universal unique ID (UUID) assigned to each status
     * @type {string}
     * @memberof InternalOrderStatus
     */
    uuid: string;
    /**
     * Timestamp for when Order status was set
     * @type {string}
     * @memberof InternalOrderStatus
     */
    status_timestamp: string;
    /**
     * Status of the Order at timestamp
     * @type {string}
     * @memberof InternalOrderStatus
     */
    requirement_line_item_status: InternalOrderStatusRequirementLineItemStatusEnum;
    /**
     * Rationale for status
     * @type {string}
     * @memberof InternalOrderStatus
     */
    requirement_line_status_code?: InternalOrderStatusRequirementLineStatusCodeEnum;
    /**
     * Explanation of Rationale for status
     * @type {string}
     * @memberof InternalOrderStatus
     */
    requirement_line_status_text?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalOrderStatusRequirementLineItemStatusEnum {
    Manual = 'manual',
    ModReceived = 'mod_received',
    ModAccepted = 'mod_accepted',
    ModRejected = 'mod_rejected',
    OrderReceived = 'order_received',
    OrderAccepted = 'order_accepted',
    OrderRejected = 'order_rejected',
    OrderActive = 'order_active',
    OrderExpired = 'order_expired',
    OrderScheduled = 'order_scheduled',
    OrderTasked = 'order_tasked',
    OrderProcessing = 'order_processing',
    OrderDelivered = 'order_delivered',
    OrderCanceled = 'order_canceled',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderStatusRequirementLineStatusCodeEnum {
    Aa = 'AA',
    Ab = 'AB',
    Ac = 'AC',
    Ad = 'AD',
    Ae = 'AE',
    Ah0 = 'AH0',
    Ah1 = 'AH1',
    Ps = 'PS',
    Ah2 = 'AH2',
    Ai = 'AI',
    Aj = 'AJ',
    Ak = 'AK',
    S1 = 'S1',
    S2 = 'S2',
    P1 = 'P1',
    P3 = 'P3',
    P2 = 'P2',
    Te = 'TE',
    Ff = 'FF',
    Null = 'null',
}

/**
 * serializer used for internal endpoints
 * @export
 * @interface InternalOrderStatusRequest
 */
export interface InternalOrderStatusRequest {
    /**
     *
     * @type {string}
     * @memberof InternalOrderStatusRequest
     */
    order: string;
    /**
     * Status of the Order at timestamp
     * @type {string}
     * @memberof InternalOrderStatusRequest
     */
    requirement_line_item_status: InternalOrderStatusRequestRequirementLineItemStatusEnum;
    /**
     * Rationale for status
     * @type {string}
     * @memberof InternalOrderStatusRequest
     */
    requirement_line_status_code?: InternalOrderStatusRequestRequirementLineStatusCodeEnum;
    /**
     * Explanation of Rationale for status
     * @type {string}
     * @memberof InternalOrderStatusRequest
     */
    requirement_line_status_text?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalOrderStatusRequestRequirementLineItemStatusEnum {
    Manual = 'manual',
    ModReceived = 'mod_received',
    ModAccepted = 'mod_accepted',
    ModRejected = 'mod_rejected',
    OrderReceived = 'order_received',
    OrderAccepted = 'order_accepted',
    OrderRejected = 'order_rejected',
    OrderActive = 'order_active',
    OrderExpired = 'order_expired',
    OrderScheduled = 'order_scheduled',
    OrderTasked = 'order_tasked',
    OrderProcessing = 'order_processing',
    OrderDelivered = 'order_delivered',
    OrderCanceled = 'order_canceled',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalOrderStatusRequestRequirementLineStatusCodeEnum {
    Aa = 'AA',
    Ab = 'AB',
    Ac = 'AC',
    Ad = 'AD',
    Ae = 'AE',
    Ah0 = 'AH0',
    Ah1 = 'AH1',
    Ps = 'PS',
    Ah2 = 'AH2',
    Ai = 'AI',
    Aj = 'AJ',
    Ak = 'AK',
    S1 = 'S1',
    S2 = 'S2',
    P1 = 'P1',
    P3 = 'P3',
    P2 = 'P2',
    Te = 'TE',
    Ff = 'FF',
    Null = 'null',
}

/**
 *
 * @export
 * @interface InternalReport
 */
export interface InternalReport {
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    report_type?: InternalReportReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    status?: InternalReportStatusEnum;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof InternalReport
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    end_date: string;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    created_at: string;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    updated_at: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalReportReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalReportStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface InternalReportRequest
 */
export interface InternalReportRequest {
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    report_type?: InternalReportRequestReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    status?: InternalReportRequestStatusEnum;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof InternalReportRequest
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    end_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalReportRequestStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface InternalRole
 */
export interface InternalRole {
    /**
     *
     * @type {number}
     * @memberof InternalRole
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof InternalRole
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof InternalRole
     */
    slug: string;
    /**
     *
     * @type {number}
     * @memberof InternalRole
     */
    permission_set: number;
    /**
     *
     * @type {number}
     * @memberof InternalRole
     */
    resource: number;
}
/**
 *
 * @export
 * @interface InternalRoleRequest
 */
export interface InternalRoleRequest {
    /**
     *
     * @type {string}
     * @memberof InternalRoleRequest
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof InternalRoleRequest
     */
    slug: string;
}
/**
 *
 * @export
 * @interface InternalSensor
 */
export interface InternalSensor {
    /**
     *
     * @type {number}
     * @memberof InternalSensor
     */
    osk_id: number;
    /**
     *
     * @type {string}
     * @memberof InternalSensor
     */
    osk_sensor_name: string;
    /**
     *
     * @type {string}
     * @memberof InternalSensor
     */
    osk_serial_number: string;
    /**
     *
     * @type {string}
     * @memberof InternalSensor
     */
    nro_id: string;
    /**
     *
     * @type {number}
     * @memberof InternalSensor
     */
    default_frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalSensor
     */
    ifov?: number | null;
    /**
     *
     * @type {string}
     * @memberof InternalSensor
     */
    catalog_number?: string | null;
    /**
     * The maximum angle allowed for the telescope to slew to/from a target
     * @type {number}
     * @memberof InternalSensor
     */
    max_slew_angle?: number | null;
    /**
     * The maximum velocity allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof InternalSensor
     */
    max_roll_velocity?: number | null;
    /**
     * The maximum acceleration allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof InternalSensor
     */
    max_roll_accel?: number | null;
    /**
     * The maximum jerk allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof InternalSensor
     */
    max_roll_jerk?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalSensor
     */
    focal_length: number;
    /**
     *
     * @type {number}
     * @memberof InternalSensor
     */
    detector_size: number;
    /**
     *
     * @type {number}
     * @memberof InternalSensor
     */
    spatial_pixels: number;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof InternalSensor
     */
    tasking_eligible_start?: string | null;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof InternalSensor
     */
    tasking_eligible_end?: string | null;
    /**
     * The identifier used by our 3rd party bus provider to identify this sensor / satellite internally. At the time of this writing, this field will contain the identifier used by Astro Digital to identity our satellites.
     * @type {string}
     * @memberof InternalSensor
     */
    external_id?: string | null;
    /**
     * Duration representing the max age of a state vector for use of either generating a tasking plan or submitting a tasking plan for use on the spacecraft (for the current sensor). This mechanism serves as a safety check to ensure we don\'t miss targets due to using a stale state vector.
     * @type {string}
     * @memberof InternalSensor
     */
    max_state_vector_age?: string | null;
    /**
     * The drag model you wish to use during orbit propagation of the current sensor.
     * @type {string}
     * @memberof InternalSensor
     */
    drag_model?: InternalSensorDragModelEnum;
    /**
     * Dimensionless coefficient used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensor
     */
    drag_coefficient?: number | null;
    /**
     * The area to mass ratio (m/kg) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensor
     */
    drag_area_mass_ratio?: number | null;
    /**
     * The atmospheric density (kg/m^3) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensor
     */
    drag_atm_density?: number | null;
    /**
     * The atmospheric density gradient (kg/m^3) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensor
     */
    drag_atm_density_gradient?: number | null;
    /**
     * The reference altitude (in meters) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensor
     */
    drag_ref_altitude?: number | null;
    /**
     * The date and time that the satellite housing this sensor was launched
     * @type {string}
     * @memberof InternalSensor
     */
    launch_date?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalSensorDragModelEnum {
    Disabled = 'DISABLED',
    Standard = 'STANDARD',
    Jacchia70 = 'JACCHIA70',
    Null = 'null',
}

/**
 *
 * @export
 * @interface InternalSensorRequest
 */
export interface InternalSensorRequest {
    /**
     *
     * @type {number}
     * @memberof InternalSensorRequest
     */
    osk_id: number;
    /**
     *
     * @type {string}
     * @memberof InternalSensorRequest
     */
    osk_sensor_name: string;
    /**
     *
     * @type {string}
     * @memberof InternalSensorRequest
     */
    osk_serial_number: string;
    /**
     *
     * @type {string}
     * @memberof InternalSensorRequest
     */
    nro_id: string;
    /**
     *
     * @type {number}
     * @memberof InternalSensorRequest
     */
    default_frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalSensorRequest
     */
    ifov?: number | null;
    /**
     *
     * @type {string}
     * @memberof InternalSensorRequest
     */
    catalog_number?: string | null;
    /**
     * The maximum angle allowed for the telescope to slew to/from a target
     * @type {number}
     * @memberof InternalSensorRequest
     */
    max_slew_angle?: number | null;
    /**
     * The maximum velocity allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof InternalSensorRequest
     */
    max_roll_velocity?: number | null;
    /**
     * The maximum acceleration allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof InternalSensorRequest
     */
    max_roll_accel?: number | null;
    /**
     * The maximum jerk allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof InternalSensorRequest
     */
    max_roll_jerk?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalSensorRequest
     */
    focal_length: number;
    /**
     *
     * @type {number}
     * @memberof InternalSensorRequest
     */
    detector_size: number;
    /**
     *
     * @type {number}
     * @memberof InternalSensorRequest
     */
    spatial_pixels: number;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof InternalSensorRequest
     */
    tasking_eligible_start?: string | null;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof InternalSensorRequest
     */
    tasking_eligible_end?: string | null;
    /**
     * The identifier used by our 3rd party bus provider to identify this sensor / satellite internally. At the time of this writing, this field will contain the identifier used by Astro Digital to identity our satellites.
     * @type {string}
     * @memberof InternalSensorRequest
     */
    external_id?: string | null;
    /**
     * Duration representing the max age of a state vector for use of either generating a tasking plan or submitting a tasking plan for use on the spacecraft (for the current sensor). This mechanism serves as a safety check to ensure we don\'t miss targets due to using a stale state vector.
     * @type {string}
     * @memberof InternalSensorRequest
     */
    max_state_vector_age?: string | null;
    /**
     * The drag model you wish to use during orbit propagation of the current sensor.
     * @type {string}
     * @memberof InternalSensorRequest
     */
    drag_model?: InternalSensorRequestDragModelEnum;
    /**
     * Dimensionless coefficient used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensorRequest
     */
    drag_coefficient?: number | null;
    /**
     * The area to mass ratio (m/kg) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensorRequest
     */
    drag_area_mass_ratio?: number | null;
    /**
     * The atmospheric density (kg/m^3) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensorRequest
     */
    drag_atm_density?: number | null;
    /**
     * The atmospheric density gradient (kg/m^3) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensorRequest
     */
    drag_atm_density_gradient?: number | null;
    /**
     * The reference altitude (in meters) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof InternalSensorRequest
     */
    drag_ref_altitude?: number | null;
    /**
     * The date and time that the satellite housing this sensor was launched
     * @type {string}
     * @memberof InternalSensorRequest
     */
    launch_date?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalSensorRequestDragModelEnum {
    Disabled = 'DISABLED',
    Standard = 'STANDARD',
    Jacchia70 = 'JACCHIA70',
    Null = 'null',
}

/**
 * Serializer for the InternalSensorScheduleViewSet
 * @export
 * @interface InternalSensorSchedule
 */
export interface InternalSensorSchedule {
    /**
     * The workflow_status field tells us if the SensorSchedule is being generated, has finished generating, has been selected to send to AD, is archived, or has failed.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    workflow_status?: InternalSensorScheduleWorkflowStatusEnum;
    /**
     * This field is used to track the progress of the schedule generation process.  The scheduler will create the schedule in \"Orbit Chunks\".  The number of orbits included in a single run of the Tasking Engine is a configurable setting.  When orbit_num >= total_orbits, then this SensorSchedule is considered complete.
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    orbit_num?: number;
    /**
     * This represents the sum of all the child SensorTasks\' score field.  The score of a SensorTask is used todetermine how the schedule is created (and determines the overall priority).
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    total_score?: number;
    /**
     * GeometryCollection that will be excluded from scheduling.  This can be used to de-conflict multiple satellites from collecting the same target or could also be used to limit scheduling to a particular area.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalSensorSchedule
     */
    exclusion_geometry?: Geometry | GeometryCollection;
    /**
     * Represents the version of the Tasking Engine that generated the Flight JAS, JSON File, and SQL Payload Script. This will be serialized to the \'sw_version\' field in the JSON file.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    software_version?: string;
    /**
     * This field contains the entire serialized JSON File Content that AD will receive. The purpose of this file is to provide AD with all the Flight JAS scripts the satellite needs to perform all the imaging tasks.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    json_file?: string;
    /**
     * This field contains the SQL script that needs to be sent to AD, uploaded to the Payload, and executed. The SQL script will populate the on-board database with data it needs to perform each collect.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    sql_file?: string;
    /**
     * This field contains the name of the SQL File. Whenever we need to materialize this file, this is the file name that will be used.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    sql_file_name?: string;
    /**
     * This represents how many SensorTask records have been generated for the current SensorSchedule.
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    total_tasks?: number;
    /**
     * This field contains the Flight Jas script that was stitched together by Astro Digital. It can be run directly on the flat-sat or satellite to perform collects.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    flight_jas_file?: string;
    /**
     *
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    id: number;
    /**
     * The tag belongs to the sched_tag field in the Flight JAS JSON and is also the name of the JSON file that we send to AD.  This field will not contain the \'.json\' extension.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    tag: string;
    /**
     * The parent TaskingSchedule for this SensorSchedule.  The TaskingSchedule serves as the parent object of all tasking related objects.  Each time the TaskingEngine runs, it will start by generating a new TaskingSchedule object.
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    tasking_schedule: number;
    /**
     * The Sensor that the current SensorSchedule belongs to.  Remember there should be 1 SensorSchedule object per Sensor that AD manages (and is active).  This field will also be serialized to the sat_id field in the JSON.
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    sensor: number;
    /**
     * This represents the total number of orbits that will be considered by the Tasking Engine.  This field will be populated by the max orbit_num value in core_api_tick for the corresponding sensor_id.
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    total_orbits: number;
    /**
     * This will be included in the JSON file for AD to reach out to (in case of trouble).  It will be serializedto the \'sched_contact\' field in the JSON.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    contact: string;
    /**
     * This represents the first time any event occurs within all the Flight JAS scripts embedded within the JSON file. This field will be serialized to plan_start_unixtime within the JSON (as a unix timestamp).Defaults to now.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    start_time: string;
    /**
     * This represents the last time any event occurs (plus any duration) within all the Flight JAS scripts embedded within the JSON file. This field will be serialized to plan_end_unixtime within the JSON (as a unix timestamp). Defaults to now.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    end_time: string;
    /**
     * The date when this SensorSchedule was created.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    create_date: string;
    /**
     * The datetime when this SensorSchedule was last updated.
     * @type {string}
     * @memberof InternalSensorSchedule
     */
    update_date: string;
    /**
     * The state vector that was used as input into the Orbit Propagator which produced this SensorSchedule record.
     * @type {number}
     * @memberof InternalSensorSchedule
     */
    state_vector: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalSensorScheduleWorkflowStatusEnum {
    Generating = 'GENERATING',
    Generated = 'GENERATED',
    Committed = 'COMMITTED',
    Archived = 'ARCHIVED',
    Failed = 'FAILED',
}

/**
 * Serializer for the InternalSensorScheduleViewSet
 * @export
 * @interface InternalSensorScheduleRequest
 */
export interface InternalSensorScheduleRequest {
    /**
     * The workflow_status field tells us if the SensorSchedule is being generated, has finished generating, has been selected to send to AD, is archived, or has failed.
     * @type {string}
     * @memberof InternalSensorScheduleRequest
     */
    workflow_status?: InternalSensorScheduleRequestWorkflowStatusEnum;
    /**
     * This field is used to track the progress of the schedule generation process.  The scheduler will create the schedule in \"Orbit Chunks\".  The number of orbits included in a single run of the Tasking Engine is a configurable setting.  When orbit_num >= total_orbits, then this SensorSchedule is considered complete.
     * @type {number}
     * @memberof InternalSensorScheduleRequest
     */
    orbit_num?: number;
    /**
     * This represents the sum of all the child SensorTasks\' score field.  The score of a SensorTask is used todetermine how the schedule is created (and determines the overall priority).
     * @type {number}
     * @memberof InternalSensorScheduleRequest
     */
    total_score?: number;
    /**
     * GeometryCollection that will be excluded from scheduling.  This can be used to de-conflict multiple satellites from collecting the same target or could also be used to limit scheduling to a particular area.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalSensorScheduleRequest
     */
    exclusion_geometry?: Geometry | GeometryCollection;
    /**
     * Represents the version of the Tasking Engine that generated the Flight JAS, JSON File, and SQL Payload Script. This will be serialized to the \'sw_version\' field in the JSON file.
     * @type {string}
     * @memberof InternalSensorScheduleRequest
     */
    software_version?: string;
    /**
     * This field contains the entire serialized JSON File Content that AD will receive. The purpose of this file is to provide AD with all the Flight JAS scripts the satellite needs to perform all the imaging tasks.
     * @type {string}
     * @memberof InternalSensorScheduleRequest
     */
    json_file?: string;
    /**
     * This field contains the SQL script that needs to be sent to AD, uploaded to the Payload, and executed. The SQL script will populate the on-board database with data it needs to perform each collect.
     * @type {string}
     * @memberof InternalSensorScheduleRequest
     */
    sql_file?: string;
    /**
     * This field contains the name of the SQL File. Whenever we need to materialize this file, this is the file name that will be used.
     * @type {string}
     * @memberof InternalSensorScheduleRequest
     */
    sql_file_name?: string;
    /**
     * This represents how many SensorTask records have been generated for the current SensorSchedule.
     * @type {number}
     * @memberof InternalSensorScheduleRequest
     */
    total_tasks?: number;
    /**
     * This field contains the Flight Jas script that was stitched together by Astro Digital. It can be run directly on the flat-sat or satellite to perform collects.
     * @type {string}
     * @memberof InternalSensorScheduleRequest
     */
    flight_jas_file?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalSensorScheduleRequestWorkflowStatusEnum {
    Generating = 'GENERATING',
    Generated = 'GENERATED',
    Committed = 'COMMITTED',
    Archived = 'ARCHIVED',
    Failed = 'FAILED',
}

/**
 * This is the serializer that will be used with the InternalTaskingBlockOutWindowViewSet.
 * @export
 * @interface InternalTaskingBlockOutWindow
 */
export interface InternalTaskingBlockOutWindow {
    /**
     * This field is the identifier of the satellite item.
     * @type {number}
     * @memberof InternalTaskingBlockOutWindow
     */
    sat_item_id?: number | null;
    /**
     * The start time (inclusive) of the TaskingBlockOutWindow.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindow
     */
    start_time: string;
    /**
     * The end time (exclusive) of the TaskingBlockOutWindow.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindow
     */
    end_time: string;
    /**
     * The \'external_id\' field on the GroundStationPass object that the TaskingBlockOutWindow corresponds to. If both the ground_station_pass and the ground_station_pass_external_id are specified, the ground_station_pass property will take precedence.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindow
     */
    ground_station_pass_external_id?: string | null;
    /**
     * This is the GroundStationPass that this block out window is associated with.
     * @type {number}
     * @memberof InternalTaskingBlockOutWindow
     */
    ground_station_pass?: number | null;
    /**
     * If this TaskingBlockOutWindow originates from an external source and has an id, this field will be populated with the external source\'s id.
     * @type {number}
     * @memberof InternalTaskingBlockOutWindow
     */
    external_id?: number | null;
    /**
     * The external source where this TaskingBlockOutWindow originates.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindow
     */
    external_source?: InternalTaskingBlockOutWindowExternalSourceEnum;
    /**
     * The seed date is used to group together records that were imported at the same time. Only records containing the max(seed_date) of the table are considered active.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindow
     */
    seed_date: string;
    /**
     * The datetime when this TaskingBlockOutWindow was created (in our DB).
     * @type {string}
     * @memberof InternalTaskingBlockOutWindow
     */
    create_date: string;
    /**
     * The datetime when this TaskingBlockOutWindow was last updated (in our DB).
     * @type {string}
     * @memberof InternalTaskingBlockOutWindow
     */
    update_date: string;
    /**
     * The sensor this TaskingBlockOutWindow belongs to.
     * @type {number}
     * @memberof InternalTaskingBlockOutWindow
     */
    sensor: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalTaskingBlockOutWindow
     */
    id: number;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalTaskingBlockOutWindowExternalSourceEnum {
    Ad = 'AD',
    Null = 'null',
}

/**
 * This is the serializer that will be used with the InternalTaskingBlockOutWindowViewSet.
 * @export
 * @interface InternalTaskingBlockOutWindowRequest
 */
export interface InternalTaskingBlockOutWindowRequest {
    /**
     * This field is the identifier of the satellite item.
     * @type {number}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    sat_item_id?: number | null;
    /**
     * The start time (inclusive) of the TaskingBlockOutWindow.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    start_time: string;
    /**
     * The end time (exclusive) of the TaskingBlockOutWindow.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    end_time: string;
    /**
     * The \'external_id\' field on the GroundStationPass object that the TaskingBlockOutWindow corresponds to. If both the ground_station_pass and the ground_station_pass_external_id are specified, the ground_station_pass property will take precedence.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    ground_station_pass_external_id?: string | null;
    /**
     * This is the GroundStationPass that this block out window is associated with.
     * @type {number}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    ground_station_pass?: number | null;
    /**
     * The external_id of the Sensor object that this GroundStationPass object belongs to.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    sensor_external_id: string;
    /**
     * If this TaskingBlockOutWindow originates from an external source and has an id, this field will be populated with the external source\'s id.
     * @type {number}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    external_id?: number | null;
    /**
     * The external source where this TaskingBlockOutWindow originates.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    external_source?: InternalTaskingBlockOutWindowRequestExternalSourceEnum;
    /**
     * The seed date is used to group together records that were imported at the same time. Only records containing the max(seed_date) of the table are considered active.
     * @type {string}
     * @memberof InternalTaskingBlockOutWindowRequest
     */
    seed_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalTaskingBlockOutWindowRequestExternalSourceEnum {
    Ad = 'AD',
    Null = 'null',
}

/**
 * Serializer for the InternalTaskingRequestViewSet.
 * @export
 * @interface InternalTaskingRequest
 */
export interface InternalTaskingRequest {
    /**
     * This geometry represents the area we wish to capture. This can be any geometry type including a GeometryCollection.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalTaskingRequest
     */
    requested_geometry: Geometry | GeometryCollection;
    /**
     * If the TaskingRequest originates from an Asset that we are monitoring, this linkage should be populated. If the TaskingRequest does not originate from an Asset, this property will be NULL.
     * @type {number}
     * @memberof InternalTaskingRequest
     */
    asset?: number | null;
    /**
     * This is the datetime (inclusive) representing when this tasking requests becomes active.
     * @type {string}
     * @memberof InternalTaskingRequest
     */
    start_date: string;
    /**
     * This is the datetime (exclusive) representing when this tasking requests becomes inactive.  If this value is NULL, the TaskingRequest will remain active until 100%% of the geometry is collected.
     * @type {string}
     * @memberof InternalTaskingRequest
     */
    end_date?: string | null;
    /**
     * Human Readable name of the Tasking Request
     * @type {string}
     * @memberof InternalTaskingRequest
     */
    name: string;
    /**
     * The priority defines how the tasking request should be prioritized and approved.
     * @type {number}
     * @memberof InternalTaskingRequest
     */
    priority?: InternalTaskingRequestPriorityEnum;
    /**
     * The Sensors that the Tasking Scheduler will consider this TaskingRequest for tasking on
     * @type {Array<number>}
     * @memberof InternalTaskingRequest
     */
    sensors: Array<number>;
    /**
     * By setting this flag to true, the Tasking Engine will not run the \"subtraction\" logic on this Tasking Request. For example, if this TaskingRequest is a point target and is_recurring is False, then the Tasking Engine will only ever schedule the target once (if the chunk size of the tasking engine is set correctly). After the engine schedules the target and the chunk is completely processed, all the areas scheduled for collection are subtracted from the next chunk. On the other hand, if is_recurring = True, this target will be scheduled over and over again. I would imagine most point targets need to be set to is_recurring = True and all other targets to is_recurring = False.
     * @type {boolean}
     * @memberof InternalTaskingRequest
     */
    is_recurring?: boolean;
    /**
     *
     * @type {number}
     * @memberof InternalTaskingRequest
     */
    id: number;
    /**
     * This field will store geometry representing what has not yet been collected for this Tasking Request (within it\'s start_time and end_time).  When imagery comes back from the satellite, the incoming imagery will be \"subtracted\" from this value.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalTaskingRequest
     */
    remaining_geometry: Geometry | GeometryCollection;
    /**
     * This field will store geometry representing what has been collected for this Tasking Request (within it\'s start_time and end_time).  When imagery comes back from the satellite, the intersection of the image\'s footprint will be \'added\' to this value.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalTaskingRequest
     */
    collected_geometry: Geometry | GeometryCollection;
    /**
     * Value in the range [0.0, 1.0] (inclusive) representing how much of the requested_geometry\'s area has been collected. When this value reaches 1.0, the TaskingRequest is considered complete and no further taskings will be scheduled for it.
     * @type {number}
     * @memberof InternalTaskingRequest
     */
    percent_complete: number;
    /**
     * If the Tasking Request is cancelled, this is the date and time it was cancelled on.
     * @type {string}
     * @memberof InternalTaskingRequest
     */
    cancelled_date: string | null;
    /**
     * The date when this Tasking Request was created.
     * @type {string}
     * @memberof InternalTaskingRequest
     */
    create_date: string;
    /**
     * The datetime when this TaskingRequest was last updated.
     * @type {string}
     * @memberof InternalTaskingRequest
     */
    update_date: string;
    /**
     * Boolean Flag indicating this Tasking Request is cancelled and no longer active.  This flag takes precedence over the start_date and end_date fields.
     * @type {boolean}
     * @memberof InternalTaskingRequest
     */
    is_cancelled: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalTaskingRequestPriorityEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
}

/**
 * Serializer for the InternalTaskingRequestViewSet.
 * @export
 * @interface InternalTaskingRequestRequest
 */
export interface InternalTaskingRequestRequest {
    /**
     * This geometry represents the area we wish to capture. This can be any geometry type including a GeometryCollection.
     * @type {Geometry | GeometryCollection}
     * @memberof InternalTaskingRequestRequest
     */
    requested_geometry: Geometry | GeometryCollection;
    /**
     * If the TaskingRequest originates from an Asset that we are monitoring, this linkage should be populated. If the TaskingRequest does not originate from an Asset, this property will be NULL.
     * @type {number}
     * @memberof InternalTaskingRequestRequest
     */
    asset?: number | null;
    /**
     * This is the datetime (inclusive) representing when this tasking requests becomes active.
     * @type {string}
     * @memberof InternalTaskingRequestRequest
     */
    start_date: string;
    /**
     * This is the datetime (exclusive) representing when this tasking requests becomes inactive.  If this value is NULL, the TaskingRequest will remain active until 100%% of the geometry is collected.
     * @type {string}
     * @memberof InternalTaskingRequestRequest
     */
    end_date?: string | null;
    /**
     * Human Readable name of the Tasking Request
     * @type {string}
     * @memberof InternalTaskingRequestRequest
     */
    name: string;
    /**
     * The priority defines how the tasking request should be prioritized and approved.
     * @type {number}
     * @memberof InternalTaskingRequestRequest
     */
    priority?: InternalTaskingRequestRequestPriorityEnum;
    /**
     * The Sensors that the Tasking Scheduler will consider this TaskingRequest for tasking on
     * @type {Array<number>}
     * @memberof InternalTaskingRequestRequest
     */
    sensors: Array<number>;
    /**
     * By setting this flag to true, the Tasking Engine will not run the \"subtraction\" logic on this Tasking Request. For example, if this TaskingRequest is a point target and is_recurring is False, then the Tasking Engine will only ever schedule the target once (if the chunk size of the tasking engine is set correctly). After the engine schedules the target and the chunk is completely processed, all the areas scheduled for collection are subtracted from the next chunk. On the other hand, if is_recurring = True, this target will be scheduled over and over again. I would imagine most point targets need to be set to is_recurring = True and all other targets to is_recurring = False.
     * @type {boolean}
     * @memberof InternalTaskingRequestRequest
     */
    is_recurring?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalTaskingRequestRequestPriorityEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
}

/**
 * Serializer for the InternalTaskingRequestViewSet (for update operations).
 * @export
 * @interface InternalTaskingRequestUpdate
 */
export interface InternalTaskingRequestUpdate {
    /**
     *
     * @type {number}
     * @memberof InternalTaskingRequestUpdate
     */
    id: number;
    /**
     * If the Tasking Request is cancelled, this is the date and time it was cancelled on.
     * @type {string}
     * @memberof InternalTaskingRequestUpdate
     */
    cancelled_date: string | null;
    /**
     * Boolean Flag indicating this Tasking Request is cancelled and no longer active.  This flag takes precedence over the start_date and end_date fields.
     * @type {boolean}
     * @memberof InternalTaskingRequestUpdate
     */
    is_cancelled?: boolean;
}
/**
 * Serializer for the InternalTaskingRequestViewSet (for update operations).
 * @export
 * @interface InternalTaskingRequestUpdateRequest
 */
export interface InternalTaskingRequestUpdateRequest {
    /**
     * Boolean Flag indicating this Tasking Request is cancelled and no longer active.  This flag takes precedence over the start_date and end_date fields.
     * @type {boolean}
     * @memberof InternalTaskingRequestUpdateRequest
     */
    is_cancelled?: boolean;
}
/**
 * Serializer for the InternalTaskingScheduleViewSet
 * @export
 * @interface InternalTaskingSchedule
 */
export interface InternalTaskingSchedule {
    /**
     * The workflow_status field tells us if the TaskingSchedule is being generated, has finished generating, has been selected to send to AD, or has been archived.
     * @type {string}
     * @memberof InternalTaskingSchedule
     */
    workflow_status?: InternalTaskingScheduleWorkflowStatusEnum;
    /**
     *
     * @type {number}
     * @memberof InternalTaskingSchedule
     */
    id: number;
    /**
     * This tracks the user that created this TaskingSchedule object.
     * @type {number}
     * @memberof InternalTaskingSchedule
     */
    created_by_user: number;
    /**
     * The date when this TaskingSchedule was created.
     * @type {string}
     * @memberof InternalTaskingSchedule
     */
    create_date: string;
    /**
     * This tracks the user that updated this TaskingSchedule object.
     * @type {number}
     * @memberof InternalTaskingSchedule
     */
    updated_by_user: number;
    /**
     * The datetime when this TaskingSchedule was last updated.
     * @type {string}
     * @memberof InternalTaskingSchedule
     */
    update_date: string;
    /**
     * This tracks the user that last committed this TaskingSchedule object.
     * @type {number}
     * @memberof InternalTaskingSchedule
     */
    committed_by_user: number | null;
    /**
     * The datetime when this TaskingSchedule was last committed.
     * @type {string}
     * @memberof InternalTaskingSchedule
     */
    committed_date: string | null;
    /**
     * This tracks the user that last archived this TaskingSchedule object.
     * @type {number}
     * @memberof InternalTaskingSchedule
     */
    archived_by_user: number | null;
    /**
     * The datetime when this TaskingSchedule was last archived.
     * @type {string}
     * @memberof InternalTaskingSchedule
     */
    archived_date: string | null;
    /**
     * The start_date represents the (inclusive) beginning of a date range that this schedule has generated a plan for.  For example, if this schedule has generated a plan for 30 days starting on May 1, 2023, the start_date would be May 1, 2023 and the end_date would be May 31, 2023.
     * @type {string}
     * @memberof InternalTaskingSchedule
     */
    start_date: string;
    /**
     * The end_date represents the (exclusive) ending of a date range that this schedule has generated a plan for.  For example, if this schedule has generated a plan for 30 days starting on May 1, 2023, the start_date would be May 1, 2023 and the end_date would be May 31, 2023.
     * @type {string}
     * @memberof InternalTaskingSchedule
     */
    end_date: string;
    /**
     *
     * @type {Array<number>}
     * @memberof InternalTaskingSchedule
     */
    sensor_schedules: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalTaskingScheduleWorkflowStatusEnum {
    Generating = 'GENERATING',
    Generated = 'GENERATED',
    Committed = 'COMMITTED',
    Archived = 'ARCHIVED',
    Failed = 'FAILED',
}

/**
 * Serializer for the InternalTaskingScheduleViewSet
 * @export
 * @interface InternalTaskingScheduleRequest
 */
export interface InternalTaskingScheduleRequest {
    /**
     * The workflow_status field tells us if the TaskingSchedule is being generated, has finished generating, has been selected to send to AD, or has been archived.
     * @type {string}
     * @memberof InternalTaskingScheduleRequest
     */
    workflow_status?: InternalTaskingScheduleRequestWorkflowStatusEnum;
    /**
     * If you are creating a TaskingSchedule for specific sensors, you can pass them via this parameter. Note, if you attempt to pass a sensor_id for a Sensor that is not Tasking Eligible, a validation error will be raised.
     * @type {Array<number>}
     * @memberof InternalTaskingScheduleRequest
     */
    sensor_ids?: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalTaskingScheduleRequestWorkflowStatusEnum {
    Generating = 'GENERATING',
    Generated = 'GENERATED',
    Committed = 'COMMITTED',
    Archived = 'ARCHIVED',
    Failed = 'FAILED',
}

/**
 * Serializer used for the UPDATE operation on the InternalSensorTaskViewSet
 * @export
 * @interface InternalUpdateSensorTask
 */
export interface InternalUpdateSensorTask {
    /**
     * task_status contains the status of the task, generated by the Payload or inferred when the imagery comes in via the datapipeline.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    task_status?: InternalUpdateSensorTaskTaskStatusEnum;
    /**
     *
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    id: number;
    /**
     * The tag is used to uniquely identify this tasking in a human-readable form for this sensor.  An example of the tag, would be: \'OSK-20230511-00000001-00000001\'.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    tag: string;
    /**
     * The date when this SensorTask was created.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    create_date: string;
    /**
     * The datetime when this SensorTask was last updated.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    update_date: string;
    /**
     * This field represents the actual Flight JAS script that will be sent to the Payload. This Flight JAS script is constructed based on all the data in this object. This Flight JAS script will be embedded in the Flight JAS JSON script stored in the SensorSchedule parent object.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    flight_jas: string;
    /**
     * This field represents a single insert statement, that is constructed from the data in this object, that can be run on the Payload\'s SQLite database. This SQL insert Script will be combined into a single SQL script within the parent SensorSchedule object. The way this script is generated will need to be determined by the version of the SQLite database currently on the Payload.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    sql_insert_script: string;
    /**
     * The SensorSchedule object represents a complete schedule for 1 individual Sensor.  A SensorSchedule object is made up of multiple SensorTask objects.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    sensor_schedule: number;
    /**
     * This is the unique id of the tasking used by the Payload. This will be used to identify each tasking in the SQL script and in the Flight JAS Script.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    task_id: string;
    /**
     * This field contains a comma separate list of names of all the targets that will be collected by this tasking.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    target_name: string;
    /**
     * The capture_index represents an ascending numeric index that starts at 0 and identifies this capture in the entire schedule for a specific sensor. The next capture should always be capture_index + 1. The parent SensorSchedule tracks the total number of captures, so you can use that to determine if this is the last capture_index.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    capture_index: number;
    /**
     * The capture_area represents the Polygon(s) that are captured during this SensorTask (aka Tasking).
     * @type {Geometry | GeometryCollection}
     * @memberof InternalUpdateSensorTask
     */
    capture_area: Geometry | GeometryCollection;
    /**
     * The priority represents the maximum priority of all the TaskingRequests that will be collected by this SensorTask.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    priority: InternalUpdateSensorTaskPriorityEnum;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value of GSD should represent the GSD of the collect at it\'s exact center point.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    gsd: number;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value should represent the minimum GSD (or the GSD of the point closest to the satellite).
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    gsd_min: number;
    /**
     * The GSD can be calculated using the following equation: distance * ifov.  When pointing off nadir,the GSD is not uniform.  This value should represent the maximum GSD (or the GSD of the point furthest from the satellite).
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    gsd_max: number;
    /**
     * The score of the SensorTask (aka Tasking) is computed by the Tasking Engine and is subject to change. Only taskings that produce the most optimal path through the graph of all possible taskings will be selected (and inserted into the database as a SensorTask). SensorTasks that are not selected will not (currently) appear in the database.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    score: number;
    /**
     * Length of time in microseconds that the payload will wait (after passing the target) to mark the task as \"FAILED\". Defaults to 10 seconds.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    capture_error_us: number;
    /**
     * The pass_time represents the exact time that the satellite will be able to perform the collect. This means the satellite has slewed to the proper orientation, warmed up the camera, and turned the camera on prior to this moment. All other timings are centered around this value.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    pass_time: string;
    /**
     * Before each capture, the camera must warmup for a certain period of time. Also, before each eclipse, the payload\'s camera is shutdown. This means that the first capture after an eclipse, it takes the camera a bit longer to warmup. For the first capture Post-Eclipse, the camera takes 5 minutes to warmup. For all subsequent captures (of that orbit), it takes the camera 1 minute to warmup. capture_on_time contains the actual time the camera begins to warmup.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    camera_on_time: string;
    /**
     * capture_start_time represents the time (inclusive) that the camera begins to capture imagery.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    capture_start_time: string;
    /**
     * capture_end_time represents the time (exclusive) that the camera stops capturing imagery.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    capture_end_time: string;
    /**
     * capture_point contains the longitude, latitude, and altitude that the satellite is pointing at, at capture_start_time
     * @type {Geometry | GeometryCollection}
     * @memberof InternalUpdateSensorTask
     */
    capture_point: Geometry | GeometryCollection;
    /**
     * The slew angle of the satellite in degrees.  At every second, we know the position and velocity of the satellite. We also know that when the slew_angle is 0, the satellite is nadir pointing. If you can imagine rotating the nadir pointing vector by capture_slew_angle using the velocity vector as the axis of rotation, you will understand the meaning of this field.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    capture_slew_angle: number;
    /**
     * The moment in time that the satellite will begin slewing to perform the capture.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    capture_slew_start_time: string;
    /**
     * The number of seconds the slew will take.  A value of 0.0 means the satellite will compute the fastest slew duration possible to reach the desired slew angle.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    capture_slew_duration: number;
    /**
     * When the satellite is not performing a collect, it needs to be doing something \"in the interim\". prev_interim_mode signifies what the satellite was doing \"in the interim\" before the current tasking (aka SensorTask). This affects various timings.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    prev_interim_mode: InternalUpdateSensorTaskPrevInterimModeEnum;
    /**
     * When the satellite is not performing a collect, it needs to be doing something \"in the interim\". next_interim_mode signifies what the satellite should do \"in the interim\" after the current tasking (aka SensorTask). This affects various timings.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    next_interim_mode: InternalUpdateSensorTaskNextInterimModeEnum;
    /**
     * orbit_capture_index represents the capture number of the current SensorTask for the current Orbit. This will be used to determine camera warm up times and determine if the payload should be shutdown after the current collect.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    orbit_capture_index: number;
    /**
     * total_orbit_captures represents the number of captures that will occur in the current (same) orbit as this SensorTask object. This will be used in conjunction with the orbit_capture_index field.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    total_orbit_captures: number;
    /**
     * orbit_num represents which orbit the current SensorTask (tasking) is in. This value will be pulled from the core_api_tick table.
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    orbit_num: number;
    /**
     * This represents the datetime after the current collect in which the satellite should begin slewing in order to return to next_interim_mode (sun tracking or nadir tracking).
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    post_capture_slew_start_time: string;
    /**
     * This represents the duration (in seconds) the post-capture slew operation will take. After the current tasking / collect, the satellite should return to next_interim_mode. This value represents how long that slew operation will take. Note, that a value of 0.0 tells the satellite to slew \"optimally\" (the satellite will calculate the shortest amount of time to perform the slew operation).
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    post_capture_slew_duration: number;
    /**
     * This linkage will track which TaskingRequest objects caused this SensorTask to be created. It is important to note that more than 1 TaskingRequest can be collected by a single SensorTask. Also, more than 1 SensorTask might be required to collect a single TaskingRequest (hence the many-to-many relationship).
     * @type {Array<number>}
     * @memberof InternalUpdateSensorTask
     */
    tasking_requests: Array<number>;
    /**
     * When imagery comes back down from the satellite, a capture record is created. Within the downlinked data, the task_id (defined within this object) should also be included. This will allow us to directly link the capture to the current SensorTask object. This field will be None until the imagery comes down and runs through the data pipeline (which will populate this field).
     * @type {number}
     * @memberof InternalUpdateSensorTask
     */
    capture: number | null;
    /**
     * The UUID of the HSI Config used for this Tasking. This represents the hsi_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    hsi_config: string;
    /**
     * The UUID of the PAN Config used for this Tasking. This represents the pan_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    pan_config: string;
    /**
     * The UUID of the Radiometric Config used for this Tasking. This represents the radiometric_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    radiometric_config: string;
    /**
     * The UUID of the Pipeline Config used for this Tasking. This represents the pipeline_config value that was injected in the SQL script that was provided to the payload.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    pipeline_config: string;
    /**
     * The time that the payload starts to power down.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    power_down_start_time: string | null;
    /**
     * The approximate time that the payload has completed the power down process. The actual power down time of the payload is likely to occur after this value. Currently, this value represents the earliest possible power down time. This is because the Flight Jas commands take a variable amount of time to execute. Also, the each power down command can be blocking or non-blocking and is followed by a WAIT(sec) command (which effectively sleeps the process). This value currently represents the power_down_start_time + TOTAL_WAIT_SECS. We should probably add an additional buffer to this value at some point.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    power_down_end_time: string | null;
    /**
     * The time of the first action of this task. No 2 tasks within the same SensorSchedule can have overlapping window_start_time / window_end_time. These values are used by the optimization process to determine if 2 tasks overlap.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    window_start_time: string;
    /**
     * The time of the last action of this task. No 2 tasks within the same SensorSchedule can have overlapping window_start_time / window_end_time. These values are used by the optimization process to determine if 2 tasks overlap.
     * @type {string}
     * @memberof InternalUpdateSensorTask
     */
    window_end_time: string;
    /**
     * True if this Tasking will use the hyperspectral sensor to perform the collect. False otherwise. This value is used in the Payload SQL script.
     * @type {boolean}
     * @memberof InternalUpdateSensorTask
     */
    use_hsi: boolean;
    /**
     * True if this Tasking will use the panchromatic (RGB) sensor to perform the collect. False otherwise. This value is used in the Payload SQL script.
     * @type {boolean}
     * @memberof InternalUpdateSensorTask
     */
    use_pan: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalUpdateSensorTaskTaskStatusEnum {
    Uplinked = 'UPLINKED',
    Failed = 'FAILED',
    FailedSettings = 'FAILED_SETTINGS',
    FailedMissedAcq = 'FAILED_MISSED_ACQ',
    FailedDarkCapture = 'FAILED_DARK_CAPTURE',
    FailedCapture = 'FAILED_CAPTURE',
    FailedProcessing = 'FAILED_PROCESSING',
    Downlinked = 'DOWNLINKED',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalUpdateSensorTaskPriorityEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalUpdateSensorTaskPrevInterimModeEnum {
    SunTracking = 'SUN_TRACKING',
    NadirTracking = 'NADIR_TRACKING',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalUpdateSensorTaskNextInterimModeEnum {
    SunTracking = 'SUN_TRACKING',
    NadirTracking = 'NADIR_TRACKING',
}

/**
 * Serializer used for the UPDATE operation on the InternalSensorTaskViewSet
 * @export
 * @interface InternalUpdateSensorTaskRequest
 */
export interface InternalUpdateSensorTaskRequest {
    /**
     * task_status contains the status of the task, generated by the Payload or inferred when the imagery comes in via the datapipeline.
     * @type {string}
     * @memberof InternalUpdateSensorTaskRequest
     */
    task_status?: InternalUpdateSensorTaskRequestTaskStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalUpdateSensorTaskRequestTaskStatusEnum {
    Uplinked = 'UPLINKED',
    Failed = 'FAILED',
    FailedSettings = 'FAILED_SETTINGS',
    FailedMissedAcq = 'FAILED_MISSED_ACQ',
    FailedDarkCapture = 'FAILED_DARK_CAPTURE',
    FailedCapture = 'FAILED_CAPTURE',
    FailedProcessing = 'FAILED_PROCESSING',
    Downlinked = 'DOWNLINKED',
}

/**
 * An Opportunity represents the sensor\'s viewable area between the start_time (inclusive) and end_time (exclusive). The nadir_point is the intersection between the sensor\'s look vector and the WGS84 ellipsoid, nadir_fov is the viewable area if pointing nadir, and the max_fov represents the maximum area that can potentially be collected given the sensor\'s +/- max slew angle. Note, max_fov does not represent what can be collected in a single pass, but instead represents the maximum area that can potentially be collected. The nadir_fov can be collected in it\'s entirety in 1 pass.
 * @export
 * @interface Opportunity
 */
export interface Opportunity {
    /**
     * Nadir point directly underneath the sensor.
     * @type {Geometry | GeometryCollection}
     * @memberof Opportunity
     */
    nadir_point: Geometry | GeometryCollection;
    /**
     * Polygon covering Field of View while pointed at nadir.
     * @type {Geometry | GeometryCollection}
     * @memberof Opportunity
     */
    nadir_fov: Geometry | GeometryCollection;
    /**
     * Polygon covering all areas potentially within the Field of View allowed by slewing. Sensors will not be able to image the entire max field of view in one pass.
     * @type {Geometry | GeometryCollection}
     * @memberof Opportunity
     */
    max_fov: Geometry | GeometryCollection;
    /**
     * Start of the time interval covered by this opportunity.
     * @type {string}
     * @memberof Opportunity
     */
    start_time: string;
    /**
     * End of the time interval covered by this opportunity, by default 1 second after start_time.
     * @type {string}
     * @memberof Opportunity
     */
    end_time: string;
    /**
     * The sensor this tick belongs to.
     * @type {number}
     * @memberof Opportunity
     */
    sensor: number;
    /**
     * If this field is true, then the satellite is considered \"eclipsed\" which means it is currently night at the location of the satellite.  At the time of this writing, collecting imagery is not possible if the target area is eclipsed.
     * @type {boolean}
     * @memberof Opportunity
     */
    eclipsed: boolean;
}
/**
 * updates the create field for serializing orders from internal endpoints
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * Universal unique ID (UUID) assigned to each order
     * @type {string}
     * @memberof Order
     */
    id: string;
    /**
     * Priority of this order. 0 (High) to 9 (low)
     * @type {number}
     * @memberof Order
     */
    priority?: number;
    /**
     * The provider of the data for this order (OSK)
     * @type {string}
     * @memberof Order
     */
    dataProvider: string;
    /**
     * Name of the customer for this order
     * @type {string}
     * @memberof Order
     */
    dataConsumer?: string;
    /**
     * Timestamp that this order was placed, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof Order
     */
    orderTimeStamp: string;
    /**
     * Unique requirement ID defined by customer
     * @type {string}
     * @memberof Order
     */
    reqId?: string;
    /**
     * Optional space for comments or notes about this order
     * @type {string}
     * @memberof Order
     */
    customerComments?: string;
    /**
     * Specifies if this a Tasking Order for new imagery or an Archive order for existing imagery
     * @type {string}
     * @memberof Order
     */
    orderType: OrderOrderTypeEnum;
    /**
     * Unique Requirement Line Item ID defined by customer
     * @type {string}
     * @memberof Order
     */
    reqLineItemId?: string;
    /**
     * True when updating a previous Order
     * @type {boolean}
     * @memberof Order
     */
    isReOrder?: boolean | null;
    /**
     * If Reorder is True, The reason the reorder was needed
     * @type {string}
     * @memberof Order
     */
    reOrderReason?: string;
    /**
     * Name of the customer\'s Point of Contact for this order
     * @type {string}
     * @memberof Order
     */
    customerPoc?: string;
    /**
     * Phone number for the customer\'s point of contact on this order
     * @type {string}
     * @memberof Order
     */
    customerPocPhoneNumber?: string;
    /**
     * If True, special handling required for this order
     * @type {boolean}
     * @memberof Order
     */
    requiredSpecialHandling?: boolean | null;
    /**
     * Specific instructions for special handling if needed
     * @type {string}
     * @memberof Order
     */
    specialHandlingInstructions?: string;
    /**
     * Minimum acceptable GSD in meters
     * @type {number}
     * @memberof Order
     */
    minimumGsd?: number | null;
    /**
     * Desired GSD for order in meters
     * @type {number}
     * @memberof Order
     */
    requestedGsd?: number | null;
    /**
     * Address of GovCloud S3 Bucket
     * @type {string}
     * @memberof Order
     */
    s3Bucket: string | null;
    /**
     * Type of S3 bucket being used
     * @type {string}
     * @memberof Order
     */
    s3Type?: OrderS3TypeEnum;
    /**
     * Optional Sub-folder within s3 Bucket path
     * @type {string}
     * @memberof Order
     */
    s3Folder?: string | null;
    /**
     * The data product level for the order
     * @type {string}
     * @memberof Order
     */
    productLevel?: OrderProductLevelEnum;
    /**
     * The file format the data should be delivered in
     * @type {string}
     * @memberof Order
     */
    fileFormat?: OrderFileFormatEnum;
    /**
     * Display name used to describe this Order
     * @type {string}
     * @memberof Order
     */
    name?: string;
    /**
     *
     * @type {OrderStatus}
     * @memberof Order
     */
    orderStatus: OrderStatus | null;
    /**
     * Always False except where True is allowed for point target orders.
     * @type {boolean}
     * @memberof Order
     */
    isArchaic01?: boolean;
    /**
     * Withold prevents imagery from showing in the public catalog.
     * @type {boolean}
     * @memberof Order
     */
    isWithhold?: boolean;
    /**
     * How long to withhold image from catalog. Possible Options: 30 days, Permanent
     * @type {string}
     * @memberof Order
     */
    withholdLength?: OrderWithholdLengthEnum;
    /**
     * Which sensor is requested to complete the tasking order. Default: Any sensor
     * @type {string}
     * @memberof Order
     */
    sensor?: string;
    /**
     * When imagery collection starts, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof Order
     */
    startCollectDateTime?: string;
    /**
     * When imagery collection ends, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof Order
     */
    endCollectDateTime?: string;
    /**
     * Maximum cloud cover acceptable in image as a percentage. Range: 0-100
     * @type {number}
     * @memberof Order
     */
    maximumCloudCover?: number | null;
    /**
     * Minimum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0.00 - 45.00 deg
     * @type {number}
     * @memberof Order
     */
    minimumOffNadirAngle?: number;
    /**
     * Maximum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0 - 90 deg
     * @type {number}
     * @memberof Order
     */
    maximumOffNadirAngle?: number;
    /**
     * The minimum azimuth angle from target to satellite for the collection with respect to North (clockwise). Range 0.0 - 359.99 deg
     * @type {number}
     * @memberof Order
     */
    minimumAzimuthAngle?: number | null;
    /**
     * The maximum azimuth angle from target to satellite for the collection with respect to North. Range: 0.0 - 359.99 deg. NOTE: Angles are read clockwise so it is possible for max to be less than min.
     * @type {number}
     * @memberof Order
     */
    maximumAzimuthAngle?: number | null;
    /**
     * The minimum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof Order
     */
    minimumSunElevationAngle?: number | null;
    /**
     * The maximum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof Order
     */
    maximumSunElevationAngle?: number | null;
    /**
     * The minimum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof Order
     */
    minimumSunAzimuth?: number | null;
    /**
     * The maximum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof Order
     */
    maximumSunAzimuth?: number | null;
    /**
     * If this order is for mono or stereo imagery. NOTE: Only mono imagery accepted at this time
     * @type {string}
     * @memberof Order
     */
    monoStereo?: string | null;
    /**
     * Maximum acceptable GSD. Range: 0.01 to 100.00 meters
     * @type {number}
     * @memberof Order
     */
    maximumAcceptableGsd?: number | null;
    /**
     * Allows order to be satisfied with an archive imagery, new imagery collection is not required.
     * @type {boolean}
     * @memberof Order
     */
    isArchiveAcceptable?: boolean | null;
    /**
     * Earliest date allowable for collection date of archived data, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof Order
     */
    archiveAcceptableDate?: string | null;
    /**
     * Required to collect in one access
     * @type {boolean}
     * @memberof Order
     */
    isSinglePass?: boolean | null;
    /**
     * Area of interest to collect, in GeoJSON or WKT format
     * @type {Geometry | GeometryCollection}
     * @memberof Order
     */
    aoiTarget?: Geometry | GeometryCollection | null;
    /**
     * Geometry type of target
     * @type {string}
     * @memberof Order
     */
    aoiTargetType?: OrderAoiTargetTypeEnum;
    /**
     * List of captures requested in this Order. Captures are referred to using inventoryId
     * @type {Array<string>}
     * @memberof Order
     */
    inventoryId?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum OrderOrderTypeEnum {
    Archive = 'archive',
    Tasking = 'tasking',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderS3TypeEnum {
    S3 = 'S3',
    GovCloudS3 = 'GovCloud S3',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderProductLevelEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderFileFormatEnum {
    Nitf = 'NITF',
    Envi = 'ENVI',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderWithholdLengthEnum {
    _30Days = '30 days',
    Permanent = 'permanent',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderAoiTargetTypeEnum {
    Point = 'point',
    Area = 'area',
}

/**
 * updates the create field for serializing orders from internal endpoints
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
    /**
     * Priority of this order. 0 (High) to 9 (low)
     * @type {number}
     * @memberof OrderRequest
     */
    priority?: number;
    /**
     * Name of the customer for this order
     * @type {string}
     * @memberof OrderRequest
     */
    dataConsumer?: string;
    /**
     * Unique requirement ID defined by customer
     * @type {string}
     * @memberof OrderRequest
     */
    reqId?: string;
    /**
     * Optional space for comments or notes about this order
     * @type {string}
     * @memberof OrderRequest
     */
    customerComments?: string;
    /**
     * Specifies if this a Tasking Order for new imagery or an Archive order for existing imagery
     * @type {string}
     * @memberof OrderRequest
     */
    orderType: OrderRequestOrderTypeEnum;
    /**
     * Unique Requirement Line Item ID defined by customer
     * @type {string}
     * @memberof OrderRequest
     */
    reqLineItemId?: string;
    /**
     * True when updating a previous Order
     * @type {boolean}
     * @memberof OrderRequest
     */
    isReOrder?: boolean | null;
    /**
     * If Reorder is True, The reason the reorder was needed
     * @type {string}
     * @memberof OrderRequest
     */
    reOrderReason?: string;
    /**
     * Name of the customer\'s Point of Contact for this order
     * @type {string}
     * @memberof OrderRequest
     */
    customerPoc?: string;
    /**
     * Phone number for the customer\'s point of contact on this order
     * @type {string}
     * @memberof OrderRequest
     */
    customerPocPhoneNumber?: string;
    /**
     * If True, special handling required for this order
     * @type {boolean}
     * @memberof OrderRequest
     */
    requiredSpecialHandling?: boolean | null;
    /**
     * Specific instructions for special handling if needed
     * @type {string}
     * @memberof OrderRequest
     */
    specialHandlingInstructions?: string;
    /**
     * Minimum acceptable GSD in meters
     * @type {number}
     * @memberof OrderRequest
     */
    minimumGsd?: number | null;
    /**
     * Desired GSD for order in meters
     * @type {number}
     * @memberof OrderRequest
     */
    requestedGsd?: number | null;
    /**
     * Address of GovCloud S3 Bucket
     * @type {string}
     * @memberof OrderRequest
     */
    s3Bucket: string | null;
    /**
     * Type of S3 bucket being used
     * @type {string}
     * @memberof OrderRequest
     */
    s3Type?: OrderRequestS3TypeEnum;
    /**
     * Optional Sub-folder within s3 Bucket path
     * @type {string}
     * @memberof OrderRequest
     */
    s3Folder?: string | null;
    /**
     * The data product level for the order
     * @type {string}
     * @memberof OrderRequest
     */
    productLevel?: OrderRequestProductLevelEnum;
    /**
     * The file format the data should be delivered in
     * @type {string}
     * @memberof OrderRequest
     */
    fileFormat?: OrderRequestFileFormatEnum;
    /**
     * Display name used to describe this Order
     * @type {string}
     * @memberof OrderRequest
     */
    name?: string;
    /**
     * Always False except where True is allowed for point target orders.
     * @type {boolean}
     * @memberof OrderRequest
     */
    isArchaic01?: boolean;
    /**
     * Withold prevents imagery from showing in the public catalog.
     * @type {boolean}
     * @memberof OrderRequest
     */
    isWithhold?: boolean;
    /**
     * How long to withhold image from catalog. Possible Options: 30 days, Permanent
     * @type {string}
     * @memberof OrderRequest
     */
    withholdLength?: OrderRequestWithholdLengthEnum;
    /**
     * Which sensor is requested to complete the tasking order. Default: Any sensor
     * @type {string}
     * @memberof OrderRequest
     */
    sensor?: string;
    /**
     * When imagery collection starts, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof OrderRequest
     */
    startCollectDateTime?: string;
    /**
     * When imagery collection ends, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof OrderRequest
     */
    endCollectDateTime?: string;
    /**
     * Maximum cloud cover acceptable in image as a percentage. Range: 0-100
     * @type {number}
     * @memberof OrderRequest
     */
    maximumCloudCover?: number | null;
    /**
     * Minimum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0.00 - 45.00 deg
     * @type {number}
     * @memberof OrderRequest
     */
    minimumOffNadirAngle?: number;
    /**
     * Maximum acceptable angle between the satellite line-of-sight (LOS) vector and the vector from sat to ground directly below it. Range: 0 - 90 deg
     * @type {number}
     * @memberof OrderRequest
     */
    maximumOffNadirAngle?: number;
    /**
     * The minimum azimuth angle from target to satellite for the collection with respect to North (clockwise). Range 0.0 - 359.99 deg
     * @type {number}
     * @memberof OrderRequest
     */
    minimumAzimuthAngle?: number | null;
    /**
     * The maximum azimuth angle from target to satellite for the collection with respect to North. Range: 0.0 - 359.99 deg. NOTE: Angles are read clockwise so it is possible for max to be less than min.
     * @type {number}
     * @memberof OrderRequest
     */
    maximumAzimuthAngle?: number | null;
    /**
     * The minimum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof OrderRequest
     */
    minimumSunElevationAngle?: number | null;
    /**
     * The maximum sun elevation angle. Allowed values: -90.00 - 90.00
     * @type {number}
     * @memberof OrderRequest
     */
    maximumSunElevationAngle?: number | null;
    /**
     * The minimum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof OrderRequest
     */
    minimumSunAzimuth?: number | null;
    /**
     * The maximum azimuth angle for the collection with respect to North (clockwise). Range: 0.0 - 359.99 deg
     * @type {number}
     * @memberof OrderRequest
     */
    maximumSunAzimuth?: number | null;
    /**
     * If this order is for mono or stereo imagery. NOTE: Only mono imagery accepted at this time
     * @type {string}
     * @memberof OrderRequest
     */
    monoStereo?: string | null;
    /**
     * Maximum acceptable GSD. Range: 0.01 to 100.00 meters
     * @type {number}
     * @memberof OrderRequest
     */
    maximumAcceptableGsd?: number | null;
    /**
     * Allows order to be satisfied with an archive imagery, new imagery collection is not required.
     * @type {boolean}
     * @memberof OrderRequest
     */
    isArchiveAcceptable?: boolean | null;
    /**
     * Earliest date allowable for collection date of archived data, in yyyy-mm-ddThh:mm:ssZ (UTC)
     * @type {string}
     * @memberof OrderRequest
     */
    archiveAcceptableDate?: string | null;
    /**
     * Required to collect in one access
     * @type {boolean}
     * @memberof OrderRequest
     */
    isSinglePass?: boolean | null;
    /**
     * Area of interest to collect, in GeoJSON or WKT format
     * @type {Geometry | GeometryCollection}
     * @memberof OrderRequest
     */
    aoiTarget?: Geometry | GeometryCollection | null;
    /**
     * Geometry type of target
     * @type {string}
     * @memberof OrderRequest
     */
    aoiTargetType?: OrderRequestAoiTargetTypeEnum;
    /**
     * List of captures requested in this Order. Captures are referred to using inventoryId
     * @type {Array<string>}
     * @memberof OrderRequest
     */
    inventoryId?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum OrderRequestOrderTypeEnum {
    Archive = 'archive',
    Tasking = 'tasking',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderRequestS3TypeEnum {
    S3 = 'S3',
    GovCloudS3 = 'GovCloud S3',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderRequestProductLevelEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderRequestFileFormatEnum {
    Nitf = 'NITF',
    Envi = 'ENVI',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderRequestWithholdLengthEnum {
    _30Days = '30 days',
    Permanent = 'permanent',
    Null = 'null',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderRequestAoiTargetTypeEnum {
    Point = 'point',
    Area = 'area',
}

/**
 * Common methods for internal and external OrderStatus serializers
 * @export
 * @interface OrderStatus
 */
export interface OrderStatus {
    /**
     * Universal unique ID (UUID) assigned to each status
     * @type {string}
     * @memberof OrderStatus
     */
    id: string;
    /**
     * Timestamp for when Order status was set
     * @type {string}
     * @memberof OrderStatus
     */
    statusTimestamp: string;
    /**
     * Status of the Order at timestamp
     * @type {string}
     * @memberof OrderStatus
     */
    reqLineItemStatus: OrderStatusReqLineItemStatusEnum;
    /**
     * Rationale for status
     * @type {string}
     * @memberof OrderStatus
     */
    reqLineStatusCode?: OrderStatusReqLineStatusCodeEnum;
    /**
     * Explanation of Rationale for status
     * @type {string}
     * @memberof OrderStatus
     */
    reqLineStatusText?: string | null;
    /**
     * Unique requirement ID defined by customer
     * @type {string}
     * @memberof OrderStatus
     */
    reqId?: string;
    /**
     * Unique Requirement Line Item ID defined by customer
     * @type {string}
     * @memberof OrderStatus
     */
    reqLineItemId?: string;
    /**
     * Universal unique ID (UUID) assigned to each order
     * @type {string}
     * @memberof OrderStatus
     */
    vendorOrderId?: string;
    /**
     * Priority of this order. 0 (High) to 9 (low)
     * @type {number}
     * @memberof OrderStatus
     */
    priority?: number | null;
    /**
     * The provider of the data for this order (OSK)
     * @type {string}
     * @memberof OrderStatus
     */
    dataProvider?: string;
    /**
     * Name of the customer for this order
     * @type {string}
     * @memberof OrderStatus
     */
    dataConsumer?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OrderStatusReqLineItemStatusEnum {
    Manual = 'manual',
    ModReceived = 'mod_received',
    ModAccepted = 'mod_accepted',
    ModRejected = 'mod_rejected',
    OrderReceived = 'order_received',
    OrderAccepted = 'order_accepted',
    OrderRejected = 'order_rejected',
    OrderActive = 'order_active',
    OrderExpired = 'order_expired',
    OrderScheduled = 'order_scheduled',
    OrderTasked = 'order_tasked',
    OrderProcessing = 'order_processing',
    OrderDelivered = 'order_delivered',
    OrderCanceled = 'order_canceled',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderStatusReqLineStatusCodeEnum {
    Aa = 'AA',
    Ab = 'AB',
    Ac = 'AC',
    Ad = 'AD',
    Ae = 'AE',
    Ah0 = 'AH0',
    Ah1 = 'AH1',
    Ps = 'PS',
    Ah2 = 'AH2',
    Ai = 'AI',
    Aj = 'AJ',
    Ak = 'AK',
    S1 = 'S1',
    S2 = 'S2',
    P1 = 'P1',
    P3 = 'P3',
    P2 = 'P2',
    Te = 'TE',
    Ff = 'FF',
    Null = 'null',
}

/**
 *
 * @export
 * @interface PaginatedApplicationList
 */
export interface PaginatedApplicationList {
    /**
     *
     * @type {number}
     * @memberof PaginatedApplicationList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedApplicationList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedApplicationList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Application>}
     * @memberof PaginatedApplicationList
     */
    results?: Array<Application>;
}
/**
 *
 * @export
 * @interface PaginatedAssetList
 */
export interface PaginatedAssetList {
    /**
     *
     * @type {number}
     * @memberof PaginatedAssetList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedAssetList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedAssetList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Asset>}
     * @memberof PaginatedAssetList
     */
    results?: Array<Asset>;
}
/**
 *
 * @export
 * @interface PaginatedCaptureArtifactList
 */
export interface PaginatedCaptureArtifactList {
    /**
     *
     * @type {number}
     * @memberof PaginatedCaptureArtifactList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedCaptureArtifactList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedCaptureArtifactList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<CaptureArtifact>}
     * @memberof PaginatedCaptureArtifactList
     */
    results?: Array<CaptureArtifact>;
}
/**
 *
 * @export
 * @interface PaginatedCaptureList
 */
export interface PaginatedCaptureList {
    /**
     *
     * @type {number}
     * @memberof PaginatedCaptureList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedCaptureList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedCaptureList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Capture>}
     * @memberof PaginatedCaptureList
     */
    results?: Array<Capture>;
}
/**
 *
 * @export
 * @interface PaginatedDataPipelineCalibrationList
 */
export interface PaginatedDataPipelineCalibrationList {
    /**
     *
     * @type {number}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<DataPipelineCalibration>}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    results?: Array<DataPipelineCalibration>;
}
/**
 *
 * @export
 * @interface PaginatedDetectionList
 */
export interface PaginatedDetectionList {
    /**
     *
     * @type {number}
     * @memberof PaginatedDetectionList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedDetectionList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedDetectionList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Detection>}
     * @memberof PaginatedDetectionList
     */
    results?: Array<Detection>;
}
/**
 *
 * @export
 * @interface PaginatedInternalAssetList
 */
export interface PaginatedInternalAssetList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalAssetList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalAssetList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalAssetList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalAsset>}
     * @memberof PaginatedInternalAssetList
     */
    results?: Array<InternalAsset>;
}
/**
 *
 * @export
 * @interface PaginatedInternalCaptureList
 */
export interface PaginatedInternalCaptureList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalCaptureList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalCaptureList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalCaptureList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalCapture>}
     * @memberof PaginatedInternalCaptureList
     */
    results?: Array<InternalCapture>;
}
/**
 *
 * @export
 * @interface PaginatedInternalCaptureTaskList
 */
export interface PaginatedInternalCaptureTaskList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalCaptureTaskList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalCaptureTaskList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalCaptureTaskList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalCaptureTask>}
     * @memberof PaginatedInternalCaptureTaskList
     */
    results?: Array<InternalCaptureTask>;
}
/**
 *
 * @export
 * @interface PaginatedInternalDataProductList
 */
export interface PaginatedInternalDataProductList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalDataProductList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalDataProductList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalDataProductList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalDataProduct>}
     * @memberof PaginatedInternalDataProductList
     */
    results?: Array<InternalDataProduct>;
}
/**
 *
 * @export
 * @interface PaginatedInternalDetectionList
 */
export interface PaginatedInternalDetectionList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalDetectionList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalDetectionList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalDetectionList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalDetection>}
     * @memberof PaginatedInternalDetectionList
     */
    results?: Array<InternalDetection>;
}
/**
 *
 * @export
 * @interface PaginatedInternalGroundStationList
 */
export interface PaginatedInternalGroundStationList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalGroundStationList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalGroundStationList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalGroundStationList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalGroundStation>}
     * @memberof PaginatedInternalGroundStationList
     */
    results?: Array<InternalGroundStation>;
}
/**
 *
 * @export
 * @interface PaginatedInternalGroundStationPassList
 */
export interface PaginatedInternalGroundStationPassList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalGroundStationPassList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalGroundStationPassList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalGroundStationPassList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalGroundStationPass>}
     * @memberof PaginatedInternalGroundStationPassList
     */
    results?: Array<InternalGroundStationPass>;
}
/**
 *
 * @export
 * @interface PaginatedInternalOpportunityList
 */
export interface PaginatedInternalOpportunityList {
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalOpportunityList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalOpportunityList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalOpportunity>}
     * @memberof PaginatedInternalOpportunityList
     */
    results?: Array<InternalOpportunity>;
}
/**
 *
 * @export
 * @interface PaginatedInternalOrderList
 */
export interface PaginatedInternalOrderList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalOrderList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalOrderList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalOrderList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalOrder>}
     * @memberof PaginatedInternalOrderList
     */
    results?: Array<InternalOrder>;
}
/**
 *
 * @export
 * @interface PaginatedInternalOrderStatusList
 */
export interface PaginatedInternalOrderStatusList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalOrderStatusList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalOrderStatusList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalOrderStatusList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalOrderStatus>}
     * @memberof PaginatedInternalOrderStatusList
     */
    results?: Array<InternalOrderStatus>;
}
/**
 *
 * @export
 * @interface PaginatedInternalReportList
 */
export interface PaginatedInternalReportList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalReportList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalReportList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalReportList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalReport>}
     * @memberof PaginatedInternalReportList
     */
    results?: Array<InternalReport>;
}
/**
 *
 * @export
 * @interface PaginatedInternalRoleList
 */
export interface PaginatedInternalRoleList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalRoleList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalRoleList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalRoleList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalRole>}
     * @memberof PaginatedInternalRoleList
     */
    results?: Array<InternalRole>;
}
/**
 *
 * @export
 * @interface PaginatedInternalSensorList
 */
export interface PaginatedInternalSensorList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalSensorList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalSensorList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalSensorList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalSensor>}
     * @memberof PaginatedInternalSensorList
     */
    results?: Array<InternalSensor>;
}
/**
 *
 * @export
 * @interface PaginatedInternalSensorScheduleList
 */
export interface PaginatedInternalSensorScheduleList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalSensorScheduleList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalSensorScheduleList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalSensorScheduleList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalSensorSchedule>}
     * @memberof PaginatedInternalSensorScheduleList
     */
    results?: Array<InternalSensorSchedule>;
}
/**
 *
 * @export
 * @interface PaginatedInternalTaskingBlockOutWindowList
 */
export interface PaginatedInternalTaskingBlockOutWindowList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalTaskingBlockOutWindowList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalTaskingBlockOutWindowList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalTaskingBlockOutWindowList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalTaskingBlockOutWindow>}
     * @memberof PaginatedInternalTaskingBlockOutWindowList
     */
    results?: Array<InternalTaskingBlockOutWindow>;
}
/**
 *
 * @export
 * @interface PaginatedInternalTaskingRequestList
 */
export interface PaginatedInternalTaskingRequestList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalTaskingRequestList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalTaskingRequestList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalTaskingRequestList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalTaskingRequest>}
     * @memberof PaginatedInternalTaskingRequestList
     */
    results?: Array<InternalTaskingRequest>;
}
/**
 *
 * @export
 * @interface PaginatedInternalTaskingScheduleList
 */
export interface PaginatedInternalTaskingScheduleList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalTaskingScheduleList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalTaskingScheduleList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalTaskingScheduleList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalTaskingSchedule>}
     * @memberof PaginatedInternalTaskingScheduleList
     */
    results?: Array<InternalTaskingSchedule>;
}
/**
 *
 * @export
 * @interface PaginatedInternalUpdateSensorTaskList
 */
export interface PaginatedInternalUpdateSensorTaskList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalUpdateSensorTaskList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalUpdateSensorTaskList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalUpdateSensorTaskList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalUpdateSensorTask>}
     * @memberof PaginatedInternalUpdateSensorTaskList
     */
    results?: Array<InternalUpdateSensorTask>;
}
/**
 *
 * @export
 * @interface PaginatedOpportunityList
 */
export interface PaginatedOpportunityList {
    /**
     *
     * @type {number}
     * @memberof PaginatedOpportunityList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedOpportunityList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedOpportunityList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Opportunity>}
     * @memberof PaginatedOpportunityList
     */
    results?: Array<Opportunity>;
}
/**
 *
 * @export
 * @interface PaginatedOrderList
 */
export interface PaginatedOrderList {
    /**
     *
     * @type {number}
     * @memberof PaginatedOrderList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedOrderList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedOrderList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Order>}
     * @memberof PaginatedOrderList
     */
    results?: Array<Order>;
}
/**
 *
 * @export
 * @interface PaginatedOrderStatusList
 */
export interface PaginatedOrderStatusList {
    /**
     *
     * @type {number}
     * @memberof PaginatedOrderStatusList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedOrderStatusList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedOrderStatusList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<OrderStatus>}
     * @memberof PaginatedOrderStatusList
     */
    results?: Array<OrderStatus>;
}
/**
 *
 * @export
 * @interface PaginatedPermissionSetList
 */
export interface PaginatedPermissionSetList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPermissionSetList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPermissionSetList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPermissionSetList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PermissionSet>}
     * @memberof PaginatedPermissionSetList
     */
    results?: Array<PermissionSet>;
}
/**
 *
 * @export
 * @interface PaginatedProgramAOIList
 */
export interface PaginatedProgramAOIList {
    /**
     *
     * @type {number}
     * @memberof PaginatedProgramAOIList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramAOIList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramAOIList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<ProgramAOI>}
     * @memberof PaginatedProgramAOIList
     */
    results?: Array<ProgramAOI>;
}
/**
 *
 * @export
 * @interface PaginatedProgramList
 */
export interface PaginatedProgramList {
    /**
     *
     * @type {number}
     * @memberof PaginatedProgramList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Program>}
     * @memberof PaginatedProgramList
     */
    results?: Array<Program>;
}
/**
 *
 * @export
 * @interface PaginatedProgramUserList
 */
export interface PaginatedProgramUserList {
    /**
     *
     * @type {number}
     * @memberof PaginatedProgramUserList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramUserList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramUserList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<ProgramUser>}
     * @memberof PaginatedProgramUserList
     */
    results?: Array<ProgramUser>;
}
/**
 *
 * @export
 * @interface PaginatedPublicDataProductList
 */
export interface PaginatedPublicDataProductList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPublicDataProductList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPublicDataProductList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPublicDataProductList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PublicDataProduct>}
     * @memberof PaginatedPublicDataProductList
     */
    results?: Array<PublicDataProduct>;
}
/**
 *
 * @export
 * @interface PaginatedRasterArtifactList
 */
export interface PaginatedRasterArtifactList {
    /**
     *
     * @type {number}
     * @memberof PaginatedRasterArtifactList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedRasterArtifactList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedRasterArtifactList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<RasterArtifact>}
     * @memberof PaginatedRasterArtifactList
     */
    results?: Array<RasterArtifact>;
}
/**
 *
 * @export
 * @interface PaginatedReportList
 */
export interface PaginatedReportList {
    /**
     *
     * @type {number}
     * @memberof PaginatedReportList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedReportList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedReportList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Report>}
     * @memberof PaginatedReportList
     */
    results?: Array<Report>;
}
/**
 *
 * @export
 * @interface PaginatedRoleList
 */
export interface PaginatedRoleList {
    /**
     *
     * @type {number}
     * @memberof PaginatedRoleList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedRoleList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedRoleList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Role>}
     * @memberof PaginatedRoleList
     */
    results?: Array<Role>;
}
/**
 *
 * @export
 * @interface PaginatedSearchAreaList
 */
export interface PaginatedSearchAreaList {
    /**
     *
     * @type {number}
     * @memberof PaginatedSearchAreaList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedSearchAreaList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedSearchAreaList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<SearchArea>}
     * @memberof PaginatedSearchAreaList
     */
    results?: Array<SearchArea>;
}
/**
 *
 * @export
 * @interface PaginatedSensorList
 */
export interface PaginatedSensorList {
    /**
     *
     * @type {number}
     * @memberof PaginatedSensorList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedSensorList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedSensorList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Sensor>}
     * @memberof PaginatedSensorList
     */
    results?: Array<Sensor>;
}
/**
 *
 * @export
 * @interface PaginatedStateVectorList
 */
export interface PaginatedStateVectorList {
    /**
     *
     * @type {number}
     * @memberof PaginatedStateVectorList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedStateVectorList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedStateVectorList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<StateVector>}
     * @memberof PaginatedStateVectorList
     */
    results?: Array<StateVector>;
}
/**
 *
 * @export
 * @interface PaginatedTLEList
 */
export interface PaginatedTLEList {
    /**
     *
     * @type {number}
     * @memberof PaginatedTLEList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedTLEList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedTLEList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<TLE>}
     * @memberof PaginatedTLEList
     */
    results?: Array<TLE>;
}
/**
 * For security purposes, we will not return client_secret in any requests except for create.
 * @export
 * @interface PatchedApplicationRequest
 */
export interface PatchedApplicationRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    client_id?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    user?: string;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    client_type?: PatchedApplicationRequestClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    authorization_grant_type?: PatchedApplicationRequestAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedApplicationRequestClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedApplicationRequestAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface PatchedAssetRequest
 */
export interface PatchedAssetRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedAssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedAssetRequest
     */
    report_emails?: Array<string>;
}
/**
 *
 * @export
 * @interface PatchedDataPipelineCalibrationRequest
 */
export interface PatchedDataPipelineCalibrationRequest {
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    calibration_type?: number;
    /**
     *
     * @type {string}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    calibration_time?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    raw_s3_uri?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    working_s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    validation_status?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    samples?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    bands?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    bin?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    sensor?: number;
}
/**
 *
 * @export
 * @interface PatchedDetectionArtifactRequest
 */
export interface PatchedDetectionArtifactRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedDetectionArtifactRequest
     */
    artifact_type?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedDetectionArtifactRequest
     */
    uri?: string;
    /**
     * Internal detection ID
     * @type {number}
     * @memberof PatchedDetectionArtifactRequest
     */
    detection?: number;
}
/**
 *
 * @export
 * @interface PatchedDetectionRequest
 */
export interface PatchedDetectionRequest {
    /**
     * Feedback and status of detection after in situ confirmation
     * @type {string}
     * @memberof PatchedDetectionRequest
     */
    feedback_status?: PatchedDetectionRequestFeedbackStatusEnum;
    /**
     * Explanation for why the selected feedback status was chosen
     * @type {string}
     * @memberof PatchedDetectionRequest
     */
    feedback_text?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedDetectionRequestFeedbackStatusEnum {
    Unreviewed = 'Unreviewed',
    Unresolved = 'Unresolved',
    ResolvedAccepted = 'Resolved - Accepted',
    ResolvedRejected = 'Resolved - Rejected',
}

/**
 *
 * @export
 * @interface PatchedInternalAssetRequest
 */
export interface PatchedInternalAssetRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalAssetRequest
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalAssetRequest
     */
    aoi?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalAssetRequest
     */
    program?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedInternalAssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedInternalAssetRequest
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalAssetRequest
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalAssetRequest
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalAssetRequest
     */
    monitoring_frequency?: PatchedInternalAssetRequestMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalAssetRequestMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface PatchedInternalCaptureRequest
 */
export interface PatchedInternalCaptureRequest {
    /**
     * Unique ID for a Task. A Task is a collection of images taken together in sequence.
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    task_id?: string;
    /**
     * Unique ID for a captured image
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    file_id?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    pipeline_execution_id?: string | null;
    /**
     * The time at which the image was captured
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    acquisition_time?: string;
    /**
     * A polygon representing the area covered by the imagery
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalCaptureRequest
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     * Percentage of the image which is covered in clouds
     * @type {number}
     * @memberof PatchedInternalCaptureRequest
     */
    cloud_cover_pct?: number | null;
    /**
     * The sensor that was used to capture the image
     * @type {number}
     * @memberof PatchedInternalCaptureRequest
     */
    sensor?: number;
}
/**
 *
 * @export
 * @interface PatchedInternalDetectionRequest
 */
export interface PatchedInternalDetectionRequest {
    /**
     * Classification for this detection
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    detection_class?: PatchedInternalDetectionRequestDetectionClassEnum;
    /**
     * Internal review status of detection
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    review_status?: PatchedInternalDetectionRequestReviewStatusEnum;
    /**
     * Feedback and status of detection after in situ confirmation
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    feedback_status?: PatchedInternalDetectionRequestFeedbackStatusEnum;
    /**
     * Explanation for why the selected feedback status was chosen
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    feedback_text?: string;
    /**
     * Latitude & longitude where this detection was found
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalDetectionRequest
     */
    coordinates?: Geometry | GeometryCollection;
    /**
     * General category for the detection (e.g., intrusion)
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    call_type?: string;
    /**
     * Category for the detection, representing the specific kind of finding found
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    detection_type?: string;
    /**
     * Time that the image used to find this detection was collected
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    detected_at?: string;
    /**
     * Intensity/magnitude for this detection. This value is not used for all detections, and has different meanings depending on the detection type
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    intensity_value?: number | null;
    /**
     * Measurement unit for the intensity value (if applicable)
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    intensity_unit?: string | null;
    /**
     * Total area of the detection area in square meters
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    area?: number | null;
    /**
     * When the detection was reviewed, if applicable
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    reviewed_at?: string | null;
    /**
     * Reference to the source image for this detection
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    capture?: number | null;
    /**
     * Customer asset associated with this finding
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    asset?: number;
    /**
     * Who reviewed the processing findings, if anyone
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    reviewed_by?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalDetectionRequestDetectionClassEnum {
    Hidden = 'hidden',
    Detection = 'detection',
    Alert = 'alert',
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalDetectionRequestReviewStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalDetectionRequestFeedbackStatusEnum {
    Unreviewed = 'Unreviewed',
    Unresolved = 'Unresolved',
    ResolvedAccepted = 'Resolved - Accepted',
    ResolvedRejected = 'Resolved - Rejected',
}

/**
 * This serializer will be used by internal applications that need all the available fields in the Tick model.
 * @export
 * @interface PatchedInternalOpportunityRequest
 */
export interface PatchedInternalOpportunityRequest {
    /**
     * The start time of the propagator run which produced this tick
     * @type {string}
     * @memberof PatchedInternalOpportunityRequest
     */
    propagator_seed_time?: string;
    /**
     * Nadir point directly underneath the sensor.
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalOpportunityRequest
     */
    nadir_point?: Geometry | GeometryCollection;
    /**
     * Polygon covering Field of View while pointed at nadir.
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalOpportunityRequest
     */
    nadir_fov?: Geometry | GeometryCollection;
    /**
     * Polygon covering all areas potentially within the Field of View allowed by slewing. Sensors will not be able to image the entire max field of view in one pass.
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalOpportunityRequest
     */
    max_fov?: Geometry | GeometryCollection;
    /**
     * Start of the time interval covered by this opportunity.
     * @type {string}
     * @memberof PatchedInternalOpportunityRequest
     */
    start_time?: string;
    /**
     * End of the time interval covered by this opportunity, by default 1 second after start_time.
     * @type {string}
     * @memberof PatchedInternalOpportunityRequest
     */
    end_time?: string;
    /**
     * The position of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    position?: Array<number>;
    /**
     * The next tick\'s position of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    position_next?: Array<number>;
    /**
     * The velocity of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    velocity?: Array<number>;
    /**
     * The next tick\'s velocity of the satellite in the J2000 Coordinate System as a vector. The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    velocity_next?: Array<number>;
    /**
     * The J2000 time for this record.  The J2000 time represents the number of seconds that have passed since Jan 1, 2000 at 12:00:00 PM.
     * @type {number}
     * @memberof PatchedInternalOpportunityRequest
     */
    j2000_time?: number;
    /**
     * The position of the satellite in the ECEF Coordinate System as a vector. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    position_ecef?: Array<number>;
    /**
     * The position of the satellite in the ECEF Coordinate System as a vector at time t + 1. The vector consists of 3 floats, the X, Y, and Z components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    position_ecef_next?: Array<number>;
    /**
     * The velocity of the satellite in the ECEF Coordinate System as a vector.The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    velocity_ecef?: Array<number>;
    /**
     * The velocity of the satellite in the ECEF Coordinate System as a vector at time t + 1.The vector consists of 3 floats, the dX, dY, and dZ components.
     * @type {Array<number>}
     * @memberof PatchedInternalOpportunityRequest
     */
    velocity_ecef_next?: Array<number>;
    /**
     * If this field is true, then the satellite is considered \"eclipsed\" which means it is currently night at the location of the satellite.  At the time of this writing, collecting imagery is not possible if the target area is eclipsed.
     * @type {boolean}
     * @memberof PatchedInternalOpportunityRequest
     */
    eclipsed?: boolean;
    /**
     * This field indicates whether or not this tick is blocked out via the core_api_taskingblockoutwindow. If true, the tick is not eligible for tasking. If false, the tick is eligible for tasking.
     * @type {boolean}
     * @memberof PatchedInternalOpportunityRequest
     */
    blocked_out?: boolean;
    /**
     * This field represents the orbit number of the Tick.  An orbit is defined as 1 eclipsed & 1 non-eclipsed period (starting with an eclipse and ending with non-eclipse).  This will be useful for filtering data from the client (and grabbing single orbits).
     * @type {number}
     * @memberof PatchedInternalOpportunityRequest
     */
    orbit_num?: number;
    /**
     * This field represents the row number (starting at 0) of this tick record in the original orbit propagator file. This is used by the tasking engine.
     * @type {number}
     * @memberof PatchedInternalOpportunityRequest
     */
    row_num?: number;
    /**
     * This represents the ellipsoidal height of the satellite (in meters). In other words, this is the distance the satellite is from the WGS84 ellipsoid.  This value can be used to find the true altitude of the satellite (the Orthometric Height) by using this simple equation:  Orthometric Height = Ellipsoidal Height - Geoid Height  Currently the most accurate Geoid that covers most of the world is the EGM2008 Geoid (which you can use to find the Geoid Height).
     * @type {number}
     * @memberof PatchedInternalOpportunityRequest
     */
    ellipsoidal_height?: number;
    /**
     * The sensor this tick belongs to.
     * @type {number}
     * @memberof PatchedInternalOpportunityRequest
     */
    sensor?: number;
    /**
     * The state vector that was used as input into the Orbit Propagator which produced this Tick record.
     * @type {number}
     * @memberof PatchedInternalOpportunityRequest
     */
    state_vector?: number | null;
}
/**
 *
 * @export
 * @interface PatchedInternalReportRequest
 */
export interface PatchedInternalReportRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    report_type?: PatchedInternalReportRequestReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    status?: PatchedInternalReportRequestStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    program?: number;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedInternalReportRequest
     */
    assets?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    start_date?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    end_date?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalReportRequestStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface PatchedInternalRoleRequest
 */
export interface PatchedInternalRoleRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalRoleRequest
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalRoleRequest
     */
    slug?: string;
}
/**
 *
 * @export
 * @interface PatchedInternalSensorRequest
 */
export interface PatchedInternalSensorRequest {
    /**
     *
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    osk_id?: number;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    osk_sensor_name?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    osk_serial_number?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    nro_id?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    default_frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    ifov?: number | null;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    catalog_number?: string | null;
    /**
     * The maximum angle allowed for the telescope to slew to/from a target
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    max_slew_angle?: number | null;
    /**
     * The maximum velocity allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    max_roll_velocity?: number | null;
    /**
     * The maximum acceleration allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    max_roll_accel?: number | null;
    /**
     * The maximum jerk allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    max_roll_jerk?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    focal_length?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    detector_size?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    spatial_pixels?: number;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    tasking_eligible_start?: string | null;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    tasking_eligible_end?: string | null;
    /**
     * The identifier used by our 3rd party bus provider to identify this sensor / satellite internally. At the time of this writing, this field will contain the identifier used by Astro Digital to identity our satellites.
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    external_id?: string | null;
    /**
     * Duration representing the max age of a state vector for use of either generating a tasking plan or submitting a tasking plan for use on the spacecraft (for the current sensor). This mechanism serves as a safety check to ensure we don\'t miss targets due to using a stale state vector.
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    max_state_vector_age?: string | null;
    /**
     * The drag model you wish to use during orbit propagation of the current sensor.
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    drag_model?: PatchedInternalSensorRequestDragModelEnum;
    /**
     * Dimensionless coefficient used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    drag_coefficient?: number | null;
    /**
     * The area to mass ratio (m/kg) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    drag_area_mass_ratio?: number | null;
    /**
     * The atmospheric density (kg/m^3) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    drag_atm_density?: number | null;
    /**
     * The atmospheric density gradient (kg/m^3) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    drag_atm_density_gradient?: number | null;
    /**
     * The reference altitude (in meters) used to calculate drag during orbit propagation of the current sensor.
     * @type {number}
     * @memberof PatchedInternalSensorRequest
     */
    drag_ref_altitude?: number | null;
    /**
     * The date and time that the satellite housing this sensor was launched
     * @type {string}
     * @memberof PatchedInternalSensorRequest
     */
    launch_date?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalSensorRequestDragModelEnum {
    Disabled = 'DISABLED',
    Standard = 'STANDARD',
    Jacchia70 = 'JACCHIA70',
    Null = 'null',
}

/**
 * Serializer for the InternalSensorScheduleViewSet
 * @export
 * @interface PatchedInternalSensorScheduleRequest
 */
export interface PatchedInternalSensorScheduleRequest {
    /**
     * The workflow_status field tells us if the SensorSchedule is being generated, has finished generating, has been selected to send to AD, is archived, or has failed.
     * @type {string}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    workflow_status?: PatchedInternalSensorScheduleRequestWorkflowStatusEnum;
    /**
     * This field is used to track the progress of the schedule generation process.  The scheduler will create the schedule in \"Orbit Chunks\".  The number of orbits included in a single run of the Tasking Engine is a configurable setting.  When orbit_num >= total_orbits, then this SensorSchedule is considered complete.
     * @type {number}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    orbit_num?: number;
    /**
     * This represents the sum of all the child SensorTasks\' score field.  The score of a SensorTask is used todetermine how the schedule is created (and determines the overall priority).
     * @type {number}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    total_score?: number;
    /**
     * GeometryCollection that will be excluded from scheduling.  This can be used to de-conflict multiple satellites from collecting the same target or could also be used to limit scheduling to a particular area.
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    exclusion_geometry?: Geometry | GeometryCollection;
    /**
     * Represents the version of the Tasking Engine that generated the Flight JAS, JSON File, and SQL Payload Script. This will be serialized to the \'sw_version\' field in the JSON file.
     * @type {string}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    software_version?: string;
    /**
     * This field contains the entire serialized JSON File Content that AD will receive. The purpose of this file is to provide AD with all the Flight JAS scripts the satellite needs to perform all the imaging tasks.
     * @type {string}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    json_file?: string;
    /**
     * This field contains the SQL script that needs to be sent to AD, uploaded to the Payload, and executed. The SQL script will populate the on-board database with data it needs to perform each collect.
     * @type {string}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    sql_file?: string;
    /**
     * This field contains the name of the SQL File. Whenever we need to materialize this file, this is the file name that will be used.
     * @type {string}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    sql_file_name?: string;
    /**
     * This represents how many SensorTask records have been generated for the current SensorSchedule.
     * @type {number}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    total_tasks?: number;
    /**
     * This field contains the Flight Jas script that was stitched together by Astro Digital. It can be run directly on the flat-sat or satellite to perform collects.
     * @type {string}
     * @memberof PatchedInternalSensorScheduleRequest
     */
    flight_jas_file?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalSensorScheduleRequestWorkflowStatusEnum {
    Generating = 'GENERATING',
    Generated = 'GENERATED',
    Committed = 'COMMITTED',
    Archived = 'ARCHIVED',
    Failed = 'FAILED',
}

/**
 * Serializer for the InternalTaskingRequestViewSet.
 * @export
 * @interface PatchedInternalTaskingRequestRequest
 */
export interface PatchedInternalTaskingRequestRequest {
    /**
     * This geometry represents the area we wish to capture. This can be any geometry type including a GeometryCollection.
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    requested_geometry?: Geometry | GeometryCollection;
    /**
     * If the TaskingRequest originates from an Asset that we are monitoring, this linkage should be populated. If the TaskingRequest does not originate from an Asset, this property will be NULL.
     * @type {number}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    asset?: number | null;
    /**
     * This is the datetime (inclusive) representing when this tasking requests becomes active.
     * @type {string}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    start_date?: string;
    /**
     * This is the datetime (exclusive) representing when this tasking requests becomes inactive.  If this value is NULL, the TaskingRequest will remain active until 100%% of the geometry is collected.
     * @type {string}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    end_date?: string | null;
    /**
     * Human Readable name of the Tasking Request
     * @type {string}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    name?: string;
    /**
     * The priority defines how the tasking request should be prioritized and approved.
     * @type {number}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    priority?: PatchedInternalTaskingRequestRequestPriorityEnum;
    /**
     * The Sensors that the Tasking Scheduler will consider this TaskingRequest for tasking on
     * @type {Array<number>}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    sensors?: Array<number>;
    /**
     * By setting this flag to true, the Tasking Engine will not run the \"subtraction\" logic on this Tasking Request. For example, if this TaskingRequest is a point target and is_recurring is False, then the Tasking Engine will only ever schedule the target once (if the chunk size of the tasking engine is set correctly). After the engine schedules the target and the chunk is completely processed, all the areas scheduled for collection are subtracted from the next chunk. On the other hand, if is_recurring = True, this target will be scheduled over and over again. I would imagine most point targets need to be set to is_recurring = True and all other targets to is_recurring = False.
     * @type {boolean}
     * @memberof PatchedInternalTaskingRequestRequest
     */
    is_recurring?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalTaskingRequestRequestPriorityEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
}

/**
 * Serializer for the InternalTaskingScheduleViewSet
 * @export
 * @interface PatchedInternalTaskingScheduleRequest
 */
export interface PatchedInternalTaskingScheduleRequest {
    /**
     * The workflow_status field tells us if the TaskingSchedule is being generated, has finished generating, has been selected to send to AD, or has been archived.
     * @type {string}
     * @memberof PatchedInternalTaskingScheduleRequest
     */
    workflow_status?: PatchedInternalTaskingScheduleRequestWorkflowStatusEnum;
    /**
     * If you are creating a TaskingSchedule for specific sensors, you can pass them via this parameter. Note, if you attempt to pass a sensor_id for a Sensor that is not Tasking Eligible, a validation error will be raised.
     * @type {Array<number>}
     * @memberof PatchedInternalTaskingScheduleRequest
     */
    sensor_ids?: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalTaskingScheduleRequestWorkflowStatusEnum {
    Generating = 'GENERATING',
    Generated = 'GENERATED',
    Committed = 'COMMITTED',
    Archived = 'ARCHIVED',
    Failed = 'FAILED',
}

/**
 * Serializer used for the UPDATE operation on the InternalSensorTaskViewSet
 * @export
 * @interface PatchedInternalUpdateSensorTaskRequest
 */
export interface PatchedInternalUpdateSensorTaskRequest {
    /**
     * task_status contains the status of the task, generated by the Payload or inferred when the imagery comes in via the datapipeline.
     * @type {string}
     * @memberof PatchedInternalUpdateSensorTaskRequest
     */
    task_status?: PatchedInternalUpdateSensorTaskRequestTaskStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalUpdateSensorTaskRequestTaskStatusEnum {
    Uplinked = 'UPLINKED',
    Failed = 'FAILED',
    FailedSettings = 'FAILED_SETTINGS',
    FailedMissedAcq = 'FAILED_MISSED_ACQ',
    FailedDarkCapture = 'FAILED_DARK_CAPTURE',
    FailedCapture = 'FAILED_CAPTURE',
    FailedProcessing = 'FAILED_PROCESSING',
    Downlinked = 'DOWNLINKED',
}

/**
 *
 * @export
 * @interface PatchedPermissionSetRequest
 */
export interface PatchedPermissionSetRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedPermissionSetRequest
     */
    name?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedPermissionSetRequest
     */
    permissions?: Array<number>;
}
/**
 *
 * @export
 * @interface PatchedProgramAOIRequest
 */
export interface PatchedProgramAOIRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedProgramAOIRequest
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedProgramAOIRequest
     */
    program?: number;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedProgramAOIRequest
     */
    geometry?: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface PatchedProgramRequest
 */
export interface PatchedProgramRequest {
    /**
     * The name of the program
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    name?: string;
    /**
     * The company or organization running this program
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    company_name?: string | null;
    /**
     * Date the program contract begins
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    start_date?: string | null;
    /**
     * Date the program contract ends
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    end_date?: string | null;
    /**
     * Is the program currently active?
     * @type {boolean}
     * @memberof PatchedProgramRequest
     */
    active?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedProgramRequest
     */
    product_level_access?: Array<PatchedProgramRequestProductLevelAccessEnum>;
    /**
     * Which regions/AOI are availible to members of this program
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedProgramRequest
     */
    region_access?: Geometry | GeometryCollection | null;
    /**
     * Maximum number of users that can be registered to this program
     * @type {number}
     * @memberof PatchedProgramRequest
     */
    max_users?: number | null;
    /**
     * Maximum number of image taskings that this program has available
     * @type {number}
     * @memberof PatchedProgramRequest
     */
    max_taskings?: number | null;
    /**
     * The satellites or aerial systems availible to members of this program
     * @type {Array<number>}
     * @memberof PatchedProgramRequest
     */
    sensor_data_access?: Array<number>;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedProgramRequest
     */
    subscribed_products?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedProgramRequestProductLevelAccessEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}

/**
 *
 * @export
 * @interface PatchedRasterArtifactRequest
 */
export interface PatchedRasterArtifactRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedRasterArtifactRequest
     */
    files?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof PatchedRasterArtifactRequest
     */
    processing_level?: number;
    /**
     *
     * @type {string}
     * @memberof PatchedRasterArtifactRequest
     */
    artifact_type?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedRasterArtifactRequest
     */
    capture?: number | null;
}
/**
 *
 * @export
 * @interface PatchedReportRequest
 */
export interface PatchedReportRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedReportRequest
     */
    report_type?: PatchedReportRequestReportTypeEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedReportRequest
     */
    program?: number;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedReportRequest
     */
    assets?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PatchedReportRequest
     */
    start_date?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedReportRequest
     */
    end_date?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface PatchedSearchAreaRequest
 */
export interface PatchedSearchAreaRequest {
    /**
     * Name of the area (must be unique for each user)
     * @type {string}
     * @memberof PatchedSearchAreaRequest
     */
    name?: string;
    /**
     * Geometry representing the area
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedSearchAreaRequest
     */
    area?: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface PatchedStateVectorRequest
 */
export interface PatchedStateVectorRequest {
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    epoch?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    position_x?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    position_y?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    position_z?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    velocity_x?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    velocity_y?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    velocity_z?: number;
}
/**
 *
 * @export
 * @interface PermissionSet
 */
export interface PermissionSet {
    /**
     *
     * @type {number}
     * @memberof PermissionSet
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PermissionSet
     */
    name: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PermissionSet
     */
    permissions?: Array<number>;
}
/**
 *
 * @export
 * @interface PermissionSetRequest
 */
export interface PermissionSetRequest {
    /**
     *
     * @type {string}
     * @memberof PermissionSetRequest
     */
    name: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PermissionSetRequest
     */
    permissions?: Array<number>;
}
/**
 *
 * @export
 * @interface Program
 */
export interface Program {
    /**
     *
     * @type {number}
     * @memberof Program
     */
    id: number;
    /**
     * The name of the program
     * @type {string}
     * @memberof Program
     */
    name: string;
    /**
     * The company or organization running this program
     * @type {string}
     * @memberof Program
     */
    company_name?: string | null;
    /**
     * Date the program contract begins
     * @type {string}
     * @memberof Program
     */
    start_date?: string | null;
    /**
     * Date the program contract ends
     * @type {string}
     * @memberof Program
     */
    end_date?: string | null;
    /**
     * Is the program currently active?
     * @type {boolean}
     * @memberof Program
     */
    active?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof Program
     */
    product_level_access?: Array<ProgramProductLevelAccessEnum>;
    /**
     * Which regions/AOI are availible to members of this program
     * @type {Geometry | GeometryCollection}
     * @memberof Program
     */
    region_access?: Geometry | GeometryCollection | null;
    /**
     * Maximum number of users that can be registered to this program
     * @type {number}
     * @memberof Program
     */
    max_users?: number | null;
    /**
     * Maximum number of image taskings that this program has available
     * @type {number}
     * @memberof Program
     */
    max_taskings?: number | null;
    /**
     * The satellites or aerial systems availible to members of this program
     * @type {Array<number>}
     * @memberof Program
     */
    sensor_data_access?: Array<number>;
    /**
     *
     * @type {Array<string>}
     * @memberof Program
     */
    subscribed_products: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum ProgramProductLevelAccessEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}

/**
 *
 * @export
 * @interface ProgramAOI
 */
export interface ProgramAOI {
    /**
     *
     * @type {number}
     * @memberof ProgramAOI
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProgramAOI
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProgramAOI
     */
    program: number;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof ProgramAOI
     */
    geometry: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface ProgramAOIRequest
 */
export interface ProgramAOIRequest {
    /**
     *
     * @type {string}
     * @memberof ProgramAOIRequest
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProgramAOIRequest
     */
    program: number;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof ProgramAOIRequest
     */
    geometry: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface ProgramRequest
 */
export interface ProgramRequest {
    /**
     * The name of the program
     * @type {string}
     * @memberof ProgramRequest
     */
    name: string;
    /**
     * The company or organization running this program
     * @type {string}
     * @memberof ProgramRequest
     */
    company_name?: string | null;
    /**
     * Date the program contract begins
     * @type {string}
     * @memberof ProgramRequest
     */
    start_date?: string | null;
    /**
     * Date the program contract ends
     * @type {string}
     * @memberof ProgramRequest
     */
    end_date?: string | null;
    /**
     * Is the program currently active?
     * @type {boolean}
     * @memberof ProgramRequest
     */
    active?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof ProgramRequest
     */
    product_level_access?: Array<ProgramRequestProductLevelAccessEnum>;
    /**
     * Which regions/AOI are availible to members of this program
     * @type {Geometry | GeometryCollection}
     * @memberof ProgramRequest
     */
    region_access?: Geometry | GeometryCollection | null;
    /**
     * Maximum number of users that can be registered to this program
     * @type {number}
     * @memberof ProgramRequest
     */
    max_users?: number | null;
    /**
     * Maximum number of image taskings that this program has available
     * @type {number}
     * @memberof ProgramRequest
     */
    max_taskings?: number | null;
    /**
     * The satellites or aerial systems availible to members of this program
     * @type {Array<number>}
     * @memberof ProgramRequest
     */
    sensor_data_access?: Array<number>;
    /**
     *
     * @type {Array<string>}
     * @memberof ProgramRequest
     */
    subscribed_products: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum ProgramRequestProductLevelAccessEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}

/**
 * Represents a user\'s association with a program. Includes relevant details about the user and the list of roles they have on a given program.
 * @export
 * @interface ProgramUser
 */
export interface ProgramUser {
    /**
     * The id of the user
     * @type {number}
     * @memberof ProgramUser
     */
    id: number;
    /**
     * The email address of the user
     * @type {string}
     * @memberof ProgramUser
     */
    email: string;
    /**
     * A list of role names associated with the user
     * @type {Array<string>}
     * @memberof ProgramUser
     */
    roles: Array<string>;
}
/**
 * Represents a user\'s association with a program. Includes relevant details about the user and the list of roles they have on a given program.
 * @export
 * @interface ProgramUserRequest
 */
export interface ProgramUserRequest {
    /**
     * A list of role names associated with the user
     * @type {Array<string>}
     * @memberof ProgramUserRequest
     */
    roles: Array<string>;
}
/**
 * API for data product information
 * @export
 * @interface PublicDataProduct
 */
export interface PublicDataProduct {
    /**
     * Primary key identifying a DataProduct
     * @type {number}
     * @memberof PublicDataProduct
     */
    id: number;
    /**
     * Short name for the product.
     * @type {string}
     * @memberof PublicDataProduct
     */
    product_level: PublicDataProductProductLevelEnum;
    /**
     * Help text for the product level value
     * @type {string}
     * @memberof PublicDataProduct
     */
    product_level_help_text: string;
    /**
     * Short name for the file format.
     * @type {string}
     * @memberof PublicDataProduct
     */
    file_format: PublicDataProductFileFormatEnum;
    /**
     * Help text for file_format
     * @type {string}
     * @memberof PublicDataProduct
     */
    file_format_help_text: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PublicDataProductProductLevelEnum {
    Raw = 'Raw',
    Unrectified = 'Unrectified',
    BasicL1A = 'Basic L1A',
    Basic = 'Basic',
    Analytics = 'Analytics',
    DarkSubtract = 'Dark Subtract',
}
/**
 * @export
 * @enum {string}
 */
export enum PublicDataProductFileFormatEnum {
    Nitf = 'NITF',
    Envi = 'ENVI',
}

/**
 *
 * @export
 * @interface RasterArtifact
 */
export interface RasterArtifact {
    /**
     *
     * @type {number}
     * @memberof RasterArtifact
     */
    id: number;
    /**
     *
     * @type {Array<string>}
     * @memberof RasterArtifact
     */
    files: Array<string>;
    /**
     *
     * @type {number}
     * @memberof RasterArtifact
     */
    processing_level: number;
    /**
     *
     * @type {string}
     * @memberof RasterArtifact
     */
    artifact_type: string;
    /**
     *
     * @type {number}
     * @memberof RasterArtifact
     */
    capture: number | null;
}
/**
 *
 * @export
 * @interface RasterArtifactRequest
 */
export interface RasterArtifactRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof RasterArtifactRequest
     */
    files: Array<string>;
    /**
     *
     * @type {number}
     * @memberof RasterArtifactRequest
     */
    processing_level: number;
    /**
     *
     * @type {string}
     * @memberof RasterArtifactRequest
     */
    artifact_type: string;
    /**
     *
     * @type {number}
     * @memberof RasterArtifactRequest
     */
    capture: number | null;
}
/**
 *
 * @export
 * @interface Report
 */
export interface Report {
    /**
     *
     * @type {string}
     * @memberof Report
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof Report
     */
    report_type?: ReportReportTypeEnum;
    /**
     *
     * @type {number}
     * @memberof Report
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof Report
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof Report
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    end_date: string;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    created_at: string;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    updated_at: string;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    pdf_presigned_uri: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ReportReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
    /**
     *
     * @type {string}
     * @memberof ReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof ReportRequest
     */
    report_type?: ReportRequestReportTypeEnum;
    /**
     *
     * @type {number}
     * @memberof ReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof ReportRequest
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof ReportRequest
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof ReportRequest
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof ReportRequest
     */
    end_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * A unique identifier for the role. This value is unique across all programs.
     * @type {number}
     * @memberof Role
     */
    id: number;
    /**
     * The name of the role. This value is unique within a program.
     * @type {string}
     * @memberof Role
     */
    name: string;
}
/**
 *
 * @export
 * @interface SearchArea
 */
export interface SearchArea {
    /**
     *
     * @type {number}
     * @memberof SearchArea
     */
    id: number;
    /**
     * Name of the area (must be unique for each user)
     * @type {string}
     * @memberof SearchArea
     */
    name: string;
    /**
     * Geometry representing the area
     * @type {Geometry | GeometryCollection}
     * @memberof SearchArea
     */
    area: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface SearchAreaRequest
 */
export interface SearchAreaRequest {
    /**
     * Name of the area (must be unique for each user)
     * @type {string}
     * @memberof SearchAreaRequest
     */
    name: string;
    /**
     * Geometry representing the area
     * @type {Geometry | GeometryCollection}
     * @memberof SearchAreaRequest
     */
    area: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface Sensor
 */
export interface Sensor {
    /**
     *
     * @type {number}
     * @memberof Sensor
     */
    osk_id: number;
    /**
     *
     * @type {string}
     * @memberof Sensor
     */
    osk_sensor_name: string;
    /**
     *
     * @type {string}
     * @memberof Sensor
     */
    osk_serial_number: string;
    /**
     *
     * @type {string}
     * @memberof Sensor
     */
    nro_id: string;
    /**
     *
     * @type {number}
     * @memberof Sensor
     */
    default_frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof Sensor
     */
    ifov?: number | null;
    /**
     *
     * @type {string}
     * @memberof Sensor
     */
    catalog_number?: string | null;
    /**
     * The maximum angle allowed for the telescope to slew to/from a target
     * @type {number}
     * @memberof Sensor
     */
    max_slew_angle?: number | null;
    /**
     *
     * @type {number}
     * @memberof Sensor
     */
    spatial_pixels: number;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof Sensor
     */
    tasking_eligible_start?: string | null;
    /**
     * For a Sensor to be considered for Tasking via the Tasking Engine, tasking_eligible_start or tasking_eligible_end must be non-null. If tasking_eligible_start is non-null but tasking_eligible_end is null, then the current time must be after tasking_eligible_start. If tasking_eligible_start is null and tasking_eligible_end is not-null, the current time must be before tasking_eligible_end. If tasking_eligible_start and tasking_eligible_end are both non-null, the current time must be between the 2 fields. Note, that tasking_eligible_start is inclusive and tasking_eligible_end is exclusive.
     * @type {string}
     * @memberof Sensor
     */
    tasking_eligible_end?: string | null;
    /**
     * The maximum velocity allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof Sensor
     */
    max_roll_velocity?: number | null;
    /**
     * The maximum acceleration allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof Sensor
     */
    max_roll_accel?: number | null;
    /**
     * The maximum jerk allowed while the telescope is slewing to/from a target
     * @type {number}
     * @memberof Sensor
     */
    max_roll_jerk?: number | null;
}
/**
 *
 * @export
 * @interface StateVector
 */
export interface StateVector {
    /**
     *
     * @type {string}
     * @memberof StateVector
     */
    epoch_date: string;
    /**
     *
     * @type {string}
     * @memberof StateVector
     */
    create_date: string;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    sensor: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    epoch: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    position_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    position_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    position_z: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    velocity_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    velocity_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    velocity_z: number;
}
/**
 *
 * @export
 * @interface StateVectorRequest
 */
export interface StateVectorRequest {
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    epoch: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    position_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    position_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    position_z: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    velocity_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    velocity_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    velocity_z: number;
}
/**
 *
 * @export
 * @interface TLE
 */
export interface TLE {
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    sensor: number;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    satellite_name: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    satellite_catalog_number: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    classification: TLEClassificationEnum;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    id_launch_year: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    id_launch_number: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    id_launch_piece: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    epoch: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    epoch_year: string;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    epoch_day: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_motion_first_derivative: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_motion_second_derivative: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    b_star: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    element_set_number: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    inclination_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    right_ascension_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    eccentricity: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    argument_of_perigee_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_anomaly_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_motion_in_revolutions_per_day: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    revolution_number_at_epoch: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    ephemeris_type: number;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    create_date: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    raw_tle: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TLEClassificationEnum {
    U = 'U',
    C = 'C',
    S = 'S',
}

/**
 *
 * @export
 * @interface TLERequest
 */
export interface TLERequest {
    /**
     *
     * @type {string}
     * @memberof TLERequest
     */
    raw_tle: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * The email address for this user
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * The user\'s first name
     * @type {string}
     * @memberof User
     */
    first_name?: string;
    /**
     * The user\'s surname
     * @type {string}
     * @memberof User
     */
    last_name?: string;
    /**
     * The company or organization employing the user
     * @type {string}
     * @memberof User
     */
    organization?: string | null;
    /**
     * The user\'s job title at their listed organization
     * @type {string}
     * @memberof User
     */
    job_title?: string | null;
}
/**
 *
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * The user\'s first name
     * @type {string}
     * @memberof UserRequest
     */
    first_name?: string;
    /**
     * The user\'s surname
     * @type {string}
     * @memberof UserRequest
     */
    last_name?: string;
    /**
     * The company or organization employing the user
     * @type {string}
     * @memberof UserRequest
     */
    organization?: string | null;
    /**
     * The user\'s job title at their listed organization
     * @type {string}
     * @memberof UserRequest
     */
    job_title?: string | null;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToProgram: async (
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('addUserToProgram', 'program', program);
            // verify required parameter 'addUserToProgramRequest' is not null or undefined
            assertParamExists('addUserToProgram', 'addUserToProgramRequest', addUserToProgramRequest);
            const localVarPath = `/public/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                addUserToProgramRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/admin/users/profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgram', 'id', id);
            const localVarPath = `/public/v1/admin/programs/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramProfile: async (program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getProgramProfile', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/profile/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary Inspect details about a program role
         * @param {number} id A unique integer value identifying this role.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramRole: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgramRole', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getProgramRole', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/roles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramUser: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getProgramUser', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAddUserToProgram: async (
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalAddUserToProgram', 'program', program);
            // verify required parameter 'addUserToProgramRequest' is not null or undefined
            assertParamExists('internalAddUserToProgram', 'addUserToProgramRequest', addUserToProgramRequest);
            const localVarPath = `/private/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                addUserToProgramRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {ApplicationCreateRequest} applicationCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateApplication: async (
            applicationCreateRequest: ApplicationCreateRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'applicationCreateRequest' is not null or undefined
            assertParamExists('internalCreateApplication', 'applicationCreateRequest', applicationCreateRequest);
            const localVarPath = `/private/v1/admin/application/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                applicationCreateRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePermissionSet: async (
            permissionSetRequest: PermissionSetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'permissionSetRequest' is not null or undefined
            assertParamExists('internalCreatePermissionSet', 'permissionSetRequest', permissionSetRequest);
            const localVarPath = `/private/v1/admin/permission_set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                permissionSetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateProgram: async (
            programRequest: ProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'programRequest' is not null or undefined
            assertParamExists('internalCreateProgram', 'programRequest', programRequest);
            const localVarPath = `/private/v1/admin/programs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(programRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRole: async (
            internalRoleRequest: InternalRoleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalRoleRequest' is not null or undefined
            assertParamExists('internalCreateRole', 'internalRoleRequest', internalRoleRequest);
            const localVarPath = `/private/v1/admin/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalRoleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyApplication', 'id', id);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyPermissionSet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyPermissionSet', 'id', id);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyProgram: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyProgram', 'id', id);
            const localVarPath = `/private/v1/admin/programs/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRole: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyRole', 'id', id);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetApplication', 'id', id);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetPermissionSet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetPermissionSet', 'id', id);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetProgramUser: async (
            id: number,
            program: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalGetProgramUser', 'program', program);
            const localVarPath = `/private/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRole: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetRole', 'id', id);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListApplication: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/application/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPermissionSet: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/permission_set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the users associated with this program
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListProgramUsers: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalListProgramUsers', 'program', program);
            const localVarPath = `/private/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPrograms: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/programs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRoles: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {PatchedApplicationRequest} [patchedApplicationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateApplication: async (
            id: number,
            patchedApplicationRequest?: PatchedApplicationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateApplication', 'id', id);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedApplicationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PatchedPermissionSetRequest} [patchedPermissionSetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdatePermissionSet: async (
            id: number,
            patchedPermissionSetRequest?: PatchedPermissionSetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdatePermissionSet', 'id', id);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedPermissionSetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {PatchedProgramRequest} [patchedProgramRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateProgram: async (
            id: number,
            patchedProgramRequest?: PatchedProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateProgram', 'id', id);
            const localVarPath = `/private/v1/admin/programs/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedProgramRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {PatchedInternalRoleRequest} [patchedInternalRoleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRole: async (
            id: number,
            patchedInternalRoleRequest?: PatchedInternalRoleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateRole', 'id', id);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalRoleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRemoveUserFromProgram: async (
            id: number,
            program: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalRemoveUserFromProgram', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalRemoveUserFromProgram', 'program', program);
            const localVarPath = `/private/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {ApplicationRequest} applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateApplication: async (
            id: number,
            applicationRequest: ApplicationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateApplication', 'id', id);
            // verify required parameter 'applicationRequest' is not null or undefined
            assertParamExists('internalUpdateApplication', 'applicationRequest', applicationRequest);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                applicationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePermissionSet: async (
            id: number,
            permissionSetRequest: PermissionSetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdatePermissionSet', 'id', id);
            // verify required parameter 'permissionSetRequest' is not null or undefined
            assertParamExists('internalUpdatePermissionSet', 'permissionSetRequest', permissionSetRequest);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                permissionSetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgram: async (
            id: number,
            programRequest: ProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateProgram', 'id', id);
            // verify required parameter 'programRequest' is not null or undefined
            assertParamExists('internalUpdateProgram', 'programRequest', programRequest);
            const localVarPath = `/private/v1/admin/programs/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(programRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgramUser: async (
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalUpdateProgramUser', 'program', program);
            // verify required parameter 'programUserRequest' is not null or undefined
            assertParamExists('internalUpdateProgramUser', 'programUserRequest', programUserRequest);
            const localVarPath = `/private/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programUserRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRole: async (
            id: number,
            internalRoleRequest: InternalRoleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateRole', 'id', id);
            // verify required parameter 'internalRoleRequest' is not null or undefined
            assertParamExists('internalUpdateRole', 'internalRoleRequest', internalRoleRequest);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalRoleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary List the roles associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramRoles: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listProgramRoles', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/roles/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the users associated with this program
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramUsers: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listProgramUsers', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/admin/programs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProgram: async (
            id: number,
            program: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeUserFromProgram', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('removeUserFromProgram', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UserRequest} [userRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (userRequest?: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/admin/users/profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramUser: async (
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('updateProgramUser', 'program', program);
            // verify required parameter 'programUserRequest' is not null or undefined
            assertParamExists('updateProgramUser', 'programUserRequest', programUserRequest);
            const localVarPath = `/public/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programUserRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
    return {
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToProgram(
                program,
                addUserToProgramRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgram(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgram(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramProfile(
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramProfile(program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary Inspect details about a program role
         * @param {number} id A unique integer value identifying this role.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramRole(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramRole(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramUser(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramUser(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalAddUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalAddUserToProgram(
                program,
                addUserToProgramRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {ApplicationCreateRequest} applicationCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateApplication(
            applicationCreateRequest: ApplicationCreateRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateApplication(
                applicationCreateRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreatePermissionSet(
            permissionSetRequest: PermissionSetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreatePermissionSet(
                permissionSetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateProgram(
            programRequest: ProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateProgram(programRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateRole(
            internalRoleRequest: InternalRoleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateRole(internalRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyApplication(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyPermissionSet(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyPermissionSet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyProgram(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyProgram(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyRole(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyRole(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetApplication(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetPermissionSet(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetPermissionSet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetProgramUser(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetProgramUser(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetRole(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetRole(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListApplication(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedApplicationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListApplication(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListPermissionSet(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPermissionSetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListPermissionSet(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the users associated with this program
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListProgramUsers(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListPrograms(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListRoles(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalRoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListRoles(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {PatchedApplicationRequest} [patchedApplicationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateApplication(
            id: number,
            patchedApplicationRequest?: PatchedApplicationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateApplication(
                id,
                patchedApplicationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PatchedPermissionSetRequest} [patchedPermissionSetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdatePermissionSet(
            id: number,
            patchedPermissionSetRequest?: PatchedPermissionSetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdatePermissionSet(
                id,
                patchedPermissionSetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {PatchedProgramRequest} [patchedProgramRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateProgram(
            id: number,
            patchedProgramRequest?: PatchedProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateProgram(
                id,
                patchedProgramRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {PatchedInternalRoleRequest} [patchedInternalRoleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateRole(
            id: number,
            patchedInternalRoleRequest?: PatchedInternalRoleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateRole(
                id,
                patchedInternalRoleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalRemoveUserFromProgram(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalRemoveUserFromProgram(
                id,
                program,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {ApplicationRequest} applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateApplication(
            id: number,
            applicationRequest: ApplicationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateApplication(
                id,
                applicationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdatePermissionSet(
            id: number,
            permissionSetRequest: PermissionSetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdatePermissionSet(
                id,
                permissionSetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateProgram(
            id: number,
            programRequest: ProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateProgram(
                id,
                programRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateProgramUser(
                id,
                program,
                programUserRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateRole(
            id: number,
            internalRoleRequest: InternalRoleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateRole(
                id,
                internalRoleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary List the roles associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramRoles(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProgramRoles(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the users associated with this program
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProgramUsers(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPrograms(limit, offset, ordering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromProgram(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromProgram(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UserRequest} [userRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(
            userRequest?: UserRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramUser(
                id,
                program,
                programUserRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration);
    return {
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .addUserToProgram(program, addUserToProgramRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<User> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram(id: number, options?: any): AxiosPromise<Program> {
            return localVarFp.getProgram(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramProfile(program: number, options?: any): AxiosPromise<ProgramUser> {
            return localVarFp.getProgramProfile(program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary Inspect details about a program role
         * @param {number} id A unique integer value identifying this role.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramRole(id: number, program: number, options?: any): AxiosPromise<Role> {
            return localVarFp.getProgramRole(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramUser(id: number, program: number, options?: any): AxiosPromise<ProgramUser> {
            return localVarFp.getProgramUser(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAddUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .internalAddUserToProgram(program, addUserToProgramRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {ApplicationCreateRequest} applicationCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateApplication(
            applicationCreateRequest: ApplicationCreateRequest,
            options?: any,
        ): AxiosPromise<ApplicationCreate> {
            return localVarFp
                .internalCreateApplication(applicationCreateRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePermissionSet(
            permissionSetRequest: PermissionSetRequest,
            options?: any,
        ): AxiosPromise<PermissionSet> {
            return localVarFp
                .internalCreatePermissionSet(permissionSetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateProgram(programRequest: ProgramRequest, options?: any): AxiosPromise<Program> {
            return localVarFp
                .internalCreateProgram(programRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRole(internalRoleRequest: InternalRoleRequest, options?: any): AxiosPromise<InternalRole> {
            return localVarFp
                .internalCreateRole(internalRoleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyApplication(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyPermissionSet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyPermissionSet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyProgram(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyProgram(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRole(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetApplication(id: number, options?: any): AxiosPromise<Application> {
            return localVarFp.internalGetApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetPermissionSet(id: number, options?: any): AxiosPromise<PermissionSet> {
            return localVarFp.internalGetPermissionSet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetProgramUser(id: number, program: number, options?: any): AxiosPromise<ProgramUser> {
            return localVarFp.internalGetProgramUser(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRole(id: number, options?: any): AxiosPromise<InternalRole> {
            return localVarFp.internalGetRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListApplication(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedApplicationList> {
            return localVarFp
                .internalListApplication(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPermissionSet(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedPermissionSetList> {
            return localVarFp
                .internalListPermissionSet(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * List the users associated with this program
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramUserList> {
            return localVarFp
                .internalListProgramUsers(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramList> {
            return localVarFp
                .internalListPrograms(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRoles(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalRoleList> {
            return localVarFp
                .internalListRoles(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {PatchedApplicationRequest} [patchedApplicationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateApplication(
            id: number,
            patchedApplicationRequest?: PatchedApplicationRequest,
            options?: any,
        ): AxiosPromise<Application> {
            return localVarFp
                .internalPartialUpdateApplication(id, patchedApplicationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PatchedPermissionSetRequest} [patchedPermissionSetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdatePermissionSet(
            id: number,
            patchedPermissionSetRequest?: PatchedPermissionSetRequest,
            options?: any,
        ): AxiosPromise<PermissionSet> {
            return localVarFp
                .internalPartialUpdatePermissionSet(id, patchedPermissionSetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {PatchedProgramRequest} [patchedProgramRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateProgram(
            id: number,
            patchedProgramRequest?: PatchedProgramRequest,
            options?: any,
        ): AxiosPromise<Program> {
            return localVarFp
                .internalPartialUpdateProgram(id, patchedProgramRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {PatchedInternalRoleRequest} [patchedInternalRoleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRole(
            id: number,
            patchedInternalRoleRequest?: PatchedInternalRoleRequest,
            options?: any,
        ): AxiosPromise<InternalRole> {
            return localVarFp
                .internalPartialUpdateRole(id, patchedInternalRoleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRemoveUserFromProgram(id: number, program: number, options?: any): AxiosPromise<void> {
            return localVarFp
                .internalRemoveUserFromProgram(id, program, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {ApplicationRequest} applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateApplication(
            id: number,
            applicationRequest: ApplicationRequest,
            options?: any,
        ): AxiosPromise<Application> {
            return localVarFp
                .internalUpdateApplication(id, applicationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePermissionSet(
            id: number,
            permissionSetRequest: PermissionSetRequest,
            options?: any,
        ): AxiosPromise<PermissionSet> {
            return localVarFp
                .internalUpdatePermissionSet(id, permissionSetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgram(id: number, programRequest: ProgramRequest, options?: any): AxiosPromise<Program> {
            return localVarFp
                .internalUpdateProgram(id, programRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .internalUpdateProgramUser(id, program, programUserRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRole(
            id: number,
            internalRoleRequest: InternalRoleRequest,
            options?: any,
        ): AxiosPromise<InternalRole> {
            return localVarFp
                .internalUpdateRole(id, internalRoleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary List the roles associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramRoles(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedRoleList> {
            return localVarFp
                .listProgramRoles(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * List the users associated with this program
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramUserList> {
            return localVarFp
                .listProgramUsers(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramList> {
            return localVarFp
                .listPrograms(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProgram(id: number, program: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeUserFromProgram(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UserRequest} [userRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(userRequest?: UserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateProfile(userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .updateProgramUser(id, program, programUserRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addUserToProgram operation in AdminApi.
 * @export
 * @interface AdminApiAddUserToProgramRequest
 */
export interface AdminApiAddUserToProgramRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiAddUserToProgram
     */
    readonly program: number;

    /**
     *
     * @type {AddUserToProgramRequest}
     * @memberof AdminApiAddUserToProgram
     */
    readonly addUserToProgramRequest: AddUserToProgramRequest;
}

/**
 * Request parameters for getProgram operation in AdminApi.
 * @export
 * @interface AdminApiGetProgramRequest
 */
export interface AdminApiGetProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiGetProgram
     */
    readonly id: number;
}

/**
 * Request parameters for getProgramProfile operation in AdminApi.
 * @export
 * @interface AdminApiGetProgramProfileRequest
 */
export interface AdminApiGetProgramProfileRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiGetProgramProfile
     */
    readonly program: number;
}

/**
 * Request parameters for getProgramRole operation in AdminApi.
 * @export
 * @interface AdminApiGetProgramRoleRequest
 */
export interface AdminApiGetProgramRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiGetProgramRole
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiGetProgramRole
     */
    readonly program: number;
}

/**
 * Request parameters for getProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiGetProgramUserRequest
 */
export interface AdminApiGetProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiGetProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiGetProgramUser
     */
    readonly program: number;
}

/**
 * Request parameters for internalAddUserToProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalAddUserToProgramRequest
 */
export interface AdminApiInternalAddUserToProgramRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiInternalAddUserToProgram
     */
    readonly program: number;

    /**
     *
     * @type {AddUserToProgramRequest}
     * @memberof AdminApiInternalAddUserToProgram
     */
    readonly addUserToProgramRequest: AddUserToProgramRequest;
}

/**
 * Request parameters for internalCreateApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreateApplicationRequest
 */
export interface AdminApiInternalCreateApplicationRequest {
    /**
     *
     * @type {ApplicationCreateRequest}
     * @memberof AdminApiInternalCreateApplication
     */
    readonly applicationCreateRequest: ApplicationCreateRequest;
}

/**
 * Request parameters for internalCreatePermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreatePermissionSetRequest
 */
export interface AdminApiInternalCreatePermissionSetRequest {
    /**
     *
     * @type {PermissionSetRequest}
     * @memberof AdminApiInternalCreatePermissionSet
     */
    readonly permissionSetRequest: PermissionSetRequest;
}

/**
 * Request parameters for internalCreateProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreateProgramRequest
 */
export interface AdminApiInternalCreateProgramRequest {
    /**
     *
     * @type {ProgramRequest}
     * @memberof AdminApiInternalCreateProgram
     */
    readonly programRequest: ProgramRequest;
}

/**
 * Request parameters for internalCreateRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreateRoleRequest
 */
export interface AdminApiInternalCreateRoleRequest {
    /**
     *
     * @type {InternalRoleRequest}
     * @memberof AdminApiInternalCreateRole
     */
    readonly internalRoleRequest: InternalRoleRequest;
}

/**
 * Request parameters for internalDestroyApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyApplicationRequest
 */
export interface AdminApiInternalDestroyApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalDestroyApplication
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyPermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyPermissionSetRequest
 */
export interface AdminApiInternalDestroyPermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalDestroyPermissionSet
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyProgramRequest
 */
export interface AdminApiInternalDestroyProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiInternalDestroyProgram
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyRoleRequest
 */
export interface AdminApiInternalDestroyRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalDestroyRole
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetApplicationRequest
 */
export interface AdminApiInternalGetApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalGetApplication
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetPermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetPermissionSetRequest
 */
export interface AdminApiInternalGetPermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalGetPermissionSet
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetProgramUserRequest
 */
export interface AdminApiInternalGetProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiInternalGetProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiInternalGetProgramUser
     */
    readonly program: number;
}

/**
 * Request parameters for internalGetRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetRoleRequest
 */
export interface AdminApiInternalGetRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalGetRole
     */
    readonly id: number;
}

/**
 * Request parameters for internalListApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalListApplicationRequest
 */
export interface AdminApiInternalListApplicationRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListApplication
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListApplication
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListApplication
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListPermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalListPermissionSetRequest
 */
export interface AdminApiInternalListPermissionSetRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListPermissionSet
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListPermissionSet
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListPermissionSet
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListProgramUsers operation in AdminApi.
 * @export
 * @interface AdminApiInternalListProgramUsersRequest
 */
export interface AdminApiInternalListProgramUsersRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListPrograms operation in AdminApi.
 * @export
 * @interface AdminApiInternalListProgramsRequest
 */
export interface AdminApiInternalListProgramsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListPrograms
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListPrograms
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListPrograms
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListRoles operation in AdminApi.
 * @export
 * @interface AdminApiInternalListRolesRequest
 */
export interface AdminApiInternalListRolesRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListRoles
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListRoles
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListRoles
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalPartialUpdateApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdateApplicationRequest
 */
export interface AdminApiInternalPartialUpdateApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdateApplication
     */
    readonly id: number;

    /**
     *
     * @type {PatchedApplicationRequest}
     * @memberof AdminApiInternalPartialUpdateApplication
     */
    readonly patchedApplicationRequest?: PatchedApplicationRequest;
}

/**
 * Request parameters for internalPartialUpdatePermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdatePermissionSetRequest
 */
export interface AdminApiInternalPartialUpdatePermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdatePermissionSet
     */
    readonly id: number;

    /**
     *
     * @type {PatchedPermissionSetRequest}
     * @memberof AdminApiInternalPartialUpdatePermissionSet
     */
    readonly patchedPermissionSetRequest?: PatchedPermissionSetRequest;
}

/**
 * Request parameters for internalPartialUpdateProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdateProgramRequest
 */
export interface AdminApiInternalPartialUpdateProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdateProgram
     */
    readonly id: number;

    /**
     *
     * @type {PatchedProgramRequest}
     * @memberof AdminApiInternalPartialUpdateProgram
     */
    readonly patchedProgramRequest?: PatchedProgramRequest;
}

/**
 * Request parameters for internalPartialUpdateRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdateRoleRequest
 */
export interface AdminApiInternalPartialUpdateRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdateRole
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalRoleRequest}
     * @memberof AdminApiInternalPartialUpdateRole
     */
    readonly patchedInternalRoleRequest?: PatchedInternalRoleRequest;
}

/**
 * Request parameters for internalRemoveUserFromProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalRemoveUserFromProgramRequest
 */
export interface AdminApiInternalRemoveUserFromProgramRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiInternalRemoveUserFromProgram
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiInternalRemoveUserFromProgram
     */
    readonly program: number;
}

/**
 * Request parameters for internalUpdateApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateApplicationRequest
 */
export interface AdminApiInternalUpdateApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalUpdateApplication
     */
    readonly id: number;

    /**
     *
     * @type {ApplicationRequest}
     * @memberof AdminApiInternalUpdateApplication
     */
    readonly applicationRequest: ApplicationRequest;
}

/**
 * Request parameters for internalUpdatePermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdatePermissionSetRequest
 */
export interface AdminApiInternalUpdatePermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalUpdatePermissionSet
     */
    readonly id: number;

    /**
     *
     * @type {PermissionSetRequest}
     * @memberof AdminApiInternalUpdatePermissionSet
     */
    readonly permissionSetRequest: PermissionSetRequest;
}

/**
 * Request parameters for internalUpdateProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateProgramRequest
 */
export interface AdminApiInternalUpdateProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiInternalUpdateProgram
     */
    readonly id: number;

    /**
     *
     * @type {ProgramRequest}
     * @memberof AdminApiInternalUpdateProgram
     */
    readonly programRequest: ProgramRequest;
}

/**
 * Request parameters for internalUpdateProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateProgramUserRequest
 */
export interface AdminApiInternalUpdateProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiInternalUpdateProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiInternalUpdateProgramUser
     */
    readonly program: number;

    /**
     *
     * @type {ProgramUserRequest}
     * @memberof AdminApiInternalUpdateProgramUser
     */
    readonly programUserRequest: ProgramUserRequest;
}

/**
 * Request parameters for internalUpdateRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateRoleRequest
 */
export interface AdminApiInternalUpdateRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalUpdateRole
     */
    readonly id: number;

    /**
     *
     * @type {InternalRoleRequest}
     * @memberof AdminApiInternalUpdateRole
     */
    readonly internalRoleRequest: InternalRoleRequest;
}

/**
 * Request parameters for listProgramRoles operation in AdminApi.
 * @export
 * @interface AdminApiListProgramRolesRequest
 */
export interface AdminApiListProgramRolesRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiListProgramRoles
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiListProgramRoles
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiListProgramRoles
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiListProgramRoles
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listProgramUsers operation in AdminApi.
 * @export
 * @interface AdminApiListProgramUsersRequest
 */
export interface AdminApiListProgramUsersRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiListProgramUsers
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiListProgramUsers
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiListProgramUsers
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiListProgramUsers
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listPrograms operation in AdminApi.
 * @export
 * @interface AdminApiListProgramsRequest
 */
export interface AdminApiListProgramsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiListPrograms
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiListPrograms
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiListPrograms
     */
    readonly ordering?: string;
}

/**
 * Request parameters for removeUserFromProgram operation in AdminApi.
 * @export
 * @interface AdminApiRemoveUserFromProgramRequest
 */
export interface AdminApiRemoveUserFromProgramRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiRemoveUserFromProgram
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiRemoveUserFromProgram
     */
    readonly program: number;
}

/**
 * Request parameters for updateProfile operation in AdminApi.
 * @export
 * @interface AdminApiUpdateProfileRequest
 */
export interface AdminApiUpdateProfileRequest {
    /**
     *
     * @type {UserRequest}
     * @memberof AdminApiUpdateProfile
     */
    readonly userRequest?: UserRequest;
}

/**
 * Request parameters for updateProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiUpdateProgramUserRequest
 */
export interface AdminApiUpdateProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiUpdateProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiUpdateProgramUser
     */
    readonly program: number;

    /**
     *
     * @type {ProgramUserRequest}
     * @memberof AdminApiUpdateProgramUser
     */
    readonly programUserRequest: ProgramUserRequest;
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
     * @summary Add a user to a program
     * @param {AdminApiAddUserToProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addUserToProgram(requestParameters: AdminApiAddUserToProgramRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .addUserToProgram(requestParameters.program, requestParameters.addUserToProgramRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProfile(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiGetProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProgram(requestParameters: AdminApiGetProgramRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProgram(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AdminApiGetProgramProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProgramProfile(requestParameters: AdminApiGetProgramProfileRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProgramProfile(requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @summary Inspect details about a program role
     * @param {AdminApiGetProgramRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProgramRole(requestParameters: AdminApiGetProgramRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProgramRole(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns relevant information about a user as well as a list of roles they currently have on the program.
     * @summary Inspect details about a program user
     * @param {AdminApiGetProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProgramUser(requestParameters: AdminApiGetProgramUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProgramUser(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
     * @summary Add a user to a program
     * @param {AdminApiInternalAddUserToProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalAddUserToProgram(
        requestParameters: AdminApiInternalAddUserToProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalAddUserToProgram(requestParameters.program, requestParameters.addUserToProgramRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalCreateApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreateApplication(
        requestParameters: AdminApiInternalCreateApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalCreateApplication(requestParameters.applicationCreateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalCreatePermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreatePermissionSet(
        requestParameters: AdminApiInternalCreatePermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalCreatePermissionSet(requestParameters.permissionSetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalCreateProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreateProgram(
        requestParameters: AdminApiInternalCreateProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalCreateProgram(requestParameters.programRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalCreateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreateRole(requestParameters: AdminApiInternalCreateRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalCreateRole(requestParameters.internalRoleRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalDestroyApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyApplication(
        requestParameters: AdminApiInternalDestroyApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalDestroyApplication(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalDestroyPermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyPermissionSet(
        requestParameters: AdminApiInternalDestroyPermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalDestroyPermissionSet(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalDestroyProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyProgram(
        requestParameters: AdminApiInternalDestroyProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalDestroyProgram(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalDestroyRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyRole(requestParameters: AdminApiInternalDestroyRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalDestroyRole(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalGetApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetApplication(
        requestParameters: AdminApiInternalGetApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalGetApplication(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalGetPermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetPermissionSet(
        requestParameters: AdminApiInternalGetPermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalGetPermissionSet(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns relevant information about a user as well as a list of roles they currently have on the program.
     * @summary Inspect details about a program user
     * @param {AdminApiInternalGetProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetProgramUser(
        requestParameters: AdminApiInternalGetProgramUserRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalGetProgramUser(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalGetRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetRole(requestParameters: AdminApiInternalGetRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalGetRole(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalListApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListApplication(
        requestParameters: AdminApiInternalListApplicationRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListApplication(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalListPermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListPermissionSet(
        requestParameters: AdminApiInternalListPermissionSetRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListPermissionSet(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the users associated with this program
     * @summary List the users associated with this program
     * @param {AdminApiInternalListProgramUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListProgramUsers(
        requestParameters: AdminApiInternalListProgramUsersRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListProgramUsers(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalListProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListPrograms(
        requestParameters: AdminApiInternalListProgramsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListPrograms(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalListRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListRoles(requestParameters: AdminApiInternalListRolesRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalListRoles(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalPartialUpdateApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdateApplication(
        requestParameters: AdminApiInternalPartialUpdateApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdateApplication(
                requestParameters.id,
                requestParameters.patchedApplicationRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalPartialUpdatePermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdatePermissionSet(
        requestParameters: AdminApiInternalPartialUpdatePermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdatePermissionSet(
                requestParameters.id,
                requestParameters.patchedPermissionSetRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalPartialUpdateProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdateProgram(
        requestParameters: AdminApiInternalPartialUpdateProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdateProgram(requestParameters.id, requestParameters.patchedProgramRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalPartialUpdateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdateRole(
        requestParameters: AdminApiInternalPartialUpdateRoleRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdateRole(requestParameters.id, requestParameters.patchedInternalRoleRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
     * @summary Remove a user from the program
     * @param {AdminApiInternalRemoveUserFromProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalRemoveUserFromProgram(
        requestParameters: AdminApiInternalRemoveUserFromProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalRemoveUserFromProgram(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalUpdateApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateApplication(
        requestParameters: AdminApiInternalUpdateApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdateApplication(requestParameters.id, requestParameters.applicationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalUpdatePermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdatePermissionSet(
        requestParameters: AdminApiInternalUpdatePermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdatePermissionSet(requestParameters.id, requestParameters.permissionSetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalUpdateProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateProgram(
        requestParameters: AdminApiInternalUpdateProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdateProgram(requestParameters.id, requestParameters.programRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
     * @summary Update a user\'s program roles
     * @param {AdminApiInternalUpdateProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateProgramUser(
        requestParameters: AdminApiInternalUpdateProgramUserRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdateProgramUser(
                requestParameters.id,
                requestParameters.program,
                requestParameters.programUserRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalUpdateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateRole(requestParameters: AdminApiInternalUpdateRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalUpdateRole(requestParameters.id, requestParameters.internalRoleRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @summary List the roles associated with this program
     * @param {AdminApiListProgramRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listProgramRoles(requestParameters: AdminApiListProgramRolesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .listProgramRoles(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the users associated with this program
     * @summary List the users associated with this program
     * @param {AdminApiListProgramUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listProgramUsers(requestParameters: AdminApiListProgramUsersRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .listProgramUsers(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiListProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listPrograms(requestParameters: AdminApiListProgramsRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .listPrograms(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
     * @summary Remove a user from the program
     * @param {AdminApiRemoveUserFromProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public removeUserFromProgram(
        requestParameters: AdminApiRemoveUserFromProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .removeUserFromProgram(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AdminApiUpdateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateProfile(requestParameters: AdminApiUpdateProfileRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .updateProfile(requestParameters.userRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
     * @summary Update a user\'s program roles
     * @param {AdminApiUpdateProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateProgramUser(requestParameters: AdminApiUpdateProgramUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .updateProgramUser(
                requestParameters.id,
                requestParameters.program,
                requestParameters.programUserRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * PipelineApi - axios parameter creator
 * @export
 */
export const PipelineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API operations related to data pipeline calibration data.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCalibration: async (
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'dataPipelineCalibrationRequest' is not null or undefined
            assertParamExists(
                'internalCreateCalibration',
                'dataPipelineCalibrationRequest',
                dataPipelineCalibrationRequest,
            );
            const localVarPath = `/private/v1/pipeline/calibrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                dataPipelineCalibrationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCapture: async (
            internalCaptureRequest: InternalCaptureRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalCaptureRequest' is not null or undefined
            assertParamExists('internalCreateCapture', 'internalCaptureRequest', internalCaptureRequest);
            const localVarPath = `/private/v1/pipeline/captures/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalCaptureRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRaster: async (
            rasterArtifactRequest: RasterArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'rasterArtifactRequest' is not null or undefined
            assertParamExists('internalCreateRaster', 'rasterArtifactRequest', rasterArtifactRequest);
            const localVarPath = `/private/v1/pipeline/rasters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                rasterArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCalibration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyCalibration', 'id', id);
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCapture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyCapture', 'id', id);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRaster: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyRaster', 'id', id);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCalibration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetCalibration', 'id', id);
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCapture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetCapture', 'id', id);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRaster: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetRaster', 'id', id);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} [bands]
         * @param {number} [bin]
         * @param {string} [calibrationTimeAfter]
         * @param {string} [calibrationTimeBefore]
         * @param {number} [calibrationType]
         * @param {number} [frameRate]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [rawS3Uri]
         * @param {number} [samples]
         * @param {number} [sensor]
         * @param {number} [validationStatus]
         * @param {string} [workingS3Uri]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCalibrations: async (
            bands?: number,
            bin?: number,
            calibrationTimeAfter?: string,
            calibrationTimeBefore?: string,
            calibrationType?: number,
            frameRate?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            rawS3Uri?: string,
            samples?: number,
            sensor?: number,
            validationStatus?: number,
            workingS3Uri?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/pipeline/calibrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (bands !== undefined) {
                localVarQueryParameter['bands'] = bands;
            }

            if (bin !== undefined) {
                localVarQueryParameter['bin'] = bin;
            }

            if (calibrationTimeAfter !== undefined) {
                localVarQueryParameter['calibration_time_after'] =
                    (calibrationTimeAfter as any) instanceof Date
                        ? (calibrationTimeAfter as any).toISOString().substr(0, 10)
                        : calibrationTimeAfter;
            }

            if (calibrationTimeBefore !== undefined) {
                localVarQueryParameter['calibration_time_before'] =
                    (calibrationTimeBefore as any) instanceof Date
                        ? (calibrationTimeBefore as any).toISOString().substr(0, 10)
                        : calibrationTimeBefore;
            }

            if (calibrationType !== undefined) {
                localVarQueryParameter['calibration_type'] = calibrationType;
            }

            if (frameRate !== undefined) {
                localVarQueryParameter['frame_rate'] = frameRate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (rawS3Uri !== undefined) {
                localVarQueryParameter['raw_s3_uri'] = rawS3Uri;
            }

            if (samples !== undefined) {
                localVarQueryParameter['samples'] = samples;
            }

            if (sensor !== undefined) {
                localVarQueryParameter['sensor'] = sensor;
            }

            if (validationStatus !== undefined) {
                localVarQueryParameter['validation_status'] = validationStatus;
            }

            if (workingS3Uri !== undefined) {
                localVarQueryParameter['working_s3_uri'] = workingS3Uri;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [taskId] Filter captures to those matching one of more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCaptureTasks: async (
            limit?: number,
            offset?: number,
            taskId?: Array<string>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/pipeline/capture_tasks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (taskId) {
                localVarQueryParameter['task_id'] = taskId.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] Filter captures by area of interest
         * @param {string} [aoiName] Filter captures by named area of interest
         * @param {string} [capturedAfter] Filter captures on acquisition time by date range
         * @param {string} [capturedBefore] Filter captures on acquisition time by date range
         * @param {number} [cloudCoverMax] Filter captures by cloud cover percentage
         * @param {number} [cloudCoverMin] Filter captures by cloud cover percentage
         * @param {Array<string>} [fileId] Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
         * @param {boolean} [latestOnly]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderId] Filter captures by the order ID.
         * @param {string} [processedAfter] Filter captures on date range of when they were processed
         * @param {string} [processedBefore] Filter captures on date range of when they were processed
         * @param {Array<number>} [sensor] Filter captures by sensor id
         * @param {Array<string>} [taskId] Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCaptures: async (
            aoi?: Geometry | GeometryCollection,
            aoiName?: string,
            capturedAfter?: string,
            capturedBefore?: string,
            cloudCoverMax?: number,
            cloudCoverMin?: number,
            fileId?: Array<string>,
            latestOnly?: boolean,
            limit?: number,
            offset?: number,
            orderId?: string,
            processedAfter?: string,
            processedBefore?: string,
            sensor?: Array<number>,
            taskId?: Array<string>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/pipeline/captures/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (aoiName !== undefined) {
                localVarQueryParameter['aoi_name'] = aoiName;
            }

            if (capturedAfter !== undefined) {
                localVarQueryParameter['captured_after'] =
                    (capturedAfter as any) instanceof Date ? (capturedAfter as any).toISOString() : capturedAfter;
            }

            if (capturedBefore !== undefined) {
                localVarQueryParameter['captured_before'] =
                    (capturedBefore as any) instanceof Date ? (capturedBefore as any).toISOString() : capturedBefore;
            }

            if (cloudCoverMax !== undefined) {
                localVarQueryParameter['cloud_cover_max'] = cloudCoverMax;
            }

            if (cloudCoverMin !== undefined) {
                localVarQueryParameter['cloud_cover_min'] = cloudCoverMin;
            }

            if (fileId) {
                localVarQueryParameter['file_id'] = fileId.join(COLLECTION_FORMATS.csv);
            }

            if (latestOnly !== undefined) {
                localVarQueryParameter['latest_only'] = latestOnly;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }

            if (processedAfter !== undefined) {
                localVarQueryParameter['processed_after'] =
                    (processedAfter as any) instanceof Date ? (processedAfter as any).toISOString() : processedAfter;
            }

            if (processedBefore !== undefined) {
                localVarQueryParameter['processed_before'] =
                    (processedBefore as any) instanceof Date ? (processedBefore as any).toISOString() : processedBefore;
            }

            if (sensor) {
                localVarQueryParameter['sensor'] = sensor;
            }

            if (taskId) {
                localVarQueryParameter['task_id'] = taskId.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {string} [artifactType]
         * @param {number} [capture]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [processingLevel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRasters: async (
            artifactType?: string,
            capture?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            processingLevel?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/pipeline/rasters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (artifactType !== undefined) {
                localVarQueryParameter['artifact_type'] = artifactType;
            }

            if (capture !== undefined) {
                localVarQueryParameter['capture'] = capture;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (processingLevel !== undefined) {
                localVarQueryParameter['processing_level'] = processingLevel;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {PatchedDataPipelineCalibrationRequest} [patchedDataPipelineCalibrationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCalibration: async (
            id: number,
            patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateCalibration', 'id', id);
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedDataPipelineCalibrationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {PatchedInternalCaptureRequest} [patchedInternalCaptureRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCapture: async (
            id: number,
            patchedInternalCaptureRequest?: PatchedInternalCaptureRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateCapture', 'id', id);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalCaptureRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {PatchedRasterArtifactRequest} [patchedRasterArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRaster: async (
            id: number,
            patchedRasterArtifactRequest?: PatchedRasterArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateRaster', 'id', id);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedRasterArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCalibration: async (
            id: number,
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateCalibration', 'id', id);
            // verify required parameter 'dataPipelineCalibrationRequest' is not null or undefined
            assertParamExists(
                'internalUpdateCalibration',
                'dataPipelineCalibrationRequest',
                dataPipelineCalibrationRequest,
            );
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                dataPipelineCalibrationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCapture: async (
            id: number,
            internalCaptureRequest: InternalCaptureRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateCapture', 'id', id);
            // verify required parameter 'internalCaptureRequest' is not null or undefined
            assertParamExists('internalUpdateCapture', 'internalCaptureRequest', internalCaptureRequest);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalCaptureRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRaster: async (
            id: number,
            rasterArtifactRequest: RasterArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateRaster', 'id', id);
            // verify required parameter 'rasterArtifactRequest' is not null or undefined
            assertParamExists('internalUpdateRaster', 'rasterArtifactRequest', rasterArtifactRequest);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                rasterArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PipelineApi - functional programming interface
 * @export
 */
export const PipelineApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PipelineApiAxiosParamCreator(configuration);
    return {
        /**
         * API operations related to data pipeline calibration data.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateCalibration(
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateCalibration(
                dataPipelineCalibrationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateCapture(
            internalCaptureRequest: InternalCaptureRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateCapture(
                internalCaptureRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateRaster(
            rasterArtifactRequest: RasterArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateRaster(
                rasterArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyCalibration(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyCalibration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyCapture(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyCapture(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyRaster(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyRaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetCalibration(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetCalibration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetCapture(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetCapture(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetRaster(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetRaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} [bands]
         * @param {number} [bin]
         * @param {string} [calibrationTimeAfter]
         * @param {string} [calibrationTimeBefore]
         * @param {number} [calibrationType]
         * @param {number} [frameRate]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [rawS3Uri]
         * @param {number} [samples]
         * @param {number} [sensor]
         * @param {number} [validationStatus]
         * @param {string} [workingS3Uri]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListCalibrations(
            bands?: number,
            bin?: number,
            calibrationTimeAfter?: string,
            calibrationTimeBefore?: string,
            calibrationType?: number,
            frameRate?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            rawS3Uri?: string,
            samples?: number,
            sensor?: number,
            validationStatus?: number,
            workingS3Uri?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDataPipelineCalibrationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListCalibrations(
                bands,
                bin,
                calibrationTimeAfter,
                calibrationTimeBefore,
                calibrationType,
                frameRate,
                limit,
                offset,
                ordering,
                rawS3Uri,
                samples,
                sensor,
                validationStatus,
                workingS3Uri,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [taskId] Filter captures to those matching one of more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListCaptureTasks(
            limit?: number,
            offset?: number,
            taskId?: Array<string>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalCaptureTaskList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListCaptureTasks(
                limit,
                offset,
                taskId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] Filter captures by area of interest
         * @param {string} [aoiName] Filter captures by named area of interest
         * @param {string} [capturedAfter] Filter captures on acquisition time by date range
         * @param {string} [capturedBefore] Filter captures on acquisition time by date range
         * @param {number} [cloudCoverMax] Filter captures by cloud cover percentage
         * @param {number} [cloudCoverMin] Filter captures by cloud cover percentage
         * @param {Array<string>} [fileId] Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
         * @param {boolean} [latestOnly]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderId] Filter captures by the order ID.
         * @param {string} [processedAfter] Filter captures on date range of when they were processed
         * @param {string} [processedBefore] Filter captures on date range of when they were processed
         * @param {Array<number>} [sensor] Filter captures by sensor id
         * @param {Array<string>} [taskId] Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListCaptures(
            aoi?: Geometry | GeometryCollection,
            aoiName?: string,
            capturedAfter?: string,
            capturedBefore?: string,
            cloudCoverMax?: number,
            cloudCoverMin?: number,
            fileId?: Array<string>,
            latestOnly?: boolean,
            limit?: number,
            offset?: number,
            orderId?: string,
            processedAfter?: string,
            processedBefore?: string,
            sensor?: Array<number>,
            taskId?: Array<string>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalCaptureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListCaptures(
                aoi,
                aoiName,
                capturedAfter,
                capturedBefore,
                cloudCoverMax,
                cloudCoverMin,
                fileId,
                latestOnly,
                limit,
                offset,
                orderId,
                processedAfter,
                processedBefore,
                sensor,
                taskId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {string} [artifactType]
         * @param {number} [capture]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [processingLevel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListRasters(
            artifactType?: string,
            capture?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            processingLevel?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRasterArtifactList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListRasters(
                artifactType,
                capture,
                limit,
                offset,
                ordering,
                processingLevel,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {PatchedDataPipelineCalibrationRequest} [patchedDataPipelineCalibrationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateCalibration(
            id: number,
            patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateCalibration(
                id,
                patchedDataPipelineCalibrationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {PatchedInternalCaptureRequest} [patchedInternalCaptureRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateCapture(
            id: number,
            patchedInternalCaptureRequest?: PatchedInternalCaptureRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateCapture(
                id,
                patchedInternalCaptureRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {PatchedRasterArtifactRequest} [patchedRasterArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateRaster(
            id: number,
            patchedRasterArtifactRequest?: PatchedRasterArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateRaster(
                id,
                patchedRasterArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateCalibration(
            id: number,
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateCalibration(
                id,
                dataPipelineCalibrationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateCapture(
            id: number,
            internalCaptureRequest: InternalCaptureRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateCapture(
                id,
                internalCaptureRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateRaster(
            id: number,
            rasterArtifactRequest: RasterArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateRaster(
                id,
                rasterArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PipelineApi - factory interface
 * @export
 */
export const PipelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PipelineApiFp(configuration);
    return {
        /**
         * API operations related to data pipeline calibration data.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCalibration(
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: any,
        ): AxiosPromise<DataPipelineCalibration> {
            return localVarFp
                .internalCreateCalibration(dataPipelineCalibrationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCapture(
            internalCaptureRequest: InternalCaptureRequest,
            options?: any,
        ): AxiosPromise<InternalCapture> {
            return localVarFp
                .internalCreateCapture(internalCaptureRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRaster(
            rasterArtifactRequest: RasterArtifactRequest,
            options?: any,
        ): AxiosPromise<RasterArtifact> {
            return localVarFp
                .internalCreateRaster(rasterArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCalibration(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyCalibration(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCapture(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyCapture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRaster(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyRaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCalibration(id: number, options?: any): AxiosPromise<DataPipelineCalibration> {
            return localVarFp.internalGetCalibration(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCapture(id: number, options?: any): AxiosPromise<InternalCapture> {
            return localVarFp.internalGetCapture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRaster(id: number, options?: any): AxiosPromise<RasterArtifact> {
            return localVarFp.internalGetRaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} [bands]
         * @param {number} [bin]
         * @param {string} [calibrationTimeAfter]
         * @param {string} [calibrationTimeBefore]
         * @param {number} [calibrationType]
         * @param {number} [frameRate]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [rawS3Uri]
         * @param {number} [samples]
         * @param {number} [sensor]
         * @param {number} [validationStatus]
         * @param {string} [workingS3Uri]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCalibrations(
            bands?: number,
            bin?: number,
            calibrationTimeAfter?: string,
            calibrationTimeBefore?: string,
            calibrationType?: number,
            frameRate?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            rawS3Uri?: string,
            samples?: number,
            sensor?: number,
            validationStatus?: number,
            workingS3Uri?: string,
            options?: any,
        ): AxiosPromise<PaginatedDataPipelineCalibrationList> {
            return localVarFp
                .internalListCalibrations(
                    bands,
                    bin,
                    calibrationTimeAfter,
                    calibrationTimeBefore,
                    calibrationType,
                    frameRate,
                    limit,
                    offset,
                    ordering,
                    rawS3Uri,
                    samples,
                    sensor,
                    validationStatus,
                    workingS3Uri,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [taskId] Filter captures to those matching one of more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCaptureTasks(
            limit?: number,
            offset?: number,
            taskId?: Array<string>,
            options?: any,
        ): AxiosPromise<PaginatedInternalCaptureTaskList> {
            return localVarFp
                .internalListCaptureTasks(limit, offset, taskId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] Filter captures by area of interest
         * @param {string} [aoiName] Filter captures by named area of interest
         * @param {string} [capturedAfter] Filter captures on acquisition time by date range
         * @param {string} [capturedBefore] Filter captures on acquisition time by date range
         * @param {number} [cloudCoverMax] Filter captures by cloud cover percentage
         * @param {number} [cloudCoverMin] Filter captures by cloud cover percentage
         * @param {Array<string>} [fileId] Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
         * @param {boolean} [latestOnly]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderId] Filter captures by the order ID.
         * @param {string} [processedAfter] Filter captures on date range of when they were processed
         * @param {string} [processedBefore] Filter captures on date range of when they were processed
         * @param {Array<number>} [sensor] Filter captures by sensor id
         * @param {Array<string>} [taskId] Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCaptures(
            aoi?: Geometry | GeometryCollection,
            aoiName?: string,
            capturedAfter?: string,
            capturedBefore?: string,
            cloudCoverMax?: number,
            cloudCoverMin?: number,
            fileId?: Array<string>,
            latestOnly?: boolean,
            limit?: number,
            offset?: number,
            orderId?: string,
            processedAfter?: string,
            processedBefore?: string,
            sensor?: Array<number>,
            taskId?: Array<string>,
            options?: any,
        ): AxiosPromise<PaginatedInternalCaptureList> {
            return localVarFp
                .internalListCaptures(
                    aoi,
                    aoiName,
                    capturedAfter,
                    capturedBefore,
                    cloudCoverMax,
                    cloudCoverMin,
                    fileId,
                    latestOnly,
                    limit,
                    offset,
                    orderId,
                    processedAfter,
                    processedBefore,
                    sensor,
                    taskId,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {string} [artifactType]
         * @param {number} [capture]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [processingLevel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRasters(
            artifactType?: string,
            capture?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            processingLevel?: number,
            options?: any,
        ): AxiosPromise<PaginatedRasterArtifactList> {
            return localVarFp
                .internalListRasters(artifactType, capture, limit, offset, ordering, processingLevel, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {PatchedDataPipelineCalibrationRequest} [patchedDataPipelineCalibrationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCalibration(
            id: number,
            patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest,
            options?: any,
        ): AxiosPromise<DataPipelineCalibration> {
            return localVarFp
                .internalPartialUpdateCalibration(id, patchedDataPipelineCalibrationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {PatchedInternalCaptureRequest} [patchedInternalCaptureRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCapture(
            id: number,
            patchedInternalCaptureRequest?: PatchedInternalCaptureRequest,
            options?: any,
        ): AxiosPromise<InternalCapture> {
            return localVarFp
                .internalPartialUpdateCapture(id, patchedInternalCaptureRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {PatchedRasterArtifactRequest} [patchedRasterArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRaster(
            id: number,
            patchedRasterArtifactRequest?: PatchedRasterArtifactRequest,
            options?: any,
        ): AxiosPromise<RasterArtifact> {
            return localVarFp
                .internalPartialUpdateRaster(id, patchedRasterArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCalibration(
            id: number,
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: any,
        ): AxiosPromise<DataPipelineCalibration> {
            return localVarFp
                .internalUpdateCalibration(id, dataPipelineCalibrationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCapture(
            id: number,
            internalCaptureRequest: InternalCaptureRequest,
            options?: any,
        ): AxiosPromise<InternalCapture> {
            return localVarFp
                .internalUpdateCapture(id, internalCaptureRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRaster(
            id: number,
            rasterArtifactRequest: RasterArtifactRequest,
            options?: any,
        ): AxiosPromise<RasterArtifact> {
            return localVarFp
                .internalUpdateRaster(id, rasterArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalCreateCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalCreateCalibrationRequest
 */
export interface PipelineApiInternalCreateCalibrationRequest {
    /**
     *
     * @type {DataPipelineCalibrationRequest}
     * @memberof PipelineApiInternalCreateCalibration
     */
    readonly dataPipelineCalibrationRequest: DataPipelineCalibrationRequest;
}

/**
 * Request parameters for internalCreateCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalCreateCaptureRequest
 */
export interface PipelineApiInternalCreateCaptureRequest {
    /**
     *
     * @type {InternalCaptureRequest}
     * @memberof PipelineApiInternalCreateCapture
     */
    readonly internalCaptureRequest: InternalCaptureRequest;
}

/**
 * Request parameters for internalCreateRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalCreateRasterRequest
 */
export interface PipelineApiInternalCreateRasterRequest {
    /**
     *
     * @type {RasterArtifactRequest}
     * @memberof PipelineApiInternalCreateRaster
     */
    readonly rasterArtifactRequest: RasterArtifactRequest;
}

/**
 * Request parameters for internalDestroyCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalDestroyCalibrationRequest
 */
export interface PipelineApiInternalDestroyCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalDestroyCalibration
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalDestroyCaptureRequest
 */
export interface PipelineApiInternalDestroyCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalDestroyCapture
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalDestroyRasterRequest
 */
export interface PipelineApiInternalDestroyRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalDestroyRaster
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalGetCalibrationRequest
 */
export interface PipelineApiInternalGetCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalGetCalibration
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalGetCaptureRequest
 */
export interface PipelineApiInternalGetCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalGetCapture
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalGetRasterRequest
 */
export interface PipelineApiInternalGetRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalGetRaster
     */
    readonly id: number;
}

/**
 * Request parameters for internalListCalibrations operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalListCalibrationsRequest
 */
export interface PipelineApiInternalListCalibrationsRequest {
    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly bands?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly bin?: number;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly calibrationTimeAfter?: string;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly calibrationTimeBefore?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly calibrationType?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly frameRate?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly ordering?: string;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly rawS3Uri?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly samples?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly sensor?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly validationStatus?: number;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly workingS3Uri?: string;
}

/**
 * Request parameters for internalListCaptureTasks operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalListCaptureTasksRequest
 */
export interface PipelineApiInternalListCaptureTasksRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PipelineApiInternalListCaptureTasks
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof PipelineApiInternalListCaptureTasks
     */
    readonly offset?: number;

    /**
     * Filter captures to those matching one of more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
     * @type {Array<string>}
     * @memberof PipelineApiInternalListCaptureTasks
     */
    readonly taskId?: Array<string>;
}

/**
 * Request parameters for internalListCaptures operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalListCapturesRequest
 */
export interface PipelineApiInternalListCapturesRequest {
    /**
     * Filter captures by area of interest
     * @type {Geometry | GeometryCollection}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Filter captures by named area of interest
     * @type {string}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly aoiName?: string;

    /**
     * Filter captures on acquisition time by date range
     * @type {string}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly capturedAfter?: string;

    /**
     * Filter captures on acquisition time by date range
     * @type {string}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly capturedBefore?: string;

    /**
     * Filter captures by cloud cover percentage
     * @type {number}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly cloudCoverMax?: number;

    /**
     * Filter captures by cloud cover percentage
     * @type {number}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly cloudCoverMin?: number;

    /**
     * Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
     * @type {Array<string>}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly fileId?: Array<string>;

    /**
     *
     * @type {boolean}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly latestOnly?: boolean;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly offset?: number;

    /**
     * Filter captures by the order ID.
     * @type {string}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly orderId?: string;

    /**
     * Filter captures on date range of when they were processed
     * @type {string}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly processedAfter?: string;

    /**
     * Filter captures on date range of when they were processed
     * @type {string}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly processedBefore?: string;

    /**
     * Filter captures by sensor id
     * @type {Array<number>}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly sensor?: Array<number>;

    /**
     * Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
     * @type {Array<string>}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly taskId?: Array<string>;
}

/**
 * Request parameters for internalListRasters operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalListRastersRequest
 */
export interface PipelineApiInternalListRastersRequest {
    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListRasters
     */
    readonly artifactType?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly capture?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof PipelineApiInternalListRasters
     */
    readonly ordering?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly processingLevel?: number;
}

/**
 * Request parameters for internalPartialUpdateCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalPartialUpdateCalibrationRequest
 */
export interface PipelineApiInternalPartialUpdateCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalPartialUpdateCalibration
     */
    readonly id: number;

    /**
     *
     * @type {PatchedDataPipelineCalibrationRequest}
     * @memberof PipelineApiInternalPartialUpdateCalibration
     */
    readonly patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest;
}

/**
 * Request parameters for internalPartialUpdateCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalPartialUpdateCaptureRequest
 */
export interface PipelineApiInternalPartialUpdateCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalPartialUpdateCapture
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalCaptureRequest}
     * @memberof PipelineApiInternalPartialUpdateCapture
     */
    readonly patchedInternalCaptureRequest?: PatchedInternalCaptureRequest;
}

/**
 * Request parameters for internalPartialUpdateRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalPartialUpdateRasterRequest
 */
export interface PipelineApiInternalPartialUpdateRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalPartialUpdateRaster
     */
    readonly id: number;

    /**
     *
     * @type {PatchedRasterArtifactRequest}
     * @memberof PipelineApiInternalPartialUpdateRaster
     */
    readonly patchedRasterArtifactRequest?: PatchedRasterArtifactRequest;
}

/**
 * Request parameters for internalUpdateCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalUpdateCalibrationRequest
 */
export interface PipelineApiInternalUpdateCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalUpdateCalibration
     */
    readonly id: number;

    /**
     *
     * @type {DataPipelineCalibrationRequest}
     * @memberof PipelineApiInternalUpdateCalibration
     */
    readonly dataPipelineCalibrationRequest: DataPipelineCalibrationRequest;
}

/**
 * Request parameters for internalUpdateCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalUpdateCaptureRequest
 */
export interface PipelineApiInternalUpdateCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalUpdateCapture
     */
    readonly id: number;

    /**
     *
     * @type {InternalCaptureRequest}
     * @memberof PipelineApiInternalUpdateCapture
     */
    readonly internalCaptureRequest: InternalCaptureRequest;
}

/**
 * Request parameters for internalUpdateRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalUpdateRasterRequest
 */
export interface PipelineApiInternalUpdateRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalUpdateRaster
     */
    readonly id: number;

    /**
     *
     * @type {RasterArtifactRequest}
     * @memberof PipelineApiInternalUpdateRaster
     */
    readonly rasterArtifactRequest: RasterArtifactRequest;
}

/**
 * PipelineApi - object-oriented interface
 * @export
 * @class PipelineApi
 * @extends {BaseAPI}
 */
export class PipelineApi extends BaseAPI {
    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalCreateCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalCreateCalibration(
        requestParameters: PipelineApiInternalCreateCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalCreateCalibration(requestParameters.dataPipelineCalibrationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalCreateCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalCreateCapture(
        requestParameters: PipelineApiInternalCreateCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalCreateCapture(requestParameters.internalCaptureRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalCreateRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalCreateRaster(
        requestParameters: PipelineApiInternalCreateRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalCreateRaster(requestParameters.rasterArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalDestroyCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalDestroyCalibration(
        requestParameters: PipelineApiInternalDestroyCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalDestroyCalibration(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalDestroyCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalDestroyCapture(
        requestParameters: PipelineApiInternalDestroyCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalDestroyCapture(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalDestroyRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalDestroyRaster(
        requestParameters: PipelineApiInternalDestroyRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalDestroyRaster(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalGetCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalGetCalibration(
        requestParameters: PipelineApiInternalGetCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalGetCalibration(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalGetCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalGetCapture(requestParameters: PipelineApiInternalGetCaptureRequest, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration)
            .internalGetCapture(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalGetRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalGetRaster(requestParameters: PipelineApiInternalGetRasterRequest, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration)
            .internalGetRaster(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalListCalibrationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalListCalibrations(
        requestParameters: PipelineApiInternalListCalibrationsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalListCalibrations(
                requestParameters.bands,
                requestParameters.bin,
                requestParameters.calibrationTimeAfter,
                requestParameters.calibrationTimeBefore,
                requestParameters.calibrationType,
                requestParameters.frameRate,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.rawS3Uri,
                requestParameters.samples,
                requestParameters.sensor,
                requestParameters.validationStatus,
                requestParameters.workingS3Uri,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalListCaptureTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalListCaptureTasks(
        requestParameters: PipelineApiInternalListCaptureTasksRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalListCaptureTasks(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.taskId,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalListCapturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalListCaptures(
        requestParameters: PipelineApiInternalListCapturesRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalListCaptures(
                requestParameters.aoi,
                requestParameters.aoiName,
                requestParameters.capturedAfter,
                requestParameters.capturedBefore,
                requestParameters.cloudCoverMax,
                requestParameters.cloudCoverMin,
                requestParameters.fileId,
                requestParameters.latestOnly,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.orderId,
                requestParameters.processedAfter,
                requestParameters.processedBefore,
                requestParameters.sensor,
                requestParameters.taskId,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalListRastersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalListRasters(
        requestParameters: PipelineApiInternalListRastersRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalListRasters(
                requestParameters.artifactType,
                requestParameters.capture,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.processingLevel,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalPartialUpdateCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalPartialUpdateCalibration(
        requestParameters: PipelineApiInternalPartialUpdateCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalPartialUpdateCalibration(
                requestParameters.id,
                requestParameters.patchedDataPipelineCalibrationRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalPartialUpdateCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalPartialUpdateCapture(
        requestParameters: PipelineApiInternalPartialUpdateCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalPartialUpdateCapture(
                requestParameters.id,
                requestParameters.patchedInternalCaptureRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalPartialUpdateRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalPartialUpdateRaster(
        requestParameters: PipelineApiInternalPartialUpdateRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalPartialUpdateRaster(requestParameters.id, requestParameters.patchedRasterArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalUpdateCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalUpdateCalibration(
        requestParameters: PipelineApiInternalUpdateCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalUpdateCalibration(requestParameters.id, requestParameters.dataPipelineCalibrationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalUpdateCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalUpdateCapture(
        requestParameters: PipelineApiInternalUpdateCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalUpdateCapture(requestParameters.id, requestParameters.internalCaptureRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalUpdateRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalUpdateRaster(
        requestParameters: PipelineApiInternalUpdateRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalUpdateRaster(requestParameters.id, requestParameters.rasterArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * SigmaApi - axios parameter creator
 * @export
 */
export const SigmaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} program
         * @param {OrderRequest} orderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (
            program: number,
            orderRequest: OrderRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('createOrder', 'program', program);
            // verify required parameter 'orderRequest' is not null or undefined
            assertParamExists('createOrder', 'orderRequest', orderRequest);
            const localVarPath = `/public/v1/sigma/programs/{program}/orders/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (
            program: number,
            reportRequest: ReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('createReport', 'program', program);
            // verify required parameter 'reportRequest' is not null or undefined
            assertParamExists('createReport', 'reportRequest', reportRequest);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSearchArea: async (
            searchAreaRequest: SearchAreaRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'searchAreaRequest' is not null or undefined
            assertParamExists('createSearchArea', 'searchAreaRequest', searchAreaRequest);
            const localVarPath = `/public/v1/sigma/search_areas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                searchAreaRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyReport: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('destroyReport', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySearchArea: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroySearchArea', 'id', id);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAsset', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getAsset', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapture: async (id: string, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCapture', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getCapture', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/captures/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaptureArtifact: async (
            id: string,
            program: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCaptureArtifact', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getCaptureArtifact', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/capture_artifacts/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a particular detection or finding
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetection: async (id: string, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetection', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getDetection', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/detections/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (id: string, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrder', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getOrder', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/orders/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get status for order matching order & program id
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatus: async (id: string, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderStatus', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getOrderStatus', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/orders/{id}/status/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all statues for this order
         * @param {string} id
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatusHistory: async (
            id: string,
            program: number,
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderStatusHistory', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getOrderStatusHistory', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/orders/{id}/status_history/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (requestedBy !== undefined) {
                localVarQueryParameter['requestedBy'] = requestedBy;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getReport', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchArea: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSearchArea', 'id', id);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensor: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('getSensor', 'oskId', oskId);
            const localVarPath = `/public/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method will return the most recently saved StateVector for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateVector: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('getStateVector', 'oskId', oskId);
            const localVarPath = `/public/v1/sigma/sensors/{osk_id}/state_vector/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method will return the most recently saved TLE for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTle: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('getTle', 'oskId', oskId);
            const localVarPath = `/public/v1/sigma/sensors/{osk_id}/tle/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAoi: async (
            programAOIRequest: ProgramAOIRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'programAOIRequest' is not null or undefined
            assertParamExists('internalCreateAoi', 'programAOIRequest', programAOIRequest);
            const localVarPath = `/private/v1/sigma/aois/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programAOIRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalAssetRequest} internalAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAsset: async (
            internalAssetRequest: InternalAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalAssetRequest' is not null or undefined
            assertParamExists('internalCreateAsset', 'internalAssetRequest', internalAssetRequest);
            const localVarPath = `/private/v1/sigma/assets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetection: async (
            internalDetectionRequest: InternalDetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalDetectionRequest' is not null or undefined
            assertParamExists('internalCreateDetection', 'internalDetectionRequest', internalDetectionRequest);
            const localVarPath = `/private/v1/sigma/detections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalDetectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetectionArtifact: async (
            detectionArtifactRequest: DetectionArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'detectionArtifactRequest' is not null or undefined
            assertParamExists('internalCreateDetectionArtifact', 'detectionArtifactRequest', detectionArtifactRequest);
            const localVarPath = `/private/v1/sigma/detection_artifacts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                detectionArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {InternalGroundStationPassRequest} internalGroundStationPassRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateGroundStationPasse: async (
            internalGroundStationPassRequest: InternalGroundStationPassRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalGroundStationPassRequest' is not null or undefined
            assertParamExists(
                'internalCreateGroundStationPasse',
                'internalGroundStationPassRequest',
                internalGroundStationPassRequest,
            );
            const localVarPath = `/private/v1/sigma/ground_station_passes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalGroundStationPassRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {InternalOpportunityRequest} internalOpportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrbit: async (
            internalOpportunityRequest: InternalOpportunityRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalOpportunityRequest' is not null or undefined
            assertParamExists('internalCreateOrbit', 'internalOpportunityRequest', internalOpportunityRequest);
            const localVarPath = `/private/v1/sigma/orbit/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalOpportunityRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalOrderRequest} internalOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrder: async (
            internalOrderRequest: InternalOrderRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalOrderRequest' is not null or undefined
            assertParamExists('internalCreateOrder', 'internalOrderRequest', internalOrderRequest);
            const localVarPath = `/private/v1/sigma/orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalOrderRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {InternalOrderStatusRequest} internalOrderStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrderStatus: async (
            internalOrderStatusRequest: InternalOrderStatusRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalOrderStatusRequest' is not null or undefined
            assertParamExists('internalCreateOrderStatus', 'internalOrderStatusRequest', internalOrderStatusRequest);
            const localVarPath = `/private/v1/sigma/order_status/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalOrderStatusRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateReport: async (
            internalReportRequest: InternalReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalReportRequest' is not null or undefined
            assertParamExists('internalCreateReport', 'internalReportRequest', internalReportRequest);
            const localVarPath = `/private/v1/sigma/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {InternalSensorRequest} internalSensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateSensor: async (
            internalSensorRequest: InternalSensorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalSensorRequest' is not null or undefined
            assertParamExists('internalCreateSensor', 'internalSensorRequest', internalSensorRequest);
            const localVarPath = `/private/v1/sigma/sensors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalSensorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {InternalCreateSensorTaskRequest} internalCreateSensorTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateSensorTask: async (
            internalCreateSensorTaskRequest: InternalCreateSensorTaskRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalCreateSensorTaskRequest' is not null or undefined
            assertParamExists(
                'internalCreateSensorTask',
                'internalCreateSensorTaskRequest',
                internalCreateSensorTaskRequest,
            );
            const localVarPath = `/private/v1/sigma/sensor_tasks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalCreateSensorTaskRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateStateVector: async (
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalCreateStateVector', 'sensorPk', sensorPk);
            // verify required parameter 'stateVectorRequest' is not null or undefined
            assertParamExists('internalCreateStateVector', 'stateVectorRequest', stateVectorRequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                stateVectorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {InternalTaskingBlockOutWindowRequest} internalTaskingBlockOutWindowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTaskingBlockOutWindow: async (
            internalTaskingBlockOutWindowRequest: InternalTaskingBlockOutWindowRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalTaskingBlockOutWindowRequest' is not null or undefined
            assertParamExists(
                'internalCreateTaskingBlockOutWindow',
                'internalTaskingBlockOutWindowRequest',
                internalTaskingBlockOutWindowRequest,
            );
            const localVarPath = `/private/v1/sigma/tasking_block_out_windows/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalTaskingBlockOutWindowRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {InternalTaskingRequestRequest} internalTaskingRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTaskingRequest: async (
            internalTaskingRequestRequest: InternalTaskingRequestRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalTaskingRequestRequest' is not null or undefined
            assertParamExists(
                'internalCreateTaskingRequest',
                'internalTaskingRequestRequest',
                internalTaskingRequestRequest,
            );
            const localVarPath = `/private/v1/sigma/tasking_requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalTaskingRequestRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {InternalTaskingScheduleRequest} [internalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTaskingSchedule: async (
            internalTaskingScheduleRequest?: InternalTaskingScheduleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/tasking_schedules/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalTaskingScheduleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTle: async (
            sensorPk: number,
            tLERequest: TLERequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalCreateTle', 'sensorPk', sensorPk);
            // verify required parameter 'tLERequest' is not null or undefined
            assertParamExists('internalCreateTle', 'tLERequest', tLERequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(tLERequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAoi: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyAoi', 'id', id);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAsset: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyAsset', 'id', id);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetection: async (pkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalDestroyDetection', 'pkId', pkId);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetectionArtifact: async (
            id: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyDetectionArtifact', 'id', id);
            const localVarPath = `/private/v1/sigma/detection_artifacts/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOrbit: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyOrbit', 'id', id);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyOrder', 'id', id);
            const localVarPath = `/private/v1/sigma/orders/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyReport', 'id', id);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroySensor: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('internalDestroySensor', 'oskId', oskId);
            const localVarPath = `/private/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyStateVector: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalDestroyStateVector', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyTle: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyTle', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalDestroyTle', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAoi: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetAoi', 'id', id);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAsset: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetAsset', 'id', id);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information on an OSK-supported data product
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetDataProduct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetDataProduct', 'id', id);
            const localVarPath = `/private/v1/sigma/data_products/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetDetection: async (pkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalGetDetection', 'pkId', pkId);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single elevation point for a given latitude and longitude.
         * @param {number} [lat] Latitude
         * @param {number} [lng] Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetElevation: async (
            lat?: number,
            lng?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/elevations/elevation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the min/max/mean/median elevation within a given area.  The bounds should a stringified geojson object representing a geometry with 4 or more points. This endpoint will then compute the summary information within a simplified rectangle based on your input area.  The results will be provided at maximum possible resolution. For convenience, the dataset which sourced the information is returned along with the summary details.  Usage example:  ``` import json  sigma_api.internal_get_elevation_summary(bounds=json.dumps(footprint)) ```
         * @param {string} [bounds] The area from which summary elevation will be calculated for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetElevationSummary: async (
            bounds?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/elevations/summary/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (bounds !== undefined) {
                localVarQueryParameter['bounds'] = bounds;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Readonly ViewSet to retrieve & list GroundStation objects.
         * @param {number} id A unique integer value identifying this ground station.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetGroundStation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetGroundStation', 'id', id);
            const localVarPath = `/private/v1/sigma/ground_stations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {number} id A unique integer value identifying this ground station pass.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetGroundStationPasse: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetGroundStationPasse', 'id', id);
            const localVarPath = `/private/v1/sigma/ground_station_passes/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrbit: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetOrbit', 'id', id);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetOrder', 'id', id);
            const localVarPath = `/private/v1/sigma/orders/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {number} id A unique integer value identifying this order status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrderStatus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetOrderStatus', 'id', id);
            const localVarPath = `/private/v1/sigma/order_status/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetReport', 'id', id);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSensor: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('internalGetSensor', 'oskId', oskId);
            const localVarPath = `/private/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSensorSchedule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetSensorSchedule', 'id', id);
            const localVarPath = `/private/v1/sigma/sensor_schedules/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSensorTask: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetSensorTask', 'id', id);
            const localVarPath = `/private/v1/sigma/sensor_tasks/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetStateVector: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalGetStateVector', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {number} id A unique integer value identifying this tasking block out window.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTaskingBlockOutWindow: async (
            id: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetTaskingBlockOutWindow', 'id', id);
            const localVarPath = `/private/v1/sigma/tasking_block_out_windows/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTaskingRequest: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetTaskingRequest', 'id', id);
            const localVarPath = `/private/v1/sigma/tasking_requests/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTaskingSchedule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetTaskingSchedule', 'id', id);
            const localVarPath = `/private/v1/sigma/tasking_schedules/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTle: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetTle', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalGetTle', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAois: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/aois/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAssets: async (
            dist?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/assets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (dist !== undefined) {
                localVarQueryParameter['dist'] = dist;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (point) {
                localVarQueryParameter['point'] = point.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List OSK-supported data products
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListDataProducts: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/data_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
         * @param {number} [areaMax] Filter detections based on their area in square meters
         * @param {number} [areaMin] Filter detections based on their area in square meters
         * @param {Array<number>} [asset] Filter detections to one or more assets
         * @param {string} [callType] Filter detections to a general detection category
         * @param {string} [detectedAfter] Filter detections to imaging time
         * @param {string} [detectedBefore] Filter detections to imaging time
         * @param {Array<'alert' | 'detection' | 'hidden'>} [detectionClass] Filter findings by detection class (hidden, detection, alert)
         * @param {Array<string>} [detectionType] Filter detections to one or more detection_types
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {'all' | 'none'} [includeFiles] include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
         * @param {number} [intensityMax] Filter detections based on their intensity
         * @param {number} [intensityMin] Filter detections based on their intensity
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {Array<0 | 1 | 2>} [reviewStatus] Filter detections by review status (e.g., no review needed, needs review, reviewed)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListDetections: async (
            aoi?: Geometry | GeometryCollection,
            areaMax?: number,
            areaMin?: number,
            asset?: Array<number>,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'detection' | 'hidden'>,
            detectionType?: Array<string>,
            dist?: number,
            includeFiles?: 'all' | 'none',
            intensityMax?: number,
            intensityMin?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            reviewStatus?: Array<0 | 1 | 2>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/detections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['area_max'] = areaMax;
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['area_min'] = areaMin;
            }

            if (asset) {
                localVarQueryParameter['asset'] = asset;
            }

            if (callType !== undefined) {
                localVarQueryParameter['call_type'] = callType;
            }

            if (detectedAfter !== undefined) {
                localVarQueryParameter['detected_after'] =
                    (detectedAfter as any) instanceof Date ? (detectedAfter as any).toISOString() : detectedAfter;
            }

            if (detectedBefore !== undefined) {
                localVarQueryParameter['detected_before'] =
                    (detectedBefore as any) instanceof Date ? (detectedBefore as any).toISOString() : detectedBefore;
            }

            if (detectionClass) {
                localVarQueryParameter['detection_class'] = detectionClass;
            }

            if (detectionType) {
                localVarQueryParameter['detection_type'] = detectionType.join(COLLECTION_FORMATS.csv);
            }

            if (dist !== undefined) {
                localVarQueryParameter['dist'] = dist;
            }

            if (includeFiles !== undefined) {
                localVarQueryParameter['include_files'] = includeFiles;
            }

            if (intensityMax !== undefined) {
                localVarQueryParameter['intensity_max'] = intensityMax;
            }

            if (intensityMin !== undefined) {
                localVarQueryParameter['intensity_min'] = intensityMin;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (point) {
                localVarQueryParameter['point'] = point.join(COLLECTION_FORMATS.csv);
            }

            if (reviewStatus) {
                localVarQueryParameter['review_status'] = reviewStatus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {boolean} [active] This filter can be used to retrieve active GroundStationPasses.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListGroundStationPasses: async (
            active?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/ground_station_passes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Readonly ViewSet to retrieve & list GroundStation objects.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListGroundStations: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/ground_stations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {Array<number>} [bbOverlapsTaskingRequests] This filter can be used to retrieve Ticks whose bounding box overlaps the passed in Tasking Requests\&#39; bounding box.
         * @param {boolean} [blockedOut] You can use this filter argument to retrieve Ticks that are blocked out and should be excluded / included from tasking depending on if you pass True / False (respectively) to this parameter.
         * @param {string} [cursor] The pagination cursor value.
         * @param {boolean} [eclipsed] Filter to areas where the nadir point on the earth\&#39;s surface is either night or day. Eclipsed &#x3D; Trueimplies you want night.
         * @param {Array<number>} [intersectsTaskingRequests] This filter can be used to retrieve Ticks that intersect the passed in Tasking Requests.
         * @param {number} [orbitNum] Grab Ticks by it\&#39;s Orbit Number. An Orbit is defined as starting and ending where the satellite transitions from not eclipsed to eclipsed. The Orbit should always start in an eclipse except for the first orbit (it can, but it\&#39;s not guaranteed). Likewise, the Orbit will always end not eclipsed.
         * @param {number} [orbitNumEnd] Grab Ticks where orbit_num is less than orbit_num_end. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
         * @param {number} [orbitNumStart] Grab Ticks where orbit_num is greater than or equal to the orbit_num_start. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [rowNum] Filter to tick records with specific row_num values. The sensor_id / row_num field combination can be thought of as a unique index. It\&#39;s not truly though due to the propagator_seed_time field.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListOrbit: async (
            aoi?: Geometry | GeometryCollection,
            bbOverlapsTaskingRequests?: Array<number>,
            blockedOut?: boolean,
            cursor?: string,
            eclipsed?: boolean,
            intersectsTaskingRequests?: Array<number>,
            orbitNum?: number,
            orbitNumEnd?: number,
            orbitNumStart?: number,
            ordering?: string,
            rowNum?: Array<number>,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/orbit/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (bbOverlapsTaskingRequests) {
                localVarQueryParameter['bb_overlaps_tasking_requests'] = bbOverlapsTaskingRequests.join(
                    COLLECTION_FORMATS.csv,
                );
            }

            if (blockedOut !== undefined) {
                localVarQueryParameter['blocked_out'] = blockedOut;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (eclipsed !== undefined) {
                localVarQueryParameter['eclipsed'] = eclipsed;
            }

            if (intersectsTaskingRequests) {
                localVarQueryParameter['intersects_tasking_requests'] = intersectsTaskingRequests.join(
                    COLLECTION_FORMATS.csv,
                );
            }

            if (orbitNum !== undefined) {
                localVarQueryParameter['orbit_num'] = orbitNum;
            }

            if (orbitNumEnd !== undefined) {
                localVarQueryParameter['orbit_num_end'] = orbitNumEnd;
            }

            if (orbitNumStart !== undefined) {
                localVarQueryParameter['orbit_num_start'] = orbitNumStart;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (rowNum) {
                localVarQueryParameter['row_num'] = rowNum.join(COLLECTION_FORMATS.csv);
            }

            if (searchTimeEnd !== undefined) {
                localVarQueryParameter['search_time_end'] =
                    (searchTimeEnd as any) instanceof Date ? (searchTimeEnd as any).toISOString() : searchTimeEnd;
            }

            if (searchTimeStart !== undefined) {
                localVarQueryParameter['search_time_start'] =
                    (searchTimeStart as any) instanceof Date ? (searchTimeStart as any).toISOString() : searchTimeStart;
            }

            if (sensor !== undefined) {
                localVarQueryParameter['sensor'] = sensor;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [order]
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListOrderStatuses: async (
            limit?: number,
            offset?: number,
            order?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/order_status/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListOrders: async (
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (requestedBy !== undefined) {
                localVarQueryParameter['requestedBy'] = requestedBy;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListReports: async (
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (externalId !== undefined) {
                localVarQueryParameter['external_id'] = externalId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (searchTimeEnd !== undefined) {
                localVarQueryParameter['search_time_end'] =
                    (searchTimeEnd as any) instanceof Date ? (searchTimeEnd as any).toISOString() : searchTimeEnd;
            }

            if (searchTimeStart !== undefined) {
                localVarQueryParameter['search_time_start'] =
                    (searchTimeStart as any) instanceof Date ? (searchTimeStart as any).toISOString() : searchTimeStart;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListSensorSchedules: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/sensor_schedules/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensorScheduleIds] This filter will allow you to specify one or many sensor schedule ids to retrieve SensorTasks with the corresponding SensorSchedule id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListSensorTasks: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            sensorScheduleIds?: Array<number>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/sensor_tasks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (sensorScheduleIds) {
                localVarQueryParameter['sensor_schedule_ids'] = sensorScheduleIds.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [taskingEligible] This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListSensors: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            taskingEligible?: boolean,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/sensors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (taskingEligible !== undefined) {
                localVarQueryParameter['tasking_eligible'] = taskingEligible;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListStateVectors: async (
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalListStateVectors', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {boolean} [active] This filter can be used to retrieve active TaskingBlockOutWindows.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [sensor] The sensor to search for. Limits TaskingBlockOutWindows to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTaskingBlockOutWindows: async (
            active?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensor?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/tasking_block_out_windows/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (sensor !== undefined) {
                localVarQueryParameter['sensor'] = sensor;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {boolean} [active] This filter can be used to retrieve TaskingRequests that are active or inactive.
         * @param {Array<number>} [ids] This filter will let you specify 1 or many ids of the tasking requests you specifically want to retrieve.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensors]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTaskingRequests: async (
            active?: boolean,
            ids?: Array<number>,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensors?: Array<number>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/tasking_requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (sensors) {
                localVarQueryParameter['sensors'] = sensors;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTaskingSchedules: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/tasking_schedules/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTles: async (
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalListTles', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {PatchedProgramAOIRequest} [patchedProgramAOIRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAoi: async (
            id: number,
            patchedProgramAOIRequest?: PatchedProgramAOIRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateAoi', 'id', id);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedProgramAOIRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PatchedInternalAssetRequest} [patchedInternalAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAsset: async (
            id: number,
            patchedInternalAssetRequest?: PatchedInternalAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateAsset', 'id', id);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId
         * @param {PatchedInternalDetectionRequest} [patchedInternalDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetection: async (
            pkId: number,
            patchedInternalDetectionRequest?: PatchedInternalDetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalPartialUpdateDetection', 'pkId', pkId);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalDetectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {PatchedDetectionArtifactRequest} [patchedDetectionArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetectionArtifact: async (
            id: number,
            patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateDetectionArtifact', 'id', id);
            const localVarPath = `/private/v1/sigma/detection_artifacts/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedDetectionArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {PatchedInternalOpportunityRequest} [patchedInternalOpportunityRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateOrbit: async (
            id: number,
            patchedInternalOpportunityRequest?: PatchedInternalOpportunityRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateOrbit', 'id', id);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalOpportunityRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {PatchedInternalReportRequest} [patchedInternalReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateReport: async (
            id: number,
            patchedInternalReportRequest?: PatchedInternalReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateReport', 'id', id);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {PatchedInternalSensorRequest} [patchedInternalSensorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensor: async (
            oskId: number,
            patchedInternalSensorRequest?: PatchedInternalSensorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('internalPartialUpdateSensor', 'oskId', oskId);
            const localVarPath = `/private/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalSensorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {PatchedInternalSensorScheduleRequest} [patchedInternalSensorScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensorSchedule: async (
            id: number,
            patchedInternalSensorScheduleRequest?: PatchedInternalSensorScheduleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateSensorSchedule', 'id', id);
            const localVarPath = `/private/v1/sigma/sensor_schedules/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalSensorScheduleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {PatchedInternalUpdateSensorTaskRequest} [patchedInternalUpdateSensorTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensorTask: async (
            id: number,
            patchedInternalUpdateSensorTaskRequest?: PatchedInternalUpdateSensorTaskRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateSensorTask', 'id', id);
            const localVarPath = `/private/v1/sigma/sensor_tasks/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalUpdateSensorTaskRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {PatchedStateVectorRequest} [patchedStateVectorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateStateVector: async (
            id: number,
            sensorPk: number,
            patchedStateVectorRequest?: PatchedStateVectorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalPartialUpdateStateVector', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedStateVectorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {PatchedInternalTaskingRequestRequest} [patchedInternalTaskingRequestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateTaskingRequest: async (
            id: number,
            patchedInternalTaskingRequestRequest?: PatchedInternalTaskingRequestRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateTaskingRequest', 'id', id);
            const localVarPath = `/private/v1/sigma/tasking_requests/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalTaskingRequestRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {PatchedInternalTaskingScheduleRequest} [patchedInternalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateTaskingSchedule: async (
            id: number,
            patchedInternalTaskingScheduleRequest?: PatchedInternalTaskingScheduleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateTaskingSchedule', 'id', id);
            const localVarPath = `/private/v1/sigma/tasking_schedules/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalTaskingScheduleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAoi: async (
            id: number,
            programAOIRequest: ProgramAOIRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateAoi', 'id', id);
            // verify required parameter 'programAOIRequest' is not null or undefined
            assertParamExists('internalUpdateAoi', 'programAOIRequest', programAOIRequest);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programAOIRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {InternalAssetRequest} internalAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAsset: async (
            id: number,
            internalAssetRequest: InternalAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateAsset', 'id', id);
            // verify required parameter 'internalAssetRequest' is not null or undefined
            assertParamExists('internalUpdateAsset', 'internalAssetRequest', internalAssetRequest);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetection: async (
            pkId: number,
            internalDetectionRequest: InternalDetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalUpdateDetection', 'pkId', pkId);
            // verify required parameter 'internalDetectionRequest' is not null or undefined
            assertParamExists('internalUpdateDetection', 'internalDetectionRequest', internalDetectionRequest);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalDetectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetectionArtifact: async (
            id: number,
            detectionArtifactRequest: DetectionArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateDetectionArtifact', 'id', id);
            // verify required parameter 'detectionArtifactRequest' is not null or undefined
            assertParamExists('internalUpdateDetectionArtifact', 'detectionArtifactRequest', detectionArtifactRequest);
            const localVarPath = `/private/v1/sigma/detection_artifacts/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                detectionArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {InternalOpportunityRequest} internalOpportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateOrbit: async (
            id: number,
            internalOpportunityRequest: InternalOpportunityRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateOrbit', 'id', id);
            // verify required parameter 'internalOpportunityRequest' is not null or undefined
            assertParamExists('internalUpdateOrbit', 'internalOpportunityRequest', internalOpportunityRequest);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalOpportunityRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateReport: async (
            id: number,
            internalReportRequest: InternalReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateReport', 'id', id);
            // verify required parameter 'internalReportRequest' is not null or undefined
            assertParamExists('internalUpdateReport', 'internalReportRequest', internalReportRequest);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {InternalSensorRequest} internalSensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensor: async (
            oskId: number,
            internalSensorRequest: InternalSensorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('internalUpdateSensor', 'oskId', oskId);
            // verify required parameter 'internalSensorRequest' is not null or undefined
            assertParamExists('internalUpdateSensor', 'internalSensorRequest', internalSensorRequest);
            const localVarPath = `/private/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalSensorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {InternalSensorScheduleRequest} [internalSensorScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensorSchedule: async (
            id: number,
            internalSensorScheduleRequest?: InternalSensorScheduleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateSensorSchedule', 'id', id);
            const localVarPath = `/private/v1/sigma/sensor_schedules/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalSensorScheduleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {InternalUpdateSensorTaskRequest} [internalUpdateSensorTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensorTask: async (
            id: number,
            internalUpdateSensorTaskRequest?: InternalUpdateSensorTaskRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateSensorTask', 'id', id);
            const localVarPath = `/private/v1/sigma/sensor_tasks/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalUpdateSensorTaskRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateStateVector: async (
            id: number,
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalUpdateStateVector', 'sensorPk', sensorPk);
            // verify required parameter 'stateVectorRequest' is not null or undefined
            assertParamExists('internalUpdateStateVector', 'stateVectorRequest', stateVectorRequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                stateVectorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {InternalTaskingRequestUpdateRequest} [internalTaskingRequestUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTaskingRequest: async (
            id: number,
            internalTaskingRequestUpdateRequest?: InternalTaskingRequestUpdateRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateTaskingRequest', 'id', id);
            const localVarPath = `/private/v1/sigma/tasking_requests/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalTaskingRequestUpdateRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {InternalTaskingScheduleRequest} [internalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTaskingSchedule: async (
            id: number,
            internalTaskingScheduleRequest?: InternalTaskingScheduleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateTaskingSchedule', 'id', id);
            const localVarPath = `/private/v1/sigma/tasking_schedules/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalTaskingScheduleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTle: async (
            id: number,
            sensorPk: number,
            tLERequest: TLERequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateTle', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalUpdateTle', 'sensorPk', sensorPk);
            // verify required parameter 'tLERequest' is not null or undefined
            assertParamExists('internalUpdateTle', 'tLERequest', tLERequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(tLERequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listAssets', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Array<string>} [fileId] Filter captures by the capture file_ids provided
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCaptureArtifacts: async (
            program: number,
            fileId?: Array<string>,
            limit?: number,
            offset?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listCaptureArtifacts', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/capture_artifacts/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (fileId) {
                localVarQueryParameter['file_id'] = fileId.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] Filter captures by area of interest
         * @param {string} [aoiName] Filter captures by named area of interest
         * @param {string} [capturedAfter] Filter captures on acquisition time by date range
         * @param {string} [capturedBefore] Filter captures on acquisition time by date range
         * @param {number} [cloudCoverMax] Filter captures by cloud cover percentage
         * @param {number} [cloudCoverMin] Filter captures by cloud cover percentage
         * @param {Array<string>} [fileId] Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderId] Filter captures by the order ID.
         * @param {string} [processedAfter] Filter captures on date range of when they were processed
         * @param {string} [processedBefore] Filter captures on date range of when they were processed
         * @param {Array<number>} [sensor] Filter captures by sensor id
         * @param {Array<string>} [taskId] Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCaptures: async (
            program: number,
            aoi?: Geometry | GeometryCollection,
            aoiName?: string,
            capturedAfter?: string,
            capturedBefore?: string,
            cloudCoverMax?: number,
            cloudCoverMin?: number,
            fileId?: Array<string>,
            limit?: number,
            offset?: number,
            orderId?: string,
            processedAfter?: string,
            processedBefore?: string,
            sensor?: Array<number>,
            taskId?: Array<string>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listCaptures', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/captures/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (aoiName !== undefined) {
                localVarQueryParameter['aoi_name'] = aoiName;
            }

            if (capturedAfter !== undefined) {
                localVarQueryParameter['captured_after'] =
                    (capturedAfter as any) instanceof Date ? (capturedAfter as any).toISOString() : capturedAfter;
            }

            if (capturedBefore !== undefined) {
                localVarQueryParameter['captured_before'] =
                    (capturedBefore as any) instanceof Date ? (capturedBefore as any).toISOString() : capturedBefore;
            }

            if (cloudCoverMax !== undefined) {
                localVarQueryParameter['cloud_cover_max'] = cloudCoverMax;
            }

            if (cloudCoverMin !== undefined) {
                localVarQueryParameter['cloud_cover_min'] = cloudCoverMin;
            }

            if (fileId) {
                localVarQueryParameter['file_id'] = fileId.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }

            if (processedAfter !== undefined) {
                localVarQueryParameter['processed_after'] =
                    (processedAfter as any) instanceof Date ? (processedAfter as any).toISOString() : processedAfter;
            }

            if (processedBefore !== undefined) {
                localVarQueryParameter['processed_before'] =
                    (processedBefore as any) instanceof Date ? (processedBefore as any).toISOString() : processedBefore;
            }

            if (sensor) {
                localVarQueryParameter['sensor'] = sensor;
            }

            if (taskId) {
                localVarQueryParameter['task_id'] = taskId.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List detections
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
         * @param {number} [areaMax] Filter detections based on their area in square meters
         * @param {number} [areaMin] Filter detections based on their area in square meters
         * @param {Array<number>} [asset] Filter detections to one or more assets
         * @param {string} [callType] Filter detections to a general detection category
         * @param {string} [detectedAfter] Filter detections to imaging time
         * @param {string} [detectedBefore] Filter detections to imaging time
         * @param {Array<'alert' | 'detection' | 'hidden'>} [detectionClass] Filter findings by detection class (detection, alert)
         * @param {Array<string>} [detectionType] Filter detections to one or more detection_types
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>} [feedbackStatus] Filter findings by feedback status
         * @param {'all' | 'none'} [includeFiles] include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
         * @param {number} [intensityMax] Filter detections based on their intensity
         * @param {number} [intensityMin] Filter detections based on their intensity
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetections: async (
            program: number,
            aoi?: Geometry | GeometryCollection,
            areaMax?: number,
            areaMin?: number,
            asset?: Array<number>,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'detection' | 'hidden'>,
            detectionType?: Array<string>,
            dist?: number,
            feedbackStatus?: Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>,
            includeFiles?: 'all' | 'none',
            intensityMax?: number,
            intensityMin?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listDetections', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/detections/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (areaMax !== undefined) {
                localVarQueryParameter['area_max'] = areaMax;
            }

            if (areaMin !== undefined) {
                localVarQueryParameter['area_min'] = areaMin;
            }

            if (asset) {
                localVarQueryParameter['asset'] = asset;
            }

            if (callType !== undefined) {
                localVarQueryParameter['call_type'] = callType;
            }

            if (detectedAfter !== undefined) {
                localVarQueryParameter['detected_after'] =
                    (detectedAfter as any) instanceof Date ? (detectedAfter as any).toISOString() : detectedAfter;
            }

            if (detectedBefore !== undefined) {
                localVarQueryParameter['detected_before'] =
                    (detectedBefore as any) instanceof Date ? (detectedBefore as any).toISOString() : detectedBefore;
            }

            if (detectionClass) {
                localVarQueryParameter['detection_class'] = detectionClass;
            }

            if (detectionType) {
                localVarQueryParameter['detection_type'] = detectionType.join(COLLECTION_FORMATS.csv);
            }

            if (dist !== undefined) {
                localVarQueryParameter['dist'] = dist;
            }

            if (feedbackStatus) {
                localVarQueryParameter['feedback_status'] = feedbackStatus;
            }

            if (includeFiles !== undefined) {
                localVarQueryParameter['include_files'] = includeFiles;
            }

            if (intensityMax !== undefined) {
                localVarQueryParameter['intensity_max'] = intensityMax;
            }

            if (intensityMin !== undefined) {
                localVarQueryParameter['intensity_min'] = intensityMin;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (point) {
                localVarQueryParameter['point'] = point.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders: async (
            program: number,
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listOrders', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/orders/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (requestedBy !== undefined) {
                localVarQueryParameter['requestedBy'] = requestedBy;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (
            program: number,
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listReports', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (externalId !== undefined) {
                localVarQueryParameter['external_id'] = externalId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (searchTimeEnd !== undefined) {
                localVarQueryParameter['search_time_end'] =
                    (searchTimeEnd as any) instanceof Date ? (searchTimeEnd as any).toISOString() : searchTimeEnd;
            }

            if (searchTimeStart !== undefined) {
                localVarQueryParameter['search_time_start'] =
                    (searchTimeStart as any) instanceof Date ? (searchTimeStart as any).toISOString() : searchTimeStart;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAreas: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/sigma/search_areas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [taskingEligible] This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSensors: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            taskingEligible?: boolean,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/sigma/sensors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (taskingEligible !== undefined) {
                localVarQueryParameter['tasking_eligible'] = taskingEligible;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PatchedAssetRequest} [patchedAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAsset: async (
            id: number,
            program: number,
            patchedAssetRequest?: PatchedAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateAsset', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('partialUpdateAsset', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Public access to detections (a.k.a. findings)
         * @param {string} id
         * @param {number} program
         * @param {PatchedDetectionRequest} [patchedDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDetection: async (
            id: string,
            program: number,
            patchedDetectionRequest?: PatchedDetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateDetection', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('partialUpdateDetection', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/detections/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedDetectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {PatchedReportRequest} [patchedReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateReport: async (
            id: number,
            program: number,
            patchedReportRequest?: PatchedReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('partialUpdateReport', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {PatchedSearchAreaRequest} [patchedSearchAreaRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSearchArea: async (
            id: number,
            patchedSearchAreaRequest?: PatchedSearchAreaRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateSearchArea', 'id', id);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedSearchAreaRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information on an OSK-supported data product
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetDataProduct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicGetDataProduct', 'id', id);
            const localVarPath = `/public/v1/sigma/data_products/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List OSK-supported data products
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListDataProducts: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/sigma/data_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 1-second piece of the orbit.
         * @summary Search orbital paths for opportunities
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {boolean} [eclipsed] Limit to satellite positions over the night side (true) or day side (false) of Earth
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrbits: async (
            aoi?: Geometry | GeometryCollection,
            eclipsed?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/sigma/orbit/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (eclipsed !== undefined) {
                localVarQueryParameter['eclipsed'] = eclipsed;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (searchTimeEnd !== undefined) {
                localVarQueryParameter['search_time_end'] =
                    (searchTimeEnd as any) instanceof Date ? (searchTimeEnd as any).toISOString() : searchTimeEnd;
            }

            if (searchTimeStart !== undefined) {
                localVarQueryParameter['search_time_start'] =
                    (searchTimeStart as any) instanceof Date ? (searchTimeStart as any).toISOString() : searchTimeStart;
            }

            if (sensor !== undefined) {
                localVarQueryParameter['sensor'] = sensor;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {AssetRequest} [assetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset: async (
            id: number,
            program: number,
            assetRequest?: AssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAsset', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('updateAsset', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(assetRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Public access to detections (a.k.a. findings)
         * @param {string} id
         * @param {number} program
         * @param {DetectionRequest} [detectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetection: async (
            id: string,
            program: number,
            detectionRequest?: DetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDetection', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('updateDetection', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/detections/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                detectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (
            id: number,
            program: number,
            reportRequest: ReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('updateReport', 'program', program);
            // verify required parameter 'reportRequest' is not null or undefined
            assertParamExists('updateReport', 'reportRequest', reportRequest);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearchArea: async (
            id: number,
            searchAreaRequest: SearchAreaRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSearchArea', 'id', id);
            // verify required parameter 'searchAreaRequest' is not null or undefined
            assertParamExists('updateSearchArea', 'searchAreaRequest', searchAreaRequest);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                searchAreaRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SigmaApi - functional programming interface
 * @export
 */
export const SigmaApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SigmaApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} program
         * @param {OrderRequest} orderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(
            program: number,
            orderRequest: OrderRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(program, orderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(
            program: number,
            reportRequest: ReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(program, reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSearchArea(
            searchAreaRequest: SearchAreaRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSearchArea(searchAreaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyReport(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyReport(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroySearchArea(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroySearchArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapture(
            id: string,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Capture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapture(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaptureArtifact(
            id: string,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptureArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaptureArtifact(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a particular detection or finding
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetection(
            id: string,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Detection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetection(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(
            id: string,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get status for order matching order & program id
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderStatus(
            id: string,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderStatus(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all statues for this order
         * @param {string} id
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderStatusHistory(
            id: string,
            program: number,
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedOrderStatusList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderStatusHistory(
                id,
                program,
                limit,
                offset,
                orderType,
                ordering,
                requestedBy,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchArea(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensor(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensor(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method will return the most recently saved StateVector for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStateVector(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStateVector(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method will return the most recently saved TLE for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTle(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTle(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateAoi(
            programAOIRequest: ProgramAOIRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateAoi(programAOIRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalAssetRequest} internalAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateAsset(
            internalAssetRequest: InternalAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateAsset(
                internalAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateDetection(
            internalDetectionRequest: InternalDetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateDetection(
                internalDetectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateDetectionArtifact(
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateDetectionArtifact(
                detectionArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {InternalGroundStationPassRequest} internalGroundStationPassRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateGroundStationPasse(
            internalGroundStationPassRequest: InternalGroundStationPassRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalGroundStationPass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateGroundStationPasse(
                internalGroundStationPassRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {InternalOpportunityRequest} internalOpportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateOrbit(
            internalOpportunityRequest: InternalOpportunityRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateOrbit(
                internalOpportunityRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalOrderRequest} internalOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateOrder(
            internalOrderRequest: InternalOrderRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateOrder(
                internalOrderRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {InternalOrderStatusRequest} internalOrderStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateOrderStatus(
            internalOrderStatusRequest: InternalOrderStatusRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOrderStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateOrderStatus(
                internalOrderStatusRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateReport(
            internalReportRequest: InternalReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateReport(
                internalReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {InternalSensorRequest} internalSensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateSensor(
            internalSensorRequest: InternalSensorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalSensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateSensor(
                internalSensorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {InternalCreateSensorTaskRequest} internalCreateSensorTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateSensorTask(
            internalCreateSensorTaskRequest: InternalCreateSensorTaskRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCreateSensorTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateSensorTask(
                internalCreateSensorTaskRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateStateVector(
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateStateVector(
                sensorPk,
                stateVectorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {InternalTaskingBlockOutWindowRequest} internalTaskingBlockOutWindowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateTaskingBlockOutWindow(
            internalTaskingBlockOutWindowRequest: InternalTaskingBlockOutWindowRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingBlockOutWindow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateTaskingBlockOutWindow(
                internalTaskingBlockOutWindowRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {InternalTaskingRequestRequest} internalTaskingRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateTaskingRequest(
            internalTaskingRequestRequest: InternalTaskingRequestRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateTaskingRequest(
                internalTaskingRequestRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {InternalTaskingScheduleRequest} [internalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateTaskingSchedule(
            internalTaskingScheduleRequest?: InternalTaskingScheduleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateTaskingSchedule(
                internalTaskingScheduleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateTle(
            sensorPk: number,
            tLERequest: TLERequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateTle(sensorPk, tLERequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyAoi(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyAoi(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyAsset(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyAsset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyDetection(
            pkId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyDetection(pkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyDetectionArtifact(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyDetectionArtifact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyOrbit(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyOrbit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyOrder(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyReport(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroySensor(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroySensor(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyStateVector(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyStateVector(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyTle(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyTle(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetAoi(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetAoi(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetAsset(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetAsset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information on an OSK-supported data product
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetDataProduct(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDataProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetDataProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetDetection(
            pkId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetDetection(pkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a single elevation point for a given latitude and longitude.
         * @param {number} [lat] Latitude
         * @param {number} [lng] Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetElevation(
            lat?: number,
            lng?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Elevation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetElevation(lat, lng, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the min/max/mean/median elevation within a given area.  The bounds should a stringified geojson object representing a geometry with 4 or more points. This endpoint will then compute the summary information within a simplified rectangle based on your input area.  The results will be provided at maximum possible resolution. For convenience, the dataset which sourced the information is returned along with the summary details.  Usage example:  ``` import json  sigma_api.internal_get_elevation_summary(bounds=json.dumps(footprint)) ```
         * @param {string} [bounds] The area from which summary elevation will be calculated for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetElevationSummary(
            bounds?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElevationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetElevationSummary(bounds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Readonly ViewSet to retrieve & list GroundStation objects.
         * @param {number} id A unique integer value identifying this ground station.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetGroundStation(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalGroundStation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetGroundStation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {number} id A unique integer value identifying this ground station pass.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetGroundStationPasse(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalGroundStationPass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetGroundStationPasse(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetOrbit(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetOrbit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetOrder(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {number} id A unique integer value identifying this order status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetOrderStatus(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOrderStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetOrderStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetReport(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetSensor(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalSensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetSensor(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetSensorSchedule(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalSensorSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetSensorSchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetSensorTask(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalUpdateSensorTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetSensorTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetStateVector(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetStateVector(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {number} id A unique integer value identifying this tasking block out window.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetTaskingBlockOutWindow(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingBlockOutWindow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetTaskingBlockOutWindow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetTaskingRequest(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetTaskingRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetTaskingSchedule(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetTaskingSchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetTle(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetTle(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListAois(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramAOIList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListAois(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListAssets(
            dist?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalAssetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListAssets(
                dist,
                limit,
                offset,
                ordering,
                point,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List OSK-supported data products
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListDataProducts(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalDataProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListDataProducts(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
         * @param {number} [areaMax] Filter detections based on their area in square meters
         * @param {number} [areaMin] Filter detections based on their area in square meters
         * @param {Array<number>} [asset] Filter detections to one or more assets
         * @param {string} [callType] Filter detections to a general detection category
         * @param {string} [detectedAfter] Filter detections to imaging time
         * @param {string} [detectedBefore] Filter detections to imaging time
         * @param {Array<'alert' | 'detection' | 'hidden'>} [detectionClass] Filter findings by detection class (hidden, detection, alert)
         * @param {Array<string>} [detectionType] Filter detections to one or more detection_types
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {'all' | 'none'} [includeFiles] include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
         * @param {number} [intensityMax] Filter detections based on their intensity
         * @param {number} [intensityMin] Filter detections based on their intensity
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {Array<0 | 1 | 2>} [reviewStatus] Filter detections by review status (e.g., no review needed, needs review, reviewed)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListDetections(
            aoi?: Geometry | GeometryCollection,
            areaMax?: number,
            areaMin?: number,
            asset?: Array<number>,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'detection' | 'hidden'>,
            detectionType?: Array<string>,
            dist?: number,
            includeFiles?: 'all' | 'none',
            intensityMax?: number,
            intensityMin?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            reviewStatus?: Array<0 | 1 | 2>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalDetectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListDetections(
                aoi,
                areaMax,
                areaMin,
                asset,
                callType,
                detectedAfter,
                detectedBefore,
                detectionClass,
                detectionType,
                dist,
                includeFiles,
                intensityMax,
                intensityMin,
                limit,
                offset,
                ordering,
                point,
                reviewStatus,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {boolean} [active] This filter can be used to retrieve active GroundStationPasses.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListGroundStationPasses(
            active?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalGroundStationPassList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListGroundStationPasses(
                active,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Readonly ViewSet to retrieve & list GroundStation objects.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListGroundStations(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalGroundStationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListGroundStations(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {Array<number>} [bbOverlapsTaskingRequests] This filter can be used to retrieve Ticks whose bounding box overlaps the passed in Tasking Requests\&#39; bounding box.
         * @param {boolean} [blockedOut] You can use this filter argument to retrieve Ticks that are blocked out and should be excluded / included from tasking depending on if you pass True / False (respectively) to this parameter.
         * @param {string} [cursor] The pagination cursor value.
         * @param {boolean} [eclipsed] Filter to areas where the nadir point on the earth\&#39;s surface is either night or day. Eclipsed &#x3D; Trueimplies you want night.
         * @param {Array<number>} [intersectsTaskingRequests] This filter can be used to retrieve Ticks that intersect the passed in Tasking Requests.
         * @param {number} [orbitNum] Grab Ticks by it\&#39;s Orbit Number. An Orbit is defined as starting and ending where the satellite transitions from not eclipsed to eclipsed. The Orbit should always start in an eclipse except for the first orbit (it can, but it\&#39;s not guaranteed). Likewise, the Orbit will always end not eclipsed.
         * @param {number} [orbitNumEnd] Grab Ticks where orbit_num is less than orbit_num_end. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
         * @param {number} [orbitNumStart] Grab Ticks where orbit_num is greater than or equal to the orbit_num_start. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [rowNum] Filter to tick records with specific row_num values. The sensor_id / row_num field combination can be thought of as a unique index. It\&#39;s not truly though due to the propagator_seed_time field.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListOrbit(
            aoi?: Geometry | GeometryCollection,
            bbOverlapsTaskingRequests?: Array<number>,
            blockedOut?: boolean,
            cursor?: string,
            eclipsed?: boolean,
            intersectsTaskingRequests?: Array<number>,
            orbitNum?: number,
            orbitNumEnd?: number,
            orbitNumStart?: number,
            ordering?: string,
            rowNum?: Array<number>,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalOpportunityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListOrbit(
                aoi,
                bbOverlapsTaskingRequests,
                blockedOut,
                cursor,
                eclipsed,
                intersectsTaskingRequests,
                orbitNum,
                orbitNumEnd,
                orbitNumStart,
                ordering,
                rowNum,
                searchTimeEnd,
                searchTimeStart,
                sensor,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [order]
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListOrderStatuses(
            limit?: number,
            offset?: number,
            order?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalOrderStatusList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListOrderStatuses(
                limit,
                offset,
                order,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListOrders(
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalOrderList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListOrders(
                limit,
                offset,
                orderType,
                ordering,
                requestedBy,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListReports(
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalReportList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListReports(
                externalId,
                limit,
                offset,
                ordering,
                searchTimeEnd,
                searchTimeStart,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListSensorSchedules(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalSensorScheduleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListSensorSchedules(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensorScheduleIds] This filter will allow you to specify one or many sensor schedule ids to retrieve SensorTasks with the corresponding SensorSchedule id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListSensorTasks(
            limit?: number,
            offset?: number,
            ordering?: string,
            sensorScheduleIds?: Array<number>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalUpdateSensorTaskList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListSensorTasks(
                limit,
                offset,
                ordering,
                sensorScheduleIds,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [taskingEligible] This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListSensors(
            limit?: number,
            offset?: number,
            ordering?: string,
            taskingEligible?: boolean,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalSensorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListSensors(
                limit,
                offset,
                ordering,
                taskingEligible,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListStateVectors(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedStateVectorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListStateVectors(
                sensorPk,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {boolean} [active] This filter can be used to retrieve active TaskingBlockOutWindows.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [sensor] The sensor to search for. Limits TaskingBlockOutWindows to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListTaskingBlockOutWindows(
            active?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensor?: number,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalTaskingBlockOutWindowList>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListTaskingBlockOutWindows(
                active,
                limit,
                offset,
                ordering,
                sensor,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {boolean} [active] This filter can be used to retrieve TaskingRequests that are active or inactive.
         * @param {Array<number>} [ids] This filter will let you specify 1 or many ids of the tasking requests you specifically want to retrieve.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensors]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListTaskingRequests(
            active?: boolean,
            ids?: Array<number>,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensors?: Array<number>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalTaskingRequestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListTaskingRequests(
                active,
                ids,
                limit,
                offset,
                ordering,
                sensors,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListTaskingSchedules(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalTaskingScheduleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListTaskingSchedules(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListTles(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTLEList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListTles(
                sensorPk,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {PatchedProgramAOIRequest} [patchedProgramAOIRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateAoi(
            id: number,
            patchedProgramAOIRequest?: PatchedProgramAOIRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateAoi(
                id,
                patchedProgramAOIRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PatchedInternalAssetRequest} [patchedInternalAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateAsset(
            id: number,
            patchedInternalAssetRequest?: PatchedInternalAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateAsset(
                id,
                patchedInternalAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId
         * @param {PatchedInternalDetectionRequest} [patchedInternalDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateDetection(
            pkId: number,
            patchedInternalDetectionRequest?: PatchedInternalDetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateDetection(
                pkId,
                patchedInternalDetectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {PatchedDetectionArtifactRequest} [patchedDetectionArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateDetectionArtifact(
            id: number,
            patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateDetectionArtifact(
                id,
                patchedDetectionArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {PatchedInternalOpportunityRequest} [patchedInternalOpportunityRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateOrbit(
            id: number,
            patchedInternalOpportunityRequest?: PatchedInternalOpportunityRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateOrbit(
                id,
                patchedInternalOpportunityRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {PatchedInternalReportRequest} [patchedInternalReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateReport(
            id: number,
            patchedInternalReportRequest?: PatchedInternalReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateReport(
                id,
                patchedInternalReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {PatchedInternalSensorRequest} [patchedInternalSensorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateSensor(
            oskId: number,
            patchedInternalSensorRequest?: PatchedInternalSensorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalSensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateSensor(
                oskId,
                patchedInternalSensorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {PatchedInternalSensorScheduleRequest} [patchedInternalSensorScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateSensorSchedule(
            id: number,
            patchedInternalSensorScheduleRequest?: PatchedInternalSensorScheduleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalSensorSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateSensorSchedule(
                id,
                patchedInternalSensorScheduleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {PatchedInternalUpdateSensorTaskRequest} [patchedInternalUpdateSensorTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateSensorTask(
            id: number,
            patchedInternalUpdateSensorTaskRequest?: PatchedInternalUpdateSensorTaskRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalUpdateSensorTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateSensorTask(
                id,
                patchedInternalUpdateSensorTaskRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {PatchedStateVectorRequest} [patchedStateVectorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateStateVector(
            id: number,
            sensorPk: number,
            patchedStateVectorRequest?: PatchedStateVectorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateStateVector(
                id,
                sensorPk,
                patchedStateVectorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {PatchedInternalTaskingRequestRequest} [patchedInternalTaskingRequestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateTaskingRequest(
            id: number,
            patchedInternalTaskingRequestRequest?: PatchedInternalTaskingRequestRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateTaskingRequest(
                id,
                patchedInternalTaskingRequestRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {PatchedInternalTaskingScheduleRequest} [patchedInternalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateTaskingSchedule(
            id: number,
            patchedInternalTaskingScheduleRequest?: PatchedInternalTaskingScheduleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateTaskingSchedule(
                id,
                patchedInternalTaskingScheduleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateAoi(
            id: number,
            programAOIRequest: ProgramAOIRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateAoi(id, programAOIRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {InternalAssetRequest} internalAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateAsset(
            id: number,
            internalAssetRequest: InternalAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateAsset(
                id,
                internalAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateDetection(
            pkId: number,
            internalDetectionRequest: InternalDetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateDetection(
                pkId,
                internalDetectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateDetectionArtifact(
            id: number,
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateDetectionArtifact(
                id,
                detectionArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {InternalOpportunityRequest} internalOpportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateOrbit(
            id: number,
            internalOpportunityRequest: InternalOpportunityRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateOrbit(
                id,
                internalOpportunityRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateReport(
            id: number,
            internalReportRequest: InternalReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateReport(
                id,
                internalReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {InternalSensorRequest} internalSensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateSensor(
            oskId: number,
            internalSensorRequest: InternalSensorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalSensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateSensor(
                oskId,
                internalSensorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {InternalSensorScheduleRequest} [internalSensorScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateSensorSchedule(
            id: number,
            internalSensorScheduleRequest?: InternalSensorScheduleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalSensorSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateSensorSchedule(
                id,
                internalSensorScheduleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {InternalUpdateSensorTaskRequest} [internalUpdateSensorTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateSensorTask(
            id: number,
            internalUpdateSensorTaskRequest?: InternalUpdateSensorTaskRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalUpdateSensorTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateSensorTask(
                id,
                internalUpdateSensorTaskRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateStateVector(
            id: number,
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateStateVector(
                id,
                sensorPk,
                stateVectorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {InternalTaskingRequestUpdateRequest} [internalTaskingRequestUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateTaskingRequest(
            id: number,
            internalTaskingRequestUpdateRequest?: InternalTaskingRequestUpdateRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingRequestUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateTaskingRequest(
                id,
                internalTaskingRequestUpdateRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {InternalTaskingScheduleRequest} [internalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateTaskingSchedule(
            id: number,
            internalTaskingScheduleRequest?: InternalTaskingScheduleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTaskingSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateTaskingSchedule(
                id,
                internalTaskingScheduleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateTle(
            id: number,
            sensorPk: number,
            tLERequest: TLERequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateTle(
                id,
                sensorPk,
                tLERequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssets(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAssetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssets(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Array<string>} [fileId] Filter captures by the capture file_ids provided
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCaptureArtifacts(
            program: number,
            fileId?: Array<string>,
            limit?: number,
            offset?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCaptureArtifactList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCaptureArtifacts(
                program,
                fileId,
                limit,
                offset,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] Filter captures by area of interest
         * @param {string} [aoiName] Filter captures by named area of interest
         * @param {string} [capturedAfter] Filter captures on acquisition time by date range
         * @param {string} [capturedBefore] Filter captures on acquisition time by date range
         * @param {number} [cloudCoverMax] Filter captures by cloud cover percentage
         * @param {number} [cloudCoverMin] Filter captures by cloud cover percentage
         * @param {Array<string>} [fileId] Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderId] Filter captures by the order ID.
         * @param {string} [processedAfter] Filter captures on date range of when they were processed
         * @param {string} [processedBefore] Filter captures on date range of when they were processed
         * @param {Array<number>} [sensor] Filter captures by sensor id
         * @param {Array<string>} [taskId] Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCaptures(
            program: number,
            aoi?: Geometry | GeometryCollection,
            aoiName?: string,
            capturedAfter?: string,
            capturedBefore?: string,
            cloudCoverMax?: number,
            cloudCoverMin?: number,
            fileId?: Array<string>,
            limit?: number,
            offset?: number,
            orderId?: string,
            processedAfter?: string,
            processedBefore?: string,
            sensor?: Array<number>,
            taskId?: Array<string>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCaptureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCaptures(
                program,
                aoi,
                aoiName,
                capturedAfter,
                capturedBefore,
                cloudCoverMax,
                cloudCoverMin,
                fileId,
                limit,
                offset,
                orderId,
                processedAfter,
                processedBefore,
                sensor,
                taskId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List detections
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
         * @param {number} [areaMax] Filter detections based on their area in square meters
         * @param {number} [areaMin] Filter detections based on their area in square meters
         * @param {Array<number>} [asset] Filter detections to one or more assets
         * @param {string} [callType] Filter detections to a general detection category
         * @param {string} [detectedAfter] Filter detections to imaging time
         * @param {string} [detectedBefore] Filter detections to imaging time
         * @param {Array<'alert' | 'detection' | 'hidden'>} [detectionClass] Filter findings by detection class (detection, alert)
         * @param {Array<string>} [detectionType] Filter detections to one or more detection_types
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>} [feedbackStatus] Filter findings by feedback status
         * @param {'all' | 'none'} [includeFiles] include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
         * @param {number} [intensityMax] Filter detections based on their intensity
         * @param {number} [intensityMin] Filter detections based on their intensity
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDetections(
            program: number,
            aoi?: Geometry | GeometryCollection,
            areaMax?: number,
            areaMin?: number,
            asset?: Array<number>,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'detection' | 'hidden'>,
            detectionType?: Array<string>,
            dist?: number,
            feedbackStatus?: Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>,
            includeFiles?: 'all' | 'none',
            intensityMax?: number,
            intensityMin?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDetectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDetections(
                program,
                aoi,
                areaMax,
                areaMin,
                asset,
                callType,
                detectedAfter,
                detectedBefore,
                detectionClass,
                detectionType,
                dist,
                feedbackStatus,
                includeFiles,
                intensityMax,
                intensityMin,
                limit,
                offset,
                ordering,
                point,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrders(
            program: number,
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedOrderList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrders(
                program,
                limit,
                offset,
                orderType,
                ordering,
                requestedBy,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(
            program: number,
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedReportList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(
                program,
                externalId,
                limit,
                offset,
                ordering,
                searchTimeEnd,
                searchTimeStart,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSearchAreas(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSearchAreaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSearchAreas(limit, offset, ordering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [taskingEligible] This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSensors(
            limit?: number,
            offset?: number,
            ordering?: string,
            taskingEligible?: boolean,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSensorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSensors(
                limit,
                offset,
                ordering,
                taskingEligible,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PatchedAssetRequest} [patchedAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateAsset(
            id: number,
            program: number,
            patchedAssetRequest?: PatchedAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateAsset(
                id,
                program,
                patchedAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Public access to detections (a.k.a. findings)
         * @param {string} id
         * @param {number} program
         * @param {PatchedDetectionRequest} [patchedDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateDetection(
            id: string,
            program: number,
            patchedDetectionRequest?: PatchedDetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Detection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateDetection(
                id,
                program,
                patchedDetectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {PatchedReportRequest} [patchedReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateReport(
            id: number,
            program: number,
            patchedReportRequest?: PatchedReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateReport(
                id,
                program,
                patchedReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {PatchedSearchAreaRequest} [patchedSearchAreaRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateSearchArea(
            id: number,
            patchedSearchAreaRequest?: PatchedSearchAreaRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSearchArea(
                id,
                patchedSearchAreaRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information on an OSK-supported data product
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGetDataProduct(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicDataProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGetDataProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List OSK-supported data products
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListDataProducts(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPublicDataProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListDataProducts(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 1-second piece of the orbit.
         * @summary Search orbital paths for opportunities
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {boolean} [eclipsed] Limit to satellite positions over the night side (true) or day side (false) of Earth
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOrbits(
            aoi?: Geometry | GeometryCollection,
            eclipsed?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedOpportunityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOrbits(
                aoi,
                eclipsed,
                limit,
                offset,
                ordering,
                searchTimeEnd,
                searchTimeStart,
                sensor,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {AssetRequest} [assetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAsset(
            id: number,
            program: number,
            assetRequest?: AssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAsset(id, program, assetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Public access to detections (a.k.a. findings)
         * @param {string} id
         * @param {number} program
         * @param {DetectionRequest} [detectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDetection(
            id: string,
            program: number,
            detectionRequest?: DetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Detection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDetection(
                id,
                program,
                detectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(
            id: number,
            program: number,
            reportRequest: ReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(id, program, reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSearchArea(
            id: number,
            searchAreaRequest: SearchAreaRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSearchArea(id, searchAreaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * SigmaApi - factory interface
 * @export
 */
export const SigmaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SigmaApiFp(configuration);
    return {
        /**
         *
         * @param {number} program
         * @param {OrderRequest} orderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(program: number, orderRequest: OrderRequest, options?: any): AxiosPromise<Order> {
            return localVarFp.createOrder(program, orderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(program: number, reportRequest: ReportRequest, options?: any): AxiosPromise<Report> {
            return localVarFp.createReport(program, reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSearchArea(searchAreaRequest: SearchAreaRequest, options?: any): AxiosPromise<SearchArea> {
            return localVarFp.createSearchArea(searchAreaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyReport(id: number, program: number, options?: any): AxiosPromise<void> {
            return localVarFp.destroyReport(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySearchArea(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.destroySearchArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(id: number, program: number, options?: any): AxiosPromise<AssetDetail> {
            return localVarFp.getAsset(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapture(id: string, program: number, options?: any): AxiosPromise<Capture> {
            return localVarFp.getCapture(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaptureArtifact(id: string, program: number, options?: any): AxiosPromise<CaptureArtifact> {
            return localVarFp.getCaptureArtifact(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a particular detection or finding
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetection(id: string, program: number, options?: any): AxiosPromise<Detection> {
            return localVarFp.getDetection(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(id: string, program: number, options?: any): AxiosPromise<Order> {
            return localVarFp.getOrder(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * get status for order matching order & program id
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatus(id: string, program: number, options?: any): AxiosPromise<OrderStatus> {
            return localVarFp.getOrderStatus(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * List all statues for this order
         * @param {string} id
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatusHistory(
            id: string,
            program: number,
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options?: any,
        ): AxiosPromise<PaginatedOrderStatusList> {
            return localVarFp
                .getOrderStatusHistory(id, program, limit, offset, orderType, ordering, requestedBy, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(id: number, program: number, options?: any): AxiosPromise<Report> {
            return localVarFp.getReport(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchArea(id: number, options?: any): AxiosPromise<SearchArea> {
            return localVarFp.getSearchArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensor(oskId: number, options?: any): AxiosPromise<Sensor> {
            return localVarFp.getSensor(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method will return the most recently saved StateVector for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateVector(oskId: number, options?: any): AxiosPromise<StateVector> {
            return localVarFp.getStateVector(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method will return the most recently saved TLE for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTle(oskId: number, options?: any): AxiosPromise<TLE> {
            return localVarFp.getTle(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAoi(programAOIRequest: ProgramAOIRequest, options?: any): AxiosPromise<ProgramAOI> {
            return localVarFp.internalCreateAoi(programAOIRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalAssetRequest} internalAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAsset(internalAssetRequest: InternalAssetRequest, options?: any): AxiosPromise<InternalAsset> {
            return localVarFp
                .internalCreateAsset(internalAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetection(
            internalDetectionRequest: InternalDetectionRequest,
            options?: any,
        ): AxiosPromise<InternalDetection> {
            return localVarFp
                .internalCreateDetection(internalDetectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetectionArtifact(
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: any,
        ): AxiosPromise<DetectionArtifact> {
            return localVarFp
                .internalCreateDetectionArtifact(detectionArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {InternalGroundStationPassRequest} internalGroundStationPassRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateGroundStationPasse(
            internalGroundStationPassRequest: InternalGroundStationPassRequest,
            options?: any,
        ): AxiosPromise<InternalGroundStationPass> {
            return localVarFp
                .internalCreateGroundStationPasse(internalGroundStationPassRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {InternalOpportunityRequest} internalOpportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrbit(
            internalOpportunityRequest: InternalOpportunityRequest,
            options?: any,
        ): AxiosPromise<InternalOpportunity> {
            return localVarFp
                .internalCreateOrbit(internalOpportunityRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalOrderRequest} internalOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrder(internalOrderRequest: InternalOrderRequest, options?: any): AxiosPromise<InternalOrder> {
            return localVarFp
                .internalCreateOrder(internalOrderRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {InternalOrderStatusRequest} internalOrderStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrderStatus(
            internalOrderStatusRequest: InternalOrderStatusRequest,
            options?: any,
        ): AxiosPromise<InternalOrderStatus> {
            return localVarFp
                .internalCreateOrderStatus(internalOrderStatusRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateReport(
            internalReportRequest: InternalReportRequest,
            options?: any,
        ): AxiosPromise<InternalReport> {
            return localVarFp
                .internalCreateReport(internalReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {InternalSensorRequest} internalSensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateSensor(
            internalSensorRequest: InternalSensorRequest,
            options?: any,
        ): AxiosPromise<InternalSensor> {
            return localVarFp
                .internalCreateSensor(internalSensorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {InternalCreateSensorTaskRequest} internalCreateSensorTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateSensorTask(
            internalCreateSensorTaskRequest: InternalCreateSensorTaskRequest,
            options?: any,
        ): AxiosPromise<InternalCreateSensorTask> {
            return localVarFp
                .internalCreateSensorTask(internalCreateSensorTaskRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateStateVector(
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: any,
        ): AxiosPromise<StateVector> {
            return localVarFp
                .internalCreateStateVector(sensorPk, stateVectorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {InternalTaskingBlockOutWindowRequest} internalTaskingBlockOutWindowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTaskingBlockOutWindow(
            internalTaskingBlockOutWindowRequest: InternalTaskingBlockOutWindowRequest,
            options?: any,
        ): AxiosPromise<InternalTaskingBlockOutWindow> {
            return localVarFp
                .internalCreateTaskingBlockOutWindow(internalTaskingBlockOutWindowRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {InternalTaskingRequestRequest} internalTaskingRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTaskingRequest(
            internalTaskingRequestRequest: InternalTaskingRequestRequest,
            options?: any,
        ): AxiosPromise<InternalTaskingRequest> {
            return localVarFp
                .internalCreateTaskingRequest(internalTaskingRequestRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {InternalTaskingScheduleRequest} [internalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTaskingSchedule(
            internalTaskingScheduleRequest?: InternalTaskingScheduleRequest,
            options?: any,
        ): AxiosPromise<InternalTaskingSchedule> {
            return localVarFp
                .internalCreateTaskingSchedule(internalTaskingScheduleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTle(sensorPk: number, tLERequest: TLERequest, options?: any): AxiosPromise<TLE> {
            return localVarFp
                .internalCreateTle(sensorPk, tLERequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAoi(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyAoi(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAsset(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetection(pkId: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyDetection(pkId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetectionArtifact(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyDetectionArtifact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOrbit(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyOrbit(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOrder(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyReport(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroySensor(oskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroySensor(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyStateVector(id: number, sensorPk: number, options?: any): AxiosPromise<void> {
            return localVarFp
                .internalDestroyStateVector(id, sensorPk, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyTle(id: number, sensorPk: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyTle(id, sensorPk, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAoi(id: number, options?: any): AxiosPromise<ProgramAOI> {
            return localVarFp.internalGetAoi(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAsset(id: number, options?: any): AxiosPromise<InternalAsset> {
            return localVarFp.internalGetAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information on an OSK-supported data product
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetDataProduct(id: number, options?: any): AxiosPromise<InternalDataProduct> {
            return localVarFp.internalGetDataProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetDetection(pkId: number, options?: any): AxiosPromise<InternalDetection> {
            return localVarFp.internalGetDetection(pkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single elevation point for a given latitude and longitude.
         * @param {number} [lat] Latitude
         * @param {number} [lng] Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetElevation(lat?: number, lng?: number, options?: any): AxiosPromise<Elevation> {
            return localVarFp.internalGetElevation(lat, lng, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the min/max/mean/median elevation within a given area.  The bounds should a stringified geojson object representing a geometry with 4 or more points. This endpoint will then compute the summary information within a simplified rectangle based on your input area.  The results will be provided at maximum possible resolution. For convenience, the dataset which sourced the information is returned along with the summary details.  Usage example:  ``` import json  sigma_api.internal_get_elevation_summary(bounds=json.dumps(footprint)) ```
         * @param {string} [bounds] The area from which summary elevation will be calculated for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetElevationSummary(bounds?: string, options?: any): AxiosPromise<ElevationSummary> {
            return localVarFp.internalGetElevationSummary(bounds, options).then((request) => request(axios, basePath));
        },
        /**
         * Readonly ViewSet to retrieve & list GroundStation objects.
         * @param {number} id A unique integer value identifying this ground station.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetGroundStation(id: number, options?: any): AxiosPromise<InternalGroundStation> {
            return localVarFp.internalGetGroundStation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {number} id A unique integer value identifying this ground station pass.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetGroundStationPasse(id: number, options?: any): AxiosPromise<InternalGroundStationPass> {
            return localVarFp.internalGetGroundStationPasse(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrbit(id: number, options?: any): AxiosPromise<InternalOpportunity> {
            return localVarFp.internalGetOrbit(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrder(id: number, options?: any): AxiosPromise<InternalOrder> {
            return localVarFp.internalGetOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {number} id A unique integer value identifying this order status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrderStatus(id: number, options?: any): AxiosPromise<InternalOrderStatus> {
            return localVarFp.internalGetOrderStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetReport(id: number, options?: any): AxiosPromise<InternalReport> {
            return localVarFp.internalGetReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSensor(oskId: number, options?: any): AxiosPromise<InternalSensor> {
            return localVarFp.internalGetSensor(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSensorSchedule(id: number, options?: any): AxiosPromise<InternalSensorSchedule> {
            return localVarFp.internalGetSensorSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSensorTask(id: number, options?: any): AxiosPromise<InternalUpdateSensorTask> {
            return localVarFp.internalGetSensorTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetStateVector(id: number, sensorPk: number, options?: any): AxiosPromise<StateVector> {
            return localVarFp.internalGetStateVector(id, sensorPk, options).then((request) => request(axios, basePath));
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {number} id A unique integer value identifying this tasking block out window.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTaskingBlockOutWindow(id: number, options?: any): AxiosPromise<InternalTaskingBlockOutWindow> {
            return localVarFp.internalGetTaskingBlockOutWindow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTaskingRequest(id: number, options?: any): AxiosPromise<InternalTaskingRequest> {
            return localVarFp.internalGetTaskingRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTaskingSchedule(id: number, options?: any): AxiosPromise<InternalTaskingSchedule> {
            return localVarFp.internalGetTaskingSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTle(id: number, sensorPk: number, options?: any): AxiosPromise<TLE> {
            return localVarFp.internalGetTle(id, sensorPk, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAois(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramAOIList> {
            return localVarFp
                .internalListAois(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAssets(
            dist?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options?: any,
        ): AxiosPromise<PaginatedInternalAssetList> {
            return localVarFp
                .internalListAssets(dist, limit, offset, ordering, point, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * List OSK-supported data products
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListDataProducts(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalDataProductList> {
            return localVarFp
                .internalListDataProducts(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
         * @param {number} [areaMax] Filter detections based on their area in square meters
         * @param {number} [areaMin] Filter detections based on their area in square meters
         * @param {Array<number>} [asset] Filter detections to one or more assets
         * @param {string} [callType] Filter detections to a general detection category
         * @param {string} [detectedAfter] Filter detections to imaging time
         * @param {string} [detectedBefore] Filter detections to imaging time
         * @param {Array<'alert' | 'detection' | 'hidden'>} [detectionClass] Filter findings by detection class (hidden, detection, alert)
         * @param {Array<string>} [detectionType] Filter detections to one or more detection_types
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {'all' | 'none'} [includeFiles] include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
         * @param {number} [intensityMax] Filter detections based on their intensity
         * @param {number} [intensityMin] Filter detections based on their intensity
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {Array<0 | 1 | 2>} [reviewStatus] Filter detections by review status (e.g., no review needed, needs review, reviewed)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListDetections(
            aoi?: Geometry | GeometryCollection,
            areaMax?: number,
            areaMin?: number,
            asset?: Array<number>,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'detection' | 'hidden'>,
            detectionType?: Array<string>,
            dist?: number,
            includeFiles?: 'all' | 'none',
            intensityMax?: number,
            intensityMin?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            reviewStatus?: Array<0 | 1 | 2>,
            options?: any,
        ): AxiosPromise<PaginatedInternalDetectionList> {
            return localVarFp
                .internalListDetections(
                    aoi,
                    areaMax,
                    areaMin,
                    asset,
                    callType,
                    detectedAfter,
                    detectedBefore,
                    detectionClass,
                    detectionType,
                    dist,
                    includeFiles,
                    intensityMax,
                    intensityMin,
                    limit,
                    offset,
                    ordering,
                    point,
                    reviewStatus,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
         * @param {boolean} [active] This filter can be used to retrieve active GroundStationPasses.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListGroundStationPasses(
            active?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalGroundStationPassList> {
            return localVarFp
                .internalListGroundStationPasses(active, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Readonly ViewSet to retrieve & list GroundStation objects.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListGroundStations(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalGroundStationList> {
            return localVarFp
                .internalListGroundStations(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {Array<number>} [bbOverlapsTaskingRequests] This filter can be used to retrieve Ticks whose bounding box overlaps the passed in Tasking Requests\&#39; bounding box.
         * @param {boolean} [blockedOut] You can use this filter argument to retrieve Ticks that are blocked out and should be excluded / included from tasking depending on if you pass True / False (respectively) to this parameter.
         * @param {string} [cursor] The pagination cursor value.
         * @param {boolean} [eclipsed] Filter to areas where the nadir point on the earth\&#39;s surface is either night or day. Eclipsed &#x3D; Trueimplies you want night.
         * @param {Array<number>} [intersectsTaskingRequests] This filter can be used to retrieve Ticks that intersect the passed in Tasking Requests.
         * @param {number} [orbitNum] Grab Ticks by it\&#39;s Orbit Number. An Orbit is defined as starting and ending where the satellite transitions from not eclipsed to eclipsed. The Orbit should always start in an eclipse except for the first orbit (it can, but it\&#39;s not guaranteed). Likewise, the Orbit will always end not eclipsed.
         * @param {number} [orbitNumEnd] Grab Ticks where orbit_num is less than orbit_num_end. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
         * @param {number} [orbitNumStart] Grab Ticks where orbit_num is greater than or equal to the orbit_num_start. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [rowNum] Filter to tick records with specific row_num values. The sensor_id / row_num field combination can be thought of as a unique index. It\&#39;s not truly though due to the propagator_seed_time field.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListOrbit(
            aoi?: Geometry | GeometryCollection,
            bbOverlapsTaskingRequests?: Array<number>,
            blockedOut?: boolean,
            cursor?: string,
            eclipsed?: boolean,
            intersectsTaskingRequests?: Array<number>,
            orbitNum?: number,
            orbitNumEnd?: number,
            orbitNumStart?: number,
            ordering?: string,
            rowNum?: Array<number>,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options?: any,
        ): AxiosPromise<PaginatedInternalOpportunityList> {
            return localVarFp
                .internalListOrbit(
                    aoi,
                    bbOverlapsTaskingRequests,
                    blockedOut,
                    cursor,
                    eclipsed,
                    intersectsTaskingRequests,
                    orbitNum,
                    orbitNumEnd,
                    orbitNumStart,
                    ordering,
                    rowNum,
                    searchTimeEnd,
                    searchTimeStart,
                    sensor,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * For internal Order Status API, provide create, read, and list operations (no update or delete)
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [order]
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListOrderStatuses(
            limit?: number,
            offset?: number,
            order?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalOrderStatusList> {
            return localVarFp
                .internalListOrderStatuses(limit, offset, order, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListOrders(
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options?: any,
        ): AxiosPromise<PaginatedInternalOrderList> {
            return localVarFp
                .internalListOrders(limit, offset, orderType, ordering, requestedBy, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListReports(
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalReportList> {
            return localVarFp
                .internalListReports(externalId, limit, offset, ordering, searchTimeEnd, searchTimeStart, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListSensorSchedules(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalSensorScheduleList> {
            return localVarFp
                .internalListSensorSchedules(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensorScheduleIds] This filter will allow you to specify one or many sensor schedule ids to retrieve SensorTasks with the corresponding SensorSchedule id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListSensorTasks(
            limit?: number,
            offset?: number,
            ordering?: string,
            sensorScheduleIds?: Array<number>,
            options?: any,
        ): AxiosPromise<PaginatedInternalUpdateSensorTaskList> {
            return localVarFp
                .internalListSensorTasks(limit, offset, ordering, sensorScheduleIds, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [taskingEligible] This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListSensors(
            limit?: number,
            offset?: number,
            ordering?: string,
            taskingEligible?: boolean,
            options?: any,
        ): AxiosPromise<PaginatedInternalSensorList> {
            return localVarFp
                .internalListSensors(limit, offset, ordering, taskingEligible, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListStateVectors(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedStateVectorList> {
            return localVarFp
                .internalListStateVectors(sensorPk, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
         * @param {boolean} [active] This filter can be used to retrieve active TaskingBlockOutWindows.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [sensor] The sensor to search for. Limits TaskingBlockOutWindows to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTaskingBlockOutWindows(
            active?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensor?: number,
            options?: any,
        ): AxiosPromise<PaginatedInternalTaskingBlockOutWindowList> {
            return localVarFp
                .internalListTaskingBlockOutWindows(active, limit, offset, ordering, sensor, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {boolean} [active] This filter can be used to retrieve TaskingRequests that are active or inactive.
         * @param {Array<number>} [ids] This filter will let you specify 1 or many ids of the tasking requests you specifically want to retrieve.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensors]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTaskingRequests(
            active?: boolean,
            ids?: Array<number>,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensors?: Array<number>,
            options?: any,
        ): AxiosPromise<PaginatedInternalTaskingRequestList> {
            return localVarFp
                .internalListTaskingRequests(active, ids, limit, offset, ordering, sensors, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTaskingSchedules(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalTaskingScheduleList> {
            return localVarFp
                .internalListTaskingSchedules(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTles(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedTLEList> {
            return localVarFp
                .internalListTles(sensorPk, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {PatchedProgramAOIRequest} [patchedProgramAOIRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAoi(
            id: number,
            patchedProgramAOIRequest?: PatchedProgramAOIRequest,
            options?: any,
        ): AxiosPromise<ProgramAOI> {
            return localVarFp
                .internalPartialUpdateAoi(id, patchedProgramAOIRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PatchedInternalAssetRequest} [patchedInternalAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAsset(
            id: number,
            patchedInternalAssetRequest?: PatchedInternalAssetRequest,
            options?: any,
        ): AxiosPromise<InternalAsset> {
            return localVarFp
                .internalPartialUpdateAsset(id, patchedInternalAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId
         * @param {PatchedInternalDetectionRequest} [patchedInternalDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetection(
            pkId: number,
            patchedInternalDetectionRequest?: PatchedInternalDetectionRequest,
            options?: any,
        ): AxiosPromise<InternalDetection> {
            return localVarFp
                .internalPartialUpdateDetection(pkId, patchedInternalDetectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {PatchedDetectionArtifactRequest} [patchedDetectionArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetectionArtifact(
            id: number,
            patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest,
            options?: any,
        ): AxiosPromise<DetectionArtifact> {
            return localVarFp
                .internalPartialUpdateDetectionArtifact(id, patchedDetectionArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {PatchedInternalOpportunityRequest} [patchedInternalOpportunityRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateOrbit(
            id: number,
            patchedInternalOpportunityRequest?: PatchedInternalOpportunityRequest,
            options?: any,
        ): AxiosPromise<InternalOpportunity> {
            return localVarFp
                .internalPartialUpdateOrbit(id, patchedInternalOpportunityRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {PatchedInternalReportRequest} [patchedInternalReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateReport(
            id: number,
            patchedInternalReportRequest?: PatchedInternalReportRequest,
            options?: any,
        ): AxiosPromise<InternalReport> {
            return localVarFp
                .internalPartialUpdateReport(id, patchedInternalReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {PatchedInternalSensorRequest} [patchedInternalSensorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensor(
            oskId: number,
            patchedInternalSensorRequest?: PatchedInternalSensorRequest,
            options?: any,
        ): AxiosPromise<InternalSensor> {
            return localVarFp
                .internalPartialUpdateSensor(oskId, patchedInternalSensorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {PatchedInternalSensorScheduleRequest} [patchedInternalSensorScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensorSchedule(
            id: number,
            patchedInternalSensorScheduleRequest?: PatchedInternalSensorScheduleRequest,
            options?: any,
        ): AxiosPromise<InternalSensorSchedule> {
            return localVarFp
                .internalPartialUpdateSensorSchedule(id, patchedInternalSensorScheduleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {PatchedInternalUpdateSensorTaskRequest} [patchedInternalUpdateSensorTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensorTask(
            id: number,
            patchedInternalUpdateSensorTaskRequest?: PatchedInternalUpdateSensorTaskRequest,
            options?: any,
        ): AxiosPromise<InternalUpdateSensorTask> {
            return localVarFp
                .internalPartialUpdateSensorTask(id, patchedInternalUpdateSensorTaskRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {PatchedStateVectorRequest} [patchedStateVectorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateStateVector(
            id: number,
            sensorPk: number,
            patchedStateVectorRequest?: PatchedStateVectorRequest,
            options?: any,
        ): AxiosPromise<StateVector> {
            return localVarFp
                .internalPartialUpdateStateVector(id, sensorPk, patchedStateVectorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {PatchedInternalTaskingRequestRequest} [patchedInternalTaskingRequestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateTaskingRequest(
            id: number,
            patchedInternalTaskingRequestRequest?: PatchedInternalTaskingRequestRequest,
            options?: any,
        ): AxiosPromise<InternalTaskingRequest> {
            return localVarFp
                .internalPartialUpdateTaskingRequest(id, patchedInternalTaskingRequestRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {PatchedInternalTaskingScheduleRequest} [patchedInternalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateTaskingSchedule(
            id: number,
            patchedInternalTaskingScheduleRequest?: PatchedInternalTaskingScheduleRequest,
            options?: any,
        ): AxiosPromise<InternalTaskingSchedule> {
            return localVarFp
                .internalPartialUpdateTaskingSchedule(id, patchedInternalTaskingScheduleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAoi(id: number, programAOIRequest: ProgramAOIRequest, options?: any): AxiosPromise<ProgramAOI> {
            return localVarFp
                .internalUpdateAoi(id, programAOIRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {InternalAssetRequest} internalAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAsset(
            id: number,
            internalAssetRequest: InternalAssetRequest,
            options?: any,
        ): AxiosPromise<InternalAsset> {
            return localVarFp
                .internalUpdateAsset(id, internalAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetection(
            pkId: number,
            internalDetectionRequest: InternalDetectionRequest,
            options?: any,
        ): AxiosPromise<InternalDetection> {
            return localVarFp
                .internalUpdateDetection(pkId, internalDetectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetectionArtifact(
            id: number,
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: any,
        ): AxiosPromise<DetectionArtifact> {
            return localVarFp
                .internalUpdateDetectionArtifact(id, detectionArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for finding opportunities to image using OSK satellites
         * @param {number} id A unique integer value identifying this tick.
         * @param {InternalOpportunityRequest} internalOpportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateOrbit(
            id: number,
            internalOpportunityRequest: InternalOpportunityRequest,
            options?: any,
        ): AxiosPromise<InternalOpportunity> {
            return localVarFp
                .internalUpdateOrbit(id, internalOpportunityRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateReport(
            id: number,
            internalReportRequest: InternalReportRequest,
            options?: any,
        ): AxiosPromise<InternalReport> {
            return localVarFp
                .internalUpdateReport(id, internalReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {InternalSensorRequest} internalSensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensor(
            oskId: number,
            internalSensorRequest: InternalSensorRequest,
            options?: any,
        ): AxiosPromise<InternalSensor> {
            return localVarFp
                .internalUpdateSensor(oskId, internalSensorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
         * @param {number} id A unique integer value identifying this sensor schedule.
         * @param {InternalSensorScheduleRequest} [internalSensorScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensorSchedule(
            id: number,
            internalSensorScheduleRequest?: InternalSensorScheduleRequest,
            options?: any,
        ): AxiosPromise<InternalSensorSchedule> {
            return localVarFp
                .internalUpdateSensorSchedule(id, internalSensorScheduleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this sensor task.
         * @param {InternalUpdateSensorTaskRequest} [internalUpdateSensorTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensorTask(
            id: number,
            internalUpdateSensorTaskRequest?: InternalUpdateSensorTaskRequest,
            options?: any,
        ): AxiosPromise<InternalUpdateSensorTask> {
            return localVarFp
                .internalUpdateSensorTask(id, internalUpdateSensorTaskRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateStateVector(
            id: number,
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: any,
        ): AxiosPromise<StateVector> {
            return localVarFp
                .internalUpdateStateVector(id, sensorPk, stateVectorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
         * @param {number} id A unique integer value identifying this tasking request.
         * @param {InternalTaskingRequestUpdateRequest} [internalTaskingRequestUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTaskingRequest(
            id: number,
            internalTaskingRequestUpdateRequest?: InternalTaskingRequestUpdateRequest,
            options?: any,
        ): AxiosPromise<InternalTaskingRequestUpdate> {
            return localVarFp
                .internalUpdateTaskingRequest(id, internalTaskingRequestUpdateRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
         * @param {number} id A unique integer value identifying this tasking schedule.
         * @param {InternalTaskingScheduleRequest} [internalTaskingScheduleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTaskingSchedule(
            id: number,
            internalTaskingScheduleRequest?: InternalTaskingScheduleRequest,
            options?: any,
        ): AxiosPromise<InternalTaskingSchedule> {
            return localVarFp
                .internalUpdateTaskingSchedule(id, internalTaskingScheduleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTle(id: number, sensorPk: number, tLERequest: TLERequest, options?: any): AxiosPromise<TLE> {
            return localVarFp
                .internalUpdateTle(id, sensorPk, tLERequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedAssetList> {
            return localVarFp
                .listAssets(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Array<string>} [fileId] Filter captures by the capture file_ids provided
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCaptureArtifacts(
            program: number,
            fileId?: Array<string>,
            limit?: number,
            offset?: number,
            options?: any,
        ): AxiosPromise<PaginatedCaptureArtifactList> {
            return localVarFp
                .listCaptureArtifacts(program, fileId, limit, offset, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] Filter captures by area of interest
         * @param {string} [aoiName] Filter captures by named area of interest
         * @param {string} [capturedAfter] Filter captures on acquisition time by date range
         * @param {string} [capturedBefore] Filter captures on acquisition time by date range
         * @param {number} [cloudCoverMax] Filter captures by cloud cover percentage
         * @param {number} [cloudCoverMin] Filter captures by cloud cover percentage
         * @param {Array<string>} [fileId] Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderId] Filter captures by the order ID.
         * @param {string} [processedAfter] Filter captures on date range of when they were processed
         * @param {string} [processedBefore] Filter captures on date range of when they were processed
         * @param {Array<number>} [sensor] Filter captures by sensor id
         * @param {Array<string>} [taskId] Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCaptures(
            program: number,
            aoi?: Geometry | GeometryCollection,
            aoiName?: string,
            capturedAfter?: string,
            capturedBefore?: string,
            cloudCoverMax?: number,
            cloudCoverMin?: number,
            fileId?: Array<string>,
            limit?: number,
            offset?: number,
            orderId?: string,
            processedAfter?: string,
            processedBefore?: string,
            sensor?: Array<number>,
            taskId?: Array<string>,
            options?: any,
        ): AxiosPromise<PaginatedCaptureList> {
            return localVarFp
                .listCaptures(
                    program,
                    aoi,
                    aoiName,
                    capturedAfter,
                    capturedBefore,
                    cloudCoverMax,
                    cloudCoverMin,
                    fileId,
                    limit,
                    offset,
                    orderId,
                    processedAfter,
                    processedBefore,
                    sensor,
                    taskId,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * List detections
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
         * @param {number} [areaMax] Filter detections based on their area in square meters
         * @param {number} [areaMin] Filter detections based on their area in square meters
         * @param {Array<number>} [asset] Filter detections to one or more assets
         * @param {string} [callType] Filter detections to a general detection category
         * @param {string} [detectedAfter] Filter detections to imaging time
         * @param {string} [detectedBefore] Filter detections to imaging time
         * @param {Array<'alert' | 'detection' | 'hidden'>} [detectionClass] Filter findings by detection class (detection, alert)
         * @param {Array<string>} [detectionType] Filter detections to one or more detection_types
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>} [feedbackStatus] Filter findings by feedback status
         * @param {'all' | 'none'} [includeFiles] include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
         * @param {number} [intensityMax] Filter detections based on their intensity
         * @param {number} [intensityMin] Filter detections based on their intensity
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetections(
            program: number,
            aoi?: Geometry | GeometryCollection,
            areaMax?: number,
            areaMin?: number,
            asset?: Array<number>,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'detection' | 'hidden'>,
            detectionType?: Array<string>,
            dist?: number,
            feedbackStatus?: Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>,
            includeFiles?: 'all' | 'none',
            intensityMax?: number,
            intensityMin?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options?: any,
        ): AxiosPromise<PaginatedDetectionList> {
            return localVarFp
                .listDetections(
                    program,
                    aoi,
                    areaMax,
                    areaMin,
                    asset,
                    callType,
                    detectedAfter,
                    detectedBefore,
                    detectionClass,
                    detectionType,
                    dist,
                    feedbackStatus,
                    includeFiles,
                    intensityMax,
                    intensityMin,
                    limit,
                    offset,
                    ordering,
                    point,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [orderType] Filter orders by if it is an Archive Request or Tasking Request
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [requestedBy] Filter orders by the user that placed the order request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders(
            program: number,
            limit?: number,
            offset?: number,
            orderType?: string,
            ordering?: string,
            requestedBy?: number,
            options?: any,
        ): AxiosPromise<PaginatedOrderList> {
            return localVarFp
                .listOrders(program, limit, offset, orderType, ordering, requestedBy, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(
            program: number,
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: any,
        ): AxiosPromise<PaginatedReportList> {
            return localVarFp
                .listReports(program, externalId, limit, offset, ordering, searchTimeEnd, searchTimeStart, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAreas(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedSearchAreaList> {
            return localVarFp
                .listSearchAreas(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [taskingEligible] This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSensors(
            limit?: number,
            offset?: number,
            ordering?: string,
            taskingEligible?: boolean,
            options?: any,
        ): AxiosPromise<PaginatedSensorList> {
            return localVarFp
                .listSensors(limit, offset, ordering, taskingEligible, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PatchedAssetRequest} [patchedAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAsset(
            id: number,
            program: number,
            patchedAssetRequest?: PatchedAssetRequest,
            options?: any,
        ): AxiosPromise<Asset> {
            return localVarFp
                .partialUpdateAsset(id, program, patchedAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Public access to detections (a.k.a. findings)
         * @param {string} id
         * @param {number} program
         * @param {PatchedDetectionRequest} [patchedDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDetection(
            id: string,
            program: number,
            patchedDetectionRequest?: PatchedDetectionRequest,
            options?: any,
        ): AxiosPromise<Detection> {
            return localVarFp
                .partialUpdateDetection(id, program, patchedDetectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {PatchedReportRequest} [patchedReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateReport(
            id: number,
            program: number,
            patchedReportRequest?: PatchedReportRequest,
            options?: any,
        ): AxiosPromise<Report> {
            return localVarFp
                .partialUpdateReport(id, program, patchedReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {PatchedSearchAreaRequest} [patchedSearchAreaRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSearchArea(
            id: number,
            patchedSearchAreaRequest?: PatchedSearchAreaRequest,
            options?: any,
        ): AxiosPromise<SearchArea> {
            return localVarFp
                .partialUpdateSearchArea(id, patchedSearchAreaRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Get information on an OSK-supported data product
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetDataProduct(id: number, options?: any): AxiosPromise<PublicDataProduct> {
            return localVarFp.publicGetDataProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List OSK-supported data products
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListDataProducts(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedPublicDataProductList> {
            return localVarFp
                .publicListDataProducts(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 1-second piece of the orbit.
         * @summary Search orbital paths for opportunities
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {boolean} [eclipsed] Limit to satellite positions over the night side (true) or day side (false) of Earth
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrbits(
            aoi?: Geometry | GeometryCollection,
            eclipsed?: boolean,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options?: any,
        ): AxiosPromise<PaginatedOpportunityList> {
            return localVarFp
                .searchOrbits(aoi, eclipsed, limit, offset, ordering, searchTimeEnd, searchTimeStart, sensor, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {AssetRequest} [assetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset(id: number, program: number, assetRequest?: AssetRequest, options?: any): AxiosPromise<Asset> {
            return localVarFp
                .updateAsset(id, program, assetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Public access to detections (a.k.a. findings)
         * @param {string} id
         * @param {number} program
         * @param {DetectionRequest} [detectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetection(
            id: string,
            program: number,
            detectionRequest?: DetectionRequest,
            options?: any,
        ): AxiosPromise<Detection> {
            return localVarFp
                .updateDetection(id, program, detectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(id: number, program: number, reportRequest: ReportRequest, options?: any): AxiosPromise<Report> {
            return localVarFp
                .updateReport(id, program, reportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearchArea(id: number, searchAreaRequest: SearchAreaRequest, options?: any): AxiosPromise<SearchArea> {
            return localVarFp
                .updateSearchArea(id, searchAreaRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createOrder operation in SigmaApi.
 * @export
 * @interface SigmaApiCreateOrderRequest
 */
export interface SigmaApiCreateOrderRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiCreateOrder
     */
    readonly program: number;

    /**
     *
     * @type {OrderRequest}
     * @memberof SigmaApiCreateOrder
     */
    readonly orderRequest: OrderRequest;
}

/**
 * Request parameters for createReport operation in SigmaApi.
 * @export
 * @interface SigmaApiCreateReportRequest
 */
export interface SigmaApiCreateReportRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiCreateReport
     */
    readonly program: number;

    /**
     *
     * @type {ReportRequest}
     * @memberof SigmaApiCreateReport
     */
    readonly reportRequest: ReportRequest;
}

/**
 * Request parameters for createSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiCreateSearchAreaRequest
 */
export interface SigmaApiCreateSearchAreaRequest {
    /**
     *
     * @type {SearchAreaRequest}
     * @memberof SigmaApiCreateSearchArea
     */
    readonly searchAreaRequest: SearchAreaRequest;
}

/**
 * Request parameters for destroyReport operation in SigmaApi.
 * @export
 * @interface SigmaApiDestroyReportRequest
 */
export interface SigmaApiDestroyReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiDestroyReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiDestroyReport
     */
    readonly program: number;
}

/**
 * Request parameters for destroySearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiDestroySearchAreaRequest
 */
export interface SigmaApiDestroySearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiDestroySearchArea
     */
    readonly id: number;
}

/**
 * Request parameters for getAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiGetAssetRequest
 */
export interface SigmaApiGetAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiGetAsset
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetAsset
     */
    readonly program: number;
}

/**
 * Request parameters for getCapture operation in SigmaApi.
 * @export
 * @interface SigmaApiGetCaptureRequest
 */
export interface SigmaApiGetCaptureRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiGetCapture
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetCapture
     */
    readonly program: number;
}

/**
 * Request parameters for getCaptureArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiGetCaptureArtifactRequest
 */
export interface SigmaApiGetCaptureArtifactRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiGetCaptureArtifact
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetCaptureArtifact
     */
    readonly program: number;
}

/**
 * Request parameters for getDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiGetDetectionRequest
 */
export interface SigmaApiGetDetectionRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiGetDetection
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetDetection
     */
    readonly program: number;
}

/**
 * Request parameters for getOrder operation in SigmaApi.
 * @export
 * @interface SigmaApiGetOrderRequest
 */
export interface SigmaApiGetOrderRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiGetOrder
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetOrder
     */
    readonly program: number;
}

/**
 * Request parameters for getOrderStatus operation in SigmaApi.
 * @export
 * @interface SigmaApiGetOrderStatusRequest
 */
export interface SigmaApiGetOrderStatusRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiGetOrderStatus
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetOrderStatus
     */
    readonly program: number;
}

/**
 * Request parameters for getOrderStatusHistory operation in SigmaApi.
 * @export
 * @interface SigmaApiGetOrderStatusHistoryRequest
 */
export interface SigmaApiGetOrderStatusHistoryRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiGetOrderStatusHistory
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetOrderStatusHistory
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiGetOrderStatusHistory
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiGetOrderStatusHistory
     */
    readonly offset?: number;

    /**
     * Filter orders by if it is an Archive Request or Tasking Request
     * @type {string}
     * @memberof SigmaApiGetOrderStatusHistory
     */
    readonly orderType?: string;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiGetOrderStatusHistory
     */
    readonly ordering?: string;

    /**
     * Filter orders by the user that placed the order request
     * @type {number}
     * @memberof SigmaApiGetOrderStatusHistory
     */
    readonly requestedBy?: number;
}

/**
 * Request parameters for getReport operation in SigmaApi.
 * @export
 * @interface SigmaApiGetReportRequest
 */
export interface SigmaApiGetReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiGetReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetReport
     */
    readonly program: number;
}

/**
 * Request parameters for getSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiGetSearchAreaRequest
 */
export interface SigmaApiGetSearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiGetSearchArea
     */
    readonly id: number;
}

/**
 * Request parameters for getSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiGetSensorRequest
 */
export interface SigmaApiGetSensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiGetSensor
     */
    readonly oskId: number;
}

/**
 * Request parameters for getStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiGetStateVectorRequest
 */
export interface SigmaApiGetStateVectorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiGetStateVector
     */
    readonly oskId: number;
}

/**
 * Request parameters for getTle operation in SigmaApi.
 * @export
 * @interface SigmaApiGetTleRequest
 */
export interface SigmaApiGetTleRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiGetTle
     */
    readonly oskId: number;
}

/**
 * Request parameters for internalCreateAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateAoiRequest
 */
export interface SigmaApiInternalCreateAoiRequest {
    /**
     *
     * @type {ProgramAOIRequest}
     * @memberof SigmaApiInternalCreateAoi
     */
    readonly programAOIRequest: ProgramAOIRequest;
}

/**
 * Request parameters for internalCreateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateAssetRequest
 */
export interface SigmaApiInternalCreateAssetRequest {
    /**
     *
     * @type {InternalAssetRequest}
     * @memberof SigmaApiInternalCreateAsset
     */
    readonly internalAssetRequest: InternalAssetRequest;
}

/**
 * Request parameters for internalCreateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateDetectionRequest
 */
export interface SigmaApiInternalCreateDetectionRequest {
    /**
     *
     * @type {InternalDetectionRequest}
     * @memberof SigmaApiInternalCreateDetection
     */
    readonly internalDetectionRequest: InternalDetectionRequest;
}

/**
 * Request parameters for internalCreateDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateDetectionArtifactRequest
 */
export interface SigmaApiInternalCreateDetectionArtifactRequest {
    /**
     *
     * @type {DetectionArtifactRequest}
     * @memberof SigmaApiInternalCreateDetectionArtifact
     */
    readonly detectionArtifactRequest: DetectionArtifactRequest;
}

/**
 * Request parameters for internalCreateGroundStationPasse operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateGroundStationPasseRequest
 */
export interface SigmaApiInternalCreateGroundStationPasseRequest {
    /**
     *
     * @type {InternalGroundStationPassRequest}
     * @memberof SigmaApiInternalCreateGroundStationPasse
     */
    readonly internalGroundStationPassRequest: InternalGroundStationPassRequest;
}

/**
 * Request parameters for internalCreateOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateOrbitRequest
 */
export interface SigmaApiInternalCreateOrbitRequest {
    /**
     *
     * @type {InternalOpportunityRequest}
     * @memberof SigmaApiInternalCreateOrbit
     */
    readonly internalOpportunityRequest: InternalOpportunityRequest;
}

/**
 * Request parameters for internalCreateOrder operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateOrderRequest
 */
export interface SigmaApiInternalCreateOrderRequest {
    /**
     *
     * @type {InternalOrderRequest}
     * @memberof SigmaApiInternalCreateOrder
     */
    readonly internalOrderRequest: InternalOrderRequest;
}

/**
 * Request parameters for internalCreateOrderStatus operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateOrderStatusRequest
 */
export interface SigmaApiInternalCreateOrderStatusRequest {
    /**
     *
     * @type {InternalOrderStatusRequest}
     * @memberof SigmaApiInternalCreateOrderStatus
     */
    readonly internalOrderStatusRequest: InternalOrderStatusRequest;
}

/**
 * Request parameters for internalCreateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateReportRequest
 */
export interface SigmaApiInternalCreateReportRequest {
    /**
     *
     * @type {InternalReportRequest}
     * @memberof SigmaApiInternalCreateReport
     */
    readonly internalReportRequest: InternalReportRequest;
}

/**
 * Request parameters for internalCreateSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateSensorRequest
 */
export interface SigmaApiInternalCreateSensorRequest {
    /**
     *
     * @type {InternalSensorRequest}
     * @memberof SigmaApiInternalCreateSensor
     */
    readonly internalSensorRequest: InternalSensorRequest;
}

/**
 * Request parameters for internalCreateSensorTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateSensorTaskRequest
 */
export interface SigmaApiInternalCreateSensorTaskRequest {
    /**
     *
     * @type {InternalCreateSensorTaskRequest}
     * @memberof SigmaApiInternalCreateSensorTask
     */
    readonly internalCreateSensorTaskRequest: InternalCreateSensorTaskRequest;
}

/**
 * Request parameters for internalCreateStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateStateVectorRequest
 */
export interface SigmaApiInternalCreateStateVectorRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalCreateStateVector
     */
    readonly sensorPk: number;

    /**
     *
     * @type {StateVectorRequest}
     * @memberof SigmaApiInternalCreateStateVector
     */
    readonly stateVectorRequest: StateVectorRequest;
}

/**
 * Request parameters for internalCreateTaskingBlockOutWindow operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateTaskingBlockOutWindowRequest
 */
export interface SigmaApiInternalCreateTaskingBlockOutWindowRequest {
    /**
     *
     * @type {InternalTaskingBlockOutWindowRequest}
     * @memberof SigmaApiInternalCreateTaskingBlockOutWindow
     */
    readonly internalTaskingBlockOutWindowRequest: InternalTaskingBlockOutWindowRequest;
}

/**
 * Request parameters for internalCreateTaskingRequest operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateTaskingRequestRequest
 */
export interface SigmaApiInternalCreateTaskingRequestRequest {
    /**
     *
     * @type {InternalTaskingRequestRequest}
     * @memberof SigmaApiInternalCreateTaskingRequest
     */
    readonly internalTaskingRequestRequest: InternalTaskingRequestRequest;
}

/**
 * Request parameters for internalCreateTaskingSchedule operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateTaskingScheduleRequest
 */
export interface SigmaApiInternalCreateTaskingScheduleRequest {
    /**
     *
     * @type {InternalTaskingScheduleRequest}
     * @memberof SigmaApiInternalCreateTaskingSchedule
     */
    readonly internalTaskingScheduleRequest?: InternalTaskingScheduleRequest;
}

/**
 * Request parameters for internalCreateTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateTleRequest
 */
export interface SigmaApiInternalCreateTleRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalCreateTle
     */
    readonly sensorPk: number;

    /**
     *
     * @type {TLERequest}
     * @memberof SigmaApiInternalCreateTle
     */
    readonly tLERequest: TLERequest;
}

/**
 * Request parameters for internalDestroyAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyAoiRequest
 */
export interface SigmaApiInternalDestroyAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalDestroyAoi
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyAssetRequest
 */
export interface SigmaApiInternalDestroyAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalDestroyAsset
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyDetectionRequest
 */
export interface SigmaApiInternalDestroyDetectionRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalDestroyDetection
     */
    readonly pkId: number;
}

/**
 * Request parameters for internalDestroyDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyDetectionArtifactRequest
 */
export interface SigmaApiInternalDestroyDetectionArtifactRequest {
    /**
     * A unique integer value identifying this detection artifact.
     * @type {number}
     * @memberof SigmaApiInternalDestroyDetectionArtifact
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyOrbitRequest
 */
export interface SigmaApiInternalDestroyOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalDestroyOrbit
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyOrder operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyOrderRequest
 */
export interface SigmaApiInternalDestroyOrderRequest {
    /**
     * A unique integer value identifying this order.
     * @type {number}
     * @memberof SigmaApiInternalDestroyOrder
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyReportRequest
 */
export interface SigmaApiInternalDestroyReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalDestroyReport
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroySensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroySensorRequest
 */
export interface SigmaApiInternalDestroySensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiInternalDestroySensor
     */
    readonly oskId: number;
}

/**
 * Request parameters for internalDestroyStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyStateVectorRequest
 */
export interface SigmaApiInternalDestroyStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalDestroyStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalDestroyStateVector
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalDestroyTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyTleRequest
 */
export interface SigmaApiInternalDestroyTleRequest {
    /**
     * A unique integer value identifying this tle.
     * @type {number}
     * @memberof SigmaApiInternalDestroyTle
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalDestroyTle
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalGetAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetAoiRequest
 */
export interface SigmaApiInternalGetAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalGetAoi
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetAssetRequest
 */
export interface SigmaApiInternalGetAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalGetAsset
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetDataProduct operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetDataProductRequest
 */
export interface SigmaApiInternalGetDataProductRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalGetDataProduct
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetDetectionRequest
 */
export interface SigmaApiInternalGetDetectionRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalGetDetection
     */
    readonly pkId: number;
}

/**
 * Request parameters for internalGetElevation operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetElevationRequest
 */
export interface SigmaApiInternalGetElevationRequest {
    /**
     * Latitude
     * @type {number}
     * @memberof SigmaApiInternalGetElevation
     */
    readonly lat?: number;

    /**
     * Longitude
     * @type {number}
     * @memberof SigmaApiInternalGetElevation
     */
    readonly lng?: number;
}

/**
 * Request parameters for internalGetElevationSummary operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetElevationSummaryRequest
 */
export interface SigmaApiInternalGetElevationSummaryRequest {
    /**
     * The area from which summary elevation will be calculated for
     * @type {string}
     * @memberof SigmaApiInternalGetElevationSummary
     */
    readonly bounds?: string;
}

/**
 * Request parameters for internalGetGroundStation operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetGroundStationRequest
 */
export interface SigmaApiInternalGetGroundStationRequest {
    /**
     * A unique integer value identifying this ground station.
     * @type {number}
     * @memberof SigmaApiInternalGetGroundStation
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetGroundStationPasse operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetGroundStationPasseRequest
 */
export interface SigmaApiInternalGetGroundStationPasseRequest {
    /**
     * A unique integer value identifying this ground station pass.
     * @type {number}
     * @memberof SigmaApiInternalGetGroundStationPasse
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetOrbitRequest
 */
export interface SigmaApiInternalGetOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalGetOrbit
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetOrder operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetOrderRequest
 */
export interface SigmaApiInternalGetOrderRequest {
    /**
     * A unique integer value identifying this order.
     * @type {number}
     * @memberof SigmaApiInternalGetOrder
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetOrderStatus operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetOrderStatusRequest
 */
export interface SigmaApiInternalGetOrderStatusRequest {
    /**
     * A unique integer value identifying this order status.
     * @type {number}
     * @memberof SigmaApiInternalGetOrderStatus
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetReportRequest
 */
export interface SigmaApiInternalGetReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalGetReport
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetSensorRequest
 */
export interface SigmaApiInternalGetSensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiInternalGetSensor
     */
    readonly oskId: number;
}

/**
 * Request parameters for internalGetSensorSchedule operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetSensorScheduleRequest
 */
export interface SigmaApiInternalGetSensorScheduleRequest {
    /**
     * A unique integer value identifying this sensor schedule.
     * @type {number}
     * @memberof SigmaApiInternalGetSensorSchedule
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetSensorTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetSensorTaskRequest
 */
export interface SigmaApiInternalGetSensorTaskRequest {
    /**
     * A unique integer value identifying this sensor task.
     * @type {number}
     * @memberof SigmaApiInternalGetSensorTask
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetStateVectorRequest
 */
export interface SigmaApiInternalGetStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalGetStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalGetStateVector
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalGetTaskingBlockOutWindow operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetTaskingBlockOutWindowRequest
 */
export interface SigmaApiInternalGetTaskingBlockOutWindowRequest {
    /**
     * A unique integer value identifying this tasking block out window.
     * @type {number}
     * @memberof SigmaApiInternalGetTaskingBlockOutWindow
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetTaskingRequest operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetTaskingRequestRequest
 */
export interface SigmaApiInternalGetTaskingRequestRequest {
    /**
     * A unique integer value identifying this tasking request.
     * @type {number}
     * @memberof SigmaApiInternalGetTaskingRequest
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetTaskingSchedule operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetTaskingScheduleRequest
 */
export interface SigmaApiInternalGetTaskingScheduleRequest {
    /**
     * A unique integer value identifying this tasking schedule.
     * @type {number}
     * @memberof SigmaApiInternalGetTaskingSchedule
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetTleRequest
 */
export interface SigmaApiInternalGetTleRequest {
    /**
     * A unique integer value identifying this tle.
     * @type {number}
     * @memberof SigmaApiInternalGetTle
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalGetTle
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalListAois operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListAoisRequest
 */
export interface SigmaApiInternalListAoisRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListAois
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListAois
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListAois
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListAssets operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListAssetsRequest
 */
export interface SigmaApiInternalListAssetsRequest {
    /**
     * Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
     * @type {number}
     * @memberof SigmaApiInternalListAssets
     */
    readonly dist?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListAssets
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListAssets
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListAssets
     */
    readonly ordering?: string;

    /**
     * Point represented in **x,y** format. Represents **point** in **Distance to point filter**
     * @type {Array<number>}
     * @memberof SigmaApiInternalListAssets
     */
    readonly point?: Array<number>;
}

/**
 * Request parameters for internalListDataProducts operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListDataProductsRequest
 */
export interface SigmaApiInternalListDataProductsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListDataProducts
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListDataProducts
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListDataProducts
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListDetections operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListDetectionsRequest
 */
export interface SigmaApiInternalListDetectionsRequest {
    /**
     * Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiInternalListDetections
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Filter detections based on their area in square meters
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly areaMax?: number;

    /**
     * Filter detections based on their area in square meters
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly areaMin?: number;

    /**
     * Filter detections to one or more assets
     * @type {Array<number>}
     * @memberof SigmaApiInternalListDetections
     */
    readonly asset?: Array<number>;

    /**
     * Filter detections to a general detection category
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly callType?: string;

    /**
     * Filter detections to imaging time
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectedAfter?: string;

    /**
     * Filter detections to imaging time
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectedBefore?: string;

    /**
     * Filter findings by detection class (hidden, detection, alert)
     * @type {Array<'alert' | 'detection' | 'hidden'>}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectionClass?: Array<'alert' | 'detection' | 'hidden'>;

    /**
     * Filter detections to one or more detection_types
     * @type {Array<string>}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectionType?: Array<string>;

    /**
     * Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly dist?: number;

    /**
     * include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
     * @type {'all' | 'none'}
     * @memberof SigmaApiInternalListDetections
     */
    readonly includeFiles?: 'all' | 'none';

    /**
     * Filter detections based on their intensity
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly intensityMax?: number;

    /**
     * Filter detections based on their intensity
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly intensityMin?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly ordering?: string;

    /**
     * Point represented in **x,y** format. Represents **point** in **Distance to point filter**
     * @type {Array<number>}
     * @memberof SigmaApiInternalListDetections
     */
    readonly point?: Array<number>;

    /**
     * Filter detections by review status (e.g., no review needed, needs review, reviewed)
     * @type {Array<0 | 1 | 2>}
     * @memberof SigmaApiInternalListDetections
     */
    readonly reviewStatus?: Array<0 | 1 | 2>;
}

/**
 * Request parameters for internalListGroundStationPasses operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListGroundStationPassesRequest
 */
export interface SigmaApiInternalListGroundStationPassesRequest {
    /**
     * This filter can be used to retrieve active GroundStationPasses.
     * @type {boolean}
     * @memberof SigmaApiInternalListGroundStationPasses
     */
    readonly active?: boolean;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListGroundStationPasses
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListGroundStationPasses
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListGroundStationPasses
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListGroundStations operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListGroundStationsRequest
 */
export interface SigmaApiInternalListGroundStationsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListGroundStations
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListGroundStations
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListGroundStations
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListOrbitRequest
 */
export interface SigmaApiInternalListOrbitRequest {
    /**
     * The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * This filter can be used to retrieve Ticks whose bounding box overlaps the passed in Tasking Requests\&#39; bounding box.
     * @type {Array<number>}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly bbOverlapsTaskingRequests?: Array<number>;

    /**
     * You can use this filter argument to retrieve Ticks that are blocked out and should be excluded / included from tasking depending on if you pass True / False (respectively) to this parameter.
     * @type {boolean}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly blockedOut?: boolean;

    /**
     * The pagination cursor value.
     * @type {string}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly cursor?: string;

    /**
     * Filter to areas where the nadir point on the earth\&#39;s surface is either night or day. Eclipsed &#x3D; Trueimplies you want night.
     * @type {boolean}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly eclipsed?: boolean;

    /**
     * This filter can be used to retrieve Ticks that intersect the passed in Tasking Requests.
     * @type {Array<number>}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly intersectsTaskingRequests?: Array<number>;

    /**
     * Grab Ticks by it\&#39;s Orbit Number. An Orbit is defined as starting and ending where the satellite transitions from not eclipsed to eclipsed. The Orbit should always start in an eclipse except for the first orbit (it can, but it\&#39;s not guaranteed). Likewise, the Orbit will always end not eclipsed.
     * @type {number}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly orbitNum?: number;

    /**
     * Grab Ticks where orbit_num is less than orbit_num_end. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
     * @type {number}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly orbitNumEnd?: number;

    /**
     * Grab Ticks where orbit_num is greater than or equal to the orbit_num_start. See the \&#39;orbit_num\&#39; NumberFilter for how an Orbit is defined.
     * @type {number}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly orbitNumStart?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly ordering?: string;

    /**
     * Filter to tick records with specific row_num values. The sensor_id / row_num field combination can be thought of as a unique index. It\&#39;s not truly though due to the propagator_seed_time field.
     * @type {Array<number>}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly rowNum?: Array<number>;

    /**
     * The time for the end of the search. Limits opportunities to those that start before the end time.
     * @type {string}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly searchTimeEnd?: string;

    /**
     * The time for the start of the search. Limits opportunities to those that end after the start time.
     * @type {string}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly searchTimeStart?: string;

    /**
     * The sensor to search for. Limits opportunities to specific sensor(s).
     * @type {number}
     * @memberof SigmaApiInternalListOrbit
     */
    readonly sensor?: number;
}

/**
 * Request parameters for internalListOrderStatuses operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListOrderStatusesRequest
 */
export interface SigmaApiInternalListOrderStatusesRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListOrderStatuses
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListOrderStatuses
     */
    readonly offset?: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalListOrderStatuses
     */
    readonly order?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListOrderStatuses
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListOrders operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListOrdersRequest
 */
export interface SigmaApiInternalListOrdersRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListOrders
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListOrders
     */
    readonly offset?: number;

    /**
     * Filter orders by if it is an Archive Request or Tasking Request
     * @type {string}
     * @memberof SigmaApiInternalListOrders
     */
    readonly orderType?: string;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListOrders
     */
    readonly ordering?: string;

    /**
     * Filter orders by the user that placed the order request
     * @type {number}
     * @memberof SigmaApiInternalListOrders
     */
    readonly requestedBy?: number;
}

/**
 * Request parameters for internalListReports operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListReportsRequest
 */
export interface SigmaApiInternalListReportsRequest {
    /**
     * The unique id for this report
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly externalId?: string;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListReports
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListReports
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly ordering?: string;

    /**
     * The time for the end of the search. Limits reports to those covering a period starting before the end time.
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly searchTimeEnd?: string;

    /**
     * The time for the start of the search. Limits reports to those covering a period ending after the start time.
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly searchTimeStart?: string;
}

/**
 * Request parameters for internalListSensorSchedules operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListSensorSchedulesRequest
 */
export interface SigmaApiInternalListSensorSchedulesRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListSensorSchedules
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListSensorSchedules
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListSensorSchedules
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListSensorTasks operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListSensorTasksRequest
 */
export interface SigmaApiInternalListSensorTasksRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListSensorTasks
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListSensorTasks
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListSensorTasks
     */
    readonly ordering?: string;

    /**
     * This filter will allow you to specify one or many sensor schedule ids to retrieve SensorTasks with the corresponding SensorSchedule id.
     * @type {Array<number>}
     * @memberof SigmaApiInternalListSensorTasks
     */
    readonly sensorScheduleIds?: Array<number>;
}

/**
 * Request parameters for internalListSensors operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListSensorsRequest
 */
export interface SigmaApiInternalListSensorsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListSensors
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListSensors
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListSensors
     */
    readonly ordering?: string;

    /**
     * This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
     * @type {boolean}
     * @memberof SigmaApiInternalListSensors
     */
    readonly taskingEligible?: boolean;
}

/**
 * Request parameters for internalListStateVectors operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListStateVectorsRequest
 */
export interface SigmaApiInternalListStateVectorsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly sensorPk: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListTaskingBlockOutWindows operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListTaskingBlockOutWindowsRequest
 */
export interface SigmaApiInternalListTaskingBlockOutWindowsRequest {
    /**
     * This filter can be used to retrieve active TaskingBlockOutWindows.
     * @type {boolean}
     * @memberof SigmaApiInternalListTaskingBlockOutWindows
     */
    readonly active?: boolean;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListTaskingBlockOutWindows
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListTaskingBlockOutWindows
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListTaskingBlockOutWindows
     */
    readonly ordering?: string;

    /**
     * The sensor to search for. Limits TaskingBlockOutWindows to specific sensor(s).
     * @type {number}
     * @memberof SigmaApiInternalListTaskingBlockOutWindows
     */
    readonly sensor?: number;
}

/**
 * Request parameters for internalListTaskingRequests operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListTaskingRequestsRequest
 */
export interface SigmaApiInternalListTaskingRequestsRequest {
    /**
     * This filter can be used to retrieve TaskingRequests that are active or inactive.
     * @type {boolean}
     * @memberof SigmaApiInternalListTaskingRequests
     */
    readonly active?: boolean;

    /**
     * This filter will let you specify 1 or many ids of the tasking requests you specifically want to retrieve.
     * @type {Array<number>}
     * @memberof SigmaApiInternalListTaskingRequests
     */
    readonly ids?: Array<number>;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListTaskingRequests
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListTaskingRequests
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListTaskingRequests
     */
    readonly ordering?: string;

    /**
     *
     * @type {Array<number>}
     * @memberof SigmaApiInternalListTaskingRequests
     */
    readonly sensors?: Array<number>;
}

/**
 * Request parameters for internalListTaskingSchedules operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListTaskingSchedulesRequest
 */
export interface SigmaApiInternalListTaskingSchedulesRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListTaskingSchedules
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListTaskingSchedules
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListTaskingSchedules
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListTles operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListTlesRequest
 */
export interface SigmaApiInternalListTlesRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalListTles
     */
    readonly sensorPk: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListTles
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListTles
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListTles
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalPartialUpdateAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateAoiRequest
 */
export interface SigmaApiInternalPartialUpdateAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateAoi
     */
    readonly id: number;

    /**
     *
     * @type {PatchedProgramAOIRequest}
     * @memberof SigmaApiInternalPartialUpdateAoi
     */
    readonly patchedProgramAOIRequest?: PatchedProgramAOIRequest;
}

/**
 * Request parameters for internalPartialUpdateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateAssetRequest
 */
export interface SigmaApiInternalPartialUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalAssetRequest}
     * @memberof SigmaApiInternalPartialUpdateAsset
     */
    readonly patchedInternalAssetRequest?: PatchedInternalAssetRequest;
}

/**
 * Request parameters for internalPartialUpdateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateDetectionRequest
 */
export interface SigmaApiInternalPartialUpdateDetectionRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateDetection
     */
    readonly pkId: number;

    /**
     *
     * @type {PatchedInternalDetectionRequest}
     * @memberof SigmaApiInternalPartialUpdateDetection
     */
    readonly patchedInternalDetectionRequest?: PatchedInternalDetectionRequest;
}

/**
 * Request parameters for internalPartialUpdateDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateDetectionArtifactRequest
 */
export interface SigmaApiInternalPartialUpdateDetectionArtifactRequest {
    /**
     * A unique integer value identifying this detection artifact.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateDetectionArtifact
     */
    readonly id: number;

    /**
     *
     * @type {PatchedDetectionArtifactRequest}
     * @memberof SigmaApiInternalPartialUpdateDetectionArtifact
     */
    readonly patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest;
}

/**
 * Request parameters for internalPartialUpdateOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateOrbitRequest
 */
export interface SigmaApiInternalPartialUpdateOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateOrbit
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalOpportunityRequest}
     * @memberof SigmaApiInternalPartialUpdateOrbit
     */
    readonly patchedInternalOpportunityRequest?: PatchedInternalOpportunityRequest;
}

/**
 * Request parameters for internalPartialUpdateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateReportRequest
 */
export interface SigmaApiInternalPartialUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalReportRequest}
     * @memberof SigmaApiInternalPartialUpdateReport
     */
    readonly patchedInternalReportRequest?: PatchedInternalReportRequest;
}

/**
 * Request parameters for internalPartialUpdateSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateSensorRequest
 */
export interface SigmaApiInternalPartialUpdateSensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateSensor
     */
    readonly oskId: number;

    /**
     *
     * @type {PatchedInternalSensorRequest}
     * @memberof SigmaApiInternalPartialUpdateSensor
     */
    readonly patchedInternalSensorRequest?: PatchedInternalSensorRequest;
}

/**
 * Request parameters for internalPartialUpdateSensorSchedule operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateSensorScheduleRequest
 */
export interface SigmaApiInternalPartialUpdateSensorScheduleRequest {
    /**
     * A unique integer value identifying this sensor schedule.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateSensorSchedule
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalSensorScheduleRequest}
     * @memberof SigmaApiInternalPartialUpdateSensorSchedule
     */
    readonly patchedInternalSensorScheduleRequest?: PatchedInternalSensorScheduleRequest;
}

/**
 * Request parameters for internalPartialUpdateSensorTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateSensorTaskRequest
 */
export interface SigmaApiInternalPartialUpdateSensorTaskRequest {
    /**
     * A unique integer value identifying this sensor task.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateSensorTask
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalUpdateSensorTaskRequest}
     * @memberof SigmaApiInternalPartialUpdateSensorTask
     */
    readonly patchedInternalUpdateSensorTaskRequest?: PatchedInternalUpdateSensorTaskRequest;
}

/**
 * Request parameters for internalPartialUpdateStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateStateVectorRequest
 */
export interface SigmaApiInternalPartialUpdateStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateStateVector
     */
    readonly sensorPk: number;

    /**
     *
     * @type {PatchedStateVectorRequest}
     * @memberof SigmaApiInternalPartialUpdateStateVector
     */
    readonly patchedStateVectorRequest?: PatchedStateVectorRequest;
}

/**
 * Request parameters for internalPartialUpdateTaskingRequest operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateTaskingRequestRequest
 */
export interface SigmaApiInternalPartialUpdateTaskingRequestRequest {
    /**
     * A unique integer value identifying this tasking request.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateTaskingRequest
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalTaskingRequestRequest}
     * @memberof SigmaApiInternalPartialUpdateTaskingRequest
     */
    readonly patchedInternalTaskingRequestRequest?: PatchedInternalTaskingRequestRequest;
}

/**
 * Request parameters for internalPartialUpdateTaskingSchedule operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateTaskingScheduleRequest
 */
export interface SigmaApiInternalPartialUpdateTaskingScheduleRequest {
    /**
     * A unique integer value identifying this tasking schedule.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateTaskingSchedule
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalTaskingScheduleRequest}
     * @memberof SigmaApiInternalPartialUpdateTaskingSchedule
     */
    readonly patchedInternalTaskingScheduleRequest?: PatchedInternalTaskingScheduleRequest;
}

/**
 * Request parameters for internalUpdateAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateAoiRequest
 */
export interface SigmaApiInternalUpdateAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalUpdateAoi
     */
    readonly id: number;

    /**
     *
     * @type {ProgramAOIRequest}
     * @memberof SigmaApiInternalUpdateAoi
     */
    readonly programAOIRequest: ProgramAOIRequest;
}

/**
 * Request parameters for internalUpdateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateAssetRequest
 */
export interface SigmaApiInternalUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {InternalAssetRequest}
     * @memberof SigmaApiInternalUpdateAsset
     */
    readonly internalAssetRequest: InternalAssetRequest;
}

/**
 * Request parameters for internalUpdateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateDetectionRequest
 */
export interface SigmaApiInternalUpdateDetectionRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalUpdateDetection
     */
    readonly pkId: number;

    /**
     *
     * @type {InternalDetectionRequest}
     * @memberof SigmaApiInternalUpdateDetection
     */
    readonly internalDetectionRequest: InternalDetectionRequest;
}

/**
 * Request parameters for internalUpdateDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateDetectionArtifactRequest
 */
export interface SigmaApiInternalUpdateDetectionArtifactRequest {
    /**
     * A unique integer value identifying this detection artifact.
     * @type {number}
     * @memberof SigmaApiInternalUpdateDetectionArtifact
     */
    readonly id: number;

    /**
     *
     * @type {DetectionArtifactRequest}
     * @memberof SigmaApiInternalUpdateDetectionArtifact
     */
    readonly detectionArtifactRequest: DetectionArtifactRequest;
}

/**
 * Request parameters for internalUpdateOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateOrbitRequest
 */
export interface SigmaApiInternalUpdateOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalUpdateOrbit
     */
    readonly id: number;

    /**
     *
     * @type {InternalOpportunityRequest}
     * @memberof SigmaApiInternalUpdateOrbit
     */
    readonly internalOpportunityRequest: InternalOpportunityRequest;
}

/**
 * Request parameters for internalUpdateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateReportRequest
 */
export interface SigmaApiInternalUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {InternalReportRequest}
     * @memberof SigmaApiInternalUpdateReport
     */
    readonly internalReportRequest: InternalReportRequest;
}

/**
 * Request parameters for internalUpdateSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateSensorRequest
 */
export interface SigmaApiInternalUpdateSensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiInternalUpdateSensor
     */
    readonly oskId: number;

    /**
     *
     * @type {InternalSensorRequest}
     * @memberof SigmaApiInternalUpdateSensor
     */
    readonly internalSensorRequest: InternalSensorRequest;
}

/**
 * Request parameters for internalUpdateSensorSchedule operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateSensorScheduleRequest
 */
export interface SigmaApiInternalUpdateSensorScheduleRequest {
    /**
     * A unique integer value identifying this sensor schedule.
     * @type {number}
     * @memberof SigmaApiInternalUpdateSensorSchedule
     */
    readonly id: number;

    /**
     *
     * @type {InternalSensorScheduleRequest}
     * @memberof SigmaApiInternalUpdateSensorSchedule
     */
    readonly internalSensorScheduleRequest?: InternalSensorScheduleRequest;
}

/**
 * Request parameters for internalUpdateSensorTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateSensorTaskRequest
 */
export interface SigmaApiInternalUpdateSensorTaskRequest {
    /**
     * A unique integer value identifying this sensor task.
     * @type {number}
     * @memberof SigmaApiInternalUpdateSensorTask
     */
    readonly id: number;

    /**
     *
     * @type {InternalUpdateSensorTaskRequest}
     * @memberof SigmaApiInternalUpdateSensorTask
     */
    readonly internalUpdateSensorTaskRequest?: InternalUpdateSensorTaskRequest;
}

/**
 * Request parameters for internalUpdateStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateStateVectorRequest
 */
export interface SigmaApiInternalUpdateStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalUpdateStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalUpdateStateVector
     */
    readonly sensorPk: number;

    /**
     *
     * @type {StateVectorRequest}
     * @memberof SigmaApiInternalUpdateStateVector
     */
    readonly stateVectorRequest: StateVectorRequest;
}

/**
 * Request parameters for internalUpdateTaskingRequest operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateTaskingRequestRequest
 */
export interface SigmaApiInternalUpdateTaskingRequestRequest {
    /**
     * A unique integer value identifying this tasking request.
     * @type {number}
     * @memberof SigmaApiInternalUpdateTaskingRequest
     */
    readonly id: number;

    /**
     *
     * @type {InternalTaskingRequestUpdateRequest}
     * @memberof SigmaApiInternalUpdateTaskingRequest
     */
    readonly internalTaskingRequestUpdateRequest?: InternalTaskingRequestUpdateRequest;
}

/**
 * Request parameters for internalUpdateTaskingSchedule operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateTaskingScheduleRequest
 */
export interface SigmaApiInternalUpdateTaskingScheduleRequest {
    /**
     * A unique integer value identifying this tasking schedule.
     * @type {number}
     * @memberof SigmaApiInternalUpdateTaskingSchedule
     */
    readonly id: number;

    /**
     *
     * @type {InternalTaskingScheduleRequest}
     * @memberof SigmaApiInternalUpdateTaskingSchedule
     */
    readonly internalTaskingScheduleRequest?: InternalTaskingScheduleRequest;
}

/**
 * Request parameters for internalUpdateTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateTleRequest
 */
export interface SigmaApiInternalUpdateTleRequest {
    /**
     * A unique integer value identifying this tle.
     * @type {number}
     * @memberof SigmaApiInternalUpdateTle
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalUpdateTle
     */
    readonly sensorPk: number;

    /**
     *
     * @type {TLERequest}
     * @memberof SigmaApiInternalUpdateTle
     */
    readonly tLERequest: TLERequest;
}

/**
 * Request parameters for listAssets operation in SigmaApi.
 * @export
 * @interface SigmaApiListAssetsRequest
 */
export interface SigmaApiListAssetsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListAssets
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListAssets
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListAssets
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListAssets
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listCaptureArtifacts operation in SigmaApi.
 * @export
 * @interface SigmaApiListCaptureArtifactsRequest
 */
export interface SigmaApiListCaptureArtifactsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListCaptureArtifacts
     */
    readonly program: number;

    /**
     * Filter captures by the capture file_ids provided
     * @type {Array<string>}
     * @memberof SigmaApiListCaptureArtifacts
     */
    readonly fileId?: Array<string>;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListCaptureArtifacts
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListCaptureArtifacts
     */
    readonly offset?: number;
}

/**
 * Request parameters for listCaptures operation in SigmaApi.
 * @export
 * @interface SigmaApiListCapturesRequest
 */
export interface SigmaApiListCapturesRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly program: number;

    /**
     * Filter captures by area of interest
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiListCaptures
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Filter captures by named area of interest
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly aoiName?: string;

    /**
     * Filter captures on acquisition time by date range
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly capturedAfter?: string;

    /**
     * Filter captures on acquisition time by date range
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly capturedBefore?: string;

    /**
     * Filter captures by cloud cover percentage
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly cloudCoverMax?: number;

    /**
     * Filter captures by cloud cover percentage
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly cloudCoverMin?: number;

    /**
     * Filter images to those matching one or more file ids. A file ID uniquely identifies a image captured by a sensor
     * @type {Array<string>}
     * @memberof SigmaApiListCaptures
     */
    readonly fileId?: Array<string>;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly offset?: number;

    /**
     * Filter captures by the order ID.
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly orderId?: string;

    /**
     * Filter captures on date range of when they were processed
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly processedAfter?: string;

    /**
     * Filter captures on date range of when they were processed
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly processedBefore?: string;

    /**
     * Filter captures by sensor id
     * @type {Array<number>}
     * @memberof SigmaApiListCaptures
     */
    readonly sensor?: Array<number>;

    /**
     * Filter images to those matching one or more task ids. Images are part of the same task if they were taken sequentially during a single imaging cycle.
     * @type {Array<string>}
     * @memberof SigmaApiListCaptures
     */
    readonly taskId?: Array<string>;
}

/**
 * Request parameters for listDetections operation in SigmaApi.
 * @export
 * @interface SigmaApiListDetectionsRequest
 */
export interface SigmaApiListDetectionsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly program: number;

    /**
     * Filter detections to a specific area of interest (AOI) (accepts GeoGJSON and WKT)
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiListDetections
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Filter detections based on their area in square meters
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly areaMax?: number;

    /**
     * Filter detections based on their area in square meters
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly areaMin?: number;

    /**
     * Filter detections to one or more assets
     * @type {Array<number>}
     * @memberof SigmaApiListDetections
     */
    readonly asset?: Array<number>;

    /**
     * Filter detections to a general detection category
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly callType?: string;

    /**
     * Filter detections to imaging time
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly detectedAfter?: string;

    /**
     * Filter detections to imaging time
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly detectedBefore?: string;

    /**
     * Filter findings by detection class (detection, alert)
     * @type {Array<'alert' | 'detection' | 'hidden'>}
     * @memberof SigmaApiListDetections
     */
    readonly detectionClass?: Array<'alert' | 'detection' | 'hidden'>;

    /**
     * Filter detections to one or more detection_types
     * @type {Array<string>}
     * @memberof SigmaApiListDetections
     */
    readonly detectionType?: Array<string>;

    /**
     * Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly dist?: number;

    /**
     * Filter findings by feedback status
     * @type {Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>}
     * @memberof SigmaApiListDetections
     */
    readonly feedbackStatus?: Array<'Resolved - Accepted' | 'Resolved - Rejected' | 'Unresolved' | 'Unreviewed'>;

    /**
     * include \&#39;all\&#39; or \&#39;none\&#39; of the files associated with this detection (default: all)
     * @type {'all' | 'none'}
     * @memberof SigmaApiListDetections
     */
    readonly includeFiles?: 'all' | 'none';

    /**
     * Filter detections based on their intensity
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly intensityMax?: number;

    /**
     * Filter detections based on their intensity
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly intensityMin?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly ordering?: string;

    /**
     * Point represented in **x,y** format. Represents **point** in **Distance to point filter**
     * @type {Array<number>}
     * @memberof SigmaApiListDetections
     */
    readonly point?: Array<number>;
}

/**
 * Request parameters for listOrders operation in SigmaApi.
 * @export
 * @interface SigmaApiListOrdersRequest
 */
export interface SigmaApiListOrdersRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListOrders
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListOrders
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListOrders
     */
    readonly offset?: number;

    /**
     * Filter orders by if it is an Archive Request or Tasking Request
     * @type {string}
     * @memberof SigmaApiListOrders
     */
    readonly orderType?: string;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListOrders
     */
    readonly ordering?: string;

    /**
     * Filter orders by the user that placed the order request
     * @type {number}
     * @memberof SigmaApiListOrders
     */
    readonly requestedBy?: number;
}

/**
 * Request parameters for listReports operation in SigmaApi.
 * @export
 * @interface SigmaApiListReportsRequest
 */
export interface SigmaApiListReportsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListReports
     */
    readonly program: number;

    /**
     * The unique id for this report
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly externalId?: string;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListReports
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListReports
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly ordering?: string;

    /**
     * The time for the end of the search. Limits reports to those covering a period starting before the end time.
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly searchTimeEnd?: string;

    /**
     * The time for the start of the search. Limits reports to those covering a period ending after the start time.
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly searchTimeStart?: string;
}

/**
 * Request parameters for listSearchAreas operation in SigmaApi.
 * @export
 * @interface SigmaApiListSearchAreasRequest
 */
export interface SigmaApiListSearchAreasRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListSearchAreas
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListSearchAreas
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListSearchAreas
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listSensors operation in SigmaApi.
 * @export
 * @interface SigmaApiListSensorsRequest
 */
export interface SigmaApiListSensorsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListSensors
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListSensors
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListSensors
     */
    readonly ordering?: string;

    /**
     * This filter will allow you to either retrieve ONLY tasking eligible sensors or ONLY non-tasking eligible sensors
     * @type {boolean}
     * @memberof SigmaApiListSensors
     */
    readonly taskingEligible?: boolean;
}

/**
 * Request parameters for partialUpdateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiPartialUpdateAssetRequest
 */
export interface SigmaApiPartialUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiPartialUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiPartialUpdateAsset
     */
    readonly program: number;

    /**
     *
     * @type {PatchedAssetRequest}
     * @memberof SigmaApiPartialUpdateAsset
     */
    readonly patchedAssetRequest?: PatchedAssetRequest;
}

/**
 * Request parameters for partialUpdateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiPartialUpdateDetectionRequest
 */
export interface SigmaApiPartialUpdateDetectionRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiPartialUpdateDetection
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiPartialUpdateDetection
     */
    readonly program: number;

    /**
     *
     * @type {PatchedDetectionRequest}
     * @memberof SigmaApiPartialUpdateDetection
     */
    readonly patchedDetectionRequest?: PatchedDetectionRequest;
}

/**
 * Request parameters for partialUpdateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiPartialUpdateReportRequest
 */
export interface SigmaApiPartialUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiPartialUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiPartialUpdateReport
     */
    readonly program: number;

    /**
     *
     * @type {PatchedReportRequest}
     * @memberof SigmaApiPartialUpdateReport
     */
    readonly patchedReportRequest?: PatchedReportRequest;
}

/**
 * Request parameters for partialUpdateSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiPartialUpdateSearchAreaRequest
 */
export interface SigmaApiPartialUpdateSearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiPartialUpdateSearchArea
     */
    readonly id: number;

    /**
     *
     * @type {PatchedSearchAreaRequest}
     * @memberof SigmaApiPartialUpdateSearchArea
     */
    readonly patchedSearchAreaRequest?: PatchedSearchAreaRequest;
}

/**
 * Request parameters for publicGetDataProduct operation in SigmaApi.
 * @export
 * @interface SigmaApiPublicGetDataProductRequest
 */
export interface SigmaApiPublicGetDataProductRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiPublicGetDataProduct
     */
    readonly id: number;
}

/**
 * Request parameters for publicListDataProducts operation in SigmaApi.
 * @export
 * @interface SigmaApiPublicListDataProductsRequest
 */
export interface SigmaApiPublicListDataProductsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiPublicListDataProducts
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiPublicListDataProducts
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiPublicListDataProducts
     */
    readonly ordering?: string;
}

/**
 * Request parameters for searchOrbits operation in SigmaApi.
 * @export
 * @interface SigmaApiSearchOrbitsRequest
 */
export interface SigmaApiSearchOrbitsRequest {
    /**
     * The area of interest (AOI) to search (accepts GeoJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiSearchOrbits
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Limit to satellite positions over the night side (true) or day side (false) of Earth
     * @type {boolean}
     * @memberof SigmaApiSearchOrbits
     */
    readonly eclipsed?: boolean;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiSearchOrbits
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiSearchOrbits
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiSearchOrbits
     */
    readonly ordering?: string;

    /**
     * The time for the end of the search. Limits opportunities to those that start before the end time.
     * @type {string}
     * @memberof SigmaApiSearchOrbits
     */
    readonly searchTimeEnd?: string;

    /**
     * The time for the start of the search. Limits opportunities to those that end after the start time.
     * @type {string}
     * @memberof SigmaApiSearchOrbits
     */
    readonly searchTimeStart?: string;

    /**
     * The sensor to search for. Limits opportunities to specific sensor(s).
     * @type {number}
     * @memberof SigmaApiSearchOrbits
     */
    readonly sensor?: number;
}

/**
 * Request parameters for updateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiUpdateAssetRequest
 */
export interface SigmaApiUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiUpdateAsset
     */
    readonly program: number;

    /**
     *
     * @type {AssetRequest}
     * @memberof SigmaApiUpdateAsset
     */
    readonly assetRequest?: AssetRequest;
}

/**
 * Request parameters for updateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiUpdateDetectionRequest
 */
export interface SigmaApiUpdateDetectionRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiUpdateDetection
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiUpdateDetection
     */
    readonly program: number;

    /**
     *
     * @type {DetectionRequest}
     * @memberof SigmaApiUpdateDetection
     */
    readonly detectionRequest?: DetectionRequest;
}

/**
 * Request parameters for updateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiUpdateReportRequest
 */
export interface SigmaApiUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiUpdateReport
     */
    readonly program: number;

    /**
     *
     * @type {ReportRequest}
     * @memberof SigmaApiUpdateReport
     */
    readonly reportRequest: ReportRequest;
}

/**
 * Request parameters for updateSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiUpdateSearchAreaRequest
 */
export interface SigmaApiUpdateSearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiUpdateSearchArea
     */
    readonly id: number;

    /**
     *
     * @type {SearchAreaRequest}
     * @memberof SigmaApiUpdateSearchArea
     */
    readonly searchAreaRequest: SearchAreaRequest;
}

/**
 * SigmaApi - object-oriented interface
 * @export
 * @class SigmaApi
 * @extends {BaseAPI}
 */
export class SigmaApi extends BaseAPI {
    /**
     *
     * @param {SigmaApiCreateOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public createOrder(requestParameters: SigmaApiCreateOrderRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .createOrder(requestParameters.program, requestParameters.orderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiCreateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public createReport(requestParameters: SigmaApiCreateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .createReport(requestParameters.program, requestParameters.reportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiCreateSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public createSearchArea(requestParameters: SigmaApiCreateSearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .createSearchArea(requestParameters.searchAreaRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiDestroyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public destroyReport(requestParameters: SigmaApiDestroyReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .destroyReport(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiDestroySearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public destroySearchArea(requestParameters: SigmaApiDestroySearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .destroySearchArea(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiGetAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getAsset(requestParameters: SigmaApiGetAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getAsset(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline results, the final output of the data pipeline of a single file
     * @param {SigmaApiGetCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getCapture(requestParameters: SigmaApiGetCaptureRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getCapture(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline results, the final output of the data pipeline of a single file
     * @param {SigmaApiGetCaptureArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getCaptureArtifact(requestParameters: SigmaApiGetCaptureArtifactRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getCaptureArtifact(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a particular detection or finding
     * @param {SigmaApiGetDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getDetection(requestParameters: SigmaApiGetDetectionRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getDetection(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiGetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getOrder(requestParameters: SigmaApiGetOrderRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getOrder(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * get status for order matching order & program id
     * @param {SigmaApiGetOrderStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getOrderStatus(requestParameters: SigmaApiGetOrderStatusRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getOrderStatus(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all statues for this order
     * @param {SigmaApiGetOrderStatusHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getOrderStatusHistory(
        requestParameters: SigmaApiGetOrderStatusHistoryRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .getOrderStatusHistory(
                requestParameters.id,
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.orderType,
                requestParameters.ordering,
                requestParameters.requestedBy,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiGetReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getReport(requestParameters: SigmaApiGetReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getReport(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiGetSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getSearchArea(requestParameters: SigmaApiGetSearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getSearchArea(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiGetSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getSensor(requestParameters: SigmaApiGetSensorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getSensor(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method will return the most recently saved StateVector for sensor_id=pk
     * @param {SigmaApiGetStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getStateVector(requestParameters: SigmaApiGetStateVectorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getStateVector(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method will return the most recently saved TLE for sensor_id=pk
     * @param {SigmaApiGetTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getTle(requestParameters: SigmaApiGetTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getTle(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateAoi(requestParameters: SigmaApiInternalCreateAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateAoi(requestParameters.programAOIRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateAsset(requestParameters: SigmaApiInternalCreateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateAsset(requestParameters.internalAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateDetection(
        requestParameters: SigmaApiInternalCreateDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateDetection(requestParameters.internalDetectionRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateDetectionArtifact(
        requestParameters: SigmaApiInternalCreateDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateDetectionArtifact(requestParameters.detectionArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
     * @param {SigmaApiInternalCreateGroundStationPasseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateGroundStationPasse(
        requestParameters: SigmaApiInternalCreateGroundStationPasseRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateGroundStationPasse(requestParameters.internalGroundStationPassRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for finding opportunities to image using OSK satellites
     * @param {SigmaApiInternalCreateOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateOrbit(requestParameters: SigmaApiInternalCreateOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateOrbit(requestParameters.internalOpportunityRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateOrder(requestParameters: SigmaApiInternalCreateOrderRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateOrder(requestParameters.internalOrderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * For internal Order Status API, provide create, read, and list operations (no update or delete)
     * @param {SigmaApiInternalCreateOrderStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateOrderStatus(
        requestParameters: SigmaApiInternalCreateOrderStatusRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateOrderStatus(requestParameters.internalOrderStatusRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateReport(requestParameters: SigmaApiInternalCreateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateReport(requestParameters.internalReportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalCreateSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateSensor(requestParameters: SigmaApiInternalCreateSensorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateSensor(requestParameters.internalSensorRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalCreateSensorTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateSensorTask(
        requestParameters: SigmaApiInternalCreateSensorTaskRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateSensorTask(requestParameters.internalCreateSensorTaskRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateStateVector(
        requestParameters: SigmaApiInternalCreateStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateStateVector(requestParameters.sensorPk, requestParameters.stateVectorRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
     * @param {SigmaApiInternalCreateTaskingBlockOutWindowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateTaskingBlockOutWindow(
        requestParameters: SigmaApiInternalCreateTaskingBlockOutWindowRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateTaskingBlockOutWindow(requestParameters.internalTaskingBlockOutWindowRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
     * @param {SigmaApiInternalCreateTaskingRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateTaskingRequest(
        requestParameters: SigmaApiInternalCreateTaskingRequestRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateTaskingRequest(requestParameters.internalTaskingRequestRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalCreateTaskingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateTaskingSchedule(
        requestParameters: SigmaApiInternalCreateTaskingScheduleRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateTaskingSchedule(requestParameters.internalTaskingScheduleRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateTle(requestParameters: SigmaApiInternalCreateTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateTle(requestParameters.sensorPk, requestParameters.tLERequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyAoi(requestParameters: SigmaApiInternalDestroyAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyAoi(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyAsset(requestParameters: SigmaApiInternalDestroyAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyAsset(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyDetection(
        requestParameters: SigmaApiInternalDestroyDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyDetection(requestParameters.pkId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyDetectionArtifact(
        requestParameters: SigmaApiInternalDestroyDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyDetectionArtifact(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for finding opportunities to image using OSK satellites
     * @param {SigmaApiInternalDestroyOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyOrbit(requestParameters: SigmaApiInternalDestroyOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyOrbit(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyOrder(requestParameters: SigmaApiInternalDestroyOrderRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyOrder(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyReport(
        requestParameters: SigmaApiInternalDestroyReportRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyReport(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalDestroySensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroySensor(
        requestParameters: SigmaApiInternalDestroySensorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroySensor(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyStateVector(
        requestParameters: SigmaApiInternalDestroyStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyStateVector(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyTle(requestParameters: SigmaApiInternalDestroyTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyTle(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetAoi(requestParameters: SigmaApiInternalGetAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetAoi(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetAsset(requestParameters: SigmaApiInternalGetAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetAsset(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information on an OSK-supported data product
     * @param {SigmaApiInternalGetDataProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetDataProduct(
        requestParameters: SigmaApiInternalGetDataProductRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetDataProduct(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetDetection(requestParameters: SigmaApiInternalGetDetectionRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetDetection(requestParameters.pkId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single elevation point for a given latitude and longitude.
     * @param {SigmaApiInternalGetElevationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetElevation(
        requestParameters: SigmaApiInternalGetElevationRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetElevation(requestParameters.lat, requestParameters.lng, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the min/max/mean/median elevation within a given area.  The bounds should a stringified geojson object representing a geometry with 4 or more points. This endpoint will then compute the summary information within a simplified rectangle based on your input area.  The results will be provided at maximum possible resolution. For convenience, the dataset which sourced the information is returned along with the summary details.  Usage example:  ``` import json  sigma_api.internal_get_elevation_summary(bounds=json.dumps(footprint)) ```
     * @param {SigmaApiInternalGetElevationSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetElevationSummary(
        requestParameters: SigmaApiInternalGetElevationSummaryRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetElevationSummary(requestParameters.bounds, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Readonly ViewSet to retrieve & list GroundStation objects.
     * @param {SigmaApiInternalGetGroundStationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetGroundStation(
        requestParameters: SigmaApiInternalGetGroundStationRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetGroundStation(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
     * @param {SigmaApiInternalGetGroundStationPasseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetGroundStationPasse(
        requestParameters: SigmaApiInternalGetGroundStationPasseRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetGroundStationPasse(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for finding opportunities to image using OSK satellites
     * @param {SigmaApiInternalGetOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetOrbit(requestParameters: SigmaApiInternalGetOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetOrbit(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetOrder(requestParameters: SigmaApiInternalGetOrderRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetOrder(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * For internal Order Status API, provide create, read, and list operations (no update or delete)
     * @param {SigmaApiInternalGetOrderStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetOrderStatus(
        requestParameters: SigmaApiInternalGetOrderStatusRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetOrderStatus(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetReport(requestParameters: SigmaApiInternalGetReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetReport(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalGetSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetSensor(requestParameters: SigmaApiInternalGetSensorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetSensor(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
     * @param {SigmaApiInternalGetSensorScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetSensorSchedule(
        requestParameters: SigmaApiInternalGetSensorScheduleRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetSensorSchedule(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalGetSensorTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetSensorTask(
        requestParameters: SigmaApiInternalGetSensorTaskRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetSensorTask(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetStateVector(
        requestParameters: SigmaApiInternalGetStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetStateVector(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
     * @param {SigmaApiInternalGetTaskingBlockOutWindowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetTaskingBlockOutWindow(
        requestParameters: SigmaApiInternalGetTaskingBlockOutWindowRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetTaskingBlockOutWindow(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
     * @param {SigmaApiInternalGetTaskingRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetTaskingRequest(
        requestParameters: SigmaApiInternalGetTaskingRequestRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetTaskingRequest(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalGetTaskingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetTaskingSchedule(
        requestParameters: SigmaApiInternalGetTaskingScheduleRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetTaskingSchedule(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetTle(requestParameters: SigmaApiInternalGetTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetTle(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListAoisRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListAois(requestParameters: SigmaApiInternalListAoisRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListAois(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListAssets(requestParameters: SigmaApiInternalListAssetsRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListAssets(
                requestParameters.dist,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.point,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * List OSK-supported data products
     * @param {SigmaApiInternalListDataProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListDataProducts(
        requestParameters: SigmaApiInternalListDataProductsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListDataProducts(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListDetectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListDetections(
        requestParameters: SigmaApiInternalListDetectionsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListDetections(
                requestParameters.aoi,
                requestParameters.areaMax,
                requestParameters.areaMin,
                requestParameters.asset,
                requestParameters.callType,
                requestParameters.detectedAfter,
                requestParameters.detectedBefore,
                requestParameters.detectionClass,
                requestParameters.detectionType,
                requestParameters.dist,
                requestParameters.includeFiles,
                requestParameters.intensityMax,
                requestParameters.intensityMin,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.point,
                requestParameters.reviewStatus,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * ViewSet for the GroundStationPass object. The supported operations are CREATE, READ, and LIST.
     * @param {SigmaApiInternalListGroundStationPassesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListGroundStationPasses(
        requestParameters: SigmaApiInternalListGroundStationPassesRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListGroundStationPasses(
                requestParameters.active,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Readonly ViewSet to retrieve & list GroundStation objects.
     * @param {SigmaApiInternalListGroundStationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListGroundStations(
        requestParameters: SigmaApiInternalListGroundStationsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListGroundStations(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for finding opportunities to image using OSK satellites
     * @param {SigmaApiInternalListOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListOrbit(requestParameters: SigmaApiInternalListOrbitRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListOrbit(
                requestParameters.aoi,
                requestParameters.bbOverlapsTaskingRequests,
                requestParameters.blockedOut,
                requestParameters.cursor,
                requestParameters.eclipsed,
                requestParameters.intersectsTaskingRequests,
                requestParameters.orbitNum,
                requestParameters.orbitNumEnd,
                requestParameters.orbitNumStart,
                requestParameters.ordering,
                requestParameters.rowNum,
                requestParameters.searchTimeEnd,
                requestParameters.searchTimeStart,
                requestParameters.sensor,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * For internal Order Status API, provide create, read, and list operations (no update or delete)
     * @param {SigmaApiInternalListOrderStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListOrderStatuses(
        requestParameters: SigmaApiInternalListOrderStatusesRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListOrderStatuses(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.order,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListOrders(requestParameters: SigmaApiInternalListOrdersRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListOrders(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.orderType,
                requestParameters.ordering,
                requestParameters.requestedBy,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListReports(
        requestParameters: SigmaApiInternalListReportsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListReports(
                requestParameters.externalId,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.searchTimeEnd,
                requestParameters.searchTimeStart,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
     * @param {SigmaApiInternalListSensorSchedulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListSensorSchedules(
        requestParameters: SigmaApiInternalListSensorSchedulesRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListSensorSchedules(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalListSensorTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListSensorTasks(
        requestParameters: SigmaApiInternalListSensorTasksRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListSensorTasks(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.sensorScheduleIds,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalListSensorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListSensors(
        requestParameters: SigmaApiInternalListSensorsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListSensors(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.taskingEligible,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListStateVectorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListStateVectors(
        requestParameters: SigmaApiInternalListStateVectorsRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListStateVectors(
                requestParameters.sensorPk,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * ViewSet for the TaskingBlockOutWindow object. The supported operations are CREATE, READ, and LIST.
     * @param {SigmaApiInternalListTaskingBlockOutWindowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListTaskingBlockOutWindows(
        requestParameters: SigmaApiInternalListTaskingBlockOutWindowsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListTaskingBlockOutWindows(
                requestParameters.active,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.sensor,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
     * @param {SigmaApiInternalListTaskingRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListTaskingRequests(
        requestParameters: SigmaApiInternalListTaskingRequestsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListTaskingRequests(
                requestParameters.active,
                requestParameters.ids,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.sensors,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalListTaskingSchedulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListTaskingSchedules(
        requestParameters: SigmaApiInternalListTaskingSchedulesRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListTaskingSchedules(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListTlesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListTles(requestParameters: SigmaApiInternalListTlesRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListTles(
                requestParameters.sensorPk,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateAoi(
        requestParameters: SigmaApiInternalPartialUpdateAoiRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateAoi(requestParameters.id, requestParameters.patchedProgramAOIRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateAsset(
        requestParameters: SigmaApiInternalPartialUpdateAssetRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateAsset(requestParameters.id, requestParameters.patchedInternalAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateDetection(
        requestParameters: SigmaApiInternalPartialUpdateDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateDetection(
                requestParameters.pkId,
                requestParameters.patchedInternalDetectionRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateDetectionArtifact(
        requestParameters: SigmaApiInternalPartialUpdateDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateDetectionArtifact(
                requestParameters.id,
                requestParameters.patchedDetectionArtifactRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for finding opportunities to image using OSK satellites
     * @param {SigmaApiInternalPartialUpdateOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateOrbit(
        requestParameters: SigmaApiInternalPartialUpdateOrbitRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateOrbit(
                requestParameters.id,
                requestParameters.patchedInternalOpportunityRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateReport(
        requestParameters: SigmaApiInternalPartialUpdateReportRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateReport(requestParameters.id, requestParameters.patchedInternalReportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalPartialUpdateSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateSensor(
        requestParameters: SigmaApiInternalPartialUpdateSensorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateSensor(
                requestParameters.oskId,
                requestParameters.patchedInternalSensorRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
     * @param {SigmaApiInternalPartialUpdateSensorScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateSensorSchedule(
        requestParameters: SigmaApiInternalPartialUpdateSensorScheduleRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateSensorSchedule(
                requestParameters.id,
                requestParameters.patchedInternalSensorScheduleRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalPartialUpdateSensorTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateSensorTask(
        requestParameters: SigmaApiInternalPartialUpdateSensorTaskRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateSensorTask(
                requestParameters.id,
                requestParameters.patchedInternalUpdateSensorTaskRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateStateVector(
        requestParameters: SigmaApiInternalPartialUpdateStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateStateVector(
                requestParameters.id,
                requestParameters.sensorPk,
                requestParameters.patchedStateVectorRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
     * @param {SigmaApiInternalPartialUpdateTaskingRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateTaskingRequest(
        requestParameters: SigmaApiInternalPartialUpdateTaskingRequestRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateTaskingRequest(
                requestParameters.id,
                requestParameters.patchedInternalTaskingRequestRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalPartialUpdateTaskingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateTaskingSchedule(
        requestParameters: SigmaApiInternalPartialUpdateTaskingScheduleRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateTaskingSchedule(
                requestParameters.id,
                requestParameters.patchedInternalTaskingScheduleRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateAoi(requestParameters: SigmaApiInternalUpdateAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateAoi(requestParameters.id, requestParameters.programAOIRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateAsset(requestParameters: SigmaApiInternalUpdateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateAsset(requestParameters.id, requestParameters.internalAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateDetection(
        requestParameters: SigmaApiInternalUpdateDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateDetection(requestParameters.pkId, requestParameters.internalDetectionRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateDetectionArtifact(
        requestParameters: SigmaApiInternalUpdateDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateDetectionArtifact(requestParameters.id, requestParameters.detectionArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for finding opportunities to image using OSK satellites
     * @param {SigmaApiInternalUpdateOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateOrbit(requestParameters: SigmaApiInternalUpdateOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateOrbit(requestParameters.id, requestParameters.internalOpportunityRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateReport(requestParameters: SigmaApiInternalUpdateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateReport(requestParameters.id, requestParameters.internalReportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalUpdateSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateSensor(requestParameters: SigmaApiInternalUpdateSensorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateSensor(requestParameters.oskId, requestParameters.internalSensorRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Update, Read, and List.  This ViewSet should not support the Delete and Create operations. A SensorSchedule object should be created by a TaskingSchedule signal handler.
     * @param {SigmaApiInternalUpdateSensorScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateSensorSchedule(
        requestParameters: SigmaApiInternalUpdateSensorScheduleRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateSensorSchedule(
                requestParameters.id,
                requestParameters.internalSensorScheduleRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting SensorTask Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalUpdateSensorTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateSensorTask(
        requestParameters: SigmaApiInternalUpdateSensorTaskRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateSensorTask(requestParameters.id, requestParameters.internalUpdateSensorTaskRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateStateVector(
        requestParameters: SigmaApiInternalUpdateStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateStateVector(
                requestParameters.id,
                requestParameters.sensorPk,
                requestParameters.stateVectorRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingRequest Create, Read, List, and a limited Update operation.  This ViewSet should not support a Delete operation.  Once a TaskingRequest is created, it should essentially be considered \"locked-in\". The update operation should only allow editing if the \"is_cancelled\" field which essentially performs a logical delete of the record.
     * @param {SigmaApiInternalUpdateTaskingRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateTaskingRequest(
        requestParameters: SigmaApiInternalUpdateTaskingRequestRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateTaskingRequest(
                requestParameters.id,
                requestParameters.internalTaskingRequestUpdateRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal ViewSet supporting TaskingSchedule Create, Update, Read, and List.  This ViewSet should not support the Delete operation.
     * @param {SigmaApiInternalUpdateTaskingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateTaskingSchedule(
        requestParameters: SigmaApiInternalUpdateTaskingScheduleRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateTaskingSchedule(
                requestParameters.id,
                requestParameters.internalTaskingScheduleRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateTle(requestParameters: SigmaApiInternalUpdateTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateTle(requestParameters.id, requestParameters.sensorPk, requestParameters.tLERequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiListAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listAssets(requestParameters: SigmaApiListAssetsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listAssets(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline results, the final output of the data pipeline of a single file
     * @param {SigmaApiListCaptureArtifactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listCaptureArtifacts(requestParameters: SigmaApiListCaptureArtifactsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listCaptureArtifacts(
                requestParameters.program,
                requestParameters.fileId,
                requestParameters.limit,
                requestParameters.offset,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline results, the final output of the data pipeline of a single file
     * @param {SigmaApiListCapturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listCaptures(requestParameters: SigmaApiListCapturesRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listCaptures(
                requestParameters.program,
                requestParameters.aoi,
                requestParameters.aoiName,
                requestParameters.capturedAfter,
                requestParameters.capturedBefore,
                requestParameters.cloudCoverMax,
                requestParameters.cloudCoverMin,
                requestParameters.fileId,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.orderId,
                requestParameters.processedAfter,
                requestParameters.processedBefore,
                requestParameters.sensor,
                requestParameters.taskId,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * List detections
     * @param {SigmaApiListDetectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listDetections(requestParameters: SigmaApiListDetectionsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listDetections(
                requestParameters.program,
                requestParameters.aoi,
                requestParameters.areaMax,
                requestParameters.areaMin,
                requestParameters.asset,
                requestParameters.callType,
                requestParameters.detectedAfter,
                requestParameters.detectedBefore,
                requestParameters.detectionClass,
                requestParameters.detectionType,
                requestParameters.dist,
                requestParameters.feedbackStatus,
                requestParameters.includeFiles,
                requestParameters.intensityMax,
                requestParameters.intensityMin,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.point,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiListOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listOrders(requestParameters: SigmaApiListOrdersRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listOrders(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.orderType,
                requestParameters.ordering,
                requestParameters.requestedBy,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiListReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listReports(requestParameters: SigmaApiListReportsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listReports(
                requestParameters.program,
                requestParameters.externalId,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.searchTimeEnd,
                requestParameters.searchTimeStart,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiListSearchAreasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listSearchAreas(requestParameters: SigmaApiListSearchAreasRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listSearchAreas(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiListSensorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listSensors(requestParameters: SigmaApiListSensorsRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listSensors(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.taskingEligible,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiPartialUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public partialUpdateAsset(requestParameters: SigmaApiPartialUpdateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .partialUpdateAsset(
                requestParameters.id,
                requestParameters.program,
                requestParameters.patchedAssetRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Public access to detections (a.k.a. findings)
     * @param {SigmaApiPartialUpdateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public partialUpdateDetection(
        requestParameters: SigmaApiPartialUpdateDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .partialUpdateDetection(
                requestParameters.id,
                requestParameters.program,
                requestParameters.patchedDetectionRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiPartialUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public partialUpdateReport(requestParameters: SigmaApiPartialUpdateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .partialUpdateReport(
                requestParameters.id,
                requestParameters.program,
                requestParameters.patchedReportRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiPartialUpdateSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public partialUpdateSearchArea(
        requestParameters: SigmaApiPartialUpdateSearchAreaRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .partialUpdateSearchArea(requestParameters.id, requestParameters.patchedSearchAreaRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information on an OSK-supported data product
     * @param {SigmaApiPublicGetDataProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public publicGetDataProduct(requestParameters: SigmaApiPublicGetDataProductRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .publicGetDataProduct(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * List OSK-supported data products
     * @param {SigmaApiPublicListDataProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public publicListDataProducts(
        requestParameters: SigmaApiPublicListDataProductsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .publicListDataProducts(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 1-second piece of the orbit.
     * @summary Search orbital paths for opportunities
     * @param {SigmaApiSearchOrbitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public searchOrbits(requestParameters: SigmaApiSearchOrbitsRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .searchOrbits(
                requestParameters.aoi,
                requestParameters.eclipsed,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.searchTimeEnd,
                requestParameters.searchTimeStart,
                requestParameters.sensor,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public updateAsset(requestParameters: SigmaApiUpdateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .updateAsset(requestParameters.id, requestParameters.program, requestParameters.assetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Public access to detections (a.k.a. findings)
     * @param {SigmaApiUpdateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public updateDetection(requestParameters: SigmaApiUpdateDetectionRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .updateDetection(
                requestParameters.id,
                requestParameters.program,
                requestParameters.detectionRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public updateReport(requestParameters: SigmaApiUpdateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .updateReport(requestParameters.id, requestParameters.program, requestParameters.reportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiUpdateSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public updateSearchArea(requestParameters: SigmaApiUpdateSearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .updateSearchArea(requestParameters.id, requestParameters.searchAreaRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}